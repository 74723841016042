import { Icon } from 'theme/types';

export const HouseIcon: Icon = (props) => {
  const { color, ...svgProps } = props;

  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.25 7L7 18.5V41.5H41.5V18.5L24.25 7ZM20.225 37.6667L17.35 26.7417L28.275 23.8667L31.15 34.7917L20.225 37.6667Z"
        fill={color || '#0A9828'}
      />
    </svg>
  );
};
