import { addMonths } from 'date-fns';
import { NextRequest } from 'next/server';

export type ExperimentName = 'location' | 'june-cc-capture';

export interface Experiment {
  name: ExperimentName;
  cookie: string;
  url: string | ((req: NextRequest) => boolean);
  variant: (req: NextRequest) => string;
  cookieOptions?: { getExpirationDate?: () => Date };
  shouldOverride?: (req: NextRequest) => boolean;
}

export const experiments: Experiment[] = [
  createExperiment(
    'june-cc-capture',
    'experiment-june-cc-capture',
    'cc-capture',
  ),
  {
    name: 'location',
    cookie: 'location',
    url: (req) => Boolean(getLocationFromPath(req)),
    variant: (req) => getLocationFromPath(req) as string,
  },
];

function createExperiment(
  experimentName: ExperimentName,
  cookieName: string,
  queryParamToOverride?: string,
): Experiment {
  return {
    name: experimentName,
    cookie: cookieName,
    url: () => true,
    variant: (req) => {
      const variant = queryParamToOverride
        ? new URL(req.url).searchParams.get(queryParamToOverride)
        : undefined;

      // `Math.random()` seems enough for us, as we don't need cryptographically strong random values
      // https://stackoverflow.com/questions/44651537/correct-function-using-math-random-to-get-50-50-chance
      // https://stackoverflow.com/questions/21507133/how-do-you-get-exactly-50-odds
      return variant || (Math.random() < 0.5 ? '0' : '1');
    },
    shouldOverride: (req) => {
      return queryParamToOverride
        ? new URL(req.url).searchParams.has(queryParamToOverride)
        : false;
    },
    cookieOptions: {
      getExpirationDate: () => addMonths(new Date(), 2),
    },
  };
}

function getLocationFromPath(req: NextRequest) {
  const url = new URL(req.url);
  const urlFragments = url.pathname.match(
    /\/[a-z-]+-in-(?<location>[a-z-]+)\/?/,
  );

  return urlFragments?.groups?.location;
}

export interface ExperimentCookieValue {
  variant: string;
  tracked: boolean;
}
