import { isSameWeek, startOfWeek } from 'date-fns';
import dynamic from 'next/dynamic';
import React, {
  forwardRef,
  PropsWithChildren,
  useEffect,
  useMemo,
} from 'react';
import { ChangeHandler } from 'react-hook-form';
import ReactTooltip from 'react-tooltip';

import { ArrowLeftIcon, ArrowRightIcon } from 'components/icons';
import { Settings, Slider } from 'components/Slider';
import {
  groupByDay,
  groupByWeek,
  splitTimeSlotValue,
  toStartAndEndDateTime,
  toTimeSlot,
  toTimeSlotValue,
} from 'utils/calendarUtils';
import { BookableSlot } from 'utils/useBookableSlots';

import { CalendarDay } from './CalendarDay';
import { CalendarSlotInput, CalendarSlotInputProps } from './CalendarSlotInput';
import { CalendarSlotRatingInput } from './CalendarSlotRatingInput';
import { CalendarTimes, CalendarTimesProps } from './CalendarTimes';
import { CalendarWeek } from './CalendarWeek';

const DynamicTooltip = dynamic(() => import('react-tooltip'), {
  ssr: false,
});

export const extractDaySlots = (
  day: BookableSlot[],
  stopSalesDatesLookup: Record<string, boolean> = {},
) => {
  const date = day[0].startDateTime;
  const formattedSlotDate = day[0].startDateTime.toISOString().split('T')[0];
  const isStopDate = !!(formattedSlotDate in stopSalesDatesLookup);
  const slots = day.map((slot) => {
    const timeSlot = toTimeSlot(slot.startDateTime, slot.endDateTime);
    return {
      time: timeSlot.slot,
      startDateTime: slot.startDateTime,
      endDateTime: slot.endDateTime,
      available: slot.isAvailable && !isStopDate,
      isEcoSlot: slot.isRatingGood,
      isBestSlot: slot.isBestSlot,
    };
  });
  return { date, slots };
};

const weekContainsDate = (week: BookableSlot[][], date: Date): boolean => {
  if (week.length === 0 || week[0].length === 0) {
    return false;
  }
  return isSameWeek(week[0][0].startDateTime, date, { weekStartsOn: 1 });
};

type SlickButtonFixProps = {
  className?: string;
  currentSlide?: number;
  slideCount?: number;
  name?: string;
};

// Workaround: https://github.com/akiran/react-slick/issues/1195#issuecomment-390383615
const SlickButtonFix = ({
  currentSlide,
  slideCount,
  children,
  ...props
}: PropsWithChildren<SlickButtonFixProps>) => (
  <button
    {...props}
    className={`${props.className} marketplace-calendar-week__button`}
    type="button"
  >
    {children}
  </button>
);

export type SlotInfo = {
  isBestSlot?: boolean;
  isEcoSlot?: boolean;
};

export type CalendarInputProps = {
  name: string;
  bookableSlots: BookableSlot[];
  slotLabels: CalendarTimesProps['timeLabels'];
  inputRef?: CalendarSlotInputProps['inputRef'];
  initialValue?: string[] | undefined;
  value?: string[];
  onChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    slotInfo?: SlotInfo,
  ) => void;
  onBlur: ChangeHandler;
  beforeSliderIndexChange?: (current: number, next: number) => void;
  includeSlotRatings?: boolean;
  trackAppointmentPageView?: (value: Record<string, any>) => void;
  stopSalesDatesLookup?: Record<string, boolean>;
};

export const CalendarInput = forwardRef<HTMLInputElement, CalendarInputProps>(
  function CalendarInput(
    {
      name,
      bookableSlots,
      slotLabels,
      initialValue,
      value: selectedValue,
      onChange,
      onBlur,
      beforeSliderIndexChange,
      includeSlotRatings = false,
      trackAppointmentPageView,
      stopSalesDatesLookup = {},
    },
    ref,
  ) {
    const weeks = groupByWeek(groupByDay(bookableSlots));
    const firstAvailableSlot = bookableSlots.find((slot) => slot.isAvailable);

    const initialTimeSlot =
      initialValue && initialValue?.length > 0
        ? splitTimeSlotValue(initialValue[0])
        : undefined;

    const firstSlotShown =
      initialTimeSlot !== undefined
        ? toStartAndEndDateTime(initialTimeSlot[0], initialTimeSlot[1])[0]
        : firstAvailableSlot?.startDateTime;

    const initialWeekIndex = firstSlotShown
      ? weeks.findIndex((week) => weekContainsDate(week, firstSlotShown))
      : undefined;

    const sliderSettings: Settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      initialSlide: initialWeekIndex ?? 0,
      nextArrow: (
        <SlickButtonFix aria-label="Next Week">
          <ArrowRightIcon />
        </SlickButtonFix>
      ),
      prevArrow: (
        <SlickButtonFix aria-label="Previous Week">
          <ArrowLeftIcon />
        </SlickButtonFix>
      ),
      beforeChange: beforeSliderIndexChange,
    };

    const trackingMemo = useMemo(
      () => ({
        number_of_slots_shown_total: bookableSlots.filter(
          (slot) => slot.isAvailable,
        ).length,
        number_of_slots_shown_week1:
          weeks[0]?.flat().filter((slot) => slot.isAvailable).length ?? 0,
        number_of_slots_shown_week2:
          weeks[1]?.flat().filter((slot) => slot.isAvailable).length ?? 0,
        number_of_slots_shown_week3:
          weeks[2]?.flat().filter((slot) => slot.isAvailable).length ?? 0,
        slots_shown: bookableSlots.filter((slot) => slot.isAvailable),
      }),
      [bookableSlots, weeks],
    );

    useEffect(() => {
      trackAppointmentPageView?.(trackingMemo);
    }, [trackAppointmentPageView, trackingMemo]);

    const handleChange = (
      e: React.ChangeEvent<HTMLInputElement>,
      slotInfo: SlotInfo,
    ) => {
      const { isEcoSlot, isBestSlot } = slotInfo;
      onChange(e, { isBestSlot, isEcoSlot });
    };

    return (
      <>
        <Slider {...sliderSettings}>
          {weeks.map((week, weekIndex) => {
            const weekStart = startOfWeek(week[0][0].startDateTime, {
              weekStartsOn: 1,
            });
            const weekEnd = week[week.length - 1][0].startDateTime;

            const title = ['This Week', 'Next Week'][weekIndex] || null;

            const weekHasSlots = week.some((day) =>
              day.some((slot) => slot.isAvailable),
            );

            return (
              <div
                className="marketplace-calendar"
                key={weekIndex}
                data-date={weekStart.toISOString()}
              >
                <CalendarWeek
                  weekStart={weekStart}
                  weekEnd={weekEnd}
                  title={title}
                >
                  {weekHasSlots && (
                    <>
                      <CalendarTimes timeLabels={slotLabels} />
                      {week.map((day) => {
                        const { date, slots } = extractDaySlots(
                          day,
                          stopSalesDatesLookup,
                        );

                        return (
                          <CalendarDay key={date.getTime()} date={date}>
                            {slots.map((slot) => {
                              const {
                                time,
                                available,
                                isEcoSlot,
                                isBestSlot,
                                startDateTime,
                                endDateTime,
                              } = slot;
                              const value = toTimeSlotValue({
                                date,
                                slot: time,
                              });
                              return includeSlotRatings ? (
                                <CalendarSlotRatingInput
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  key={time}
                                  title={time}
                                  group={name}
                                  value={value}
                                  checked={
                                    selectedValue
                                      ? value.includes(selectedValue[0])
                                      : false
                                  }
                                  disabled={!available}
                                  startDateTime={startDateTime}
                                  endDateTime={endDateTime}
                                  ref={ref}
                                />
                              ) : (
                                <CalendarSlotInput
                                  onChange={(event) =>
                                    handleChange(event, {
                                      isBestSlot,
                                      isEcoSlot,
                                    })
                                  }
                                  onBlur={onBlur}
                                  key={time}
                                  title={time}
                                  group={name}
                                  value={value}
                                  // TODO: make sure I'm porting the latest version of booking portal
                                  // as props.checked doesn't exist on CalendarSlotInput for me locally
                                  // but it was added by Tom/Adrian while we paired
                                  checked={
                                    selectedValue
                                      ? value.includes(selectedValue[0])
                                      : false
                                  }
                                  disabled={!available}
                                  ref={ref}
                                  isEcoSlot={isEcoSlot ?? false}
                                  isBestSlot={isBestSlot ?? false}
                                />
                              );
                            })}
                          </CalendarDay>
                        );
                      })}
                    </>
                  )}

                  {!weekHasSlots && (
                    <div className="marketplace-calendar-week__empty-content">
                      <p>No slots available for this week</p>
                    </div>
                  )}
                </CalendarWeek>
              </div>
            );
          })}

          <div>
            <div className="marketplace-calendar-week">
              <h2 className="marketplace-calendar-week__date-range marketplace-calendar-week__date-range--no-title heading2">
                More Availability
              </h2>
            </div>
            <div className="marketplace-calendar-week__empty-content">
              <p>
                New availability is released each week, please come back on
                Monday to see more choices
              </p>
            </div>
          </div>
        </Slider>

        <DynamicTooltip
          event="click"
          afterShow={() => setTimeout(ReactTooltip.hide, 3000)}
          backgroundColor="black"
          offset={{ top: 0 }}
        />
      </>
    );
  },
);
