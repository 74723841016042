import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useAddressStore } from 'store';

import {
  CommonNestedProductConfigOptions,
  CommonRootProductConfigOptions,
  fetchProductConfig,
  ProductConfigResponse,
  ServerOnlyRootProductConfigOptions,
} from 'services/kantanClient';

import { PRODUCT_TYPE } from './jobTypes';
import { mapServerProductConfig } from './product/mapServerProductConfig';

export type ProductDrivenConfig = CommonRootProductConfigOptions &
  ServerOnlyRootProductConfigOptions &
  CommonNestedProductConfigOptions;

// prettier-ignore
export type ProductAndPartnerConfig =
  { product?: ProductDrivenConfig } &
  Omit<ProductConfigResponse, 'product'>;

interface UseProductConfigOptions {
  productType?: PRODUCT_TYPE;
  productId?: string;
  requestBaseUrl: string | undefined;
}

export interface ProductDrivenConfigQuery {
  isLoading: boolean;
  data?: ProductAndPartnerConfig;
}

/**
 * Meant to be used to fetch a product config.
 *
 * In order to consume the current global product config, use:
 * - `useNullableStepContext` if you need step info but it can be missing
 * - `useExistingStepContext` if you need step info and it's definitely present
 * (such as in the new EE booking flow)
 * - `useNullableProductContext` if you only need a product config
 * but it can be missing (such as in the rescheduling and cancellation flows)
 * - `useExistingProductContext` if you only need a product config
 * and you know it's present (such as in the booking flow of EE jobs)
 */
export const useProvideProductConfig = ({
  productId,
  requestBaseUrl,
}: UseProductConfigOptions): ProductDrivenConfigQuery => {
  const router = useRouter();
  const addressData = useAddressStore();

  const productConfigQuery = useCurrentServerProductConfig({
    productId,
    requestBaseUrl,
  });

  const nextAddressStep = !!addressData.line1
    ? 'confirm-address'
    : 'select-address';

  if (!productConfigQuery.data) {
    return productConfigQuery;
  }

  const serverProductConfig = mapServerProductConfig(productConfigQuery.data, {
    productType: router.query.productType as string,
    rateDomain: router.query.rateDomain as string,
    nextAddressStep,
  });

  return {
    ...productConfigQuery,
    data: serverProductConfig,
  };
};

interface UseCurrentServerProductConfigParams {
  requestBaseUrl: string | undefined;
  productId?: string;
  skip?: boolean;
}

function useCurrentServerProductConfig({
  productId,
  requestBaseUrl,
  skip,
}: UseCurrentServerProductConfigParams) {
  const router = useRouter();
  const baseUrl = getBaseUrl(requestBaseUrl);
  const url = baseUrl + router.asPath;

  if (!productId && router.query.productId) {
    productId = router.query.productId as string;
  }

  const productConfigQuery = useServerProductConfig({
    variables: { productId, url: productId ? undefined : url },
    skip,
  });

  return productConfigQuery;
}

function getBaseUrl(requestBaseUrl?: string) {
  if (typeof window === 'undefined') return requestBaseUrl ?? '';

  // replace "https://" with "http://" to be consistent with the server
  // prefetching product config and ensure a cache hit;
  // the protocol part is ignored when matching a URL to a partner/product
  // (we're only looking at the hostname and pathname)
  return window.location.origin.replace(/^https:\/\//, 'http://');
}

interface UseServerProductConfigParams {
  variables: { url?: string; productId?: string };
  skip?: boolean;
}

const HOUR_IN_MILLISECONDS = 60 * 60 * 1000;

function useServerProductConfig(params: UseServerProductConfigParams) {
  const query = useQuery({
    queryKey: ['product-config', params.variables],
    queryFn: () => fetchProductConfig(params.variables),
    enabled: !params.skip,
    staleTime: HOUR_IN_MILLISECONDS,
    // Product config gets requested each time we transition to new steps,
    // as the page URL is updated. `keepPreviousData: true` returns the old
    // `query.data` while new requests are in flight. If we were to pass `false`,
    // we'd have a blank screen instead of step transition animation,
    // as we render nothing in the EE booking flow is product config is missing.
    keepPreviousData: true,
  });

  return query;
}
