import styled from '@emotion/styled';
import { m } from 'framer-motion';
import { mediaQuery } from 'theme/kantan';

import { shouldForwardProp } from 'utils/transientStyled';

const RadioButton = styled(m.div, { shouldForwardProp })<{
  $isSelected: boolean;
}>`
  box-shadow: 0px -1px 0px 0px rgba(208, 216, 229, 1) inset;
  background-color: ${({ theme }) => theme.app.background.white};
  border-radius: ${({ theme }) => theme.app.borderRadius.largeCorner};
  width: 100%;
  height: 175px;
  padding: 16px 0px 0px 0px;
  cursor: pointer;
  margin-bottom: 20px;
  text-align: center;
  font-size: 16px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media (${mediaQuery('tablet')}) {
    width: 325px;
  }

  @media (${mediaQuery('desktop')}) {
    &:nth-of-type(1) {
      margin-right: 20px;
    }
  }

  &:hover {
    box-shadow: 0 8px 10px rgba(208, 216, 229, 0.5);
  }

  ${({ theme, $isSelected }) =>
    $isSelected &&
    `
    background-color: ${theme.app.background.selectedDark};
    color: ${theme.app.text.white};
    `}
`;

const RadioButtonContainer = styled(m.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 25px;

  @media (${mediaQuery('desktop')}) {
    flex-direction: row;
    margin-top: -20px;
  }
`;

export const CheckMarkIcon = styled(m.div)`
  color: white;
  font-size: 5px;
  width: 40px;
  height: 40x;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #0a9828;
`;

export { RadioButton, RadioButtonContainer };
