import { Icon } from 'theme/types';

export const SpannerIcon: Icon = (props) => {
  const { color = '#000000', width = 21, height = 21 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
        d="M14.2028 1.14278C11.4948 0.409414 8.61085 1.16222 6.62525 3.14781C4.80315 4.96991 4.01389 7.55473 4.47278 10.0733L1.38052 13.1655C-0.37684 14.9229 -0.37684 17.7721 1.38052 19.5295C3.13788 21.2868 5.98712 21.2868 7.74448 19.5295L10.8367 16.4372C13.3553 16.8961 15.9401 16.1068 17.7622 14.2847C19.7341 12.3128 20.491 9.45428 19.7813 6.76002C19.7717 6.72346 19.7618 6.68696 19.7516 6.65052C19.5589 5.95941 18.693 5.73208 18.1857 6.23941L15.4789 8.94619L12.5723 8.34128L12.0105 5.48163L14.7463 2.74589C15.2503 2.24188 15.0297 1.38205 14.3454 1.18284C14.298 1.16904 14.2504 1.15568 14.2028 1.14278ZM7.95108 4.47364C9.06694 3.35778 10.5587 2.75278 12.098 2.74252L10.4727 4.3678C10.1635 4.67698 10.0286 5.11983 10.1129 5.54888L10.8044 9.06894C10.9061 9.58681 11.3082 9.99337 11.8249 10.1009L15.3944 10.8438C15.8275 10.9339 16.277 10.7997 16.5899 10.4869L18.166 8.91076C18.1311 10.4155 17.5281 11.8672 16.4364 12.9589C15.018 14.3773 12.9883 14.974 11.0195 14.5626L10.2256 14.3967L6.41866 18.2037C5.39353 19.2288 3.73147 19.2288 2.70634 18.2037C1.68122 17.1785 1.68122 15.5165 2.70634 14.4913L6.51329 10.6844L6.34739 9.89045C5.936 7.9217 6.53273 5.89199 7.95108 4.47364ZM4.125 15.3049C3.84886 15.3049 3.625 15.5288 3.625 15.8049V16.6799C3.625 16.956 3.84886 17.1799 4.125 17.1799H5C5.27614 17.1799 5.5 16.956 5.5 16.6799V15.8049C5.5 15.5288 5.27614 15.3049 5 15.3049H4.125Z"
      />
    </svg>
  );
};
