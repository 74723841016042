import { useRouter } from 'next/router';
import { useState } from 'react';
import { FormProvider, useController, useForm } from 'react-hook-form';
import { useGenericStore } from 'store';
import theme from 'theme/theme';

import { BaseRadioButtonContainer } from 'components/BaseRadioButtonContainer';
import { BaseSelectionView } from 'components/BaseSelectionView';
import { IconsAboveLabelButton } from 'components/IconsAboveLabelButton';
import { useExistingStepContext } from 'pages/[productType]/[rateDomain]/[step]';
import { stepSlideTransitionProps } from 'utils/framerMotion';
import { trackEvent } from 'utils/tracking';

import { InnerContainer, OuterContainer } from '../ReusedComponents.styles';

export type Icon = {
  name: keyof typeof theme.app.icons;
  opacityWhenSelected?: number;
};

type BoilerType = {
  value: string;
  icons?: Icon[];
  labelNode: () => JSX.Element;
  tooltipMessage?: string;
  maxHeightMobile?: string;
  gridColumnMobile?: string;
};

const boilerTypes: BoilerType[] = [
  {
    value: 'Combi Boiler',
    icons: [{ name: 'combiBoiler', opacityWhenSelected: undefined }],
    labelNode: () => <p>Combi Boiler</p>,
    tooltipMessage:
      'You do not have a hot water cylinder and water is heated on demand.',
  },
  {
    value: 'System Boiler',
    icons: [
      { name: 'combiBoiler', opacityWhenSelected: undefined },
      { name: 'systemBoiler', opacityWhenSelected: 0.3 },
    ],
    labelNode: () => <p>System Boiler</p>,
    tooltipMessage:
      'You have a hot water cylinder to store heated water for later use.',
  },
  {
    value: 'Not sure',
    labelNode: () => <p>Not sure</p>,
    maxHeightMobile: '54px',
    gridColumnMobile: '1 / -1',
  },
];

export const SelectBoilerType = () => {
  const context = useExistingStepContext();
  const { currentStep } = context;
  const { nextStep } = currentStep.options;

  const [isNavigating, setIsNavigating] = useState(false);
  const router = useRouter();

  const { metadata, setState } = useGenericStore((state) => ({
    metadata: state.metadata,
    setState: state.setState,
  }));

  const form = useForm({
    mode: 'all',
    defaultValues: { boilerType: metadata.boilerType },
  });
  const { boilerType } = form.getValues();

  const {
    field: { onChange },
  } = useController({
    name: 'boilerType',
    control: form.control,
  });

  const handleClickButton = (boilerType: string) => {
    onChange(boilerType);
    trackEvent('Boiler type selected', {
      boiler_type: boilerType,
    });
    updateStore(boilerType);
    setIsNavigating(true);
    void router.push(nextStep);
  };

  const updateStore = (boilerType: string) => {
    setState({ metadata: { ...metadata, boilerType } });
  };

  return (
    <OuterContainer {...stepSlideTransitionProps}>
      <InnerContainer $padding="90px 162px 44px">
        <FormProvider {...form}>
          <BaseSelectionView maxOptionsPerRowDesktop={boilerTypes.length}>
            {boilerTypes.map((option) => {
              const {
                value,
                icons,
                labelNode,
                tooltipMessage,
                maxHeightMobile,
                gridColumnMobile,
              } = option;

              return (
                <BaseRadioButtonContainer
                  key={value}
                  value={value}
                  name={'boilerType'}
                  isSelected={value === boilerType}
                  required={true}
                  onChange={() => handleClickButton(value)}
                  disabled={isNavigating}
                  maxHeightMobile={maxHeightMobile}
                  gridColumnMobile={gridColumnMobile}
                  tooltipMessage={tooltipMessage}
                >
                  <IconsAboveLabelButton
                    icons={icons}
                    label={labelNode}
                    isSelected={value === boilerType}
                  />
                </BaseRadioButtonContainer>
              );
            })}
          </BaseSelectionView>
        </FormProvider>
      </InnerContainer>
    </OuterContainer>
  );
};
