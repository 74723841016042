import { Icon } from 'theme/types';

export const PowerLead: Icon = (props) => {
  const { color = '#0A9828', ...svgProps } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      {...svgProps}
    >
      <path
        d="M24 14.5C24 19.725 28.275 24 33.5 24C38.725 24 43 19.725 43 14.5H24Z"
        fill={color}
      />
      <path
        opacity="0.3"
        d="M37.5714 14.5L43.0339 14.5L43.0339 5L37.5714 5L37.5714 14.5Z"
        fill={color}
      />
      <path
        opacity="0.3"
        d="M23.966 14.5L29.4285 14.5L29.4285 5L23.966 5L23.966 14.5Z"
        fill={color}
      />
      <path
        opacity="0.3"
        d="M32.075 14.5342L34.8063 14.5342L34.8063 9.07168L32.075 9.07168L32.075 14.5342Z"
        fill={color}
      />
      <path
        d="M20.4375 43C28.9875 43 35.875 36.1125 35.875 27.5625V21.625H31.125V27.5625C31.125 33.5 26.375 38.25 20.4375 38.25C14.5 38.25 9.75 33.5 9.75 27.5625H5C5 36.1125 11.8875 43 20.4375 43Z"
        fill={color}
      />
    </svg>
  );
};
