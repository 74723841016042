import { Context, createContext, useContext } from 'react';

export const createSafeContext = <T>(
  name: string,
): [Context<T | null>, () => T] => {
  const context = createContext<T | null>(null);
  const useContextValue = () => {
    const maybeValue = useContext(context);
    if (!maybeValue) {
      throw new Error(
        `No ${name} provider in scope. Provide a ${name} provider in a parent component.`,
      );
    }
    return maybeValue;
  };
  return [context, useContextValue];
};
