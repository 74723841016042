import { useTheme } from '@emotion/react';
import theme from 'theme/theme';

import {
  Container,
  IconsContainer,
  Title,
} from './IconsAboveLabelButton.styles';

type Icon = {
  name: keyof typeof theme.app.icons;
  opacityWhenSelected?: number;
};

export type ServiceTypeIconAndTextProps = {
  icons?: Icon[];
  label: string | (() => JSX.Element);
  isSelected: boolean;
};

export const IconsAboveLabelButton = ({
  icons,
  label,
  isSelected,
}: ServiceTypeIconAndTextProps) => {
  const theme = useTheme();

  const renderLabel = () => {
    return typeof label === 'string' ? label : label();
  };

  return (
    <Container data-testid="IconsAboveLabelButton" $isSelected={isSelected}>
      <IconsContainer>
        {icons?.map((icon) => {
          const { name, opacityWhenSelected } = icon;
          return (
            <div key={icon.name}>
              {theme.app.icons[name]({
                color: isSelected
                  ? theme.app.text.white
                  : theme.app.background.selected,
                secondarycolor: isSelected
                  ? theme.app.text.white
                  : theme.app.background.selected,
                opacity: isSelected ? opacityWhenSelected : undefined,
                height: 48,
              })}
            </div>
          );
        })}
      </IconsContainer>
      <Title $isSelected={isSelected}>{renderLabel()}</Title>
    </Container>
  );
};
