import styled from '@emotion/styled';
import { RefCallBack } from 'react-hook-form';

const StyledContainer = styled.div`
  position: relative;
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 10px;
`;

export const StyledLabel = styled.label`
  display: inline-block;
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[14]};
  line-height: 1.7;
  padding: 8px 12px;
  border-radius: ${({ theme }) => theme.app.borderRadius.smallCorner};
  background-color: ${({ theme }) => theme.app.background.white};
  box-shadow: inset 0px -1px 0px #d0d8e5;
`;

interface StyledInputProps {
  $isCalloutsV2: boolean;
}

const StyledInput = styled.input<StyledInputProps>`
  cursor: pointer;
  appearance: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 0;

  @supports not (appearance: none) {
    border: none;
  }

  &:focus-visible {
    outline: 1px solid ${({ theme }) => theme.app.border.focus};
    border-radius: ${({ theme }) => theme.app.borderRadius.smallCorner};
  }

  ${({ $isCalloutsV2, theme }) =>
    $isCalloutsV2
      ? `&:checked + label {
      background: ${theme.app.background.selectedDark};
      color: ${theme.app.text.white};
    }`
      : `&:checked + label {
      background: ${theme.app.background.selected};
      color: ${theme.app.text.white};
    }`}
`;

interface OptionInputProps {
  type: string;
  name: string;
  value?: string;
  label?: string;
  checked?: boolean;
  isCalloutsV2?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
  inputRef?: RefCallBack;
}

export const OptionInput = ({
  type,
  name,
  value,
  label,
  checked,
  isCalloutsV2 = false,
  onChange,
  onClick,
  inputRef,
}: OptionInputProps) => (
  <StyledContainer>
    <StyledInput
      ref={inputRef}
      type={type}
      name={name}
      value={value}
      checked={checked}
      $isCalloutsV2={isCalloutsV2}
      onChange={onChange}
      onClick={onClick}
    />
    <StyledLabel htmlFor={value} key={`${value}-label`}>
      {label || value || name}
    </StyledLabel>
  </StyledContainer>
);
