import styled from '@emotion/styled';
import { mediaQuery } from 'theme/kantan';

export const OuterContainer = styled.div`
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 110px;

  @media (${mediaQuery('tablet')}) {
    display: none;
  }
`;
export const TrackerMobileContainer = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  background-color: ${({ theme }) => theme.app.background.white};
`;
export const Logo = styled.div`
  padding: ${({ theme }) => theme.app.padding.mediumPadding};

  svg {
    width: 104px;
    height: 28px;
    fill: ${({ theme }) => theme.app.text.action};
  }
`;
export const StepsContainer = styled.div<{ $isLastStep?: boolean }>`
  flex-grow: 1;

  display: flex;
  flex-flow: column;
  align-items: start;
  justify-content: ${({ $isLastStep }) => $isLastStep && 'center'};

  padding: 13px ${({ theme }) => theme.app.padding.mediumPadding};
`;
export const StepsCounter = styled.span`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[12]};
  line-height: 1.33;
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.light};
  color: ${({ theme }) => theme.app.landingPage.tracker.stepsCounter};
`;
export const StepsTitle = styled.span`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
  line-height: 1.25;
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.semiBold};
  color: ${({ theme }) => theme.app.landingPage.tracker.stepsTitle};
`;
export const SubTitle = styled.span<{ $backgroundColor?: string }>`
  padding: ${({ theme }) => theme.app.spacing[16]};
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
  background-color: ${({ theme, $backgroundColor }) =>
    $backgroundColor ?? theme.app.background.layoutContainerMobile};
  display: block;
`;

export const ProgressContainer = styled.div`
  width: 100vw;
  color: ${({ theme }) => theme.app.messages.logo};
`;
