import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { m } from 'framer-motion';
import { mediaQuery } from 'theme/kantan';

import { shouldForwardProp } from 'utils/transientStyled';

export const TrackerGrid = styled('div', { shouldForwardProp })<{
  $hasSteps?: boolean;
  $simplified?: boolean;
  $borderRadius?: string;
}>`
  width: 100%;
  max-width: ${({ $simplified }) => ($simplified ? '100%' : '990px')};
  border-radius: ${({ theme, $borderRadius }) =>
    $borderRadius || theme.app.borderRadius.largeCorner};
  overflow: hidden;

  @media (${mediaQuery('tablet')}) {
    display: grid;
    grid-template-columns: ${({ $hasSteps = true }) =>
      $hasSteps
        ? '[logo-price] 2fr [steps] repeat(4, 1fr) [checkmark] 0.5fr'
        : '[logo-price] 2fr'};
    grid-template-rows: [logo-price] 80px [content] auto;

    ${({ $simplified }) =>
      $simplified &&
      `
        grid-template-rows: [logo-price] 60px [content] auto;
        grid-template-columns: repeat(4, 1fr);
    `}
  }
`;

const TopRowStyles = styled.div`
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BottomRowStyles = css`
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(20px);

  @media (${mediaQuery('tablet')}) {
    background-color: rgba(255, 255, 255, 0.7);
  }
`;

const BaseHeaderStyles = styled.h1`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[24]};
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.semiBold};
`;

export const SubHeader = styled(BaseHeaderStyles)<{ $variant?: string }>`
  color: ${({ theme }) => theme.app.text.primary};
  display: ${({ $variant }) => ($variant === 'mobile' ? 'block' : 'none')};
  padding: ${({ theme }) => theme.app.padding.mediumPadding};
  padding-left: 0;
  letter-spacing: -0.02em;
  
  @media (${mediaQuery('tablet')}) {
    padding: ${({ theme }) => theme.app.padding.mediumLargePadding}
      ${({ theme }) => theme.app.padding.largePadding};

    display: ${({ $variant }) => ($variant === 'mobile' ? 'none' : 'block')};
`;

export const Header = styled(BaseHeaderStyles)<{ $isOVOPartner?: boolean }>`
  color: ${({ theme }) => theme.app.text.action};
  padding-top: 2px;

  ${({ $isOVOPartner, theme }) =>
    $isOVOPartner &&
    `
    color: ${theme.app.messages.logo};
    font-weight: ${theme.app.fontSettings.fontWeight.regular};
    font-size: ${theme.app.fontSettings.fontSize[24]};
    line-height: 1;
    text-transform: lowercase;
    margin-left: ${theme.app.margin.smallMargin};
    margin-top: -5px;
  `}
`;

export const TrackerTypography = styled.p<{
  $isActive?: boolean;
  $hasBeenVisited?: boolean;
  $variant?: string;
  $simplified?: boolean;
  $isGreyedOut?: boolean;
}>`
  user-select: none;
  color: ${({ theme, $simplified }) =>
    $simplified
      ? theme.app.landingPage.tracker.simplifiedStepsTitle
      : theme.app.text.tertiary};
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.light};
  line-height: 1.25;

  ${({ $hasBeenVisited, theme }) =>
    $hasBeenVisited &&
    `
    color: ${theme.app.messages.action};
    font-weight: ${theme.app.fontSettings.fontWeight.light};
  `}

  ${({ $isActive, theme }) =>
    $isActive &&
    `
      color: ${theme.app.messages.action};
      font-size: ${theme.app.fontSettings.fontSize[16]};
      font-weight: ${theme.app.fontSettings.fontWeight.bold};
  `}

  ${({ $isGreyedOut = false, theme }) =>
    $isGreyedOut &&
    `
      color: #D1D6DE;
      font-weight: ${theme.app.fontSettings.fontWeight.light};
  `}

   ${({ $variant }) =>
    $variant === 'inactive' &&
    `
    opacity: 0.1;
  `}
`;
export const VerticalDivider = styled.div`
  height: 100%;
  width: 1px;
  margin-left: auto;
  background-color: ${({ theme }) => theme.app.border.secondary};
`;

export const PriceContainer = styled(TopRowStyles)`
  position: relative;
  display: flex;
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[18]};
  line-height: 1.1;
  letter-spacing: -0.02em;

  @media (${mediaQuery('tablet')}) {
    z-index: 1;

    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[24]};
    line-height: 1.33;
  }
`;

export const Price = styled.h2<{ $isCombo?: boolean }>`
  padding: ${({ theme }) => theme.app.padding.mediumPadding};
  color: ${({ theme, $isCombo }) =>
    $isCombo
      ? theme.app.text.comboPrice
      : theme.app.landingPage.light.priceSection};
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.semiBold};

  > span {
    font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.lightest};
  }

  > strong > *:first-of-type {
    color: ${({ theme, $isCombo }) =>
      $isCombo ? theme.app.text.warning : theme.app.text.success};
  }

  #strike-through {
    color: ${({ theme }) => theme.app.text.tertiary};
    text-decoration: line-through;
  }

  @media (${mediaQuery('tablet')}) {
    padding: 0;
    padding-left: ${({ theme, $isCombo }) =>
      $isCombo
        ? theme.app.padding.smallPadding
        : theme.app.padding.mediumPadding};
  }

  @media (${mediaQuery('desktop')}) {
    padding-left: ${({ theme, $isCombo }) =>
      $isCombo
        ? theme.app.padding.smallPadding
        : theme.app.padding.largePadding};
  }
`;

export const VATSubscript = styled.span`
  @media (${mediaQuery('tablet')}) {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[18]};
  }
`;

const HideOnMobile = css`
  display: none;

  @media (${mediaQuery('tablet')}) {
    display: flex;
  }
`;

export const Logo = styled.a`
  display: none;
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
  align-items: center;
  cursor: pointer;

  [href] {
    cursor: pointer;
  }

  @media (${mediaQuery('tablet')}) {
    display: flex;
    position: relative;
    z-index: 1;
    gap: ${({ theme }) => theme.app.padding.smallPadding};

    svg:first-of-type {
      margin-left: ${({ theme }) => theme.app.margin.largeMargin};
    }
  }
`;

export const Step = styled(TopRowStyles)<{ $simplified?: boolean }>`
  ${HideOnMobile};
  position: relative;
  z-index: 1;

  ${({ $simplified, theme }) =>
    $simplified &&
    `
    justify-content: flex-start;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 4px;
      background: #D1D6DE;
      bottom: 0;
      left: 0;
    }
    
    p {
      font-size: ${theme.app.fontSettings.fontSize[18]};
      padding: ${theme.app.padding.mediumLargePadding};
      color: ${theme.app.landingPage.tracker.simplifiedStepsTitle};
      width: 100%;
    }
  `}
  }
`;

export const Check = styled(Step)``;

export const Content = styled.ul`
  padding: ${({ theme }) => theme.app.padding.mediumPadding};

  > li {
    margin-bottom: ${({ theme }) => theme.app.margin.mediumMargin};
  }

  @media (${mediaQuery('tablet')}) {
    padding: ${({ theme }) => theme.app.padding.mediumLargePadding} 0
      ${({ theme }) => theme.app.padding.mediumLargePadding}
      ${({ theme }) => theme.app.padding.largePadding};
  }
`;

export const ContentEntry = styled.li`
  display: flex;
  align-items: center;
`;

export const ContentEntryText = styled.p<{ $regularText?: boolean }>`
  color: ${({ theme }) => theme.app.text.primary};
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
  line-height: 1.5;
  letter-spacing: -0.04em;
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.lightest};
  margin-left: ${({ theme }) => theme.app.margin.mediumMargin};

  strong {
    ${({ theme, $regularText }) =>
      $regularText &&
      `
      font-weight: ${theme.app.fontSettings.fontWeight.lightest};
    `}
  }

  @media (${mediaQuery('tablet')}) {
    color: ${({ theme, $regularText }) =>
      $regularText ? theme.app.messages.black : theme.app.text.actionDark};
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[20]};
    line-height: 1.33;
  }
`;

export const InputBox = styled.div<{ $simplified?: boolean }>`
  height: 100%;
  display: flex;
  flex-flow: row;
  padding: ${({ theme }) => theme.app.padding.mediumPadding};

  @media (${mediaQuery('tablet')}) {
    align-items: center;
    padding: ${({ theme }) => theme.app.padding.mediumLargePadding}
      ${({ theme }) => theme.app.padding.largePadding};
  }

  ${({ $simplified, theme }) =>
    $simplified &&
    `
    margin: 0 auto;
    padding: ${theme.app.padding.mediumPadding} 0;
    background: white;
    width: 100%;

    @media (${mediaQuery('tablet')}) {
      padding: 0;
      width: auto;
    }

  `}
`;

export const LandingPageContentBox = styled.div<{ $simplified?: boolean }>`
  ${BottomRowStyles};
  grid-row: content;
  grid-column: 1 / -1;
  display: flex;
  flex-flow: column-reverse;

  @media (${mediaQuery('tablet')}) {
    flex-flow: row;
    justify-content: space-between;
  }

  ${({ $simplified, theme }) =>
    $simplified &&
    `
      backdrop-filter: blur(0);
      background: ${theme.app.background.white};

      @media (${mediaQuery('tablet')}) {
        background: ${theme.app.background.white};
      }
    `}
`;

export const HeaderContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.7);
  grid-row: content;
  grid-column: 1 / -1;
  display: flex;
  flex-flow: column-reverse;

  @media (${mediaQuery('tablet')}) {
    flex-flow: row;
    justify-content: space-between;
  }
`;

export const Progress = styled(m.div, { shouldForwardProp })<{
  $variant?: string;
  $isKantan?: boolean;
  $borderRadius?: string;
}>`
  position: absolute;
  bottom: 0;
  background-color: ${({ theme, $isKantan }) =>
    $isKantan ? theme.app.messages.actionBase : theme.app.border.focus};
  height: 4px;
  width: 102%;
  border-radius: ${({ theme, $borderRadius }) =>
    $borderRadius || theme.app.borderRadius.largeCorner};
  transform-origin: left;

  ${({ $variant }) =>
    $variant === 'inactive' &&
    `
    opacity: 0.1;
  `}
`;

export const FlexWrappedSpan = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  column-gap: ${({ theme }) => theme.app.margin.smallMargin};

  @media (${mediaQuery('tablet')}) {
    column-gap: ${({ theme }) => theme.app.spacing[6]};
    row-gap: ${({ theme }) => theme.app.spacing[6]};
    padding: ${({ theme }) => theme.app.padding.smallPadding} 0;
  }
`;

export const WhiteBoldText = styled.span`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[20]};
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.semiBold};
  line-height: 1.2;

  @media (${mediaQuery('desktop')}) {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[32]};
    line-height: 1;
  }
`;

export const WhiteLineThroughText = styled.span`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.lightest};
  line-height: ${({ theme }) => theme.app.spacing[22]};
  text-decoration: line-through;

  @media (${mediaQuery('desktop')}) {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[26]};
    line-height: 1.15;
  }
`;

export const WhiteBoldSmallerText = styled(WhiteBoldText)`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[14]};

  @media (${mediaQuery('desktop')}) {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[26]};
  }
`;
