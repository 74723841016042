import { useRouter } from 'next/router';
import { useState } from 'react';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { useGenericStore } from 'store';

import { NavigationButtonsContainer } from 'components/NavigationButtonsContainer';
import { useExistingStepContext } from 'pages/[productType]/[rateDomain]/[step]';
import { stepSlideTransitionProps } from 'utils/framerMotion';
import { constructPath } from 'utils/product/constructPath';
import { trackEvent } from 'utils/tracking';

import { OuterContainer } from '../ReusedComponents.styles';
import { YourHomeQuestion } from '../YourHomeQuestion';
import { useYourHomeOptions } from './hooks/useYourHomeOptions';
import {
  InlineTextContainer,
  InnerContainer,
  QuestionsContainer,
  SupplementaryText,
} from './YourHome.styles';

export type YourHomeQuestionKey =
  | 'is_your_heating_working?'
  | 'do_you_have_a_working_internet_connection?'
  | 'do_you_have_a_smart_phone?'
  | 'do_you_have_underfloor_heating_in_your_property?';

type YourHomeQuestionLabel =
  | 'Is your heating working?'
  | 'Do you have a working internet connection?'
  | 'Do you have a smart phone?'
  | 'Do you have underfloor heating in your property?';

export type YourHomeOption = {
  key: YourHomeQuestionKey;
  label: YourHomeQuestionLabel;
  requiredForEligibility: boolean;
  marginBottom: {
    mobile: number;
    tablet: number;
  };
  supplementaryText?: (
    form: UseFormReturn<Record<YourHomeQuestionKey, string>>,
  ) => JSX.Element | undefined;
};

export const YourHome = () => {
  const context = useExistingStepContext();
  const { currentStep } = context;
  const { nextStep, prevStep } = currentStep.options;

  const [isNavigating, setIsNavigating] = useState(false);
  const router = useRouter();

  const { metadata, setState } = useGenericStore((state) => ({
    metadata: state.metadata,
    setState: state.setState,
  }));

  const { yourHomeOptions } = useYourHomeOptions();
  const yourHomeDefaultValues = yourHomeOptions.reduce(
    (result: Record<string, YourHomeQuestionKey | undefined>, item) => {
      result[item.key] = undefined;
      return result;
    },
    {},
  );
  const form = useForm<Record<YourHomeQuestionKey, string>>({
    mode: 'all',
    defaultValues: yourHomeDefaultValues,
  });

  const updateStore = (yourHome: Record<YourHomeQuestionKey, string>) => {
    setState({ metadata: { ...metadata, yourHome } });
  };

  const yourHome = form.getValues();
  const isEligible = checkAnswersForEligiblity(yourHome, yourHomeOptions);

  const onClickRightButton = () => {
    setIsNavigating(true);

    trackEvent('Smart thermostat home information selected', {
      smart_thermostat_home_information: yourHome,
    });
    updateStore(yourHome);

    if (isEligible) {
      void router.push(nextStep);
    } else {
      const ineligiblePath = constructPath(router, 'ineligible');
      void router.push(ineligiblePath);
    }
  };

  const onClickLeftButton = () => {
    void router.push(prevStep);
  };

  return (
    <OuterContainer {...stepSlideTransitionProps}>
      <InnerContainer $padding="56px 64px 43px" $alignItems="start">
        <FormProvider {...form}>
          <QuestionsContainer>
            {yourHomeOptions.map((option: YourHomeOption) => (
              <YourHomeQuestion key={option.key} option={option} />
            ))}
            {maybeRenderConfirmKitOrderedText(
              isEligible && form.formState.isValid,
            )}
          </QuestionsContainer>
          <NavigationButtonsContainer
            disabled={!form.formState.isValid}
            isBusy={isNavigating}
            onClickRightButton={onClickRightButton}
            onClickLeftButton={onClickLeftButton}
          />
        </FormProvider>
      </InnerContainer>
    </OuterContainer>
  );
};

const checkAnswersForEligiblity = (
  yourHomeInputs: Record<YourHomeQuestionKey, string>,
  yourHomeOptions: YourHomeOption[],
) => {
  let isEligible = true;

  Object.entries(yourHomeInputs).forEach((el) => {
    const isYesRequired = yourHomeOptions.find(
      (option) => option.key === el[0],
    )?.requiredForEligibility;

    if (isYesRequired && el[1] !== 'Yes') {
      isEligible = false;
      return;
    }
  });

  return isEligible;
};

const maybeRenderConfirmKitOrderedText = (isEligible: boolean) => (
  <InlineTextContainer>
    {isEligible && (
      <SupplementaryText>
        By clicking Next you confirm that you have ordered the Wireless tado°
        Smart Thermostat and this appointment is for the fitting of one Wireless
        tado° Smart Thermostat only
      </SupplementaryText>
    )}
  </InlineTextContainer>
);
