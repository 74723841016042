import { Icon } from 'theme/types';

export const GasSafetyCertificateOVOIcon: Icon = (props) => {
  const { color = '#0A9828', width = 20, height = 20, ...svgProps } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        opacity="0.3"
        d="M10.8444 2.24207L9.15555 0.5H0.5V17.7583H2.24167V2.24167H10.8444Z"
        fill={color}
      />
      <path
        d="M11.7417 2.2417H2.2417V19.5H14.4861C12.85 19.5 11.425 18.8139 10.3695 17.7584C9.31392 16.7028 8.68059 15.2778 8.68059 13.6945C8.68059 13.0084 8.78614 12.3223 9.05003 11.7417H4.88059V10H10C11.0556 8.73337 12.5861 7.9417 14.3278 7.88892C14.3806 7.88892 14.4334 7.88892 14.4861 7.88892C15.0139 7.88892 15.5417 7.99448 16.0695 8.10003V6.5167L11.7417 2.2417ZM8.25837 13.4306V15.1723H4.82781V13.4306H8.25837Z"
        fill={color}
      />
      <path
        d="M14.2223 17.3889L11.0557 13.8528L12.639 12.4278L14.2223 14.1694L17.9168 10L19.5001 11.425L14.2223 17.3889Z"
        fill={color}
      />
    </svg>
  );
};
