import Image from 'next/legacy/image';
import { useRouter } from 'next/router';
import ConfirmationSquareIcon from 'public/assets/svg/ovo/ConfirmationSquareIcon.svg';
import ConfirmationSquareIconWhite from 'public/assets/svg/ovo/ConfirmationSquareIconWhite.svg';
import ExternalLink from 'public/assets/svg/ovo/ExternalLink.svg';
import { useState } from 'react';

import { useExistingStepContext } from 'pages/[productType]/[rateDomain]/[step]';
import { stepSlideTransitionProps } from 'utils/framerMotion';

import { InnerContainer, OuterContainer } from '../ReusedComponents.styles';
import {
  RadioButton,
  RadioButtonContainer,
} from './ConfirmTadoPurchase.styles';

export const ConfirmTadoPurchase = () => {
  const router = useRouter();
  const context = useExistingStepContext();
  const { currentStep } = context;
  const [isSelected, setIsSelected] = useState(false);

  const { nextStep } = currentStep.options;

  const navigateToNextStep = () => {
    setIsSelected(true);
    router.push(nextStep);
  };

  const navigateToTadoPurchase = () => {
    window.open(
      `https://account.ovoenergy.com/offers/smart-thermostats/compatibility`,
      '_blank',
    );
  };
  return (
    <OuterContainer {...stepSlideTransitionProps}>
      <InnerContainer $padding="20px 0px 0px 0px">
        <RadioButtonContainer>
          <RadioButton $isSelected={isSelected} onClick={navigateToNextStep}>
            <Image
              src={
                isSelected
                  ? ConfirmationSquareIconWhite
                  : ConfirmationSquareIcon
              }
              width={40}
              height={40}
              alt="icon"
            />
            Yes, proceed with booking the installation
          </RadioButton>
          <RadioButton $isSelected={false} onClick={navigateToTadoPurchase}>
            <Image src={ExternalLink} width={40} height={40} alt="icon" />
            No, take me to purchase my tado° thermostat
          </RadioButton>
        </RadioButtonContainer>
      </InnerContainer>
    </OuterContainer>
  );
};
