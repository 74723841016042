import * as React from 'react';

import { IconBase } from './utils';

export const CheckboxSelectedOutlineIcon: IconBase = (props) => {
  const { color, ...svgProps } = props;

  return (
    <svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...svgProps}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 3h14c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2Zm14 16V5H5v14h14Z"
        fill={color || '#0B4ED5'}
      />
      <path
        d="m17.99 9-1.41-1.42-6.59 6.59-2.58-2.57-1.42 1.41 4 3.99 8-8Z"
        fill={color || '#0B4ED5'}
      />
    </svg>
  );
};
