import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useMediaQuery } from '@mui/material';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useEnergyExpertsStore, useGenericStore } from 'store';
import { useHasStoreHydrated } from 'store/utils';
import { mediaQuery } from 'theme/energy_experts';

import { InfoIcon } from 'components/icons/InfoIcon';
import { NavigationButtonsContainer } from 'components/NavigationButtonsContainer';
import { OnChange } from 'components/redesign/AddressStep/AddressInputManual';
import { MuiTextField } from 'components/redesign/MuiTextField';
import { InputChangeEvent } from 'components/redesign/MuiTextField/MuiTextField';
import { StrongText } from 'components/StrongText';
import { DEFAULT_CURRENCY } from 'layouts/MarketingLayout/MarketingLayout';
import { useExistingStepContext } from 'pages/[productType]/[rateDomain]/[step]';
import { StepOptions } from 'services/kantanClient';
import { stepSlideTransitionProps } from 'utils/framerMotion';
import { JOB_TYPES, mapJobTypeToProductType } from 'utils/jobTypes';
import { contactDetailEntryOptions } from 'utils/residentDetails';
import { splitStringOnFirstInstanceOfCharacter } from 'utils/splitStringOnFirstInstanceOfCharacter';
import { getPriceFromJobType, grabGclid, trackEvent } from 'utils/tracking';
import { useManagedRates } from 'utils/useManagedRates';

import { AddressStyles } from '.';

export const ResidentDetails = (stepConfigOptions: StepOptions) => {
  const { OuterContainer } = AddressStyles;
  const theme = useTheme();
  const isTabletOrBigger = useMediaQuery(mediaQuery('tablet', true));

  const context = useExistingStepContext();
  const {
    productConfig: {
      useGenericStoreData: shouldUseGenericStoreData,
      temporaryJobType: jobType = JOB_TYPES.HOME_ENERGY_ASSESSMENT,
      metadataFields: additionalMetadataOptions,
      customSupportingInfoText,
    },
  } = context;

  const [genericData, setGenericData] = useGenericStore((state) => [
    state,
    state.setState,
  ]);
  const [energyExpertsData, setEnergyExpertsData] = useEnergyExpertsStore(
    (state) => [state, state.setState],
  );

  // TODO: use generic store for EE
  const storeData = shouldUseGenericStoreData ? genericData : energyExpertsData;

  const setStoreData = shouldUseGenericStoreData
    ? setGenericData
    : setEnergyExpertsData;

  const form = useForm({
    mode: 'all',
    defaultValues: storeData,
  });

  const { reset, formState, handleSubmit } = form;

  const hasStoreHydrated = useHasStoreHydrated();
  const hasFormReset = useRef(false);

  useEffect(() => {
    if (!hasFormReset.current && hasStoreHydrated) {
      reset(storeData, {
        keepErrors: true,
      });
      hasFormReset.current = true;
    }
  }, [hasStoreHydrated, reset, storeData]);

  const handleFieldChange = (event: InputChangeEvent, onChange: OnChange) => {
    const { name, value } = event.target;
    onChange(value);
    setStoreData({ ...storeData, [name]: value });
  };

  const handleMetadataFieldChange = (
    event: InputChangeEvent,
    onChange: OnChange,
  ) => {
    const { name, value } = event.target;
    onChange(value);

    // in order for the config-driven metadata fields to work with react-hook-form,
    // we need to set their names to 'metadata.insertKeyName'
    // however when saving to store, we want just 'insertKeyName' as the key name used, hence using name.split('.')[1]
    const metadataKey = splitStringOnFirstInstanceOfCharacter(name, '.');
    setStoreData({
      ...storeData,
      metadata: { ...storeData.metadata, [metadataKey]: value },
    });
  };

  const [isNavigating, setIsNavigating] = useState(false);
  const router = useRouter();
  const managedRates = useManagedRates();

  const onSubmit = async () => {
    setIsNavigating(true);
    trackEvent('Resident details submitted', {
      product_type: mapJobTypeToProductType(jobType),
      job_type: jobType,
      price: getPriceFromJobType(jobType, managedRates).amount,
      currency: DEFAULT_CURRENCY,
      gclid: grabGclid(router),
    });
    void router.push({
      pathname: stepConfigOptions.nextStep,
    });
  };

  const prevStep = context.currentStep.options.prevStep;
  const onClickLeftButton = () => {
    void router.push(prevStep);
  };

  return (
    <OuterContainer {...stepSlideTransitionProps}>
      <FormProvider {...form}>
        <MainContent onSubmit={handleSubmit(onSubmit)}>
          <InfoText>
            <StrongText weight="semiBold">
              {isTabletOrBigger
                ? 'Let us know how we can contact you about your booking'
                : 'Who can we contact about your booking?'}
            </StrongText>
          </InfoText>
          <MainBody>
            <TextFieldsContainer>
              {contactDetailEntryOptions.map((option) => (
                <MuiTextField
                  key={option.name}
                  option={option}
                  handleFieldChange={handleFieldChange}
                  disabled={
                    option.canBeDisabled && !genericData.userCanEditDetails
                  }
                />
              ))}
              {additionalMetadataOptions &&
                additionalMetadataOptions
                  .filter(({ name }) => {
                    // if customerReferenceId is present, we don't want to show the ovoAccountNumber field as it was supplied via the query string. when token is used this screen is bypassed as details are extracted from the token.
                    if (storeData.customerReferenceId) {
                      return name !== 'metadata.ovoAccountNumber';
                    } else {
                      return true;
                    }
                  })
                  .map((option) => (
                    <MuiTextField
                      key={option.name}
                      option={option}
                      handleFieldChange={handleMetadataFieldChange}
                    />
                  ))}

              <MuiTextField
                option={{
                  name: 'metadata.additionalResidentInfo',
                  label: 'Anything else we should know?',
                  type: 'text',
                  maxLength: 250,
                }}
                handleFieldChange={handleMetadataFieldChange}
              />
            </TextFieldsContainer>
            <SupportingInfoContainer>
              <InfoIconContainerDesktop>
                <InfoIcon
                  {...{ color: theme.app.background.supplementaryInfo }}
                />
              </InfoIconContainerDesktop>
              <InfoIconContainerMobile>
                <InfoIcon
                  {...{
                    width: 16,
                    height: 16,
                    color: theme.app.background.supplementaryInfo,
                  }}
                />
              </InfoIconContainerMobile>
              <SupportingInfoText>
                <p>
                  {customSupportingInfoText
                    ? customSupportingInfoText
                    : `We'll only use these details to confirm your appointment.
                  Your information won't be used for any marketing purposes
                  or added to any mailing lists.`}
                </p>
              </SupportingInfoText>
            </SupportingInfoContainer>
          </MainBody>
          <NavigationButtonsContainer
            type="submit"
            isBusy={isNavigating}
            disabled={!formState.isValid}
            onClickLeftButton={onClickLeftButton}
          />
        </MainContent>
      </FormProvider>
    </OuterContainer>
  );
};

const MainContent = styled.form`
  display: flex;
  width: 100%;
  max-width: 990px;
  flex-direction: column;
  overflow: hidden;
  margin-top: 110px;

  border-radius: ${({ theme }) => theme.app.borderRadius.largeCorner};
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[12]};
  background-color: ${({ theme }) => theme.app.background.primary};

  @media (${mediaQuery('tablet')}) {
    padding: ${({ theme }) => theme.app.spacing[32]};
    margin-top: ${({ theme }) => theme.app.spacing[16]};

    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(20px);
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[14]};
  }
`;

const InfoText = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.app.spacing[16]};
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.semiBold};

  @media (${mediaQuery('tablet')}) {
    margin-bottom: ${({ theme }) => theme.app.spacing[32]};
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[18]};
    font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.bold};
  }
`;

const MainBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;

  @media (${mediaQuery('tablet')}) {
    display: grid;
    grid-template-columns: [fields] 1fr [info] 1fr;
    grid-template-rows: [fieldsStart] 80px [infoStart] 90px [infoEnd] auto [fieldsEnd];
    grid-gap: 3%;
    margin-bottom: 72px;
  }
`;

const TextFieldsContainer = styled.section`
  display: grid;
  grid-column: fields;
  grid-row: fieldsStart / fieldsEnd;
  height: fit-content;
  grid-row-gap: ${({ theme }) => theme.app.spacing[16]};
  margin-bottom: 16px;

  @media (${mediaQuery('tablet')}) {
    margin-bottom: unset;
  }
`;

const SupportingInfoContainer = styled.section`
  display: flex;
  grid-column: info;
  grid-row: infoStart / infoEnd;
  flex-direction: row;

  @media (${mediaQuery('tablet')}) {
    justify-content: end;
  }
`;

const InfoIconContainerDesktop = styled.div`
  display: none;
  padding-top: 10px;

  @media (${mediaQuery('tablet')}) {
    display: block;
  }
`;

const InfoIconContainerMobile = styled.div`
  padding-top: 3px;

  @media (${mediaQuery('tablet')}) {
    display: none;
  }
`;

const SupportingInfoText = styled.div`
  margin: 0 0 0 10px;

  line-height: ${({ theme }) => theme.app.fontSettings.fontSize[18]};
  color: ${({ theme }) => theme.app.background.supplementaryInfo};

  @media (${mediaQuery('tablet')}) {
    width: 296px;
    margin: 8px 0 8px 10px;

    > p {
      margin-bottom: 18px;
    }
  }
`;
