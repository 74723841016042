import { Icon } from 'theme/types';

export const RaisedHandIcon: Icon = (props) => {
  const { color, ...svgProps } = props;

  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <g clipPath="url(#clip0_28_2110)">
        <path
          d="M20.8398 8.19734C20.744 7.33532 19.9778 6.56909 19.02 6.56909C18.0622 6.56909 17.296 7.23954 17.2002 8.19734V24.4798H20.8398V8.19734Z"
          fill={color || '#0A9828'}
        />
        <path
          d="M26.8736 5.41926C26.7778 4.55725 26.0116 3.79102 25.0538 3.79102C24.096 3.79102 23.3298 4.46147 23.234 5.41926V24.4793H26.8736V5.41926Z"
          fill={color || '#0A9828'}
        />
        <path
          d="M33.0039 9.63386C32.9081 8.77185 32.1418 8.00562 31.1841 8.00562C30.2263 8.00562 29.46 8.67607 29.3643 9.63386V24.3838H33.0039V9.63386Z"
          fill={color || '#0A9828'}
        />
        <path
          d="M39.1334 13.2723C39.0376 12.4103 38.2714 11.644 37.3136 11.644C36.3558 11.644 35.5896 12.3145 35.4938 13.2723V29.9378C35.4938 35.7804 30.7048 40.5693 24.8623 40.5693C21.1269 40.5693 17.5831 38.5579 15.6675 35.3015L10.2081 24.3827C9.72921 23.4249 8.57987 23.0417 7.71785 23.5206C6.76006 23.9995 6.47273 25.0531 6.85584 26.0109L12.5068 37.0255L12.6026 37.1213C15.1886 41.5271 19.8818 44.2089 24.9581 44.2089C32.812 44.2089 39.2292 37.7917 39.2292 29.9378L39.1334 13.2723Z"
          fill={color || '#0A9828'}
        />
        <path
          opacity="0.3"
          d="M3.6947 20.457L8.86677 39.5171L44.305 20.457H3.6947Z"
          fill={color || '#0A9828'}
        />
      </g>
      <defs>
        <clipPath id="clip0_28_2110">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
