import styled from '@emotion/styled';

import { StrongText } from 'components/StrongText';

import {
  AlignContent,
  PageHeaderHighlight,
  ResponsiveContainer,
  SectionHeader,
  SectionSubtitle,
} from './utilityStyles';

const ReasonsSection = styled(ResponsiveContainer)`
  background-color: ${({ theme }) => theme.app.colors.grey.lighter};
  padding: ${({ theme }) => theme.app.padding.largePadding}
    ${({ theme }) => theme.app.padding.mediumLargePadding};

  @media (min-width: 680px) {
    border-radius: ${({ theme }) => theme.app.borderRadius.extraLargeCorner};
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.08);
  }
`;

const ReasonsHeader = styled(SectionHeader)`
  @media (min-width: 680px) {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[32]};
    line-height: 1;
  }
`;

const ReasonsSubtitle = styled(SectionSubtitle)`
  @media (min-width: 680px) {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[24]};
    line-height: 1.33;
  }
`;

const SectionSubSubtitle = styled(PageHeaderHighlight)`
  margin-top: ${({ theme }) => theme.app.margin.largeMargin};

  @media (min-width: 680px) {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
  }
`;

export const ReasonsToChooseSection = () => {
  return (
    <AlignContent>
      <ReasonsSection>
        <ReasonsHeader>
          <StrongText>Three reasons</StrongText> to choose us
        </ReasonsHeader>
        <ReasonsSubtitle>
          <StrongText weight="semiBold">
            Certified engineers local to you
          </StrongText>
        </ReasonsSubtitle>
        <SectionSubSubtitle>
          We&apos;ve got thousands of expert engineers everywhere in the UK,
          including in your area.
        </SectionSubSubtitle>
        <ReasonsSubtitle>
          <StrongText weight="semiBold">Simple booking</StrongText>
        </ReasonsSubtitle>
        <SectionSubSubtitle>
          Book online 24 hours a day, and choose a time that suits you best.
        </SectionSubSubtitle>
        <ReasonsSubtitle>
          <StrongText weight="semiBold">Unbeatable price</StrongText>
        </ReasonsSubtitle>
        <SectionSubSubtitle>
          Worried about your boiler repair cost? We guarantee the most
          competitive prices in the industry.
        </SectionSubSubtitle>
      </ReasonsSection>
    </AlignContent>
  );
};
