import styled from '@emotion/styled';
import { Input, Typography } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { m } from 'framer-motion';
import Image from 'next/legacy/image';
import { useRouter } from 'next/router';
import { useEnergyExpertsStore } from 'store';
import { mediaQuery } from 'theme/energy_experts';

import { Button } from 'components/Button';
import { EmailIcon } from 'components/redesign/EmailIcon';
import { stepSlideTransitionProps } from 'utils/framerMotion';
import { getStringOrFirstElement } from 'utils/getStringOrFirstElement';
import { JOB_TYPES, PRODUCT_TYPE } from 'utils/jobTypes';
import { grabGclid, trackEvent } from 'utils/tracking';
import { shouldForwardProp } from 'utils/transientStyled';

export const NotInYourArea = () => {
  const [email, setState] = useEnergyExpertsStore((state) => [
    state.email,
    state.setState,
  ]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({ email: e.target.value });
  };

  const router = useRouter();

  const onClick = async () => {
    trackEvent('area_unserved_signup', {
      product_type: PRODUCT_TYPE.ENERGY_EXPERTS,
      job_type: JOB_TYPES.HOME_ENERGY_ASSESSMENT,
      gclid: grabGclid(router),
      email,
    });
    const rateDomain = getStringOrFirstElement(router.query.rateDomain);
    const pathName = `${PRODUCT_TYPE.ENERGY_EXPERTS}/${rateDomain}`;
    await router.push(`/${pathName}/sign-up-ack`);
  };

  const isTabletOrLarger = useMediaQuery(`${mediaQuery('tablet', true)}`);

  return (
    <OuterContainer {...stepSlideTransitionProps}>
      <InnerContainer>
        <ImageContainer>
          <Image
            src="/assets/images/NoAddressesOVO.webp"
            alt="Not in your area"
            width={168}
            height={200}
            layout="intrinsic"
          />
        </ImageContainer>
        <TextContainer>
          <Typography variant="h1">
            Sorry! We don&apos;t have any Energy Experts in your area yet
          </Typography>
          <Typography variant="subtitle1">
            We’re expanding Energy Experts across the UK. Share your email and
            we&apos;ll let you know when we can assess your home.
          </Typography>
          <EmailSectionContainer>
            <EmailInputContainer>
              <EmailInput
                disableUnderline
                placeholder={
                  isTabletOrLarger ? 'Enter your email' : 'Enter email'
                }
                fullWidth
                startAdornment={<EmailIcon height="32" width="32" />}
                value={email}
                onChange={handleChange}
              />
            </EmailInputContainer>
            <Button type="submit" variant="bookingFlowCTA" onClick={onClick}>
              {isTabletOrLarger ? 'Keep me updated' : 'Sign up'}
            </Button>
          </EmailSectionContainer>
        </TextContainer>
      </InnerContainer>
    </OuterContainer>
  );
};

const OuterContainer = styled(m.div, {
  shouldForwardProp,
})`
  display: flex;
  flex-flow: column;
  align-items: center;
  grid-column: 1 / -1;
  width: 100%;
  height: 100%;
  background-color: transparent;

  @media (${mediaQuery('tablet')}) {
    background-color: transparent;
  }
`;

const InnerContainer = styled.div`
  width: 100%;
  max-width: 990px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.app.borderRadius.largeCorner};
  overflow: hidden;
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[14]};
  margin-top: 130px;
  padding-top: 5px;
  background-color: transparent;

  @media (${mediaQuery('tablet')}) {
    height: 480px;
    box-shadow: 0 8px 28px rgba(0, 0, 0, 0.06);
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(20px);
    margin-top: 16px;
    padding: ${({ theme }) => theme.app.margin.massiveMargin};
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.app.margin.largeMargin};
`;

const TextContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    height: 48px;
    width: 100%;
    margin-left: 10px;
    margin-top: ${({ theme }) => theme.app.margin.extraExtraLargeMargin};

    @media (${mediaQuery('tablet')}) {
      margin-top: ${({ theme }) => theme.app.margin.largeMargin};
      margin-bottom: ${({ theme }) => theme.app.padding.smallPadding};
    }
  }

  > h1 {
    text-align: center;
    font-size: 16px;
    line-height: 24px;

    @media (${mediaQuery('tablet')}) {
      text-align: unset;
      font-size: 18px;
    }
  }

  > h6 {
    text-align: center;
    margin-top: 16px;
    max-width: 592px;
    line-height: 20px;
  }
`;

const EmailSectionContainer = styled.div`
  display: flex;
  width: 100%;
  flex-flow: row;
  justify-content: space-between;
  height: 64px;
  margin-bottom: 120px;
  padding: ${({ theme }) => theme.app.padding.mediumPadding};

  @media (${mediaQuery('tablet')}) {
    align-items: center;
    width: 448px;
    padding: ${({ theme }) => theme.app.padding.mediumPadding};
    margin-bottom: ${({ theme }) => theme.app.padding.smallPadding};
    box-shadow: unset;
  }
`;

const EmailInputContainer = styled.div`
  display: flex;
  width: 100%;
  flex-flow: row;
  justify-content: space-between;
  height: 48px;
  padding: ${({ theme }) => theme.app.padding.smallPadding};
  padding-left: ${({ theme }) => theme.app.padding.mediumPadding};
  border-radius: ${({ theme }) => theme.app.borderRadius.largeCorner};
  border: 2px solid ${({ theme }) => theme.app.border.focus};
  background-color: ${({ theme }) => theme.app.background.white};
  margin-top: ${({ theme }) => theme.app.margin.extraExtraLargeMargin};

  @media (${mediaQuery('tablet')}) {
    align-items: center;
    padding: ${({ theme }) => theme.app.padding.mediumPadding};
    margin-bottom: ${({ theme }) => theme.app.padding.smallPadding};
    margin-top: ${({ theme }) => theme.app.margin.largeMargin};
    box-shadow: unset;
  }
`;

const EmailInput = styled(Input)`
  .MuiInput-input {
    height: 48px;
    width: 280px
    padding: 10px 10px 11px 20px;
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[18]};

    @media (${mediaQuery('tablet')}) {
      font-size: ${({ theme }) => theme.app.fontSettings.fontSize[24]};
    }

    &::placeholder {
      font-family: ${({ theme }) => theme.app.fontSettings.fontFamily};
      font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
      line-height: 1.75;
      letter-spacing: -0.04em;
      color: ${({ theme }) => theme.app.text.primary};
      font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.light};

      @media (${mediaQuery('tablet')}) {
        font-size: ${({ theme }) => theme.app.fontSettings.fontSize[20]};
        line-height: 1.33;
      }

      @media (${mediaQuery('desktop')}) {
        font-size: ${({ theme }) => theme.app.fontSettings.fontSize[20]};
      }
    }
  }
`;
