export const numberToTextRepresentation = (num: number): string => {
  // we probably don't need to handle >20 at this stage
  const numbers =
    'zero one two three four five six seven eight nine ten eleven twelve thirteen fourteen fifteen sixteen seventeen eighteen nineteen'.split(
      ' ',
    );

  if (num < 20) return `${numbers[num]} (${num})`;

  return '';
};
