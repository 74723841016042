import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useHasStoreHydrated } from 'store/utils';

export const useRedirectIfMissingData = <T>({
  data: storeData,
  dataToCheck,
  fieldsToIgnore = ['acceptPromos', 'acceptTerms'],
  routeToRedirectTo = '/',
  keepQueryParams = true,
  isFlowDisabled,
}: {
  data: T & {
    metadata?: T;
  };
  dataToCheck: Array<keyof T> | undefined;
  fieldsToIgnore?: Array<keyof T | string>;
  routeToRedirectTo?: string;
  keepQueryParams?: boolean;
  isFlowDisabled?: boolean;
}) => {
  const hasStateHydrated = useHasStoreHydrated();
  const router = useRouter();

  useEffect(() => {
    if (!storeData || !dataToCheck || isFlowDisabled) return;
    const filteredDataToCheck = dataToCheck.filter(
      (key) => !fieldsToIgnore.includes(key),
    );

    if (
      hasStateHydrated &&
      !filteredDataToCheck.every((key) => {
        let valueToCheck;
        const dotSeparatedKey = typeof key === 'string' && key.split('.');

        if (dotSeparatedKey && dotSeparatedKey[0] === 'metadata') {
          const metadataKey = dotSeparatedKey[1] as keyof T;
          valueToCheck = storeData.metadata && storeData.metadata[metadataKey];
        } else {
          valueToCheck = storeData[key];
        }

        if (Array.isArray(valueToCheck)) {
          return !!valueToCheck?.length;
        }
        return !!valueToCheck;
      })
    ) {
      const newQueryParams = keepQueryParams ? router.query : undefined;
      !!newQueryParams?.referenceId && delete newQueryParams.referenceId;

      void router.replace({
        pathname: routeToRedirectTo,
        query: newQueryParams,
      });
    }
  }, [
    storeData,
    dataToCheck,
    fieldsToIgnore,
    hasStateHydrated,
    routeToRedirectTo,
    keepQueryParams,
    router,
    isFlowDisabled,
  ]);
};
