import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { m } from 'framer-motion';
import Image from 'next/legacy/image';
import { mediaQuery } from 'theme/kantan';

import { stepSlideTransitionProps } from 'utils/framerMotion';
import { shouldForwardProp } from 'utils/transientStyled';

export const SignUpAcknowledge = () => {
  return (
    <OuterContainer {...stepSlideTransitionProps}>
      <InnerContainer>
        <ImageContainer>
          <Image
            src="/assets/images/TickOVO.webp"
            alt="Tick"
            width={134}
            height={134}
            layout="intrinsic"
          />
        </ImageContainer>
        <TextContainer>
          <Typography variant="h1">Thank you for signing up!</Typography>
        </TextContainer>
      </InnerContainer>
    </OuterContainer>
  );
};

const OuterContainer = styled(m.div, {
  shouldForwardProp,
})`
  display: flex;
  flex-flow: column;
  align-items: center;
  grid-column: 1 / -1;
  width: 100%;
  height: 100%;
  background-color: transparent;

  @media (${mediaQuery('tablet')}) {
    background-color: transparent;
  }
`;

const InnerContainer = styled.div`
  width: 100%;
  max-width: 990px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.app.borderRadius.largeCorner};
  overflow: hidden;
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[14]};
  margin-top: 130px;
  padding-top: 5px;
  background-color: transparent;

  @media (${mediaQuery('tablet')}) {
    height: 480px;
    box-shadow: 0 8px 28px rgba(0, 0, 0, 0.06);
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(20px);
    margin-top: 16px;
    padding: ${({ theme }) => theme.app.margin.massiveMargin};
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.app.margin.largeMargin};
`;

const TextContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    height: 44px;
    width: 336px;
    margin-top: ${({ theme }) => theme.app.margin.extraExtraLargeMargin};

    @media (${mediaQuery('tablet')}) {
      margin-top: ${({ theme }) => theme.app.margin.largeMargin};
    }
  }

  > h1 {
    text-align: center;
    font-size: 16px;
    line-height: 24px;

    @media (${mediaQuery('tablet')}) {
      text-align: unset;
      font-size: 18px;
    }
  }

  > h6 {
    text-align: center;
    margin-top: 16px;
    max-width: 592px;
    line-height: 20px;
  }
`;
