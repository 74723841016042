export interface JobType {
  value: string;
  label: string;
}

export enum JOB_TYPES {
  HEATING_REPAIR = 'heating_repair',
  BOILER_SERVICE = 'boiler_service',
  GAS_SAFETY_CERTIFICATE = 'boiler_service_landlord',
  BOILER_SERVICE_COMBO = 'boiler_service_combo',
  PLUMBING_REPAIR = 'plumbing_repair',
  HOME_ENERGY_ASSESSMENT = 'home_energy_assessment',
  TADO_INSTALLATION = 'smart_thermostat_install',
}

export enum SERVICE_TYPES_ROUTES {
  HEATING_REPAIR = 'heating',
  BOILER_SERVICE = 'boiler-service',
  GAS_SAFETY_CERTIFICATE = 'gas-safety-certificate',
  COMBO_SAVER = 'combo-saver',
  PLUMBING_REPAIR = 'plumbing',
}

export const MARKETPLACE_JOB_TYPES: JobType[] = [
  {
    value: JOB_TYPES.BOILER_SERVICE,
    label: 'Boiler Service',
  },
  {
    value: JOB_TYPES.GAS_SAFETY_CERTIFICATE,
    label: 'Gas Safety Certificate',
  },
  {
    value: JOB_TYPES.BOILER_SERVICE_COMBO,
    label: 'Gas Safety Cert & Boiler Service',
  },
];

export const JOB_TYPES_MAP_TO_WHITE_LIST_FLAG: Record<JOB_TYPES, boolean> = {
  [JOB_TYPES.HEATING_REPAIR]: true,
  [JOB_TYPES.BOILER_SERVICE]: true,
  [JOB_TYPES.GAS_SAFETY_CERTIFICATE]: true,
  [JOB_TYPES.BOILER_SERVICE_COMBO]: true,
  [JOB_TYPES.PLUMBING_REPAIR]: true,
  [JOB_TYPES.HOME_ENERGY_ASSESSMENT]: true,
  [JOB_TYPES.TADO_INSTALLATION]: false,
};

export const JOB_TYPES_MAP: Record<JOB_TYPES | string, string> = {
  [JOB_TYPES.HEATING_REPAIR]: 'Heating Repair',
  [JOB_TYPES.BOILER_SERVICE]: 'Boiler Service',
  [JOB_TYPES.GAS_SAFETY_CERTIFICATE]: 'Gas Safety Certificate',
  [JOB_TYPES.BOILER_SERVICE_COMBO]: 'Gas Safety Cert & Boiler Service',
  [JOB_TYPES.PLUMBING_REPAIR]: 'Plumbing Repair',
  [JOB_TYPES.HOME_ENERGY_ASSESSMENT]: 'OVO Energy Expert Visit',
  [JOB_TYPES.TADO_INSTALLATION]: 'Tado Installation',
};

export enum TRADESPERSON_SKILLS {
  GAS_ENGINEER = 'gas_engineer',
  PLUMBER = 'plumber',
}

export const TRADESPERSON_SKILLS_MAP: Record<TRADESPERSON_SKILLS, string> = {
  [TRADESPERSON_SKILLS.PLUMBER]: 'Plumber',
  [TRADESPERSON_SKILLS.GAS_ENGINEER]: 'Gas engineer',
};

export const JOB_TYPES_TO_TRADESPERSON_SKILLS_MAP: Record<
  JOB_TYPES | string,
  string
> = {
  [JOB_TYPES.BOILER_SERVICE]:
    TRADESPERSON_SKILLS_MAP[TRADESPERSON_SKILLS.GAS_ENGINEER],
  [JOB_TYPES.GAS_SAFETY_CERTIFICATE]:
    TRADESPERSON_SKILLS_MAP[TRADESPERSON_SKILLS.GAS_ENGINEER],
  [JOB_TYPES.BOILER_SERVICE_COMBO]:
    TRADESPERSON_SKILLS_MAP[TRADESPERSON_SKILLS.GAS_ENGINEER],
  [JOB_TYPES.HEATING_REPAIR]:
    TRADESPERSON_SKILLS_MAP[TRADESPERSON_SKILLS.GAS_ENGINEER],
  [JOB_TYPES.PLUMBING_REPAIR]:
    TRADESPERSON_SKILLS_MAP[TRADESPERSON_SKILLS.PLUMBER],
};

export const PRIVATE_AUTOBOOKING_JOB_TYPES = [
  {
    value: JOB_TYPES.BOILER_SERVICE,
    label: 'Boiler Service',
  },
  {
    value: JOB_TYPES.GAS_SAFETY_CERTIFICATE,
    label: 'Gas Safety Certificate',
  },
];

export const queryParamToJobTypeMap = (
  queryOrJob?: string | string[],
  reverse?: boolean,
) => {
  if (Array.isArray(queryOrJob)) return 'boiler-service';

  if (reverse) {
    switch (queryOrJob) {
      case JOB_TYPES.HEATING_REPAIR:
        return SERVICE_TYPES_ROUTES.HEATING_REPAIR;
      case JOB_TYPES.BOILER_SERVICE:
        return SERVICE_TYPES_ROUTES.BOILER_SERVICE;
      case JOB_TYPES.GAS_SAFETY_CERTIFICATE:
        return SERVICE_TYPES_ROUTES.GAS_SAFETY_CERTIFICATE;
      case JOB_TYPES.BOILER_SERVICE_COMBO:
        return SERVICE_TYPES_ROUTES.COMBO_SAVER;
      case JOB_TYPES.PLUMBING_REPAIR:
        return SERVICE_TYPES_ROUTES.PLUMBING_REPAIR;
      default:
        return SERVICE_TYPES_ROUTES.BOILER_SERVICE;
    }
  } else {
    switch (queryOrJob) {
      case SERVICE_TYPES_ROUTES.HEATING_REPAIR:
        return JOB_TYPES.HEATING_REPAIR;
      case SERVICE_TYPES_ROUTES.BOILER_SERVICE:
        return JOB_TYPES.BOILER_SERVICE;
      case SERVICE_TYPES_ROUTES.GAS_SAFETY_CERTIFICATE:
        return JOB_TYPES.GAS_SAFETY_CERTIFICATE;
      case SERVICE_TYPES_ROUTES.COMBO_SAVER:
        return JOB_TYPES.BOILER_SERVICE_COMBO;
      case SERVICE_TYPES_ROUTES.PLUMBING_REPAIR:
        return JOB_TYPES.PLUMBING_REPAIR;
      default:
        return JOB_TYPES.BOILER_SERVICE;
    }
  }
};

export const mapProductTypeToJobType = (productType: PRODUCT_TYPE) => {
  switch (productType) {
    case PRODUCT_TYPE.REPAIR:
      return JOB_TYPES.HEATING_REPAIR;
    case PRODUCT_TYPE.SERVICE:
      return JOB_TYPES.BOILER_SERVICE;
    case PRODUCT_TYPE.ENERGY_EXPERTS:
      return JOB_TYPES.HOME_ENERGY_ASSESSMENT;
    default:
      return JOB_TYPES.BOILER_SERVICE;
  }
};

export const mapJobTypeToProductType = (jobType: JOB_TYPES) => {
  switch (jobType) {
    case JOB_TYPES.HEATING_REPAIR:
      return PRODUCT_TYPE.REPAIR;
    case JOB_TYPES.BOILER_SERVICE:
      return PRODUCT_TYPE.SERVICE;
    case JOB_TYPES.HOME_ENERGY_ASSESSMENT:
      return PRODUCT_TYPE.ENERGY_EXPERTS;
    default:
      return PRODUCT_TYPE.SERVICE;
  }
};

export enum PRODUCT_TYPE {
  REPAIR = 'repair',
  SERVICE = 'service',
  ENERGY_EXPERTS = 'energy-experts',
  TADO_INSTALLATION = 'tadoinstallation',
}

export enum PRODUCT_NAME {
  HOME_ENERGY_ASSESSMENT = 'Home Energy Assessment',
  TADO_INSTALLATION = 'Tado Installation',
}

export const REPAIR_JOB_TYPES = [
  JOB_TYPES.PLUMBING_REPAIR,
  JOB_TYPES.HEATING_REPAIR,
];
