import { captureException } from '@sentry/nextjs';
import { z } from 'zod';

export type Address = z.TypeOf<typeof addressSchema>;

export type Contact = z.TypeOf<typeof contactSchema>;

export type PreloadedCustomerData = z.TypeOf<
  typeof preloadedCustomerDataSchema
>;

const addressSchema = z.object({
  line1: z.string(),
  line2: z.string().optional(),
  town: z.string(),
  postcode: z.string(),
});

const contactSchema = z.object({
  fullName: z.string(),
  email: z.string(),
  phone: z.string(),
  alternativePhoneNumber: z.string().optional(),
  customerReferenceId: z.string(),
});

export const preloadedCustomerDataSchema = z.object({
  address: addressSchema,
  contact: contactSchema,
  userCanEditDetails: z.boolean(),
});

/**
 * preloadedCustomerData is a variable that might be injected by OVO Beyond WebView.
 */
declare const preloadedCustomerData: unknown;

export function getPreloadedData(): PreloadedCustomerData | null {
  let beyondData: PreloadedCustomerData | null = null;

  try {
    // if WebView is running on iOS then preloadedCustomerData will be available from injectedJavaScriptBeforeContentLoaded prop
    // a typeof check is required because the variable might not have been initialised
    // a normal null check might error if the variable is not initialised
    if (typeof preloadedCustomerData !== undefined) {
      beyondData = preloadedCustomerDataSchema.parse(preloadedCustomerData);

      // if WebView is running on Android then preloadedCustomerData will be available from injectedObjectJson() function
    } else if (
      typeof window.ReactNativeWebView?.injectedObjectJson === 'function'
    ) {
      const injectedObjectJson = window.ReactNativeWebView.injectedObjectJson();
      if (injectedObjectJson !== null && injectedObjectJson !== undefined) {
        beyondData = preloadedCustomerDataSchema.parse(
          JSON.parse(injectedObjectJson),
        );
      }
    }
  } catch (e) {
    captureException(e);
  }

  return beyondData;
}
