// can't import Node.js built-in "http" module at runtime here,
// as this module is also evaluated in Next.js Middleware running outside Node.js
import type { IncomingMessage } from 'http';
import { NextRequest } from 'next/server';

/**
 * Get flag value without ConfigCat client and `axios`.
 * Meant to be used in the browser (in `App.getInitialProps`) and in Next.js Middleware.
 *
 * We can't use ConfigCat client (`configCatClient.getValueAsync()`) in Next.js Middleware
 * because Next.js Middleware is executed in the Edge Runtime, even when self-hosted:
 * https://nextjs.org/docs/api-reference/edge-runtime
 *
 * ConfigCat client uses axios under the hood, and the latter
 * is bound to Node.js API when it isn't run in the browser.
 *
 * Edge Runtime, however, doesn't support native Node.js APIs,
 * and it doesn't seem to support `XMLHttpRequest` (https://github.com/prisma-labs/graphql-request/issues/399),
 * so we can use neither `configcat-js-ssr` nor `configcat-js` (https://github.com/configcat/js-sdk/tree/v6.0.1#troubleshooting).
 * We can only use Fetch API to request our API route.
 *
 * It isn't possible to opt out from Edge Runtime for Middleware, see
 * https://github.com/vercel/next.js/discussions/34179#discussioncomment-4059789
 */
export async function getFlagValue(
  flagName: string,
  defaultValue: unknown,
  req?: IncomingMessage | NextRequest,
): Promise<unknown> {
  const url = new URL(
    `/api/flags/${flagName}`,
    req ? getOriginalRequestUrl(req) : window.location.href,
  );

  url.searchParams.set('defaultValue', JSON.stringify(defaultValue));

  // using `fetch()` instead of axios here to use this function in Next.js Middleware
  const res = await fetch(url.toString());

  if (res.status < 200 || res.status > 299) {
    throw new Error(`Failed to get flag value: ${res.statusText}`);
  }

  return res.json();
}

function getOriginalRequestUrl(req: IncomingMessage | NextRequest) {
  if (isIncomingMessage(req)) {
    return `http://${req.headers.host}${req.url}`;
  }

  return req.url;
}

function isIncomingMessage(
  req: NextRequest | IncomingMessage,
): req is IncomingMessage {
  return req.constructor?.name === 'IncomingMessage';
}
