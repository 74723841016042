import { PRODUCT_NAME, PRODUCT_TYPE } from 'utils/jobTypes';

export const mapProductNameToProductType = (
  productName?: PRODUCT_NAME,
): PRODUCT_TYPE | undefined => {
  switch (productName) {
    case PRODUCT_NAME.HOME_ENERGY_ASSESSMENT:
      return PRODUCT_TYPE.ENERGY_EXPERTS;
    case PRODUCT_NAME.TADO_INSTALLATION:
      return PRODUCT_TYPE.TADO_INSTALLATION;
  }
};

export const mapProductTypeToProductName = (
  productType?: PRODUCT_TYPE,
): PRODUCT_NAME | undefined => {
  switch (productType) {
    case PRODUCT_TYPE.ENERGY_EXPERTS:
      return PRODUCT_NAME.HOME_ENERGY_ASSESSMENT;
    case PRODUCT_TYPE.TADO_INSTALLATION:
      return PRODUCT_NAME.TADO_INSTALLATION;
  }
};
