import { Icon } from 'theme/types';

export const ElectricOVOIcon: Icon = (props) => {
  const { color, ...svgProps } = props;

  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path d="M28 6V20H37L20 42V28H11L28 6Z" fill={color || '#0A9828'} />
    </svg>
  );
};
