import styled from '@emotion/styled';
import { m } from 'framer-motion';
import { mediaQuery } from 'theme/energy_experts';

import { shouldForwardProp } from 'utils/transientStyled';

const OuterContainer = styled(m.div, {
  shouldForwardProp,
})`
  display: flex;
  flex-flow: column;
  align-items: center;
  grid-column: 1 / -1;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.app.background.primary};

  @media (${mediaQuery('tablet')}) {
    background-color: transparent;
  }
`;

const MainContent = styled.form`
  width: 100%;
  max-width: 990px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: ${({ theme }) => theme.app.borderRadius.largeCorner};
  overflow: hidden;
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[14]};
  margin-top: 110px;
  background-color: ${({ theme }) => theme.app.background.primary};

  @media (${mediaQuery('tablet')}) {
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(20px);
    margin-top: ${({ theme }) => theme.app.spacing[16]};
    padding: ${({ theme }) => theme.app.spacing[32]};
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
  }
`;

const SelectAddressContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 68px;
`;

const InfoText = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.app.spacing[16]};
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[14]};
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.semiBold};

  & > * {
    margin: 0 2px;
  }

  @media (${mediaQuery('tablet')}) {
    margin-bottom: ${({ theme }) => theme.app.spacing[32]};
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
    font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.bold};
  }
`;

const InfoTextConfirmAddress = styled(InfoText)`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};

  @media (${mediaQuery('tablet')}) {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[18]};
  }
`;

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 24px 0 0 0;

  @media (${mediaQuery('tablet')}) {
    justify-content: start;
  }
`;

const LinkText = styled.a`
  color: ${({ theme }) => theme.app.text.action};
  margin-right: 5px;
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[14]};
  line-height: ${({ theme }) => theme.app.fontSettings.fontSize[20]};

  @media (${mediaQuery('tablet')}) {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
  }
`;

const AddressStyles = {
  OuterContainer,
  MainContent,
  SelectAddressContainer,
  InfoText,
  InfoTextConfirmAddress,
  LinkContainer,
  LinkText,
};

export { AddressStyles };
