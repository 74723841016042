import styled from '@emotion/styled';
import { mediaQuery } from 'theme/kantan';

const Container = styled.div<{
  $isSelected?: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => theme.app.padding.mediumPadding};
  border-radius: ${({ theme }) => theme.app.borderRadius.smallCorner};

  ${({ theme, $isSelected }) =>
    $isSelected &&
    `
      background-color: ${theme.app.background.radioButton};
    `}
`;

const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Title = styled.div<{ $isSelected: boolean }>`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[14]};
  color: ${({ theme, $isSelected }) =>
    $isSelected
      ? theme.app.text.white
      : theme.app.landingPage.generalText.major};
  text-align: center;

  @media (${mediaQuery('tablet')}) {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[18]};
  }
`;

export { Container, Title, IconsContainer };
