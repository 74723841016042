import styled from '@emotion/styled';
import { m } from 'framer-motion';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useEnergyExpertsStore } from 'store';
import { mediaQuery } from 'theme/kantan';

import { OnChange } from 'components/redesign/AddressStep/AddressInputManual';
import { InputChangeEvent } from 'components/redesign/MuiTextField/MuiTextField';
import { useExistingStepContext } from 'pages/[productType]/[rateDomain]/[step]';
import { stepSlideTransitionProps } from 'utils/framerMotion';
import { trackEvent } from 'utils/tracking';
import { shouldForwardProp } from 'utils/transientStyled';

import { ReasonOptions } from '.';

export const Motivation = () => {
  const { motivations, otherReasonDetail, setState } = useEnergyExpertsStore(
    (state) => ({
      motivations: state.motivations,
      otherReasonDetail: state.otherReasonDetail,
      setState: state.setState,
    }),
  );
  const context = useExistingStepContext();
  const { nextStep } = context.currentStep.options;

  const form = useForm({
    mode: 'all',
    defaultValues: { motivations, otherReasonDetail },
  });
  const { reset } = form;

  const router = useRouter();

  useEffect(() => {
    reset({ motivations, otherReasonDetail });
  }, [reset, motivations, otherReasonDetail]);

  useEffect(() => {
    setState({ motivations: '', otherReasonDetail: '' });
  }, [setState]);

  const handleSelectOption = (value: string) => {
    const motivationsSplitByComma = motivations
      .split(',')
      .filter((e) => e !== '');
    const updatedMotivations = motivationsSplitByComma.includes(value)
      ? motivationsSplitByComma.filter((e) => e !== value)
      : [...motivationsSplitByComma, value];

    setState({ motivations: updatedMotivations.join(',') });
  };

  const handleOtherInputChange = (e: InputChangeEvent, onChange: OnChange) => {
    const { value } = e.target;
    onChange(value);
    setState({ otherReasonDetail: value });
  };

  const navigateToNextStep = () => {
    trackEvent('Visit Reason selected', {
      visit_reason: motivations,
      other_reason_detail: otherReasonDetail,
    });
    void router.push({
      pathname: nextStep,
    });
  };

  return (
    <OuterContainer {...stepSlideTransitionProps}>
      <SelectReasonContainer>
        <Header>Select all that apply</Header>
        <FormProvider {...form}>
          <ReasonOptions
            selected={motivations}
            handleSelectOption={handleSelectOption}
            handleOtherInputChange={handleOtherInputChange}
            navigateToNextStep={navigateToNextStep}
          />
        </FormProvider>
      </SelectReasonContainer>
    </OuterContainer>
  );
};

const OuterContainer = styled(m.div, {
  shouldForwardProp,
})`
  display: flex;
  flex-flow: column;
  align-items: center;
  grid-column: 1 / -1;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.app.background.primary};

  @media (${mediaQuery('tablet')}) {
    background-color: transparent;
  }
`;

const SelectReasonContainer = styled.div`
  width: 100%;
  max-width: 990px;
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.app.borderRadius.largeCorner};
  overflow: hidden;
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[14]};
  margin-top: 120px;
  padding-top: 5px;
  background-color: ${({ theme }) => theme.app.background.primary};

  @media (${mediaQuery('tablet')}) {
    box-shadow: 0 8px 28px rgba(0, 0, 0, 0.06);
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(20px);
    margin-top: 16px;
    padding: 26px ${({ theme }) => theme.app.padding.massivePadding} 50px;
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
  }

  button {
    height: 44px;
  }
`;

const Header = styled.h2`
  display: none;

  @media (${mediaQuery('tablet')}) {
    display: block;
    padding: ${({ theme }) => theme.app.padding.mediumPadding};
    padding-bottom: 26px;
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[18]};
    font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.bold};
    color: ${({ theme }) => theme.app.text.primary};
    letter-spacing: -0.03em;
  }
`;
