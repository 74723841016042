import cookie from 'cookie';
import { IncomingMessage, ServerResponse } from 'http';
import setCookieParser from 'set-cookie-parser';

export type ServerCookies = Record<string, string | undefined>;

export const getServerCookies = (
  req?: IncomingMessage,
  res?: ServerResponse<IncomingMessage>,
): ServerCookies => {
  const reqCookies = req?.headers.cookie
    ? cookie.parse(req.headers.cookie)
    : undefined;

  const resCookies = res ? getResponseCookies(res) : undefined;

  return { ...reqCookies, ...resCookies };
};

const getResponseCookies = (res: ServerResponse<IncomingMessage>) => {
  const setCookieHeaders = res?.getHeader('set-cookie');

  if (!setCookieHeaders) return {};
  if (typeof setCookieHeaders === 'number') return {};

  const cookies = setCookieParser(setCookieHeaders);

  return cookies.reduce((acc, cookie) => {
    acc[cookie.name] = cookie.value;
    return acc;
  }, {} as Record<string, string>);
};
