import { Icon } from 'theme/types';

export const CombiBoiler: Icon = (props) => {
  const {
    color = '#0A9828',
    secondarycolor = '#0A9828',
    opacity = 0.3,
    ...svgProps
  } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      {...svgProps}
    >
      <path d="M38 32.8889H10V44H38V32.8889Z" fill={color} />
      <path
        opacity={opacity}
        d="M10 4V32.8889H38V4H10ZM31.5385 28.4444H16.4615V19.5556H31.5385V28.4444Z"
        fill={secondarycolor}
      />
      <path
        d="M20.7692 26.2222C21.9587 26.2222 22.923 25.2273 22.923 24C22.923 22.7727 21.9587 21.7778 20.7692 21.7778C19.5797 21.7778 18.6154 22.7727 18.6154 24C18.6154 25.2273 19.5797 26.2222 20.7692 26.2222Z"
        fill={secondarycolor}
      />
      <path
        d="M27.2308 26.2222C28.4203 26.2222 29.3846 25.2273 29.3846 24C29.3846 22.7727 28.4203 21.7778 27.2308 21.7778C26.0412 21.7778 25.0769 22.7727 25.0769 24C25.0769 25.2273 26.0412 26.2222 27.2308 26.2222Z"
        fill={secondarycolor}
      />
    </svg>
  );
};
