import { Icon } from 'theme/types';

export const GasOVOIcon: Icon = (props) => {
  const { color, ...svgProps } = props;

  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M24 6V6.7C24 9.9 22.1 12.7 19.3 14C15.6 15.7 13 19.3 13 23.5C13 29.3 17.9 34 24 34V33.3C24 30.1 25.9 27.3 28.7 26C32.4 24.3 35 20.7 35 16.5C35 10.7 30.1 6 24 6Z"
        fill={color || '#0A9828'}
      />
      <path opacity="0.3" d="M9 38V42H39V38H9Z" fill={color || '#0A9828'} />
    </svg>
  );
};
