import { Theme } from '@emotion/react';
import { NextRouter } from 'next/router';

import { PRODUCT_NAME } from 'utils/jobTypes';

import {
  materialUiTheme as energyExpertsMaterialUiTheme,
  theme as energyExpertsTheme,
} from './energy_experts';
import {
  materialUiTheme as kantanMaterialUiTheme,
  theme as kantanTheme,
} from './kantan';
import {
  materialUiTheme as ovoMaterialUiTheme,
  theme as ovoTheme,
} from './ovo';
import {
  materialUiTheme as tadoKantanMaterialUiTheme,
  theme as tadoThemeKantan,
} from './tado-kantan';
import {
  materialUiTheme as tadoOvoMaterialUiTheme,
  theme as tadoThemeOvo,
} from './tado-ovo';
import { mapProductNameToProductType } from './utils';

export type AvailableTheme =
  | 'kantan'
  | 'ovo'
  | 'energy-experts'
  | 'tadoinstallation-kantan'
  | 'tadoinstallation-ovo';

const availableThemes: Record<AvailableTheme, Theme> = {
  kantan: { ...kantanMaterialUiTheme, app: kantanTheme },
  ovo: { ...ovoMaterialUiTheme, app: ovoTheme },
  'energy-experts': {
    ...energyExpertsMaterialUiTheme,
    app: energyExpertsTheme,
  },
  'tadoinstallation-kantan': {
    ...tadoKantanMaterialUiTheme,
    app: tadoThemeKantan,
  },
  'tadoinstallation-ovo': {
    ...tadoOvoMaterialUiTheme,
    app: tadoThemeOvo,
  },
};

const routerDerivedThemes: AvailableTheme[] = [
  'energy-experts',
  'tadoinstallation-kantan',
  'tadoinstallation-ovo',
];

const routerAndPartnerDerivedThemes = ['tadoinstallation'];

export const getTheme = (router: NextRouter, productName?: PRODUCT_NAME) => {
  let productType: AvailableTheme;

  if (!!productName) {
    productType = mapProductNameToProductType(productName) as AvailableTheme;
  } else {
    productType = router.pathname.split('/')[1] as AvailableTheme;
  }

  if (routerAndPartnerDerivedThemes.includes(productType)) {
    productType = (productType + '-' + activeTheme) as AvailableTheme;
  }

  if (routerDerivedThemes.includes(productType)) {
    return availableThemes[productType];
  } else {
    return availableThemes[activeTheme];
  }
};

const activeTheme =
  // 'ENERGY_EXPERTS' -> 'energy-experts'
  (process.env.NEXT_PUBLIC_PARTNER?.toLowerCase().replace(
    /_/,
    '-',
  ) as AvailableTheme) ?? 'kantan';

export default availableThemes[activeTheme];
