/**
 * __isNullOrUndefined__
 *
 * Allows filtering out items that are not null or undefined.
 * Narrows the type of each item so you don't need to handle it elsewhere.
 *
 */
export const isNullOrUndefined = <T>(
  maybeT: T | null | undefined,
): maybeT is null | undefined => {
  return maybeT === null || maybeT === undefined;
};
