import styled from '@emotion/styled';

import { StrongText } from 'components/StrongText';

import {
  AccordionDetailsTypography,
  AlignContent,
  ResponsiveContainer,
  SectionHeader,
} from './utilityStyles';

const BookingStepsContainer = styled(ResponsiveContainer)`
  background-color: ${({ theme }) => theme.app.background.selected};
  padding: ${({ theme }) => theme.app.padding.mediumLargePadding};

  @media (min-width: 680px) {
    border-radius: ${({ theme }) => theme.app.borderRadius.extraLargeCorner};
  }
`;

const BookingStepsHeader = styled(SectionHeader)`
  color: ${({ theme }) => theme.app.text.white};
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[24]};
  line-height: 1.33;
  letter-spacing: -0.02em;

  @media (min-width: 680px) {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[32]};
    line-height: 1.25;
  }
`;

const BookingStepsContent = styled.div`
  p {
    margin-top: ${({ theme }) => theme.app.margin.extraLargeMargin};
  }
`;

export const ExpertsSection = () => {
  return (
    <AlignContent>
      <BookingStepsContainer>
        <BookingStepsHeader>
          Our <StrongText weight="semiBold">expert engineers</StrongText> are{' '}
          <StrongText weight="semiBold">your neighbours</StrongText>
        </BookingStepsHeader>
        <BookingStepsContent>
          <AccordionDetailsTypography>
            We only accept the top <StrongText>gas safe certified</StrongText>{' '}
            engineers into our network
          </AccordionDetailsTypography>
          <AccordionDetailsTypography>
            <StrongText>Unbeatable prices</StrongText> that are{' '}
            <StrongText>simple</StrongText> for you to understand
          </AccordionDetailsTypography>
          <AccordionDetailsTypography>
            <StrongText>Book your boiler repair immediately</StrongText> or on
            your schedule
          </AccordionDetailsTypography>
        </BookingStepsContent>

        {/*TODO: We should keep the accordion and its styles for later use
         */}
        {/*<AccordionSection>*/}
        {/*  <StyledAccordion disableGutters square>*/}
        {/*    <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>*/}
        {/*      <p>*/}
        {/*        1. Tell us the <StrongText>problem</StrongText>*/}
        {/*      </p>*/}
        {/*    </StyledAccordionSummary>*/}
        {/*    <StyledAccordionDetails>*/}
        {/*      <AccordionDetailsTypography variant="caption">*/}
        {/*        So that we can speed up the process of matching you with an*/}
        {/*        engineer who can help.*/}
        {/*      </AccordionDetailsTypography>*/}
        {/*    </StyledAccordionDetails>*/}
        {/*  </StyledAccordion>*/}
        {/*  <StyledAccordion disableGutters square>*/}
        {/*    <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>*/}
        {/*      <p>*/}
        {/*        2. Enter your <StrongText>problem</StrongText>*/}
        {/*      </p>*/}
        {/*    </StyledAccordionSummary>*/}
        {/*    <StyledAccordionDetails>*/}
        {/*      <AccordionDetailsTypography variant="caption">*/}
        {/*        To let us know where we can send one of our friendly*/}
        {/*        engineers in your area.*/}
        {/*      </AccordionDetailsTypography>*/}
        {/*    </StyledAccordionDetails>*/}
        {/*  </StyledAccordion>*/}
        {/*  <StyledAccordion disableGutters square>*/}
        {/*    <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>*/}
        {/*      <p>*/}
        {/*        3. Select your <StrongText>availability</StrongText>*/}
        {/*      </p>*/}
        {/*    </StyledAccordionSummary>*/}
        {/*    <StyledAccordionDetails>*/}
        {/*      <AccordionDetailsTypography variant="caption">*/}
        {/*        For the best chance of getting you an engineer ASAP, choose*/}
        {/*        all available slots. The earliest will be prioritised.*/}
        {/*      </AccordionDetailsTypography>*/}
        {/*    </StyledAccordionDetails>*/}
        {/*  </StyledAccordion>*/}
        {/*  <StyledAccordion disableGutters square>*/}
        {/*    <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>*/}
        {/*      <p>*/}
        {/*        4. Share your <StrongText>contact details</StrongText>*/}
        {/*      </p>*/}
        {/*    </StyledAccordionSummary>*/}
        {/*    <StyledAccordionDetails>*/}
        {/*      <AccordionDetailsTypography variant="caption">*/}
        {/*        So we can keep you updated about your appointment.*/}
        {/*      </AccordionDetailsTypography>*/}
        {/*    </StyledAccordionDetails>*/}
        {/*  </StyledAccordion>*/}
        {/*</AccordionSection>*/}
      </BookingStepsContainer>
    </AlignContent>
  );
};
