import { SlotShape } from 'components/form';

export const checkRequiredSlotsSelected = (
  minRequired: number,
  maxRequired?: number,
  selectedSlots?: SlotShape[] | string[],
): {
  fewerThanMinSelected: boolean;
  equalToMaxSelected: boolean;
  moreThanMaxSelected: boolean;
  requiredAmountSelected: boolean;
} => {
  let fewerThanMinSelected = false;
  let equalToMaxSelected = false;
  let moreThanMaxSelected = false;
  let requiredAmountSelected = false;

  if (!selectedSlots || (minRequired && selectedSlots.length < minRequired)) {
    requiredAmountSelected = false;
    fewerThanMinSelected = true;
  } else if (maxRequired && selectedSlots.length === maxRequired) {
    requiredAmountSelected = true;
    equalToMaxSelected = true;
  } else if (maxRequired && selectedSlots.length > maxRequired) {
    requiredAmountSelected = false;
    moreThanMaxSelected = true;
  } else {
    requiredAmountSelected = true;
  }

  return {
    fewerThanMinSelected,
    equalToMaxSelected,
    moreThanMaxSelected,
    requiredAmountSelected,
  };
};
