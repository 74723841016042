import styled from '@emotion/styled';
import { Typography } from '@mui/material';

import { StrongText } from 'components/StrongText';
import { formatPrice } from 'utils/formatPrice';
import { JOB_TYPES } from 'utils/jobTypes';
import { getPriceFromJobType } from 'utils/tracking';
import { useManagedRates } from 'utils/useManagedRates';

import {
  AlignContent,
  ResponsiveContainer,
  SectionHeader,
} from './utilityStyles';

const PriceContainer = styled(ResponsiveContainer)`
  background-color: ${({ theme }) => theme.app.background.selected};
  padding: ${({ theme }) => theme.app.padding.mediumLargePadding};

  > * {
    padding-top: 16px;
  }

  @media (min-width: 680px) {
    border-radius: ${({ theme }) => theme.app.borderRadius.extraLargeCorner};
  }
`;

const PriceSectionHeader = styled(SectionHeader)`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[24]};
  color: ${({ theme }) => theme.app.text.white};
`;

const PriceCaption = styled(Typography)`
  display: inline-block;
  color: ${({ theme }) => theme.app.text.white};
  padding-bottom: ${({ theme }) => theme.app.padding.mediumPadding};
`;

export const PriceSection = () => {
  const managedRates = useManagedRates();

  return (
    <AlignContent>
      <PriceContainer>
        <PriceSectionHeader>
          Only{' '}
          <StrongText>
            £
            {formatPrice(
              getPriceFromJobType(JOB_TYPES.HEATING_REPAIR, managedRates)
                .amount,
            )}
          </StrongText>{' '}
          including VAT
        </PriceSectionHeader>
        <PriceSectionHeader>
          <StrongText>
            Best price in the industry. Most issues fixed in just one hour.
          </StrongText>
        </PriceSectionHeader>
        <PriceCaption variant="caption">
          First hour boiler repair cost only. Additional hours charged at £
          {formatPrice(
            getPriceFromJobType(JOB_TYPES.HEATING_REPAIR, managedRates).amount,
          )}
          . Parts charged separately.
        </PriceCaption>
      </PriceContainer>
    </AlignContent>
  );
};
