import Error from 'next/error';

const NotFoundPage = () => {
  return <Error statusCode={404} />;
};

// manually activating SSG for the 404 route, as it's disabled by default
// due to `getInitialProps` in `_app.tsx`; this reduces server load a little bit
// and should help to deal with vulnerability scanner bots slamming us with requests
export function getStaticProps() {
  return { props: {} };
}

export default NotFoundPage;
