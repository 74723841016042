import theme from 'theme/theme';

import { ComboPriceText, NonComboPriceText } from 'components/PriceText';

import { JOB_TYPES } from './jobTypes';

export const mainProblemOptions = {
  labels: [
    'Boiler not working',
    'No heating',
    'No hot water',
    'No heating or hot water',
    'Other',
  ],
  errorCopy: 'Please select the problem',
};

export const fuelTypeOptions = {
  labels: ['Gas', 'LPG', 'Not sure'],
  errorCopy: 'Please select the fuel type. If unknown, select "Not sure"',
};

export type Icon = keyof typeof theme.app.icons;

export type ServiceOption = {
  value: JOB_TYPES;
  icons: Icon[];
  serviceTypeLabel: string;
  priceText: () => JSX.Element;
  optionText?: string;
  isCombo?: boolean;
};

export const getServiceOptions = (): ServiceOption[] => [
  {
    value: JOB_TYPES.BOILER_SERVICE,
    icons: ['spanner'],
    serviceTypeLabel: 'Boiler Service',
    priceText: () => NonComboPriceText(JOB_TYPES.BOILER_SERVICE),
  },
  {
    value: JOB_TYPES.GAS_SAFETY_CERTIFICATE,
    icons: ['gasSafetyCertificate'],
    serviceTypeLabel: 'Gas Safety Certificate',
    priceText: () => NonComboPriceText(JOB_TYPES.GAS_SAFETY_CERTIFICATE),
  },
  {
    value: JOB_TYPES.BOILER_SERVICE_COMBO,
    icons: ['spanner', 'gasSafetyCertificate'],
    serviceTypeLabel: 'Boiler Service + Gas Safety Certificate',
    priceText: () => ComboPriceText(JOB_TYPES.BOILER_SERVICE_COMBO),
    optionText: 'COMBO SAVER',
    isCombo: true,
  },
];

export type MainProblemOption = {
  value: string;
  icons?: Icon[];
  serviceTypeLabel: string;
  tooltipLabel?: string;
  maxHeightMobile?: string;
  gridColumn?: string;
  gridGap?: string;
};

export const getMainProblemOptions = (
  type: 'heating' | 'plumbing',
): MainProblemOption[] =>
  type === 'heating' ? mainHeatingProblems : mainPlumbingProblems;

export const mainHeatingProblems: MainProblemOption[] = [
  {
    value: 'noHeating',
    icons: ['noHeating'],
    serviceTypeLabel: 'No heating',
  },
  {
    value: 'noHotWater',
    icons: ['noHotWaterIcon'],
    serviceTypeLabel: 'No hot water',
  },
  {
    value: 'noHotWaterNoHeating',
    icons: ['noHotWaterIcon', 'noHeating'],
    serviceTypeLabel: 'No heating or hot water',
  },
  {
    value: 'boilerNotWorking',
    icons: ['boilerNotWorking'],
    serviceTypeLabel: 'Boiler not working',
  },
  {
    value: 'boilerLeaking',
    icons: ['boilerLeaking'],
    serviceTypeLabel: 'Boiler leaking water',
  },
  {
    value: 'other',
    serviceTypeLabel: 'Other',
  },
];

export const mainPlumbingProblems: MainProblemOption[] = [
  {
    value: 'leak',
    icons: ['boilerLeaking'],
    serviceTypeLabel: 'Leak',
  },
  {
    value: 'blockage',
    icons: ['blockage'],
    serviceTypeLabel: 'Blockage',
  },
  {
    value: 'noHotWater',
    icons: ['noHotWaterIcon'],
    serviceTypeLabel: 'No hot water',
  },
  {
    value: 'other',
    serviceTypeLabel: 'Other',
  },
];

export const getFuelTypeOptions = (
  hasElectric?: boolean,
): MainProblemOption[] => [
  {
    value: 'gas',
    icons: ['gas'],
    serviceTypeLabel: 'Gas',
    tooltipLabel: 'If you have a gas meter, your boiler uses Gas',
    gridGap: '6px 0',
  },
  {
    value: 'lpg',
    icons: ['lpg'],
    serviceTypeLabel: 'LPG',
    tooltipLabel: 'Liquid Petroleum Gas is usually stored in a cylinder',
    gridGap: '6px 0',
  },
  {
    value: 'oil',
    icons: ['oil'],
    serviceTypeLabel: 'Oil',
    tooltipLabel: 'Oil is usually stored in a large tank outside',
    gridGap: '6px 0',
  },
  ...(hasElectric
    ? [
        {
          value: 'electric',
          icons: ['electric'],
          serviceTypeLabel: 'Electric',
          tooltipLabel: 'Electric heating systems do not use a gas meter',
          gridGap: '6px 0',
        } as MainProblemOption,
      ]
    : []),
  {
    value: 'notSure',
    serviceTypeLabel: 'Not sure',
    maxHeightMobile: '54px',
    gridColumn: '1 / -1',
    gridGap: '0',
  },
];
