import { format, isSameDay, isToday, isTomorrow } from 'date-fns';

import { StrongText } from '../components/StrongText';

export const formatDates = (day: Date) => {
  switch (true) {
    case isToday(day):
      return ['Today,', ` ${format(day, 'eee d MMM')}`];
    case isTomorrow(day):
      return ['Tomorrow,', ` ${format(day, 'eee d MMM')}`];
    default:
      return [format(day, 'iiii'), ` ${format(day, 'd MMM')}`];
  }
};

export const formatDateText = (day: Date) => {
  const [namedDay, date] = formatDates(day);
  return (
    <>
      <StrongText weight="semiBold">{namedDay}</StrongText>
      {date}
    </>
  );
};

type Payload = Array<{
  slot: { startDateTime: string; endDateTime: string };
  name: string;
}>;

export type FormattedSlot = {
  startDateTime: string;
  endDateTime: string;
  name: string;
};

export type FormattedSlots = {
  currentDay: Date;
  slots: FormattedSlot[];
};

export const extractDays = (payload: Payload = []) => {
  return payload.reduce<FormattedSlots[]>((acc: FormattedSlots[], curr) => {
    const startDateTime = new Date(curr.slot.startDateTime);
    const day: FormattedSlots = {
      currentDay: startDateTime,
      slots: [],
    };
    if (acc.some((day) => isSameDay(day.currentDay, startDateTime))) {
      acc[acc.length - 1].slots.push({
        startDateTime: curr.slot.startDateTime,
        endDateTime: curr.slot.endDateTime,
        name: curr.name,
      });
    } else {
      day.slots.push({
        startDateTime: curr.slot.startDateTime,
        endDateTime: curr.slot.endDateTime,
        name: curr.name,
      });
      acc.push(day);
    }
    return acc;
  }, []);
};
