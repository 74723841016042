import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { useAddressStore } from 'store';

import {
  AddressData,
  formatAddressFromPostcoder,
} from 'components/marketplace/PostcodeLookup';
import { AddressPicker } from 'components/redesign/AddressStep';
import { StepOptions } from 'services/kantanClient';
import { stepSlideTransitionProps } from 'utils/framerMotion';
import { useSearchAddress } from 'utils/useSearchAddress';

import { AddressStyles } from '.';

export const SelectAddress = (stepConfigOptions: StepOptions) => {
  const {
    OuterContainer,
    MainContent,
    SelectAddressContainer,
    InfoText,
    LinkContainer,
    LinkText,
  } = AddressStyles;

  const [addressData, setAddressData] = useAddressStore((state) => [
    state,
    state.setState,
  ]);

  const [
    _searchString,
    suggestedAddresses,
    search,
    addCategory,
    currentCategory,
    isAutocompleteLoading,
  ] = useSearchAddress();

  const { postcode } = addressData;
  useEffect(() => {
    search(postcode ?? '');
  }, [search, postcode]);

  const router = useRouter();
  const selectAddress = useCallback(
    (address: AddressData) => {
      if (address) {
        setAddressData(address);
      }
    },
    [setAddressData],
  );

  useEffect(() => {
    if (
      currentCategory &&
      suggestedAddresses.length === 1 &&
      suggestedAddresses[0].type === 'ADD'
    ) {
      selectAddress(formatAddressFromPostcoder(suggestedAddresses[0]));
    }
  }, [currentCategory, selectAddress, suggestedAddresses]);

  const navigateToNextStep = async () => {
    await router.push({
      pathname: stepConfigOptions.nextStep,
    });
  };

  return (
    <OuterContainer {...stepSlideTransitionProps}>
      <MainContent>
        <SelectAddressContainer>
          <InfoText>Choose your address from the list</InfoText>
          <AddressPicker
            suggestedAddresses={suggestedAddresses}
            selectAddress={selectAddress}
            closeMenu={navigateToNextStep}
            addCategory={addCategory}
            loading={isAutocompleteLoading}
          />
          <LinkContainer onClick={navigateToNextStep}>
            <LinkText>Enter address manually</LinkText>
          </LinkContainer>
        </SelectAddressContainer>
      </MainContent>
    </OuterContainer>
  );
};
