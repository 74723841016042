import styled from '@emotion/styled';
import { mediaQuery } from 'theme/tado-kantan';

type MarginBottom = {
  mobile: number;
  tablet: number;
};

const Container = styled.div<{ $marginBottom: MarginBottom }>`
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: fit-content;
  margin-bottom: ${({ $marginBottom }) => `${$marginBottom.mobile}px`};

  @media (${mediaQuery('tablet')}) {
    margin-bottom: ${({ $marginBottom }) => `${$marginBottom.tablet}px`};
  }
`;

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;

  * {
    font-size: 14px !important;
    @media (${mediaQuery('tablet')}) {
      font-size: unset !important;
    }
  }

  @media (${mediaQuery('tablet')}) {
    flex-direction: row;
    align-items: center;
    height: 43px;
  }
`;

const QuestionText = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 8px;

  @media (${mediaQuery('tablet')}) {
    margin-bottom: unset;
    width: 340px;
  }
`;

const RadioButtonsContainer = styled.div`
  display: flex;
  width: 300px;

  @media (${mediaQuery('tablet')}) {
    margin-left: 32px;
  }
`;

const RadioButton = styled.div<{ $isSelected: boolean }>`
  display: flex;
  width: fit-content;
  min-width: 144px;
  width: 144px;

  button {
    min-width: 150px;
    width: 150px;
    background-color: ${({ theme, $isSelected }) =>
      $isSelected
        ? theme.app.background.radioButton
        : theme.app.background.white};
    color: ${({ theme, $isSelected }) =>
      $isSelected
        ? theme.app.text.white
        : theme.app.landingPage.generalText.major};
    font-weight: 400;

    &:hover,
    &:focus-visible {
      background-color: ${({ theme, $isSelected }) =>
        $isSelected
          ? theme.app.background.radioButton
          : theme.app.background.white};
      color: ${({ theme, $isSelected }) =>
        $isSelected
          ? theme.app.text.white
          : theme.app.landingPage.generalText.major};
    }
  }

  &:last-of-type {
    button {
      margin-left: 16px;
    }
  }
`;

export {
  Container,
  QuestionContainer,
  QuestionText,
  RadioButtonsContainer,
  RadioButton,
};
