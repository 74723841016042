import * as React from 'react';

import { IconBase } from './utils';

export const ArrowLeftIcon: IconBase = (props) => {
  const { color, ...svgProps } = props;

  return (
    <svg width={12} height={9} fill="none" viewBox="0 0 12 9" {...svgProps}>
      <path
        d="m4.344.592 1.28 1.28-.96.96-.704.608.016.064 1.28-.064h6.624v1.952H5.256l-1.28-.064-.016.064.704.608.96.96-1.28 1.28L.52 4.416 4.344.592Z"
        fill={color || 'black'}
      />
    </svg>
  );
};
