import 'node_modules/normalize.css/normalize.css';
import 'styles/index.scss';
import 'styles/slider.scss';
import 'styles/components/spinner.scss';
import 'styles/components/marketplace.scss';
import 'styles/components/calendar.scss';
import 'styles/components/private-booking.scss';
import 'styles/components/calendar-best-slot-modal.scss';

import { datadogRum } from '@datadog/browser-rum-slim';
import { Global, Theme } from '@emotion/react';
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AnimatePresence, LazyMotion } from 'framer-motion';
import type { IncomingMessage } from 'http';
import App, { AppContext as NextAppContext, AppProps } from 'next/app';
import Head from 'next/head';
import { NextRouter, Router, useRouter } from 'next/router';
import Script from 'next/script';
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import globalStyles from 'theme/global';
import { getTheme } from 'theme/theme';

import { ErrorModalContext } from 'components/ErrorModal/ErrorModal';
import { Loading } from 'components/Loading';
import { fetchProductConfig } from 'services/kantanClient';
import { dehydrateState } from 'utils/api/dehydrateState';
import { setQueryClient } from 'utils/api/queryClient';
import { getServerCookies } from 'utils/cookies';
import { createSafeContext } from 'utils/createSafeContext';
import {
  ActiveExperiments,
  useExperimentsProvider,
} from 'utils/experiments/useExperiments';
import { ProductConfigProvider } from 'utils/product/Provider';
import { GlobalStateProvider } from 'utils/state';
import { NextPageWithLayout, PageProps } from 'utils/types';
import { getPagePath } from 'utils/urls';

import {
  ProductParams,
  StepContext,
} from './[productType]/[rateDomain]/[step]';
import NotFoundPage from './404';

declare global {
  interface Window {
    dataLayer: object[];
    Intercom?: (action: string) => void;
  }
}

// Segment - dynamic render snippet
Router.events.on('routeChangeComplete', (url) => {
  window.analytics.page(url);
});

// Framer Motion dynamic import
const loadFeatures = () =>
  import('utils/framerMotion').then((res) => res.default);

// React-Axe - accessibility tool for development
if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'production') {
  import('@axe-core/react').then((axe) => {
    axe.default(React, ReactDOM, 1000);
  });
}

interface AppContextProps {
  experiments: ActiveExperiments;
  headerVariant?: string;
  cityLocation: {
    name: string;
    criteriaId: number;
  } | null;
  isFallbackAvailability: boolean;
  setIsFallbackAvailability: (newState: boolean) => void;
}

export const AppContext = createContext<AppContextProps>({
  experiments: {} as ActiveExperiments,
  cityLocation: null,
  isFallbackAvailability: false,
  setIsFallbackAvailability: () => {},
});

type NextPageWithLayoutApp = AppProps<PageProps> & {
  Component: NextPageWithLayout;
};

type ThemeContextValue = {
  setTheme: Dispatch<SetStateAction<Theme>>;
};

export const [ThemeContext, useThemeContext] =
  createSafeContext<ThemeContextValue>('theme');

function MyApp({ Component, pageProps }: NextPageWithLayoutApp) {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout || ((page) => page);
  const {
    landingPageHeaderVariant: headerVariant,
    cityLocation,
    serverCookies,
  } = pageProps;
  const [isOpen, setIsOpen] = React.useState(false);
  const [isFallbackAvailability, setIsFallbackAvailability] =
    React.useState(false);
  const router = useRouter();

  const defaultTheme = getTheme(router);
  const [theme, setTheme] = useState(defaultTheme);

  const [productParams, setProductParams] = useState<ProductParams>({});
  const [step, setStep] = useState<string>();

  useEffect(() => {
    // Datadog RUM
    if (
      typeof window !== 'undefined' &&
      process.env.NODE_ENV === 'production'
    ) {
      datadogRum.init({
        applicationId: process.env.NEXT_PUBLIC_DATADOG_RUM_APP_ID ?? '',
        clientToken: process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN ?? '',
        site: 'datadoghq.eu',
        service: 'resident-portal',
        env: process.env.NODE_ENV,
        sampleRate: 100,
        premiumSampleRate: 0,
        trackInteractions: true,
        allowedTracingOrigins: [
          process.env.NEXT_PUBLIC_KANTAN_GATEWAY_URI ?? '',
          process.env.NEXT_PUBLIC_MARKETPLACE_URI ?? '',
        ],
      });
    }
  }, []);

  const getPageInstanceKey =
    Component.getInstanceKey || ((router: NextRouter) => router.asPath);

  const experiments = useExperimentsProvider(serverCookies);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, height=device-height, minimal-ui, initial-scale=1, minimum-scale=1, maximum-scale=1"
        />
      </Head>
      {/* Segment script */}
      {/* TODO (adrian.pop): Switch back to strategy worker after we figure out what's going on with analytics.push error */}
      <Script id="segment-base" strategy="afterInteractive">
        {`!function () {
          var analytics = window.analytics = window.analytics || [];
          if (!analytics.initialize) if (analytics.invoked) window.console && console.error && console.error("Segment snippet included twice."); else {
            analytics.invoked = !0;
            analytics.methods = ["trackSubmit", "trackClick", "trackLink", "trackForm", "pageview", "identify", "reset", "group", "track", "ready", "alias", "debug", "page", "once", "off", "on", "addSourceMiddleware", "addIntegrationMiddleware", "setAnonymousId", "addDestinationMiddleware"];
            analytics.factory = function (e) {
              return function () {
                var t = Array.prototype.slice.call(arguments);
                t.unshift(e);
                analytics.push(t);
                return analytics
              }
            };
            for (var e = 0; e < analytics.methods.length; e++) {
              var key = analytics.methods[e];
              analytics[key] = analytics.factory(key)
            }
            analytics.load = function (key, e) {
              var t = document.createElement("script");
              t.type = "text/javascript";
              t.async = !0;
              t.src = "https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";
              var n = document.getElementsByTagName("script")[0];
              n.parentNode.insertBefore(t, n);
              analytics._loadOptions = e
            };
            analytics._writeKey = "${process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY}";
            ;analytics.SNIPPET_VERSION = "4.15.3";
            analytics.load("${process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY}");
            analytics.page();
          }
        }();`}
      </Script>
      <Script
        id="trustbox-base"
        strategy="lazyOnload"
        src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
      />
      <ProductConfigProvider
        productId={productParams.productId}
        productType={productParams.productType}
        requestBaseUrl={pageProps.requestBaseUrl ?? undefined}
      >
        {(productConfigQuery, isConfigLoading) => (
          <>
            {isConfigLoading && (
              <Loading useTheme={false} loadingText="Loading..." />
            )}
            <div
              style={{
                display: isConfigLoading ? 'none' : 'grid',
              }}
            >
              <ErrorModalContext.Provider value={{ isOpen, setIsOpen }}>
                <AppContext.Provider
                  value={{
                    experiments,
                    headerVariant,
                    cityLocation,
                    isFallbackAvailability,
                    setIsFallbackAvailability,
                  }}
                >
                  <Global styles={globalStyles} />
                  <StepContext.Provider
                    value={{
                      productConfig: productConfigQuery.data?.product,
                      currentStep: step
                        ? productConfigQuery.data?.product?.steps.find(
                            (s) => s.name === step,
                          )
                        : undefined,
                      setProductParams,
                      setStep,
                    }}
                  >
                    <ThemeContext.Provider
                      value={{
                        setTheme,
                      }}
                    >
                      <MUIThemeProvider theme={theme}>
                        <GlobalStateProvider>
                          <LazyMotion features={loadFeatures} strict>
                            <AnimatePresence mode="wait">
                              {getLayout(
                                <Component
                                  {...pageProps}
                                  key={getPageInstanceKey(router)}
                                />,
                              )}
                            </AnimatePresence>
                          </LazyMotion>
                        </GlobalStateProvider>
                      </MUIThemeProvider>
                    </ThemeContext.Provider>
                  </StepContext.Provider>
                </AppContext.Provider>
              </ErrorModalContext.Provider>
            </div>
          </>
        )}
      </ProductConfigProvider>
    </>
  );
}

const MyAppWrapper = (props: NextPageWithLayoutApp) => {
  const [queryClient] = React.useState(() => new QueryClient());

  // returning a simple not found route component alone to skip loading
  // product config and feature flags when the page lands in the browser
  if (props.Component === NotFoundPage) {
    return <NotFoundPage />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={props.pageProps.dehydratedState}>
        <MyApp {...props} />
        <ReactQueryDevtools initialIsOpen={false} />
      </Hydrate>
    </QueryClientProvider>
  );
};

MyAppWrapper.getInitialProps = async (appContext: NextAppContext) => {
  // do as little work as possible here, as we're going to show a blank 404 screen
  // in this case; requests from vulnerability scanner bots resolve to 404,
  // so this should reduce server load and downtime; this function doesn't seem
  // to be called for the 404 route in the release build as we've defined
  // `getStaticProps` in the 404 route file but it's called in the dev mode
  if (appContext.router.route === '/404') {
    return App.getInitialProps(appContext);
  }

  const queryClient = new QueryClient();

  if (appContext.ctx.req) {
    setQueryClient(appContext.ctx.req, queryClient);
    await prefetchProductConfig(appContext.ctx.req, queryClient);
  }

  const appProps = await App.getInitialProps(appContext);

  const requestHost = appContext.ctx.req?.headers.host;

  return {
    ...appProps,
    pageProps: {
      ...appProps.pageProps,
      serverCookies: getServerCookies(appContext.ctx.req, appContext.ctx.res),
      dehydratedState: dehydrateState(queryClient),
      requestBaseUrl: requestHost ? `http://${requestHost}` : null,
    },
  };
};

async function prefetchProductConfig(
  req: IncomingMessage,
  queryClient: QueryClient,
) {
  // don't prefetch product config during client-side page transitions:
  // - page transition is blocked until this function resolves
  // - we need the new product config only if we switch to another product's
  //   booking flow, which is unlikely
  // - we can easily load the config after the transition (we do)
  // - query params in the associated /_next/... requests don't always
  //   match the real params the page will get, so we end up loading the product config
  //   again after the transition in the browser anyway (we could ignore query params
  //   though, they don't affect product config choice at the moment)
  if (req.url?.includes('/_next')) return;

  const host = req.headers.host;
  const path = getPagePath(req);
  const url = `http://${host}${path}`;
  const productId = new URL(url).searchParams.get('productId') ?? undefined;
  const axiosBaseUrl = `http://${host}`;

  await queryClient.prefetchQuery(
    ['product-config', { productId, url: productId ? undefined : url }],
    () => fetchProductConfig({ productId, url, axiosBaseUrl }),
  );
}

export default MyAppWrapper;
