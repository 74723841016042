import styled from '@emotion/styled';
import { Swiper } from 'swiper/react';
import { mediaQuery } from 'theme/kantan';

import { InfoText as _InfoText } from 'components/InfoText';

export const CalendarSwiper = styled(Swiper)`
  width: 100%;

  .swiper-button-prev,
  .swiper-button-next {
    top: 28px;
    color: ${({ theme }) => theme.app.component.sliderArrow};
    background-color: white;
    border-radius: ${({ theme }) => theme.app.borderRadius.largeCorner};
    width: 48px;
    height: 48px;

    &::after {
      font-size: 18px;
      font-weight: bold;
    }

    @media (${mediaQuery('tablet')}) {
      top: 36px;
    }
  }

  @media (${mediaQuery('tablet')}) {
    .swiper-button-prev {
      left: 18px;
    }
    .swiper-button-next {
      right: 18px;
    }
  }
`;

export const CalendarGrid = styled.div<{ $backgroundColor?: string }>`
  display: grid;
  grid-template-columns: repeat(5, minmax(16px, 1fr));
  grid-template-rows: [navigation] 60px [days] 1fr [day-grid] 3fr;
  background-color: ${({ theme, $backgroundColor }) =>
    $backgroundColor ?? theme.app.background.primary};
  justify-items: center;

  @media (${mediaQuery('tablet')}) {
    background-color: unset;
    padding: ${({ theme }) => theme.app.padding.smallPadding};
  }
`;

export const CalendarDay = styled.div<{ $startColumn: number }>`
  grid-column: ${({ $startColumn }) => $startColumn + 1} / span 1;
  grid-row: day-grid;
  width: 100%;
  display: grid;
  justify-content: center;
  grid-template-columns: minmax(64px, 90%);
  grid-template-rows: repeat(3, minmax(64px, 1fr));
  gap: 10px 0;

  ${({ theme, $startColumn }) =>
    $startColumn !== 0 &&
    `
  border-left: 1px solid ${theme.app.border.secondary};
  `};

  @media (${mediaQuery('tablet')}) {
    gap: 8px 0;
    padding-top: ${({ theme }) => theme.app.padding.smallPadding};
  }
`;

export const NoSlotsAvailable = styled.div`
  grid-column: 1 / -1;
  margin-top: 20px;
`;

export const CalendarLoader = styled.div`
  width: 100%;
  height: 340px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MoreAvailabilityContainer = styled.div`
  grid-column: 1 / -1;
  grid-row: days/ span 2;

  display: flex;
  flex-direction: column;
  align-items: center;

  color: ${({ theme }) => theme.app.text.primary};

  h1 {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[18]};
    font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.bold};
  }

  h2 {
    margin-top: ${({ theme }) => theme.app.margin.largeMargin};
    padding: 0 ${({ theme }) => theme.app.padding.largePadding};
    text-align: center;
    max-width: 500px;
  }

  @media (${mediaQuery('tablet')}) {
    margin-top: ${({ theme }) => theme.app.margin.extraLargeMargin};
  }
`;

export const EmptySlot = styled.div<{ $backgroundColor?: string }>`
  ${({ $backgroundColor }) =>
    $backgroundColor && `background-color: ${$backgroundColor};`}

  @media (${mediaQuery('tablet')}) {
    background-color: ${({ theme }) => theme.app.background.primary};
    border-radius: ${({ theme }) => theme.app.borderRadius.smallCorner};
  }
`;

export const AlignButton = styled.div`
  margin-top: 20px;
  padding: 0 ${({ theme }) => theme.app.padding.smallPadding};

  @media (${mediaQuery('wideMobile')}) {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: unset;
  }
`;

export const CalendarLegend = styled.div<{ $marginTop?: number }>`
  display: flex;
  align-items: start;
  margin-top: ${({ $marginTop = '20px' }) => `${$marginTop}px`};
  padding: ${({ theme }) => theme.app.padding.smallPadding}
    ${({ theme }) => theme.app.padding.mediumPadding}
    ${({ theme }) => theme.app.padding.mediumPadding};
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[12]};
  line-height: 1.33;

  strong {
    color: ${({ theme }) => theme.app.messages.ecoBanner};
  }

  p {
    font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.light};
    margin-left: ${({ theme }) => theme.app.margin.smallMargin};
  }
`;

export const InfoText = styled.p`
  margin-left: 6px;
  color: ${({ theme }) => theme.app.text.actionDark};
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.light};
`;

export const InfoTextContainer = styled(_InfoText)`
  width: 100%;
  align-items: start;
  padding: 0 8px;
  margin-top: 0;
  margin-bottom: 0;

  @media (${mediaQuery('tablet')}) {
    width: unset;
    margin: 0 auto 4px;
    align-items: center;
    margin-top: 11px;
  }

  > svg {
    width: 30px;
    height: 18px;

    @media (${mediaQuery('tablet')}) {
      width: unset;
      height: unset;
    }
  }
`;
