import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';

import { ServerCookies } from 'utils/cookies';
import { trackEvent } from 'utils/tracking';

import {
  Experiment,
  ExperimentCookieValue,
  ExperimentName,
  experiments as experimentDeclarations,
} from './utils';

export type ActiveExperiments = Record<ExperimentName, string | undefined>;

/**
 * Returns active experiments and tracks that the user has seen them.
 * Should not be used directly other than in `_app.tsx`. Instead, use `useContext(AppContext)`.
 */
export const useExperimentsProvider = (
  serverCookies: ServerCookies,
): ActiveExperiments => {
  const [experiments, setExperiments] = useState<ClientExperiments>(() =>
    getClientExperiments(serverCookies),
  );

  useEffect(() => {
    const clientExperiments = getClientExperiments();

    setExperiments(clientExperiments);

    clientExperiments.forEach((experiment) => {
      if (experiment.variant === undefined || experiment.tracked) return;

      trackEvent('experiment viewed', {
        experiment_id: experiment.name,
        variation_id: experiment.variant,
      });

      setTrackedExperimentCookieValue({
        experiment: experiment.declaration,
        variant: experiment.variant,
      });
    });
  }, []);

  const experimentsVariants = getActiveExperiments(experiments);

  return experimentsVariants;
};

export function getClientExperiments(serverCookies?: ServerCookies) {
  const experiments = experimentDeclarations
    .map((experiment) => {
      const cookieValue = getExperimentCookieValue(
        experiment.cookie,
        serverCookies,
      );

      return {
        name: experiment.name,
        variant: cookieValue?.variant,
        tracked: cookieValue?.tracked,
        declaration: experiment,
      };
    })
    .filter((experiment) => experiment.variant !== undefined);

  return experiments;
}

type ClientExperiments = ReturnType<typeof getClientExperiments>;

function getExperimentCookieValue(
  cookieName: string,
  serverCookies?: ServerCookies,
): ExperimentCookieValue | undefined {
  const cookieValueRaw = serverCookies
    ? serverCookies[cookieName]
    : Cookies.get(cookieName);

  const cookieValue = cookieValueRaw
    ? (JSON.parse(cookieValueRaw) as ExperimentCookieValue)
    : undefined;

  return cookieValue;
}

function setTrackedExperimentCookieValue({
  experiment,
  variant,
}: {
  experiment: Experiment;
  variant: string;
}) {
  const cookieValue: ExperimentCookieValue = {
    variant,
    tracked: true,
  };

  Cookies.set(experiment.cookie, JSON.stringify(cookieValue), {
    expires: experiment.cookieOptions?.getExpirationDate?.(),
  });
}

export function getActiveExperiments(
  experiments: ClientExperiments,
): ActiveExperiments {
  const experimentsVariants = experiments.reduce((acc, experiment) => {
    acc[experiment.name] = experiment.variant;
    return acc;
  }, {} as ActiveExperiments);

  return experimentsVariants;
}
