import styled from '@emotion/styled';
import { TextField as MuiTextField } from '@mui/material';
import { forwardRef } from 'react';

import { shouldForwardProp } from 'utils/transientStyled';

const reusableBorder = () => `
  margin-top: 4px;
  margin-bottom: 4px;
`;

const ReusableTextField = styled(MuiTextField)`
  margin-top: 4px;
  margin-bottom: 4px;
`;

interface StyledTextFieldProps {
  $isValid?: boolean;
  $isInvalid?: boolean;
}

const StyledTextField = styled(ReusableTextField, {
  shouldForwardProp,
})<StyledTextFieldProps>`
  /* Can also use a function
  ${reusableBorder()}
  */

  .MuiInputBase {
    &-root {
      border: 1px solid #fff;
      border-radius: 4px;
      overflow: hidden;
      background-color: #fcfcfb;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.02em;
      color: #292b2e;

      & input {
        padding: 22px 12px 8px;

        // InputBase's default height is different in Mui v4 and v5.
        // Bringing it back to the default in v4 to match the old flow.
        // https://github.com/mui/material-ui/blob/v4.12.4/packages/material-ui/src/InputBase/InputBase.js#L43
        height: 1.1876em;
      }

      &:hover {
        background-color: #fff;
      }

      ${({ $isValid }) =>
        $isValid &&
        `
          border-color: #0DBB7C;
      `}
      ${({ $isInvalid }) =>
        $isInvalid &&
        `
          border-color: #DD2417;
          color: #DD2417;
      `}

      &.Mui-focused {
        background-color: #fff;
        border-color: #0b4ed5;
      }
    }
  }

  .MuiInputLabel {
    &-root {
      font-size: 12px;
      line-height: 24px;
      color: #a1a6b3;
    }

    &-asterisk {
      color: #dd2417;
    }

    &-shrink {
      transform: translate(12px, 2px);
    }
  }

  .MuiFormHelperText-root {
    margin-bottom: -4px;
    font-size: 12px;
    line-height: 18px;
  }
`;

interface TextInputProps {
  label: string;
  value?: unknown;
  name?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  helperText?: string;
  required?: boolean;
  valid?: boolean;
  invalid?: boolean;
  type?: string;
  autoComplete?: string;
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  function TextInput(
    {
      label,
      value,
      name,
      onChange,
      onBlur,
      required,
      valid,
      invalid,
      helperText,
      type,
      autoComplete,
    },
    ref,
  ) {
    return (
      <StyledTextField
        ref={ref}
        $isValid={valid}
        $isInvalid={invalid}
        label={label}
        value={value}
        name={name}
        required={required}
        fullWidth
        variant="filled"
        helperText={helperText}
        error={invalid}
        type={type}
        autoComplete={autoComplete}
        onChange={onChange}
        onBlur={onBlur}
        InputLabelProps={{ shrink: true }}
        FormHelperTextProps={{ error: invalid }}
        InputProps={{ disableUnderline: true, required: false }}
      />
    );
  },
);
