import styled from '@emotion/styled';
import { m } from 'framer-motion';
import { mediaQuery } from 'theme/kantan';

import { shouldForwardProp } from 'utils/transientStyled';

const OuterContainer = styled(m.section, {
  shouldForwardProp,
})`
  padding-top: 105px;
  display: flex;
  flex-flow: column;
  align-items: center;
  grid-column: 1 / -1;
  width: 100%;
  height: 100%;
  @media (${mediaQuery('tablet')}) {
    padding-top: unset;
  }
`;

const InnerContainer = styled(m.div, {
  shouldForwardProp,
})<{
  $padding?: string;
  $alignItems?: string;
}>`
  width: 100%;
  max-width: 990px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: ${({ $alignItems = 'center' }) => $alignItems};
  border-radius: ${({ theme }) => theme.app.borderRadius.largeCorner};
  overflow: hidden;
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[14]};
  padding-top: 5px;
  background-color: ${({ theme }) => theme.app.background.primary};

  @media (${mediaQuery('tablet')}) {
    height: 480px;
    box-shadow: 0 8px 28px rgba(0, 0, 0, 0.06);
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(20px);
    margin-top: 16px;
    padding: ${({ theme, $padding }) =>
      $padding ? $padding : `90px ${theme.app.margin.extraLargeMargin} 44px`};
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
  }
`;

export { InnerContainer, OuterContainer };
