import axios from 'axios';
import { useCallback } from 'react';

export const useCreateInvoicePol = ({}: {}) => {
  const createInvoice = useCallback(async (jobId: string) => {
    const response = await axios.post(
      '/api/services/create-invoice-pol',
      { jobId },
      {
        headers: {
          url: window.location.href,
        },
      },
    );
    const invoice = response.data;
    const { polPaymentUrl } = invoice;
    return polPaymentUrl;
  }, []);

  return { createInvoice };
};
