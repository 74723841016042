/**
 * Generated using svgr with the react-native option and a custom template
 * from the following file:
 * https://www.figma.com/file/Qul4lqXRzrrmmgHFgRyrmB/Brand?node-id=1401%3A1458
 */
import * as React from 'react';

import { IconBase } from './utils';

export const TickIcon: IconBase = (props) => {
  const { color, ...svgProps } = props;

  return (
    <svg width="36" height="35" viewBox="0 0 36 35" fill="none" {...svgProps}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color || '#089660'}
        d="M3.41666 17.5001C3.41666 9.45008 9.94999 2.91675 18 2.91675C26.05 2.91675 32.5833 9.45008 32.5833 17.5001C32.5833 25.5501 26.05 32.0834 18 32.0834C9.94999 32.0834 3.41666 25.5501 3.41666 17.5001ZM15.0833 20.6647L24.6937 11.0542L26.75 13.1251L15.0833 24.7917L9.24999 18.9584L11.3062 16.9022L15.0833 20.6647Z"
      />
    </svg>
  );
};
