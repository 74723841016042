export enum PARTNERS {
  KANTAN = 'KANTAN',
  OVO = 'OVO',
}

export const currentPartner = process.env.NEXT_PUBLIC_PARTNER;

export const mapPartnerEnvVariableToFullName = (
  partner?: PARTNERS | string,
) => {
  switch (partner) {
    case PARTNERS.KANTAN:
      return 'Kantan';
    case PARTNERS.OVO:
      return 'OVO Energy';
    default:
      return 'Kantan';
  }
};

export const partnerFullName = mapPartnerEnvVariableToFullName(currentPartner);

export function mapPartnerEnvVariableToShortName(partner?: PARTNERS | string) {
  switch (partner) {
    case PARTNERS.OVO:
      return 'OVO';
    case PARTNERS.KANTAN:
    default:
      return 'Kantan';
  }
}

export const mapPartnerEnvVariableToSlug = (partner?: PARTNERS | string) => {
  switch (partner) {
    case PARTNERS.KANTAN:
      return 'kantan';
    case PARTNERS.OVO:
      return 'ovo-energy';
  }
};

export type PartnerSlug = ReturnType<typeof mapPartnerEnvVariableToSlug>;

export const partnerSlug: PartnerSlug =
  mapPartnerEnvVariableToSlug(currentPartner);
