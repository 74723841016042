import styled from '@emotion/styled';
import { format, getMonth } from 'date-fns';
import React from 'react';
import { mediaQuery } from 'theme/kantan';

const CalendarDayHeaderContainer = styled.div`
  grid-column: 1 / -1;
  grid-row: navigation;
  place-self: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${({ theme }) => theme.app.background.calendarNavigation};
  border-top-left-radius: ${({ theme }) => theme.app.borderRadius.largeCorner};
  border-top-right-radius: ${({ theme }) => theme.app.borderRadius.largeCorner};

  @media (${mediaQuery('tablet')}) {
    border-radius: ${({ theme }) => theme.app.borderRadius.largeCorner};
  }
`;

const CalendarDayHeaderTitle = styled.h2`
  margin-bottom: -8px;
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[12]};
  line-height: 2;
  text-align: center;
  letter-spacing: -0.04em;
  color: ${({ theme }) => theme.app.text.secondary};
`;

const CalendarDayHeaderDateRange = styled.h3<{ $hasTitle: boolean }>`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[14]};
  line-height: 2;
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.semiBold};
  color: ${({ theme }) => theme.app.text.primary};
  letter-spacing: -0.04em;
  text-align: center;
`;

const DateRangeSpan = styled.span`
  display: inline-block;
`;

const getDateRangeLabel = (date1: Date, date2: Date) => {
  if (getMonth(date1) === getMonth(date2)) {
    return (
      <>
        {format(date1, 'd')}
        {'-'}
        <DateRangeSpan>{format(date2, 'd MMMM')}</DateRangeSpan>
      </>
    );
  }
  return (
    <>
      {format(date1, 'd MMM')}
      {' - '}
      <DateRangeSpan>{format(date2, 'd MMM')}</DateRangeSpan>
    </>
  );
};

interface CalendarHeaderProps {
  weekStart: Date;
  weekEnd: Date;
  title: string | null;
}

export const CalendarHeader = ({
  title,
  weekStart,
  weekEnd,
}: CalendarHeaderProps) => {
  const dateRange = getDateRangeLabel(weekStart, weekEnd);

  return (
    <CalendarDayHeaderContainer>
      {title && <CalendarDayHeaderTitle>{title}</CalendarDayHeaderTitle>}
      <CalendarDayHeaderDateRange $hasTitle={!!title}>
        {dateRange}
      </CalendarDayHeaderDateRange>
    </CalendarDayHeaderContainer>
  );
};
