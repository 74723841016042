import styled from '@emotion/styled';
import Image from 'next/legacy/image';
import checkMark from 'public/assets/images/Check.webp';
import engineer1 from 'public/assets/images/Engineer1.webp';

import { StrongText } from 'components/StrongText';

import {
  PageHeaderImageSection,
  ResponsiveContainer,
  ResponsiveImageContainer,
  SectionHeader,
} from './utilityStyles';

const PaymentInfoImageContainer = styled(ResponsiveImageContainer)`
  @media (min-width: 680px) {
    overflow: hidden;
    border-top-left-radius: ${({ theme }) =>
      theme.app.borderRadius.extraLargeCorner};
    border-top-right-radius: ${({ theme }) =>
      theme.app.borderRadius.extraLargeCorner};
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.08);
  }
`;

const PaymentInfoContainer = styled(ResponsiveContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 40px ${({ theme }) => theme.app.padding.mediumPadding};

  @media (min-width: 680px) {
    border-bottom-left-radius: ${({ theme }) =>
      theme.app.borderRadius.extraLargeCorner};
    border-bottom-right-radius: ${({ theme }) =>
      theme.app.borderRadius.extraLargeCorner};
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.08);
  }
`;

const PaymentInfoSectionHeader = styled(SectionHeader)`
  color: #45c2b1;
  margin-top: 60px;
`;

const EmojiContainer = styled.div`
  width: 185px;
  height: 148px;

  @media (min-width: 680px) {
    width: 200px;
    height: 162px;
  }
`;

export const PaymentInfoSection = () => {
  return (
    <PageHeaderImageSection>
      <PaymentInfoImageContainer>
        <Image
          src={engineer1}
          alt="Engineer fixing the boiler"
          layout="fill"
          objectFit="cover"
          placeholder="blur"
        />
      </PaymentInfoImageContainer>
      <PaymentInfoContainer>
        <EmojiContainer>
          <Image
            src={checkMark}
            alt="Green checkmark"
            width={200}
            height={162}
            placeholder="blur"
          />
        </EmojiContainer>
        <PaymentInfoSectionHeader>
          <StrongText>Pay only</StrongText> after{' '}
          <StrongText>the repair is completed</StrongText>
        </PaymentInfoSectionHeader>
      </PaymentInfoContainer>
    </PageHeaderImageSection>
  );
};
