import styled from '@emotion/styled';

import { StrongText } from 'components/StrongText';
import { formatPrice, getDiscountAndFullPrice } from 'utils/formatPrice';
import { JOB_TYPES } from 'utils/jobTypes';
import { getPriceFromJobType } from 'utils/tracking';
import { useManagedRates } from 'utils/useManagedRates';

const InlineText = styled.div`
  display: flex;

  & > * {
    margin: 0 3px;
  }
`;

const CrossedOutText = styled.del`
  color: ${({ theme }) => theme.app.text.tertiary};
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.light};
`;

export const NonComboPriceText = (jobType: JOB_TYPES) => {
  const managedRates = useManagedRates();
  const rate = getPriceFromJobType(jobType, managedRates);
  return (
    <StrongText weight="semiBold">
      {rate.isPromoRate && (
        <CrossedOutText>
          £{formatPrice(rate.prePromoAmount || rate.amount)}
        </CrossedOutText>
      )}{' '}
      £{formatPrice(rate.amount)}
    </StrongText>
  );
};

export const ComboPriceText = (jobType: JOB_TYPES) => {
  const managedRates = useManagedRates();
  const { fullComboPrice, isPromoComboRate, prePromoComboAmount } =
    getDiscountAndFullPrice(managedRates);

  return (
    <InlineText>
      <StrongText weight="semiBold">
        £{formatPrice(getPriceFromJobType(jobType, managedRates).amount)}
      </StrongText>
      <CrossedOutText>
        £{isPromoComboRate ? prePromoComboAmount : fullComboPrice}
      </CrossedOutText>
    </InlineText>
  );
};
