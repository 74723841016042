import { MotivationOption } from 'services/kantanClient';

export const getMotivationLabels = (
  selectedMotivations: string,
  allMotivationOptions?: MotivationOption[],
) => {
  const selectedMotivationsArray = selectedMotivations.split(',');

  return selectedMotivationsArray.map(
    (el) =>
      allMotivationOptions?.find((option) => option.value === el)?.label ??
      'Other',
  );
};
