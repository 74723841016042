export const KantanLogo = ({
  color = '#FFFFFF',
  width = '48',
  height = '48',
}: {
  color?: string;
  width?: string;
  height?: string;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 213 48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.563 0.731893C28.2347 -0.245527 30.3009 -0.243856 31.971 0.736269L55.6367 14.6252C57.4326 15.6791 58.5365 17.6109 58.5365 19.6995L58.5366 44.0472C58.5366 46.2113 56.7894 47.9656 54.6341 47.9656L44.8781 48C42.7228 48 40.9756 46.2113 40.9756 44.0472L40.9756 30.3188C40.9756 29.0938 40.3247 27.9619 39.2682 27.3495L31.2194 22.6839C30.012 21.984 28.5245 21.984 27.3172 22.6839L19.2684 27.3495C18.2118 27.9619 17.5609 29.0938 17.5609 30.3188L17.561 44.0472C17.561 46.2112 15.8138 47.9656 13.6586 47.9656H3.90249C1.74724 47.9656 5.95464e-05 46.2112 5.95464e-05 44.0472L0 19.6423C0 17.5503 1.10747 15.6159 2.90796 14.5632L26.563 0.731893Z"
        fill={color}
      />
      <path
        d="M32.1385 32.972C32.1385 31.4842 33.3397 30.2781 34.8215 30.2781C36.3032 30.2781 37.5044 31.4842 37.5044 32.972C37.5044 34.4598 36.3032 35.6659 34.8215 35.6659C33.3397 35.6659 32.1385 34.4598 32.1385 32.972Z"
        fill={color}
      />
      <path
        d="M84.8001 28.84L81.1601 33.24V41H75.0801V13.08H81.1601V26.36H81.4001L85.3601 20.96L91.6401 13.08H98.5201L89.1201 24.56L99.3601 41H92.2001L84.8001 28.84Z"
        fill={color}
      />
      <path
        d="M116.141 41C115.074 41 114.181 40.6667 113.461 40C112.768 39.3334 112.328 38.44 112.141 37.32H111.901C111.581 38.68 110.874 39.72 109.781 40.44C108.688 41.1334 107.341 41.48 105.741 41.48C103.634 41.48 102.021 40.92 100.901 39.8C99.781 38.68 99.221 37.2 99.221 35.36C99.221 33.1467 100.021 31.5067 101.621 30.44C103.248 29.3467 105.448 28.8 108.221 28.8H111.541V27.48C111.541 26.4667 111.274 25.6667 110.741 25.08C110.208 24.4667 109.314 24.16 108.061 24.16C106.888 24.16 105.954 24.4134 105.261 24.92C104.568 25.4267 103.994 26 103.541 26.64L100.021 23.52C100.874 22.2667 101.941 21.2934 103.221 20.6C104.528 19.88 106.288 19.52 108.501 19.52C111.488 19.52 113.728 20.1734 115.221 21.48C116.714 22.7867 117.461 24.6933 117.461 27.2V36.4H119.421V41H116.141ZM107.941 37.36C108.928 37.36 109.768 37.1467 110.461 36.72C111.181 36.2934 111.541 35.6 111.541 34.64V32.16H108.661C106.341 32.16 105.181 32.9467 105.181 34.52V35.12C105.181 35.8934 105.421 36.4667 105.901 36.84C106.381 37.1867 107.061 37.36 107.941 37.36Z"
        fill={color}
      />
      <path
        d="M121.794 41V20H127.714V23.56H127.954C128.328 22.4667 128.981 21.52 129.914 20.72C130.848 19.92 132.141 19.52 133.794 19.52C135.954 19.52 137.581 20.24 138.674 21.68C139.794 23.12 140.354 25.1733 140.354 27.84V41H134.434V28.32C134.434 26.96 134.221 25.96 133.794 25.32C133.368 24.6533 132.608 24.32 131.514 24.32C131.034 24.32 130.568 24.3867 130.114 24.52C129.661 24.6267 129.248 24.8134 128.874 25.08C128.528 25.3467 128.248 25.68 128.034 26.08C127.821 26.4534 127.714 26.8933 127.714 27.4V41H121.794Z"
        fill={color}
      />
      <path
        d="M151.383 41C149.329 41 147.769 40.48 146.703 39.44C145.663 38.4 145.143 36.88 145.143 34.88V24.6H142.183V20H143.663C144.463 20 145.009 19.8133 145.303 19.44C145.596 19.04 145.743 18.48 145.743 17.76V14.32H151.063V20H155.223V24.6H151.063V36.4H154.903V41H151.383Z"
        fill={color}
      />
      <path
        d="M173.255 41C172.188 41 171.295 40.6667 170.575 40C169.882 39.3334 169.442 38.44 169.255 37.32H169.015C168.695 38.68 167.988 39.72 166.895 40.44C165.802 41.1334 164.455 41.48 162.855 41.48C160.748 41.48 159.135 40.92 158.015 39.8C156.895 38.68 156.335 37.2 156.335 35.36C156.335 33.1467 157.135 31.5067 158.735 30.44C160.362 29.3467 162.562 28.8 165.335 28.8H168.655V27.48C168.655 26.4667 168.388 25.6667 167.855 25.08C167.322 24.4667 166.428 24.16 165.175 24.16C164.002 24.16 163.068 24.4134 162.375 24.92C161.682 25.4267 161.108 26 160.655 26.64L157.135 23.52C157.988 22.2667 159.055 21.2934 160.335 20.6C161.642 19.88 163.402 19.52 165.615 19.52C168.602 19.52 170.842 20.1734 172.335 21.48C173.828 22.7867 174.575 24.6933 174.575 27.2V36.4H176.535V41H173.255ZM165.055 37.36C166.042 37.36 166.882 37.1467 167.575 36.72C168.295 36.2934 168.655 35.6 168.655 34.64V32.16H165.775C163.455 32.16 162.295 32.9467 162.295 34.52V35.12C162.295 35.8934 162.535 36.4667 163.015 36.84C163.495 37.1867 164.175 37.36 165.055 37.36Z"
        fill={color}
      />
      <path
        d="M178.909 41V20H184.829V23.56H185.069C185.442 22.4667 186.095 21.52 187.029 20.72C187.962 19.92 189.255 19.52 190.909 19.52C193.069 19.52 194.695 20.24 195.789 21.68C196.909 23.12 197.469 25.1733 197.469 27.84V41H191.549V28.32C191.549 26.96 191.335 25.96 190.909 25.32C190.482 24.6533 189.722 24.32 188.629 24.32C188.149 24.32 187.682 24.3867 187.229 24.52C186.775 24.6267 186.362 24.8134 185.989 25.08C185.642 25.3467 185.362 25.68 185.149 26.08C184.935 26.4534 184.829 26.8933 184.829 27.4V41H178.909Z"
        fill={color}
      />
    </svg>
  );
};
