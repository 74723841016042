import { Icon } from 'theme/types';

export const BoilerLeakingIcon: Icon = (props) => {
  const { color, ...svgProps } = props;

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8413 3.4594C17.6573 3.17309 17.3403 3 17 3C16.6597 3 16.3427 3.17309 16.1587 3.4594L13.9848 6.84246C13.3418 7.84305 13 9.00735 13 10.1967C13 12.2972 14.7028 14 16.8033 14H17H17.1967C19.2972 14 21 12.2972 21 10.1967C21 9.00736 20.6582 7.84305 20.0152 6.84246L17.8413 3.4594ZM15.6674 7.92365L17 5.8498L18.3326 7.92365C18.7683 8.60172 19 9.39073 19 10.1967C19 11.1926 18.1926 12 17.1967 12H17H16.8033C15.8074 12 15 11.1926 15 10.1967C15 9.39073 15.2317 8.60172 15.6674 7.92365ZM8.84128 7.4594C8.6573 7.17309 8.34033 7 8 7C7.65967 7 7.3427 7.17309 7.15872 7.4594L4.26017 11.9701C3.43742 13.2505 3 14.7404 3 16.2623C3 18.8789 5.12114 21 7.7377 21H8H8.2623C10.8789 21 13 18.8789 13 16.2623C13 14.7404 12.5626 13.2505 11.7398 11.9701L8.84128 7.4594ZM5.94273 13.0513L8 9.8498L10.0573 13.0513C10.6728 14.0092 11 15.1237 11 16.2623C11 17.7743 9.77429 19 8.2623 19H8H7.7377C6.22571 19 5 17.7743 5 16.2623C5 15.1237 5.32723 14.0092 5.94273 13.0513Z"
        fill={color || '#292B2E'}
      />
    </svg>
  );
};
