import React from 'react';

export type CalendarTimesProps = {
  timeLabels: Array<{
    name: string;
    time: string;
  }>;
};

export const CalendarTimes = ({ timeLabels }: CalendarTimesProps) => {
  const toolTipText = 'Select from the available times on the right';

  return (
    <div className="marketplace-calendar-times marketplace-calendar-week-table__times">
      <div className="marketplace-calendar-times__slot--mimic"></div>
      {timeLabels.map(({ name, time }) => (
        <div
          key={name}
          className="marketplace-calendar__slot marketplace-calendar-times__slot"
          data-tip={toolTipText}
        >
          <h3 className="marketplace-calendar-times__name">{name}</h3>
          <p>{time}</p>
        </div>
      ))}
    </div>
  );
};
