import { NextRouter } from 'next/router';
import { defaultManagedRates } from 'store/utils';

import { SlotShape } from 'components/form';
import { extractDaySlots } from 'components/marketplace/CalendarFormNew/CalendarInput';
import { DEFAULT_CURRENCY } from 'layouts/MarketingLayout/MarketingLayout';

import {
  JobRateItemUnitEnum,
  ManagedRate,
} from '../services/models/ManagedRate';
import { toTimeSlotValue } from './calendarUtils';
import { FormattedSlots } from './dateManipulation';
import { JOB_TYPES } from './jobTypes';
import { BookableSlot } from './useBookableSlots';
import { CalloutData } from './usePersistedState';

export const trackEvent = (event: string, options: object) => {
  window.analytics.track(event, options);
};

const calculateNumberOfSlots = (appointmentSlots: Array<SlotShape>) => {
  const allDaySlots = appointmentSlots?.filter((appointment) => {
    return appointment.value.includes('All Day');
  });

  return appointmentSlots!.length + (allDaySlots?.length ?? 0);
};

const problemPageSubmitTracking = (
  calloutData: CalloutData,
  managedRates?: Record<JOB_TYPES, ManagedRate>,
  props?: PageTrackingMapProps,
) => {
  const { mainProblem, fuelType, additionalInformation, jobType } = calloutData;
  trackEvent('Problem type submitted', {
    problem_type: mainProblem,
    jobType,
    fuel: fuelType,
    additional_information: additionalInformation,
    price: getPriceFromJobType(jobType).amount,
    currency: DEFAULT_CURRENCY,
    gclid: props?.gclid,
  });
};

const appointmentPageSubmitTracking = (
  calloutData: CalloutData,
  managedRates?: Record<JOB_TYPES, ManagedRate>,
  props?: PageTrackingMapProps,
) => {
  const { appointmentSlots, jobType, mainProblem } = calloutData;

  const appointmentSlotCount =
    appointmentSlots && calculateNumberOfSlots(appointmentSlots);

  trackEvent('Appointment preferences submitted', {
    job_type: jobType,
    problem_type: mainProblem,
    price: getPriceFromJobType(jobType).amount,
    currency: DEFAULT_CURRENCY,
    count_of_slots_selected: appointmentSlotCount, // All day slots count as 2
    slots_shown: props?.extractedDates,
    slots_chosen: appointmentSlots,
    gclid: props?.gclid,
  });
};

interface PageTrackingMapProps {
  gclid?: string | string[];
  extractedDates?: FormattedSlots[];
}

export const pageTrackingMap: Record<
  string,
  (
    calloutData: CalloutData,
    managedRates?: Record<JOB_TYPES, ManagedRate>,
    props?: PageTrackingMapProps,
  ) => void
> = {
  '/': problemPageSubmitTracking,
  '/appointment': appointmentPageSubmitTracking,
};

export const grabGclid = (router: NextRouter): string | string[] | undefined =>
  router?.query['gclid'] ?? '';

export const getPriceFromJobType = (
  jobType: string = '',
  managedRates?: Record<JOB_TYPES, ManagedRate>,
): ManagedRate => {
  const managedRate = !managedRates
    ? undefined
    : managedRates[jobType as JOB_TYPES];

  return (
    managedRate || {
      amount: defaultManagedRates[jobType as JOB_TYPES].amount,
      isAmountWithVat:
        defaultManagedRates[jobType as JOB_TYPES].isAmountWithVat,
      unit: JobRateItemUnitEnum.Hour,
      jobType: jobType as JOB_TYPES,
      isPromoRate: false,
    }
  );
};

export const checkSelectedTimeIsEco = (
  allBookableSlots: BookableSlot[],
  selectedTimeSlots?: string[],
) => {
  if (!selectedTimeSlots) return false;
  // If multiple slots are selected, don't report ECO
  if (selectedTimeSlots.length !== 1) return false;
  return allBookableSlots.some((slot) => {
    // convert BookableSlot types in slotAvailability?.bookableSlots to same format as timeSlot from the store
    const date = slot.startDateTime;
    const time = slot.startDateTime.toTimeString();
    const timeSlotValue = toTimeSlotValue({
      date,
      slot: time,
    });
    const daySlot = extractDaySlots([slot]).slots.map((slot) => slot.time)[0];
    const sortedSlotConvertedToString =
      timeSlotValue.split('||')[0] + '||' + daySlot;

    // find the BookableSlot which matches the timeSlot from store
    if (sortedSlotConvertedToString === selectedTimeSlots[0])
      return slot.isRatingGood;
    return false;
  });
};
