import { IconBase } from './utils';

export const LaunchLinkIcon: IconBase = (props) => {
  const { color = '#0B4ED5', ...svgProps } = props;

  return (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" {...svgProps}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
        d="M1.33333 1.83333V11.1667H10.6667V6.5H12V11.1667C12 11.9 11.4 12.5 10.6667 12.5H1.33333C0.593333 12.5 0 11.9 0 11.1667V1.83333C0 1.1 0.593333 0.5 1.33333 0.5H6V1.83333H1.33333ZM7.33333 1.83333V0.5H12V5.16667H10.6667V2.77333L4.11333 9.32667L3.17333 8.38667L9.72667 1.83333H7.33333Z"
      />
    </svg>
  );
};
