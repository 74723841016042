import { captureException } from '@sentry/nextjs';
import axios from 'axios';

export const validEmailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const getIsValidPhoneNumber = async (phoneNumber: string) => {
  try {
    const response = await axios.get(
      `/api/validate-phone-number?phoneNumber=${phoneNumber}`,
    );
    return response.data.isValid;
  } catch (error) {
    // TODO: hook up to error modal
    captureException(error);
  }
};

export const isValidEmail = (email: string) => {
  return validEmailRegex.test(String(email).toLowerCase());
};

export async function validatePostcode(postcode?: string) {
  try {
    await axios.get(`https://api.postcodes.io/postcodes/${postcode}`);
  } catch (error) {
    const { data } = await axios.get(
      // NOTE: Once we go multinational, uk needs to be replaced with actual country
      `https://ws.postcoder.com/pcw/${process.env.NEXT_PUBLIC_POSTCODER_API_KEY}/position/uk/${postcode}?identifier=resident-portal-postcode`,
    );
    if (data.length === 0) throw error;
  }
}
