import { Typography } from '@mui/material';
import Image from 'next/legacy/image';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { Button } from 'components/Button';
import {
  AlignButton,
  OuterContainer as ButtonsOuterContainer,
} from 'components/NavigationButtonsContainer/NavigationButtonsContainer.styles';
import { useExistingStepContext } from 'pages/[productType]/[rateDomain]/[step]';
import { stepSlideTransitionProps } from 'utils/framerMotion';
import { JOB_TYPES } from 'utils/jobTypes';
import { currentPartner, PARTNERS } from 'utils/partners';

import { OuterContainer } from '../ReusedComponents.styles';
import {
  ImageContainer,
  InnerContainer,
  TextContainer,
} from './IneligibleHomeSetup.styles';

export const IneligibleHomeSetup = () => {
  const context = useExistingStepContext();
  const isKantanFlow: boolean =
    context.productConfig.temporaryJobType === JOB_TYPES.TADO_INSTALLATION &&
    currentPartner === PARTNERS.KANTAN;
  const router = useRouter();

  const onClickBack = () => {
    void router.back();
  };

  return (
    <OuterContainer {...stepSlideTransitionProps}>
      <InnerContainer $alignItems="center" $padding="40px 32px 44px">
        <ImageContainer>
          <Image
            src="/assets/images/DisconnectedCableGreen.webp"
            alt="Disconnected cable"
            width={200}
            height={200}
            layout="intrinsic"
          />
        </ImageContainer>
        <TextContainer>
          <Typography variant="h1">
            Sorry, we cannot complete your tado° Smart Thermostat installation
            this time
          </Typography>
          <Typography variant="subtitle1">
            Based on your previous selections, it seems your home set up is
            ineligible for installation by our engineers. Tado is compatible
            with most water-based gas heating systems, such as combi boilers and
            system boilers, and we offer installation for common heating setups.
            All installations require a working heating system and internet
            connection.
          </Typography>
          <ButtonsOuterContainer>
            <AlignButton>
              <Button variant="secondary" onClick={onClickBack}>
                Back
              </Button>
            </AlignButton>
            {!isKantanFlow && (
              <AlignButton>
                <Link href="https://my.ovoenergy.com/login">
                  <Button variant="bookingFlowCTA">Back to My OVO</Button>
                </Link>
              </AlignButton>
            )}
          </ButtonsOuterContainer>
        </TextContainer>
      </InnerContainer>
    </OuterContainer>
  );
};
