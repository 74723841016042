import styled from '@emotion/styled';
import React from 'react';

type Color = 'light' | 'dark';

const StyledSpinner = styled.div<{ $color: Color }>`
  margin: 0 auto;
  margin-top: -10px;
  position: relative;
  width: 60px;
  transform: scale(0.5);
  height: 34px;

  div {
    transform-origin: 28px 28px;
    animation: spinner 1.2s linear infinite;

    &:after {
      content: ' ';
      display: block;
      position: absolute;
      top: 3px;
      left: 26px;
      width: 4px;
      height: 12px;
      border-radius: 22%;
      background: ${({ $color }) => ($color === 'light' ? 'white' : 'black')};
    }

    //TODO: Replace nth-of-type with nth-of-type for safer SSR
    &:nth-of-type(1) {
      transform: rotate(0deg);
      animation-delay: -1.1s;
    }

    &:nth-of-type(2) {
      transform: rotate(30deg);
      animation-delay: -1s;
    }

    &:nth-of-type(3) {
      transform: rotate(60deg);
      animation-delay: -0.9s;
    }

    &:nth-of-type(4) {
      transform: rotate(90deg);
      animation-delay: -0.8s;
    }

    &:nth-of-type(5) {
      transform: rotate(120deg);
      animation-delay: -0.7s;
    }

    &:nth-of-type(6) {
      transform: rotate(150deg);
      animation-delay: -0.6s;
    }

    &:nth-of-type(7) {
      transform: rotate(180deg);
      animation-delay: -0.5s;
    }

    &:nth-of-type(8) {
      transform: rotate(210deg);
      animation-delay: -0.4s;
    }

    &:nth-of-type(9) {
      transform: rotate(240deg);
      animation-delay: -0.3s;
    }

    &:nth-of-type(10) {
      transform: rotate(270deg);
      animation-delay: -0.2s;
    }

    &:nth-of-type(11) {
      transform: rotate(300deg);
      animation-delay: -0.1s;
    }

    &:nth-of-type(12) {
      transform: rotate(330deg);
      animation-delay: 0s;
    }
  }

  @keyframes spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

interface SpinnerProps {
  color?: Color;
}

export const Spinner = ({ color = 'light' }: SpinnerProps) => (
  <StyledSpinner role="spinner" $color={color}>
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
  </StyledSpinner>
);
