import styled from '@emotion/styled';
import { m } from 'framer-motion';
import { useRouter } from 'next/router';
import React from 'react';
import { mediaQuery } from 'theme/kantan';
import { Spacing } from 'theme/kantan/spacing';

import { isBeyond } from 'utils/urls';

export const ReviewPage = styled(m.section)`
  grid-column: 1 / -1;

  @media (${mediaQuery('tablet')}) {
    max-width: 990px;
    width: 100%;
    justify-self: center;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0 8px 28px rgba(0, 0, 0, 0.06);
    backdrop-filter: blur(20px);
    border-radius: ${({ theme }) => theme.app.borderRadius.largeCorner};
    padding: ${({ theme }) => theme.app.padding.largePadding};
    margin-top: ${({ theme }) => theme.app.margin.mediumLargeMargin};
    height: max-content;
  }
`;

export const ReviewPageNavigation = styled.nav`
  grid-column: 1 / -1;
  display: flex;
  justify-content: center;
`;

export const ReviewPageForm = styled.form`
  > button {
    margin-top: ${({ theme }) => theme.app.margin.mediumLargeMargin};
  }

  @media (${mediaQuery('tablet')}) {
    display: grid;
    grid-template: 185px repeat(2, min-content) / repeat(2, 1fr);
    grid-template-areas: 'summary payment' 'summary checkbox' 'cta cta';
    gap: 0 32px;

    > button {
      margin-top: ${({ theme }) => theme.app.margin.largeMargin};
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

export const CheckboxesSection = styled.section<{ $marginTop?: number }>`
  grid-area: checkbox;
  display: flex;
  flex-direction: column;
  margin-top: ${({ $marginTop = 15 }) => `${$marginTop}px`};
`;

export const ReviewSubtitle = styled.span`
  color: ${({ theme }) => theme.app.landingPage.light.title};
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.semiBold};
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[24]};
  letter-spacing: -0.02em;
  line-height: 1.33;
`;

export const CTAContainer = styled.div<{ $marginTop?: Spacing }>`
  grid-area: cta;
  position: sticky;
  bottom: 10px;
  margin-top: ${({ theme }) => theme.app.margin.extraExtraLargeMargin};
  box-shadow: 0 0 0 100vmax white;
  clip-path: inset(-2vmax -100vmax);

  @media (${mediaQuery('tablet')}) {
    margin-top: ${({ theme, $marginTop = 40 }) =>
      theme.app.spacing[$marginTop]};
    justify-self: center;
    box-shadow: unset;
    clip-path: unset;
  }
`;

const Link = styled.a`
  color: ${({ theme }) => theme.app.text.link};
  text-decoration: none;
  cursor: pointer;
`;

const TextContainer = styled.span`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[14]};
  line-height: 22px;
`;

type TermsAndConditionsProps = {
  url?: string;
};

export const TermsAndConditions = () => {
  const router = useRouter();
  const url = 'https://kantan.co.uk/terms-and-conditions';

  let props = (
    <>
      I agree to the{' '}
      <Link target="_blank" href={url} rel="noreferrer">
        Terms and Conditions
      </Link>
    </>
  );

  if (isBeyond(router.asPath)) {
    const beyondUrl = 'https://beyond.ovoenergy.com/offer-terms';
    const homefixUrl = 'https://beyond.ovoenergy.com/homefix-terms';
    const beyondText = 'Beyond offer terms';
    const homefixText = 'Homefix terms';

    props = (
      <>
        I agree to the{' '}
        <Link target="_blank" href={beyondUrl} rel="noreferrer">
          {beyondText}
        </Link>{' '}
        and{' '}
        <Link target="_blank" href={homefixUrl} rel="noreferrer">
          {homefixText}
        </Link>
      </>
    );
  }

  return props;
};

type TermsConditionsPrivacyPolicyProps = {
  termsAndConditionsUrl: string;
  privacyPolicyUrl: string;
  termsAndConditionsText?: string;
};

export const TermsConditionsPrivacyPolicy = ({
  termsAndConditionsUrl = 'https://www.ovoenergy.com/terms/energy-experts',
  privacyPolicyUrl = 'https://www.ovoenergy.com/privacy-policy',
  termsAndConditionsText = 'Terms and Conditions',
}: TermsConditionsPrivacyPolicyProps) => {
  const router = useRouter();

  let props = (
    <TextContainer>
      I agree to the{' '}
      <Link target="_blank" href={termsAndConditionsUrl} rel="noreferrer">
        {termsAndConditionsText}
      </Link>{' '}
      and{' '}
      <Link target="_blank" href={privacyPolicyUrl} rel="noreferrer">
        Privacy Policy
      </Link>
    </TextContainer>
  );

  if (isBeyond(router.asPath)) {
    const beyondUrl = 'https://beyond.ovoenergy.com/offer-terms';
    const energyExpertUrl = 'https://www.ovoenergy.com/terms/energy-experts';
    const beyondText = 'Beyond offer terms';
    const energyExpertText = 'Energy Expert terms';

    props = (
      <TextContainer>
        I agree to the{' '}
        <Link target="_blank" href={beyondUrl} rel="noreferrer">
          {beyondText}
        </Link>
        ,{' '}
        <Link target="_blank" href={energyExpertUrl} rel="noreferrer">
          {energyExpertText}
        </Link>{' '}
        and{' '}
        <Link target="_blank" href={privacyPolicyUrl} rel="noreferrer">
          Privacy Policy
        </Link>
      </TextContainer>
    );
  }

  return props;
};
