import { useRouter } from 'next/router';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useGenericStore } from 'store';

import { BaseSelectionView } from 'components/BaseSelectionView';
import { ServiceFuelTypeRadioButton } from 'components/redesign/ServiceFuelTypeRadioButton';
import { useExistingStepContext } from 'pages/[productType]/[rateDomain]/[step]';
import { getFuelTypeOptions } from 'utils/formRadioOptions';
import { stepSlideTransitionProps } from 'utils/framerMotion';
import { constructPath } from 'utils/product/constructPath';
import { trackEvent } from 'utils/tracking';

import { InnerContainer, OuterContainer } from '../ReusedComponents.styles';

export const SelectFuelType = () => {
  const context = useExistingStepContext();
  const { currentStep } = context;
  const { nextStep } = currentStep.options;

  const [isNavigating, setIsNavigating] = useState(false);
  const router = useRouter();

  const { metadata, setState } = useGenericStore((state) => ({
    metadata: state.metadata,
    setState: state.setState,
  }));

  const form = useForm({
    mode: 'all',
    defaultValues: { fuelType: metadata.fuelType },
  });

  const fuelTypeOptions = getFuelTypeOptions(true);

  const handleClickButton = (fuelType: string) => {
    setIsNavigating(true);
    updateStore(fuelType);
    trackEvent('Fuel type selected', {
      fuel_type: fuelType,
    });
    handleNavigation(fuelType);
  };

  const updateStore = (fuelType: string) => {
    setState({
      metadata: {
        ...metadata,
        fuelType,
        // now that we've removed SelectThermostatType step, set thermostatType to
        // wireless here because SelectFuelType is the first step that is specific to
        // Tado where we can therefore do this (wireless is now the only possible option)
        thermostatType: 'Install Starter Kit: Wireless Smart Thermostat V3+',
      },
    });
  };

  const handleNavigation = (fuelType: string) => {
    if (fuelType === 'gas') {
      const selectBoilerTypePath = constructPath(router, 'boiler-type');
      void router.push(selectBoilerTypePath);
    } else if (fuelType === 'notSure') {
      void router.push(nextStep);
    } else {
      const ineligibleHomeSetupPath = constructPath(router, 'ineligible');
      void router.push(ineligibleHomeSetupPath);
    }
  };

  return (
    <OuterContainer {...stepSlideTransitionProps}>
      <InnerContainer>
        <FormProvider {...form}>
          <BaseSelectionView
            optionsContainerPadding="0 64px"
            maxOptionsPerRowDesktop={4}
          >
            {fuelTypeOptions.map((option) => {
              const { maxHeightMobile, gridColumn } = option;
              return (
                <ServiceFuelTypeRadioButton
                  key={option.value}
                  name="fuelType"
                  option={option}
                  isSelected={option.value === metadata.fuelType}
                  required
                  onChange={() => handleClickButton(option.value)}
                  disabled={isNavigating}
                  styles={{ height: maxHeightMobile, gridColumn }}
                />
              );
            })}
          </BaseSelectionView>
        </FormProvider>
      </InnerContainer>
    </OuterContainer>
  );
};
