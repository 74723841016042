import styled from '@emotion/styled';
import { ExpandMore } from '@mui/icons-material';
import { AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { useMemo } from 'react';
import { AddressData, ContactData } from 'store/types';
import { mediaQuery } from 'theme/kantan';

import { getAddress } from 'components/marketplace/CalendarFormNew/AppointmentPreview';
import { BasicAccordion } from 'layouts/MarketingLayout/components/utilityStyles';
import { formatDates } from 'utils/dateManipulation';
import { JOB_TYPES } from 'utils/jobTypes';
import { CalloutData } from 'utils/usePersistedState';

// TODO: Move to utils once redesign is finished
import { renderTimeSlot } from '../marketplace/CalendarFormNew/AppointmentSummary';

export const AppointmentSummaryTitle = styled.h3`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[18]};
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.bold};
  line-height: ${({ theme }) => theme.app.fontSettings.fontSize[24]};
`;

export const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.app.background.primary};
  margin: ${({ theme }) => theme.app.margin.mediumMargin} 0;
`;

const StyledAccordion = styled(BasicAccordion)`
  .MuiCollapse-root {
    width: 100%;
    height: 0;
  }
  @media (${mediaQuery('tablet')}) {
    grid-column: 1/ -1;
    grid-row: 3;
    background-color: unset;

    place-self: center;
    display: flex;
    flex-flow: column;
    align-items: start;
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  width: 100%;
  min-height: 0;
  padding: 0;

  .MuiAccordionSummary-content {
    flex-grow: 0;
    padding: 0;
    min-height: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .MuiAccordionSummary {
    &-expandIconWrapper {
      color: inherit;
    }

    &-content {
      margin: 0;
    }
  }
`;

const StyledAccordionEntry = styled(AccordionDetails)`
  padding: 0;
`;

const StyledH3 = styled(Typography)`
  color: ${({ theme }) => theme.app.reviewPage.text.summary};
  overflow-wrap: break-word;
`;

const StyledCaption = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.app.margin.mediumMargin};
`;

const StyledDiv = styled.div`
  margin-bottom: ${({ theme }) => theme.app.margin.mediumMargin};
`;

const renderTimeSlotCallout = (timeSlot: string) => {
  const [timeSlotDate, _unusedTimeSlotEndDate, timeSlotName] = (
    timeSlot ?? ''
  ).split('||');
  const [namedDay, date] = formatDates(new Date(timeSlotDate));

  return `${namedDay}${date} (${timeSlotName})`;
};

const renderTimeSlotService = (timeSlot: string) => {
  const [timeSlotDate, timeSlotName] = (timeSlot ?? '').split('||');
  const [namedDay, date] = formatDates(new Date(timeSlotDate));

  return `${namedDay}${date} (${timeSlotName})`;
};

const getContactDetails = (data: ContactData) =>
  [data?.fullName, data?.email, data?.phone, data?.alternativePhoneNumber]
    .filter(Boolean)
    .join(', ');

const typeToTextMap = {
  callout: 'Your boiler problem',
  service: 'Your service',
};

export type JobTypeGroup = 'callout' | 'service';

export type AppointmentSummaryProps = {
  type?: JobTypeGroup;
  isHeating?: boolean;
  appointmentSlots?: string[] | CalloutData['appointmentSlots'];
  bookingSummaryDetail?: JOB_TYPES | CalloutData['mainProblem'] | string[];
  addressData?: AddressData;
  contactData?: ContactData;
  ovoAccountNumber?: string;
  useAccordion?: boolean;
  bookingSummaryHeader?: string;
};

export const AppointmentSummary = ({
  type = 'callout',
  isHeating,
  bookingSummaryDetail,
  appointmentSlots,
  addressData,
  contactData,
  ovoAccountNumber,
  useAccordion = false,
  bookingSummaryHeader,
}: AppointmentSummaryProps) => {
  const address = addressData ? getAddress(addressData) : undefined;

  const timeSlots = useMemo(() => {
    switch (type) {
      case 'callout':
        return (appointmentSlots as CalloutData['appointmentSlots'])?.map(
          ({ value, group }) => (
            <StyledH3 variant="h3" key={group}>
              {renderTimeSlotCallout(value)}
            </StyledH3>
          ),
        );
      case 'service':
        if (Array.isArray(appointmentSlots) && appointmentSlots.length > 1) {
          const sortedArray = [...appointmentSlots];
          sortedArray.sort();

          return sortedArray?.map((slot) => (
            <StyledH3 variant="h3" key={slot as string}>
              {renderTimeSlotService(slot as string)}
            </StyledH3>
          ));
        } else {
          return renderTimeSlot(
            (appointmentSlots?.[0] as string | undefined) ?? '',
          );
        }
    }
  }, [appointmentSlots, type]);

  const renderTimeSlots = () => {
    if (!!appointmentSlots?.length) {
      if (type === 'service' && appointmentSlots.length === 1) {
        return (
          <StyledDiv>
            <StyledCaption variant="caption">
              Your appointment time:
            </StyledCaption>
            <StyledH3 variant="h3">{timeSlots}</StyledH3>
          </StyledDiv>
        );
      } else {
        return (
          <StyledDiv>
            <StyledCaption variant="caption">
              Your preferred times:
            </StyledCaption>
            {timeSlots}
          </StyledDiv>
        );
      }
    }
    return null;
  };

  const renderBookingSummaryHeader = () => {
    if (bookingSummaryHeader) {
      return bookingSummaryHeader;
    } else if (isHeating) {
      return typeToTextMap[type];
    } else {
      return 'Your plumbing problem';
    }
  };

  const renderBookingSummaryDetail = (
    bookingSummaryDetail?: string | string[],
  ) => {
    if (!bookingSummaryDetail) return;
    if (Array.isArray(bookingSummaryDetail)) {
      return bookingSummaryDetail.map((el: string, i: number) => (
        <StyledH3 key={i} variant="h3">
          {el}
        </StyledH3>
      ));
    } else {
      return <StyledH3 variant="h3">{bookingSummaryDetail}</StyledH3>;
    }
  };

  const renderContent = () => {
    return (
      <>
        <Divider />
        <div>
          <StyledDiv>
            <StyledCaption variant="caption">
              {renderBookingSummaryHeader()}:
            </StyledCaption>
            {renderBookingSummaryDetail(bookingSummaryDetail)}
          </StyledDiv>
          {renderTimeSlots()}
          {address && (
            <StyledDiv>
              <StyledCaption variant="caption">
                Your appointment address:
              </StyledCaption>
              <StyledH3 variant="h3">{address}</StyledH3>
            </StyledDiv>
          )}
          {contactData && (
            <StyledDiv>
              <StyledCaption variant="caption">
                Your contact details:
              </StyledCaption>
              <StyledH3 variant="h3">{getContactDetails(contactData)}</StyledH3>
            </StyledDiv>
          )}
          {ovoAccountNumber && (
            <StyledDiv>
              <StyledCaption variant="caption">
                Your OVO Account Number:
              </StyledCaption>
              <StyledH3 variant="h3">{ovoAccountNumber}</StyledH3>
            </StyledDiv>
          )}
        </div>
      </>
    );
  };

  return useAccordion ? (
    <StyledAccordion
      disableGutters
      square
      TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
    >
      <StyledAccordionSummary expandIcon={<ExpandMore />}>
        <AppointmentSummaryTitle>Booking details</AppointmentSummaryTitle>
      </StyledAccordionSummary>
      <StyledAccordionEntry>{renderContent()}</StyledAccordionEntry>
    </StyledAccordion>
  ) : (
    <>
      <AppointmentSummaryTitle>Booking details</AppointmentSummaryTitle>
      {renderContent()}
    </>
  );
};
