import { Icon } from 'theme/types';

export const SystemBoiler: Icon = (props) => {
  const { color = '#0A9828', opacity = 0.3, ...svgProps } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      {...svgProps}
    >
      <path
        opacity={opacity}
        d="M31.125 9.75H12.125V16.875H31.125V9.75Z"
        fill={color}
      />
      <path
        opacity={opacity}
        d="M31.125 31.125H12.125V38.25H31.125V31.125Z"
        fill={color}
      />
      <path d="M43 33.5H38.25V38.25H43V33.5Z" fill={color} />
      <path d="M12.125 5H5V43H12.125V5Z" fill={color} />
      <path d="M25.1875 5H18.0625V43H25.1875V5Z" fill={color} />
      <path d="M38.25 5H31.125V43H38.25V5Z" fill={color} />
      <path d="M38.25 14.5V9.75H43L38.25 14.5Z" fill={color} />
    </svg>
  );
};
