import React, { forwardRef, useEffect, useState } from 'react';

import { SlotRatingResponse } from 'pages/api/slot-rating';
import { usePersistedAppointmentData } from 'utils/usePersistedState';

import { CalendarSlotInput, CalendarSlotInputProps } from './CalendarSlotInput';

const SLOT_THRESHOLD = 1.0;

export const isSlotCloseEnough = (rating: SlotRatingResponse) => {
  return rating.score >= SLOT_THRESHOLD;
};

export type CalendarSlotRatingInputProps = Omit<
  CalendarSlotInputProps,
  'isEcoSlot' | 'isBestSlot'
> & {
  startDateTime: Date;
  endDateTime: Date;
};

export const CalendarSlotRatingInput = forwardRef<
  HTMLInputElement,
  CalendarSlotRatingInputProps
>(function CalendarSlotRatingInput(props, ref) {
  const { startDateTime, endDateTime } = props;
  const [state] = usePersistedAppointmentData();
  const [isEco, setIsEco] = useState(false);
  const [isDisabled, setIsDisabled] = useState(props.disabled);

  const postcode = state.postcode;

  useEffect(() => {
    const getRating = async () => {
      const region = new Intl.DateTimeFormat('en-GB');
      const timezone = region.resolvedOptions().timeZone;

      if (postcode && !isDisabled) {
        const rating: SlotRatingResponse = await fetch(
          `/api/slot-rating?postcode=${postcode}&startDatetime=${startDateTime.toISOString()}&endDatetime=${endDateTime.toISOString()}&timezone=${timezone}`,
        ).then((res) => res.json());

        const isCloseEnough = isSlotCloseEnough(rating);

        setIsDisabled(!isCloseEnough);
        setIsEco(rating.isEco);
      }
    };
    getRating();
  }, [startDateTime, endDateTime, postcode, isDisabled]);

  return (
    <CalendarSlotInput
      {...props}
      ref={ref}
      isEcoSlot={isEco}
      isBestSlot={false}
      disabled={isDisabled}
    />
  );
});
