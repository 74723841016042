import styled from '@emotion/styled';
import { format, isToday } from 'date-fns';
import { mediaQuery } from 'theme/kantan';

const CalendarWeekDaysContainer = styled.div<{
  $startColumn: number;
  $isCurrentDate: boolean;
}>`
  grid-row: days;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: start;
  width: 100%;
  color: ${({ theme, $isCurrentDate }) =>
    $isCurrentDate
      ? theme.app.text.currentCalendarWeekDay
      : theme.app.text.primary};

  margin-top: ${({ theme }) => theme.app.margin.largeMargin};
  padding-left: 2vw;

  ${({ theme, $startColumn }) =>
    $startColumn !== 0 &&
    `
  border-left: 1px solid ${theme.app.border.secondary};
  `}
`;

const CalendarWeekDayWeekDay = styled.p`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[14]};
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.semiBold};
  line-height: 1.25;
  text-align: center;

  @media (${mediaQuery('tablet')}) {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
    line-height: 1.5;
    letter-spacing: -0.03em;
  }
`;
const CalendarWeekDayDate = styled(CalendarWeekDayWeekDay)`
  color: ${({ theme }) => theme.app.text.secondary};
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.light};
`;

export const CalendarWeekDays = ({
  date,
  startColumn,
}: {
  date: Date;
  startColumn: number;
}) => {
  const weekday = format(date, 'EEE');
  const displayDate = format(date, 'd MMM');
  const isCurrentDate = isToday(date);

  return (
    <CalendarWeekDaysContainer
      $startColumn={startColumn}
      $isCurrentDate={isCurrentDate}
    >
      <CalendarWeekDayWeekDay>{weekday}</CalendarWeekDayWeekDay>
      <CalendarWeekDayDate>{displayDate}</CalendarWeekDayDate>
    </CalendarWeekDaysContainer>
  );
};
