export const LocationIcon = ({
  width = '24',
  height = '34',
  color = '#A1A6B3',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1685 16.7589L11.9997 28.5836L4.8309 16.7589C1.35383 11.0236 5.39373 3.61834 11.9997 3.61834C18.6056 3.61834 22.6455 11.0236 19.1685 16.7589ZM2.09562 18.4879C-2.70813 10.5643 2.87321 0.333496 11.9997 0.333496C21.1261 0.333496 26.7075 10.5643 21.9037 18.4879L13.0708 33.0576C12.5783 33.8699 11.421 33.8699 10.9285 33.0576L2.09562 18.4879ZM12 8.54561C10.2233 8.54561 8.78303 10.0163 8.78303 11.8305C8.78303 13.6446 10.2233 15.1153 12 15.1153C13.7767 15.1153 15.217 13.6446 15.217 11.8305C15.217 10.0163 13.7767 8.54561 12 8.54561Z"
        fill={color}
      />
    </svg>
  );
};
