import { Icon } from 'theme/types';

export const LPGOVOIcon: Icon = (props) => {
  const { color, ...svgProps } = props;

  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path opacity="0.3" d="M40 40H8V44H40V40Z" fill={color || '#0A9828'} />
      <path opacity="0.3" d="M40 4H8V8H40V4Z" fill={color || '#0A9828'} />
      <path
        d="M10 8V40H38V8H10ZM27.2 28.1C25.2 29 24 30.9 24 33.1V33.6C19.9 33.6 16.5 30.4 16.5 26.4C16.5 23.5 18.2 21.1 20.8 19.9C22.8 19 24 17.1 24 14.9V14.4C28.1 14.4 31.5 17.6 31.5 21.6C31.5 24.5 29.8 26.9 27.2 28.1Z"
        fill={color || '#0A9828'}
      />
    </svg>
  );
};
