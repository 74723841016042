import { Icon } from 'theme/types';

export const NoHeatingIcon: Icon = (props) => {
  const { color, ...svgProps } = props;

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2213 17.2117L21.0575 20.0762L22.4577 18.662L5.30512 1.33789L3.90491 2.7521L9.72012 8.62548C9.65127 8.75306 9.57811 8.87808 9.49996 8.99996C9.17693 9.50374 8.95021 9.08082 8.6899 8.48054C8.66554 8.42436 8.64089 8.36663 8.61583 8.30796L8.61574 8.30773C8.50248 8.04252 8.38103 7.75814 8.24156 7.51131C8.20559 7.44764 8.16842 7.38647 8.12988 7.32877C7.84823 6.90713 7.49354 6.67089 6.99996 6.99995C5.49996 8.49995 4.99996 13.0997 4.99996 14C4.99996 17.8659 8.13397 21 12 21L12.009 20.9999C14.7135 20.9965 17.0586 19.4594 18.2213 17.2117ZM16.7091 15.6844L11.1742 10.0941C11.0089 10.3495 10.4286 11.1768 9.30977 11.2219C8.41767 11.2578 7.83721 10.7721 7.55523 10.4627C7.497 10.6845 7.44272 10.9135 7.39257 11.1462C7.10133 12.4975 6.99996 13.7271 6.99996 14C6.99996 15.6358 7.78551 17.0881 8.99996 18.0003L8.99996 18C8.99996 16.484 9.41854 16.0678 11.4046 14.0931C11.5892 13.9096 11.7874 13.7126 12 13.5C14 13.5 15 16.3431 15 18V18.0003C15.7756 17.4177 16.3763 16.6148 16.7091 15.6844ZM16.993 7.73987C17.7741 9.03225 18.5508 10.3172 18.8601 12.2002L14.9892 8.2906C14.4148 7.32777 13.7985 6.20141 13.3941 4.7558C13.2963 4.86759 13.1794 5.04806 13.0463 5.34985C12.9481 5.57228 12.8639 5.80261 12.7792 6.05856L11.2356 4.49949C11.8312 3.17495 12.774 2.25262 15 2.49995C15.2327 4.82761 16.1157 6.28839 16.993 7.73986L16.993 7.73987ZM12 19L12.0044 18.9999C12.5546 18.9976 13 18.5508 13 18C13 17.4941 12.8283 16.6997 12.4936 16.0854C12.4619 16.0272 12.4306 15.974 12.4 15.9256C11.7481 16.5769 11.404 16.9324 11.1835 17.2376C11.0731 17.3903 11.052 17.4572 11.0418 17.4931C11.0258 17.5497 11 17.6778 11 18C11 18.5522 11.4477 19 12 19Z"
        fill={color || '#292B2E'}
      />
    </svg>
  );
};
