import styled from '@emotion/styled';
import { mediaQuery } from 'theme/tado-ovo';

import { InnerContainer as _InnerContainer } from '../ReusedComponents.styles';

const InnerContainer = styled(_InnerContainer)`
  justify-content: flex-start;
  gap: ${({ theme }) => theme.app.margin.mediumMargin};

  @media (${mediaQuery('tablet')}) {
    padding: ${({ theme }) =>
      `${theme.app.margin.mediumMargin} ${theme.app.margin.extraLargeMargin} ${theme.app.margin.extraExtraLargeMargin}`};
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.app.margin.mediumLargeMargin};
`;

const TextContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${({ theme }) => theme.app.margin.mediumMargin};

  button {
    height: 44px;
    width: 336px;
    margin-top: ${({ theme }) => theme.app.margin.extraExtraLargeMargin};

    @media (${mediaQuery('tablet')}) {
      margin-top: ${({ theme }) => theme.app.margin.largeMargin};
    }
  }

  > h1 {
    text-align: center;
    font-size: 16px;
    line-height: 24px;

    @media (${mediaQuery('tablet')}) {
      text-align: unset;
      font-size: 18px;
    }
  }

  > h6 {
    text-align: center;
    margin-top: 16px;
    max-width: 673px;
    line-height: 20px;
  }
`;

export { ImageContainer, TextContainer, InnerContainer };
