import axios from 'axios';
import { Dispatch, SetStateAction, useState } from 'react';

type ValidatePostcodeHooks = {
  validatePostcode: (postcode?: string | undefined) => Promise<boolean>;
  isTooltipOpen: boolean;
  setIsTooltipOpen: Dispatch<SetStateAction<boolean>>;
  tooltipMessage: () => string;
  isPostcodeError: boolean;
};

// TODO: adapt Services flow to use this hook
export const useValidatePostcode = (): ValidatePostcodeHooks => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [isPostcodeError, setIsPostcodeError] = useState(false);

  const handleInvalidPostcode = async () => {
    setIsTooltipOpen(true);
    setIsPostcodeError(true);
    return false;
  };

  const tooltipMessage = () => {
    return isPostcodeError
      ? 'Please enter a valid postcode'
      : 'Enter a postcode to start booking';
  };

  const validatePostcode = async (postcode?: string) => {
    setIsPostcodeError(false);

    if (!postcode) {
      setIsTooltipOpen(true);
      return false;
    }
    return await firstValidationAttempt(postcode);
  };

  const firstValidationAttempt = async (postcode: string) => {
    try {
      await axios.get(`https://api.postcodes.io/postcodes/${postcode}`);
      return true;
    } catch {
      return await secondValidationAttempt(postcode);
    }
  };

  const secondValidationAttempt = async (postcode: string) => {
    try {
      const result = await axios.get(
        // NOTE: Once we go multinational, uk needs to be replaced with actual country
        `https://ws.postcoder.com/pcw/${process.env.NEXT_PUBLIC_POSTCODER_API_KEY}/position/uk/${postcode}?identifier=resident-portal-postcode`,
      );
      if (result.data?.length) {
        return true;
      } else {
        throw new Error('No postcode found.');
      }
    } catch (e) {
      console.error(e);
      return await handleInvalidPostcode();
    }
  };

  return {
    validatePostcode,
    isTooltipOpen,
    setIsTooltipOpen,
    tooltipMessage,
    isPostcodeError,
  };
};
