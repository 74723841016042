import { trademarkLinks as trademarkLinksKantan } from 'components/Footer/Footer';
import { PRODUCT_NAME } from 'utils/jobTypes';

import { PARTNERS } from './name';

export * from './name';

export const getSupportInfoFromProductName = (productName?: PRODUCT_NAME) => {
  switch (productName) {
    case PRODUCT_NAME.HOME_ENERGY_ASSESSMENT:
      return {
        emailAddress: 'energy.experts@ovo.com',
        phoneNumber: '+44 333 034 1562',
      };
    default:
      return {
        emailAddress: 'support@kantan.tech',
        phoneNumber: '+44 330 818 8759',
        termsAndConditionsCopy:
          'Kantan is responsible for the management of jobs',
      };
  }
};

export const getSupportInfoFromPartner = (partner?: PARTNERS | string) => {
  switch (partner) {
    case 'KANTAN':
      return {
        emailAddress: 'support@kantan.tech',
        phoneNumber: '+44 330 818 8759',
        phoneNumberText: '0330 818 8759',
        termsAndConditionsCopy:
          'Kantan is responsible for the management of jobs',
      };
    case 'OVO':
      return {
        emailAddress: 'homefix@ovo.com',
        phoneNumber: '+44 330 818 6751',
        phoneNumberText: '0330 818 6751',
        termsAndConditionsCopy:
          'This website is operated by Kantan on behalf of OVO Energy Limited',
      };
    default:
      return {
        emailAddress: 'support@kantan.tech',
        phoneNumber: '+44 330 818 8759',
        phoneNumberText: '0330 818 8759',
        termsAndConditionsCopy:
          'Kantan is responsible for the management of jobs',
      };
  }
};

export const trademarkLinksOvo = [
  { title: 'Privacy', url: 'https://kantan.co.uk/privacy-policy' },
  { title: 'Cookies', url: 'https://kantan.co.uk/cookie-policy' },
  {
    title: 'Terms',
    url: 'https://kantan.co.uk/ovo-on-demand-terms-and-conditions',
  },
];

export const trademarkLinksOvoBeyond = [
  { title: 'Privacy', url: 'https://kantan.co.uk/privacy-policy' },
  {
    title: 'Cookies',
    url: 'https://beyond.ovoenergy.com/homefix-cookie-policy',
  },
  {
    title: 'Terms',
    url: 'https://kantan.co.uk/ovo-on-demand-terms-and-conditions',
  },
];

export const getTrademarkLinksFromPartner = (
  partner?: PARTNERS | string,
  isBeyond?: boolean,
) => {
  switch (partner) {
    case 'KANTAN':
      return trademarkLinksKantan;
    case 'OVO':
      return isBeyond ? trademarkLinksOvoBeyond : trademarkLinksOvo;
    default:
      return trademarkLinksKantan;
  }
};

export const getNoAddressesImageFromPartner = (partner?: PARTNERS | string) => {
  switch (partner) {
    case PARTNERS.KANTAN:
      return {
        path: '/assets/images/NoAddressesKantan.webp',
        height: 270,
        width: 299,
      };
    case PARTNERS.OVO:
      return {
        path: '/assets/images/NoAddressesOVO.webp',
        height: 255,
        width: 227,
      };
    default:
      return {
        path: '/assets/images/NoAddressesKantan.webp',
        height: 270,
        width: 299,
      };
  }
};
