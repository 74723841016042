import { UseFormReturn } from 'react-hook-form';

import { trackEvent } from 'utils/tracking';

import { YourHomeOption, YourHomeQuestionKey } from '../YourHome';
import {
  InlineTextContainer,
  LinkContainer,
  LinkText,
  SupplementaryText,
} from './../YourHome.styles';

export const useYourHomeOptions = () => {
  const yourHomeOptions: YourHomeOption[] = [
    {
      key: 'is_your_heating_working?',
      label: 'Is your heating working?',
      requiredForEligibility: true,
      marginBottom: {
        mobile: 25,
        tablet: 40,
      },
      supplementaryText: (form) => renderBookRepairText(form),
    },
    {
      key: 'do_you_have_a_working_internet_connection?',
      label: 'Do you have a working internet connection?',
      requiredForEligibility: true,
      marginBottom: {
        mobile: 25,
        tablet: 40,
      },
    },
    {
      key: 'do_you_have_a_smart_phone?',
      label: 'Do you have a smart phone?',
      requiredForEligibility: true,
      marginBottom: {
        mobile: 25,
        tablet: 40,
      },
    },
    {
      key: 'do_you_have_underfloor_heating_in_your_property?',
      label: 'Do you have underfloor heating in your property?',
      requiredForEligibility: false,
      marginBottom: {
        mobile: 0,
        tablet: 0,
      },
    },
  ];

  return { yourHomeOptions };
};

const renderBookRepairText = (
  form: UseFormReturn<Record<YourHomeQuestionKey, string>>,
) => {
  if (form.getValues('is_your_heating_working?') !== 'No') return;
  return (
    <InlineTextContainer>
      <SupplementaryText>Let&apos;s fix that first!</SupplementaryText>
      <LinkContainer onClick={handleClick}>
        <LinkText>Book a repair</LinkText>
      </LinkContainer>
    </InlineTextContainer>
  );
};

const handleClick = () => {
  trackEvent('Booking flow type changed', {
    from_booking_flow_type: 'tado_installation',
    to_booking_flow_type: 'heating_repair',
  });
  window.open(`${window.location.origin}/repair/heating`, '_blank');
};
