import { Icon } from 'theme/types';

export const SolarIcon: Icon = (props) => {
  const { color, ...svgProps } = props;

  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.3"
        d="M38 10H10L6 34H42L38 10Z"
        fill={color || '#0A9828'}
      />
      <path d="M29 34H19V38H29V34Z" fill={color || '#0A9828'} />
      <path
        opacity="0.3"
        d="M29 18L28 10H20L19 18H29Z"
        fill={color || '#0A9828'}
      />
      <path
        opacity="0.3"
        d="M30 26H40.7L39.3 18H29L30 26Z"
        fill={color || '#0A9828'}
      />
      <path
        opacity="0.3"
        d="M30 26H18L17 34H31L30 26Z"
        fill={color || '#0A9828'}
      />
      <path
        opacity="0.3"
        d="M19 18H8.69999L7.29999 26H18L19 18Z"
        fill={color || '#0A9828'}
      />
    </svg>
  );
};
