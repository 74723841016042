import styled from '@emotion/styled';
import { mediaQuery } from 'theme/tado-ovo';

import { InnerContainer as _InnerContainer } from '../ReusedComponents.styles';

const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InnerContainer = styled(_InnerContainer)`
  height: 560px;

  @media (${mediaQuery('tablet')}) {
    height: 550px;
  }
`;

const InlineTextContainer = styled.div`
  display: flex;
  align-items: start;
  margin-top: 2px;
  padding: 0;
  height: 65px;

  @media (${mediaQuery('tablet')}) {
    margin-top: 0;
    padding-left: 373px;
  }
`;

const LinkContainer = styled.div`
  margin-left: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const LinkText = styled.a`
  color: ${({ theme }) => theme.app.text.action};
  margin-right: 5px;
`;

const SupplementaryText = styled.p<{ $marginTopMobile?: string }>`
  margin-top: ${({ $marginTopMobile }) => $marginTopMobile};
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[14]};
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.light};

  @media (${mediaQuery('tablet')}) {
    margin-top: unset;
  }
`;

export {
  QuestionsContainer,
  InnerContainer,
  InlineTextContainer,
  LinkContainer,
  LinkText,
  SupplementaryText,
};
