import styled from '@emotion/styled';
import { useMediaQuery } from '@mui/material';
import { useRouter } from 'next/router';
import { useController } from 'react-hook-form';
import { mediaQuery } from 'theme/energy_experts';

import { Button } from 'components/Button';
import { OnChange } from 'components/redesign/AddressStep/AddressInputManual';
import { MuiTextField } from 'components/redesign/MuiTextField';
import { InputChangeEvent } from 'components/redesign/MuiTextField/MuiTextField';
import {
  InnerContainer,
  RadioContainer,
} from 'components/redesign/ServiceFuelTypeRadioButton/ServiceFuelTypeRadioButton.styles';
import { useExistingStepContext } from 'pages/[productType]/[rateDomain]/[step]';

import { MotivationRadioButton } from '.';

type ReasonOptionsProps = {
  selected: string;
  handleSelectOption: (value: string) => void;
  handleOtherInputChange: (e: InputChangeEvent, onChange: OnChange) => void;
  navigateToNextStep: () => void;
};

export const ReasonOptions = ({
  selected,
  handleSelectOption,
  handleOtherInputChange,
  navigateToNextStep,
}: ReasonOptionsProps) => {
  const router = useRouter();
  const context = useExistingStepContext();
  const { motivations } = context.productConfig;
  const options = motivations?.options ?? [];
  const { prevStep } = context.currentStep.options;
  const otherValue = 'other';
  const otherLabel = 'Other';
  const {
    formState: { isValid },
    fieldState: { error },
  } = useController({
    name: 'otherReasonDetail',
  });

  const otherReasonFormAttributes = {
    name: 'otherReasonDetail',
    label: 'Please tell us more',
    placeholder: 'Why did you choose Energy Experts?',
    required: selected.includes('other'),
  };
  const isTabletOrLarger = useMediaQuery(`${mediaQuery('tablet', true)}`);

  return (
    <>
      <ReasonOptionsContainer
        $containsOther={
          !!motivations?.config?.includeTextInput ||
          options.some((option) => option.value === 'other')
        }
      >
        {options?.map((option) => (
          <MotivationRadioButton
            key={option.value}
            name="motivations"
            option={option}
            isSelected={selected.includes(option.value)}
            required
            onChange={() => handleSelectOption(option.value)}
          />
        ))}
        {!!motivations?.config?.includeTextInput && (
          <MotivationRadioButton
            key={otherValue}
            name="motivations"
            option={{
              label: motivations.config?.textInputLabel || otherLabel,
              value: otherValue,
            }}
            isSelected={selected.includes(otherValue)}
            required
            onChange={() => handleSelectOption(otherValue)}
          />
        )}
        {selected.includes('other') && isTabletOrLarger && (
          <OtherInputContainer $isValid={!error}>
            <MuiTextField
              option={otherReasonFormAttributes}
              handleFieldChange={handleOtherInputChange}
              placeholder={otherReasonFormAttributes.placeholder}
            />
          </OtherInputContainer>
        )}
      </ReasonOptionsContainer>
      {selected.includes('other') && !isTabletOrLarger && (
        <OtherInputContainer $isValid={!error}>
          <MuiTextField
            option={otherReasonFormAttributes}
            handleFieldChange={handleOtherInputChange}
            placeholder={otherReasonFormAttributes.placeholder}
          />
        </OtherInputContainer>
      )}
      <ButtonsContainer>
        <AlignButton>
          <Button variant="secondary" onClick={() => router.push(prevStep)}>
            Back
          </Button>
        </AlignButton>
        <AlignButton>
          <Button
            disabled={!selected || !isValid}
            variant="bookingFlowCTA"
            onClick={navigateToNextStep}
          >
            Next
          </Button>
        </AlignButton>
      </ButtonsContainer>
    </>
  );
};

const ReasonOptionsContainer = styled.div<{ $containsOther: boolean }>`
  @media (${mediaQuery('tablet', false, true)}) {
    ${RadioContainer} {
      &:last-of-type {
        grid-column: 1/-1;
        justify-self: center;
        width: 100%;
        max-height: 54px;

        ${InnerContainer} {
          gap: 0;
        }
      }
    }
  }

  display: grid;
  flex-flow: row wrap;
  column-gap: ${({ theme }) => theme.app.margin.mediumMargin};
  row-gap: ${({ theme }) => theme.app.margin.mediumLargeMargin};
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-rows: 180px 180px auto;
  margin-bottom: ${({ theme }) => theme.app.margin.mediumLargeMargin};
  ${RadioContainer} {
    ${({ $containsOther }) =>
      $containsOther
        ? `
          &:last-of-type {
            grid-column: 1;
            grid-auto-rows: 100px;
            max-height: 54px;
  
            ${InnerContainer} {
              gap: 0;
            }
          }
        `
        : ''}
  }

  > div {
    flex-basis: fit-content;
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[14]};
    line-height: 1.3;
  }

  @media (${mediaQuery('tablet')}) {
    margin-bottom: ${({ theme }) => theme.app.margin.mediumMargin};
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    justify-content: space-evenly;

    > div {
      height: 100%;
    }
  }

  @media screen and (max-width: 957px) {
    grid-auto-rows: auto;
  }
`;

const OtherInputContainer = styled.div<{ $isValid: boolean }>`
  margin-left: 1em;
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.app.margin.extraLargeMargin};

  label {
    font-size: 22px;
    font-family: ${({ theme }) => theme.app.fontSettings.fontFamily};
    font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.semiBold};
    color: ${({ theme }) => theme.app.landingPage.generalText.major};
  }

  & label.Mui-focused {
    color: ${({ theme }) => theme.app.landingPage.generalText.major} !important;
  }

  .MuiInputBase-root {
    margin-top: ${({ theme }) => theme.app.margin.mediumLargeMargin};
  }

  .MuiInputBase-input {
    padding: 12px 0 0 !important;
    border-bottom: 1px solid
      ${({ $isValid, theme }) =>
        $isValid ? '#D1D6DE !important' : theme.app.messages.error};
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
    font-family: ${({ theme }) => theme.app.fontSettings.fontFamily};
  }

  input::placeholder {
    color: #666d78;
    opacity: 1;
  }

  .MuiFormLabel-asterisk {
    color: ${({ theme }) => theme.app.text.error};
    margin-left: -2px;
  }

  @media (${mediaQuery('tablet')}) {
    margin-bottom: unset;
  }

  @media screen and (max-width: 600px) {
    margin-left: unset;
    grid-column: unset;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 363px;
  margin: 0 auto;

  button {
    font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.bold};
  }

  > div:first-of-type > button {
    border: ${({ theme }) => `1px solid ${theme.app.text.actionCta}`};
  }

  > div:last-of-type > button {
    border: none;
  }

  @media (${mediaQuery('tablet')}) {
    flex-direction: row;
    width: unset;
    max-width: 458px;
    margin: 0 auto;
  }
`;

const AlignButton = styled.div`
  @media (${mediaQuery('tablet', false, true)}) {
    &:first-of-type {
      margin-right: 10px;
    }
    &:last-of-type {
      width: 100%;
      button {
        width: 100%;
      }
    }
  }

  @media (${mediaQuery('tablet')}) {
    padding: 0 ${({ theme }) => theme.app.padding.smallPadding};
    margin-top: 20px;
    max-width: 328px;

    &:first-of-type {
      margin-right: 16px;
    }
  }

  @media (${mediaQuery('wideMobile')}) {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: unset;
  }
`;
