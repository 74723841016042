import { isAfter, isBefore, set } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useEffect } from 'react';

import { SlotShape } from 'components/form';

import { DEFAULT_TIMEZONE } from './calendarUtils';
import { CalloutData, usePersistedCalloutData } from './usePersistedState';

const checkSlot = ({
  slot,
  currentTime,
  morningCutOff,
  afternoonCutOff,
}: {
  slot: SlotShape;
  currentTime: Date;
  morningCutOff: Date;
  afternoonCutOff: Date;
}) => {
  const slotComponents = slot.value.split('||');
  const slotStartTime = utcToZonedTime(
    new Date(slotComponents[0]),
    DEFAULT_TIMEZONE,
  );

  if (
    isAfter(currentTime, morningCutOff) &&
    isBefore(currentTime, afternoonCutOff)
  ) {
    if (slotComponents[2] === 'All Day') return false;
    return isBefore(slotStartTime, morningCutOff);
  } else if (isAfter(currentTime, afternoonCutOff)) {
    return isBefore(slotStartTime, afternoonCutOff);
  }
};

export const isAnySlotStale = (slots: CalloutData['appointmentSlots'] = []) => {
  const zonedCurrentTime = utcToZonedTime(new Date(), DEFAULT_TIMEZONE);
  const morningCutOff = set(utcToZonedTime(new Date(), DEFAULT_TIMEZONE), {
    hours: 10,
    minutes: 0,
    seconds: 1,
  });
  const afternoonCutOff = set(utcToZonedTime(new Date(), DEFAULT_TIMEZONE), {
    hours: 14,
    minutes: 0,
    seconds: 1,
  });

  return slots.some((slot) =>
    checkSlot({
      slot,
      currentTime: zonedCurrentTime,
      morningCutOff,
      afternoonCutOff,
    }),
  );
};

export const filterOutStaleSlots = (
  slots: CalloutData['appointmentSlots'] = [],
) => {
  const zonedCurrentTime = utcToZonedTime(new Date(), DEFAULT_TIMEZONE);
  const morningCutOff = set(utcToZonedTime(new Date(), DEFAULT_TIMEZONE), {
    hours: 10,
    minutes: 0,
    seconds: 1,
  });
  const afternoonCutOff = set(utcToZonedTime(new Date(), DEFAULT_TIMEZONE), {
    hours: 14,
    minutes: 0,
    seconds: 1,
  });

  return slots.filter(
    (slot) =>
      !checkSlot({
        slot,
        currentTime: zonedCurrentTime,
        morningCutOff,
        afternoonCutOff,
      }),
  );
};

export const useCheckForStaleAppointments = () => {
  const [state, setData, hasStateHydrated] = usePersistedCalloutData();

  useEffect(() => {
    if (!hasStateHydrated) return;
    if (!state.appointmentSlots?.length) return;

    const foundSlotsBeforeCurrentTime = isAnySlotStale(state.appointmentSlots);
    const filteredSlots = filterOutStaleSlots(state.appointmentSlots);

    if (foundSlotsBeforeCurrentTime) {
      setData({
        ...state,
        appointmentSlots: filteredSlots,
      });
    }
  }, [state, setData, hasStateHydrated]);
};
