import Image from 'next/image';
import EcoIcon from 'public/assets/svg/LeafIcon.svg';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Tooltip } from 'components/Tooltip';

import {
  DaySlot,
  DaySlotInput,
  DaySlotName,
  DaySlotTime,
  EcoSlotLabel,
} from './CalendarDaySlot.styles';

const slotLabels = [
  {
    name: 'Morning',
    time: '8-12pm',
  },
  {
    name: 'Afternoon',
    time: '12-4pm',
  },
  {
    name: 'Evening',
    time: '4-8pm',
  },
];

interface CalendarDaySlotProps {
  slotIndex: number;
  value: string;
  checked: boolean;
  setState?: (timeSlot: string[]) => void;
  isEcoSlot?: boolean;
  selectedSlots: string[];
  canSelectMultipleSlots: boolean;
  isFallback?: boolean;
  maxSlotsRequired: number;
  moreThanOrEqualToMaxSlotsSelected: boolean;
}

export const CalendarDaySlot = ({
  slotIndex,
  value,
  checked,
  setState,
  isEcoSlot,
  selectedSlots,
  canSelectMultipleSlots,
  isFallback,
  maxSlotsRequired,
  moreThanOrEqualToMaxSlotsSelected,
}: CalendarDaySlotProps) => {
  const { register } = useFormContext();
  const { onChange, ...registeredInput } = register('timeSlots', {
    required: true,
  });
  const [showTooltip, setShowTooltip] = useState(false);

  const checkShowTooltip = () => {
    if (!isFallback) return;
    if (!checked && moreThanOrEqualToMaxSlotsSelected) {
      setShowTooltip(true);
    } else if (checked) {
      setShowTooltip(false);
    }
  };

  const daySlotTooltipMessage = () => {
    if (!showTooltip) {
      return '';
    } else if (showTooltip && moreThanOrEqualToMaxSlotsSelected) {
      return `Select maximum ${maxSlotsRequired} slots`;
    }
  };

  return (
    <Tooltip
      title={daySlotTooltipMessage()}
      followCursor={true}
      placement="right-start"
    >
      <DaySlot
        $isChecked={checked}
        onClick={checkShowTooltip}
        onBlur={checkShowTooltip}
      >
        <DaySlotName>{slotLabels[slotIndex].name}</DaySlotName>
        <DaySlotTime>{slotLabels[slotIndex].time}</DaySlotTime>
        {isEcoSlot && (
          <EcoSlotLabel>
            <Image src={EcoIcon} alt="Eco icon" priority />
            ECO
          </EcoSlotLabel>
        )}
        <DaySlotInput
          type="checkbox"
          value={value}
          {...registeredInput}
          onChange={(event) => {
            onChange(event);
            setState?.(
              canSelectMultipleSlots
                ? selectedSlots.includes(event.target.value)
                  ? selectedSlots.filter((item) => item !== event.target.value)
                  : [...selectedSlots, event.target.value]
                : [event.target.value],
            );
          }}
        />
      </DaySlot>
    </Tooltip>
  );
};
