import { ReactNode } from 'react';

import { OptionsContainer } from './BaseSelectionView.styles';

type BaseSelectionViewProps = {
  children: ReactNode;
  maxOptionsPerRowDesktop: number;
  optionsContainerPadding?: string;
  rowHeightMobile?: string;
};

export const BaseSelectionView = ({
  children,
  optionsContainerPadding,
  maxOptionsPerRowDesktop,
  rowHeightMobile,
}: BaseSelectionViewProps) => {
  return (
    <OptionsContainer
      $padding={optionsContainerPadding}
      $maxOptionsPerRowDesktop={maxOptionsPerRowDesktop}
      $rowHeightMobile={rowHeightMobile}
    >
      {children}
    </OptionsContainer>
  );
};
