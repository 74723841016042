import { addBreadcrumb, captureException } from '@sentry/nextjs';
import axios from 'axios';
import { useRouter } from 'next/router';
import { extractOutcode } from 'productAgnostic/utils';
import { useEffect } from 'react';
import {
  useAddressStore,
  useCalloutsStore,
  useEnergyExpertsStore,
  useGenericStore,
  useServicesStore,
} from 'store';

import { PartnerTokenResponse } from 'api/services/msContactApiClient';
import { partnerSlug } from 'utils/partners/name';

import { CustomerSource } from '../store/types';
import { useLandingPage } from './useLandingPage';

export const usePartnerToken = async () => {
  const router = useRouter();
  const { setState: setEnergyExpertsData } = useEnergyExpertsStore();
  const { setState: setAddressData } = useAddressStore();
  const { setState: setGenericData } = useGenericStore();
  const { setState: setCalloutsData } = useCalloutsStore();
  const { setState: setServicesData } = useServicesStore();

  const { isRepair } = useLandingPage();
  const setResidentData = isRepair ? setCalloutsData : setServicesData;

  useEffect(() => {
    if (typeof router.query.token === 'string') {
      addBreadcrumb({
        message: `Got token from query string: ${router.query.token}`,
      });
      axios
        .post<PartnerTokenResponse>(
          `/api/decrypt-token?partnerSlug=${partnerSlug}&token=${router.query.token}`,
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 400) {
            const tokenPayload = response?.data.payload;

            if (tokenPayload) {
              const {
                customerReferenceId,
                fullName,
                emailAddress,
                phoneNumber,
                alternativePhoneNumber = '',
                address,
                userCanEditDetails = true,
                customerSource = CustomerSource.ENERGY_EXPERTS,
              } = tokenPayload;

              const contact = {
                fullName,
                email: emailAddress,
                phone: phoneNumber,
                alternativePhoneNumber,
                customerReferenceId,
                customerSource,
              };

              setEnergyExpertsData(contact);
              setResidentData(contact);
              if (address) {
                setAddressData(address);
              }
              setGenericData({
                userCanEditDetails,
              });

              analytics.identify(customerReferenceId, {
                outcodecode: !!address?.postcode
                  ? extractOutcode(address?.postcode)
                  : undefined,
                customer_partner: partnerSlug,
              });
            }
          } else {
            throw new Error(
              `Receieved status code ${response.status} and body ${response.data} from decrypting token ${router.query.token}`,
            );
          }
        })
        .catch((err) => captureException(err));
    } else if (router.query.accountId) {
      const accountId = router.query.accountId;
      const path = router.asPath;

      const isBeyond = !path.includes('energy-experts');

      const contact = {
        customerReferenceId: accountId ? accountId.toString() : '',
        customerSource: isBeyond
          ? CustomerSource.BEYOND
          : CustomerSource.ENERGY_EXPERTS,
      };

      setEnergyExpertsData(contact);
      setGenericData(contact);
      setResidentData(contact);
    }
  }, [
    router.asPath,
    router.query.token,
    router.query.accountId,
    setAddressData,
    setEnergyExpertsData,
    setGenericData,
    setResidentData,
  ]);
};
