import { ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Tooltip } from 'components/Tooltip';

import {
  Input,
  InputContainer,
  RadioContainer,
} from './BaseRadioButtonContainer.styles';

export interface BaseRadioButtonProps {
  name: string;
  value: string;
  isSelected: boolean;
  children: ReactNode;
  onChange: () => void;
  required?: boolean;
  disabled?: boolean;
  height?: string;
  maxWidth?: string;
  maxHeightMobile?: string;
  gridColumnMobile?: string;
  tooltipMessage?: string;
}

export const BaseRadioButtonContainer = ({
  name,
  value,
  isSelected,
  children,
  onChange,
  required = false,
  disabled,
  height,
  maxWidth,
  maxHeightMobile,
  gridColumnMobile,
  tooltipMessage,
}: BaseRadioButtonProps) => {
  const form = useFormContext();

  return (
    <RadioContainer
      whileHover={!disabled ? { scale: 1.1 } : {}}
      whileTap={!disabled ? { scale: 0.9 } : {}}
      $isSelected={isSelected}
      $height={height}
      $maxWidth={maxWidth}
      onClick={onChange}
      $maxHeightMobile={maxHeightMobile}
      $gridColumnMobile={gridColumnMobile}
    >
      <Controller
        name={name}
        control={form.control}
        rules={{ required }}
        render={({ field: { ref, ...field } }) => (
          <Tooltip title={tooltipMessage}>
            <InputContainer onClick={(e) => field.onChange(e)}>
              <Input
                type="radio"
                {...field}
                ref={ref}
                name={name}
                value={value}
                checked={isSelected}
                data-testid={value}
                disabled={disabled}
              />
              {children}
            </InputContainer>
          </Tooltip>
        )}
      />
    </RadioContainer>
  );
};
