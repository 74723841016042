import { Icon } from 'theme/types';

export const CarbonIcon: Icon = (props) => {
  const { color, ...svgProps } = props;

  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 45L36 45L24 24L12 45Z"
        fill={color || '#0D8426'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36 3L12 3L24 24L36 3Z"
        fill={color || '#0D8426'}
      />
    </svg>
  );
};
