import styled from '@emotion/styled';
import { AddressDataState } from 'store/types';

import { MuiTextField } from '../MuiTextField';
import { InputChangeEvent } from '../MuiTextField/MuiTextField';

type AddressInputManual = {
  addressData: AddressDataState;
  handleFieldChange: (event: InputChangeEvent, onChange: OnChange) => void;
  disabled?: boolean;
};

export type OnChange = (...event: any[]) => void;

type ManualAddressEntryOption = {
  name: 'line1' | 'line2' | 'town' | 'postcode';
  label: string;
  required: boolean;
  value?: string;
  errorMessage?: string;
};

export const AddressInputManual = ({
  addressData,
  handleFieldChange,
  disabled = false,
}: AddressInputManual) => {
  const { line1, line2, postcode, town } = addressData;
  const manualAddressEntryOptions: ManualAddressEntryOption[] = [
    {
      name: 'line1',
      label: 'Address line 1',
      value: line1,
      required: true,
      errorMessage: 'This is a required field',
    },
    {
      name: 'line2',
      label: 'Address line 2',
      value: line2,
      required: false,
    },
    {
      name: 'town',
      label: 'Town',
      value: town,
      required: true,
      errorMessage: 'This is a required field',
    },
    {
      name: 'postcode',
      label: 'Postcode',
      value: postcode,
      required: true,
      errorMessage: 'Please enter a valid postcode',
    },
  ];
  return (
    <ManualEntryContainer>
      {manualAddressEntryOptions.map((option, index) => (
        <MuiTextField
          key={index}
          option={option}
          handleFieldChange={handleFieldChange}
          disabled={disabled}
        />
      ))}
    </ManualEntryContainer>
  );
};

const ManualEntryContainer = styled.section`
  max-width: 448px;
  height: fit-content;
  display: grid;
  grid-row-gap: ${({ theme }) => theme.app.spacing[16]};
`;
