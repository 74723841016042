import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';

interface StyledTextProps {
  $variant?: 'semiBold' | 'bold';
}

const StyledText = styled.strong<StyledTextProps>`
  font-weight: ${({ theme, $variant }) =>
    $variant === 'semiBold'
      ? theme.app.fontSettings.fontWeight.semiBold
      : theme.app.fontSettings.fontWeight.bold};
`;

interface StrongTextProps {
  weight?: 'semiBold' | 'bold';
  children: React.ReactNode;
}

export const StrongText = ({
  weight,
  children,
}: PropsWithChildren<StrongTextProps>) => {
  return <StyledText $variant={weight}>{children}</StyledText>;
};
