import { captureMessage } from '@sentry/nextjs';
import { QueryClient } from '@tanstack/react-query';
import type { IncomingMessage } from 'http';

/**
 * Request/response objects seem to be the only way we can share
 * state between App.getInitialProps() and SomePage.getServerSideProps()
 *
 * Can likely get rid of that once/if we migrate to Next.js App Router:
 * https://nextjs.org/docs/app
 */
interface RequestContext {
  __queryClient: QueryClient;
}

export function setQueryClient(req: IncomingMessage, queryClient: QueryClient) {
  (req as unknown as RequestContext).__queryClient = queryClient;
}

export function getQueryClient(req: IncomingMessage): QueryClient {
  const client = (req as unknown as RequestContext).__queryClient;

  if (client) return client;

  captureMessage(
    'Expected query client to be in the request context. ' +
      'Falling back to creating a new instance.' +
      'Data fetched in App.getInitialProps() during SSR ' +
      'might need to be fetched again in the browser.',
    'warning',
  );

  return new QueryClient();
}
