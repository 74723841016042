import { MutableRefObject } from 'react';
import { ValidateResult } from 'react-hook-form';

import { isNullOrUndefined } from 'utils/isNullOrUndefined';
import { getIsValidPhoneNumber, isValidEmail } from 'utils/validation';

import { debounce } from './debounce';

interface ValidationOptions {
  required?: boolean;
  type?: string;
  timer?: MutableRefObject<NodeJS.Timeout | undefined>;
  name?: string;
}

export function getFieldValidators({
  required,
  type,
  timer,
  name,
}: ValidationOptions) {
  const validators = [];

  if (required) {
    validators.push(validateRequired);
  }

  if (type === 'tel') {
    if (!!timer) {
      validators.push(debounce({ callback: validatePhone, timer, wait: 300 }));
    } else {
      validators.push(validatePhone);
    }
  }

  if (type === 'email') {
    validators.push(validateEmail);
  }

  if (name?.includes('ovoAccountNumber')) {
    validators.push(validateOvoAccountNumber);
  }
  return validators;
}

function validateRequired(value: unknown) {
  if (
    isNullOrUndefined(value) ||
    (typeof value === 'string' && !value.trim())
  ) {
    return 'This field is required';
  }
}

export function validateOvoAccountNumber(value: unknown) {
  var isNumeric = /^\d*$/;

  if (!value) return;

  const valueAsString = value.toString();

  if (!isNumeric.test(valueAsString)) {
    return 'Only numbers are allowed.';
  }
  if (valueAsString.length < 4 || valueAsString.length > 19) {
    return 'Please enter a number between 4 and 19 digits.';
  }
}

async function validatePhone(
  args: unknown | unknown[],
): Promise<ValidateResult> {
  let value;
  if (Array.isArray(args)) {
    value = args[0];
  } else {
    value = args;
  }

  if (isNilOrEmptyString(value)) return;
  if (typeof value !== 'string') {
    return 'Please enter a valid phone number';
  }

  const isValidPhoneNumber = await getIsValidPhoneNumber(value);
  if (!isValidPhoneNumber) {
    return 'Please enter a valid phone number';
  }
}

export function validateEmail(value: unknown) {
  if (isNilOrEmptyString(value)) return;

  if (typeof value !== 'string' || !isValidEmail(value)) {
    return 'Please enter a valid email address';
  }
}

function isNilOrEmptyString(value: unknown) {
  return isNullOrUndefined(value) || (typeof value === 'string' && !value);
}
