import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import Image from 'next/image';

import { StrongText } from 'components/StrongText';
import { JOB_TYPES_MAP } from 'utils/jobTypes';
import { getPriceFromJobType } from 'utils/tracking';
import { useManagedRates } from 'utils/useManagedRates';

type ChargingInfoProps = {
  isRepair: boolean;
  jobType: string;
  setIsDrawerOpen: (value: boolean) => void;
};

type IconProps = {
  setIsDrawerOpen: (value: boolean) => void;
};

const StyledOuterContainer = styled.div`
  max-width: 416px;
  padding: 16px 24px;
`;

const IconWrap = styled.div`
  width: ${({ theme }) => theme.app.spacing[40]};
  height: ${({ theme }) => theme.app.spacing[40]};
  cursor: pointer;
`;

const StyledTopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const StyledH3 = styled(Typography)`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[20]};
  line-height: ${({ theme }) => theme.app.fontSettings.fontSize[26]};
  color: ${({ theme }) => theme.app.background.selectedDark};
  overflow-wrap: break-word;
`;

const StyledContentContainer = styled.div`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
  line-height: ${({ theme }) => theme.app.fontSettings.fontSize[20]};

  > p {
    margin: 2px 0 32px 0;
    color: ${({ theme }) => theme.app.text.primary};
  }

  > strong {
    line-height: ${({ theme }) => theme.app.fontSettings.fontSize[24]};
  }
`;

export const ChargingInfo = ({
  isRepair,
  jobType,
  setIsDrawerOpen,
}: ChargingInfoProps) => {
  const managedRates = useManagedRates();
  const managedRate = getPriceFromJobType(jobType, managedRates);

  return (
    <StyledOuterContainer>
      <StyledTopContainer>
        <StyledH3>How much will I be charged?</StyledH3>
        <Icon setIsDrawerOpen={setIsDrawerOpen} />
      </StyledTopContainer>
      {isRepair
        ? repairContent(
            managedRate.amount,
            managedRate.isAmountWithVat,
            jobType,
          )
        : serviceContent(
            managedRate.amount,
            managedRate.isAmountWithVat,
            jobType,
          )}
    </StyledOuterContainer>
  );
};

const Icon = ({ setIsDrawerOpen }: IconProps) => {
  return (
    <IconWrap>
      <Image
        src={`/assets/svg/CloseIcon.svg`}
        alt="Cross Icon"
        width={40}
        height={40}
        onClick={() => setIsDrawerOpen(false)}
      />
    </IconWrap>
  );
};

const repairContent = (
  initialInspectionFee: string,
  isAmountWithVat: boolean,
  jobType: string,
) => (
  <>
    <StyledContentContainer>
      <StrongText>
        Your initial repair visit cost is £{initialInspectionFee}{' '}
        {isAmountWithVat ? ' inc. VAT' : ` exc. VAT`}
      </StrongText>
      <p>
        This is payable AFTER your appointment, and covers the cost of the
        engineer inspecting your{' '}
        {jobType === 'heating_repair' ? 'boiler' : 'plumbing'} to diagnose the
        problem.
      </p>

      {jobType === 'heating_repair' && (
        <>
          <StrongText>70% repairs are completed at no extra charge</StrongText>
          <p>
            In most cases, the problem is fixed on the spot during your initial
            repair visit.
          </p>
        </>
      )}

      <StrongText>
        For complex repairs, you’ll receive a no obligation quote
      </StrongText>
      <br />
      <br />
      <p>
        The initial cost covers the first hour repair visit. If your{' '}
        {jobType === 'heating_repair' ? 'boiler ' : 'plumbing '}
        requires any additional parts and/or hours to fix, this is charged
        separately.
        <br />
        <br />
        If this is required, we’ll send you a quote detailing these costs, which
        you are free to accept or decline.
      </p>
    </StyledContentContainer>
  </>
);

const serviceContent = (
  initialInspectionFee: string,
  isAmountWithVat: boolean,
  jobType: string,
) => (
  <StyledContentContainer>
    <p>
      Your {JOB_TYPES_MAP[jobType]} will cost you only{' '}
      <StrongText>
        £{initialInspectionFee} {isAmountWithVat ? ' inc. VAT' : ` exc. VAT`}
        {jobType === 'smart_thermostat_install' && isAmountWithVat ? ' 0%' : ''}
      </StrongText>
      , which is payable{' '}
      {jobType === 'smart_thermostat_install'
        ? 'within 7 days of the completed appointment.'
        : 'AFTER the service is complete.'}
      <br />
      {jobType === 'boiler_service' && (
        <>
          <br />
          The cost includes a full annual service, which is essential for
          keeping your boiler running smoothly and safely.
          <br />
          <br />
          Our qualified engineers will perform all essential checks and
          maintenance of your boiler and issue you with your annual Boiler
          Service Certificate.
        </>
      )}
      {jobType === 'smart_thermostat_install' && (
        <>
          <br />
          <StrongText>
            What will the engineer fit during the appointment?
          </StrongText>
          <br />
          <br />
          Your appointment will be for the fitting of one tado° Wireless Smart
          Thermostat to control your whole home. You must have purchased your
          tado° Smart Thermostat from OVO before the installation. The engineer
          will not be able to fit multiple Smart Thermostats, multiple heating
          zones or any other additional products during the appointment.
        </>
      )}
    </p>
  </StyledContentContainer>
);
