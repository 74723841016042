import { Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { mediaQuery } from 'theme/kantan';

const getDisclaimerTextColor = ({
  $isPayment,
  theme,
  $partner,
}: {
  $isPayment?: boolean;
  theme: Theme;
  $partner?: string;
}) => {
  switch ($partner) {
    case 'ovo':
      return $isPayment
        ? theme.app.landingPage.bookingStepsPromo.paymentTerms
        : theme.app.landingPage.light.disclaimer;
    default:
      return $isPayment ? theme.app.text.tertiary : theme.app.text.white;
  }
};

export const CalloutDisclaimerText = styled.p<{
  $isPayment?: boolean;
  $partner?: string;
}>`
  margin-top: ${({ theme }) => theme.app.margin.mediumMargin};
  color: ${({ theme, $isPayment, $partner }) =>
    getDisclaimerTextColor({ $isPayment, theme, $partner })};
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[12]};
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.light};
  z-index: 1;

  ${({ theme, $isPayment }) =>
    !$isPayment &&
    `
    @media (${mediaQuery('tablet')}) {
      justify-self: end;
      margin-right: ${theme.app.margin.largeMargin};
    }
  
    @media (${mediaQuery('desktop')}) {
      justify-self: end;
      margin-right: calc((100vw - 990px) / 2);
    }
  `}
`;
