import { tintColor } from 'utils/theme';

export const colors = {
  grey: {
    darkest: '#292b2e',
    veryDark: '#292b2e',
    quiteDark: '#292b2e',
    darker: '#696c75',
    dark: '#a1a6b3',
    base: '#c3c9d7',
    light: '#dae0ed',
    lighter: '#e8edf9',
    lightest: '#eff4fc',
    lightTranslucent: '#b9c0d01a',
  },
  red: {
    darkest: '#820A02',
    darkerSelected: '#A71F20',
    darker: '#B81313',
    dark: '#dd2417',
    base: '#ff4133',
    light: '#ff6458',
    lighter: '#ff928a',
    lightest: '#ffc4c0',
    evenlighter: '#FFE1DF',
    lightestTranslucent: 'rgba(255, 196, 192, 0.5)',
    cancel: '#C7153E',
  },
  orange: {
    darkest: '#613a09',
    darker: '#a95800',
    dark: '#cf6a00',
    base: '#FF7700',
    light: '#ff9021',
    lighter: '#ffb166',
    lightest: '#ffd6b3',
  },
  yellow: {
    darkest: '#5f4d12',
    darker: '#9f7905',
    dark: '#daa402',
    base: '#fac010',
    light: '#ffd13e',
    lighter: '#ffe17b',
    lightest: '#ffeebd',
  },
  lime: {
    darkest: '#384d00',
    darker: '#5a8201',
    dark: '#75AF0B',
    base: '#8CD021',
    light: '#A3E546',
    lighter: '#BFF27A',
    lightest: '#DBF7B7',
    muted: '#F4F9F6',
    ovoLogo: '#0A9928',
    ovoDark: '#006945',
  },
  green: {
    ctaButton: '#03754E',
    darkest: '#004D36',
    darker: '#03754E',
    dark: '#089660',
    base: '#0DBB7C',
    light: '#31D49D',
    lighter: '#6BE5BE',
    lightest: '#B1F0DD',
  },
  teal: {
    darkest: '#194347',
    darker: '#1D4B54',
    dark: '#275B64',
    darkish: '#147D88',
    base: '#3D7F8C',
    light: '#60AEBB',
    lighter: '#86CED8',
    lightest: '#AAE0E6',
  },
  blue: {
    darkest: '#00246B',
    darker: '#0439A3',
    dark: '#0B4ED5',
    base: '#1E67FC',
    light: '#478EFF',
    lighter: '#80B7FF',
    lightest: '#BFDBFA',
    lightestTranslucent: 'rgba(191, 219, 250, 0.5)',
    lightestNearlyTransparent: 'rgba(191, 219, 250, 0.3)',
    link: '#2A71BE',
  },
  purple: {
    darkest: '#1C0080',
    darker: '#2704A7',
    dark: '#3810C9',
    base: '#5028E2',
    light: '#724FF2',
    lighter: '#A087FB',
    lightest: '#D3C7FF',
  },
  pink: {
    get darkest() {
      return tintColor(colors.pink.dark, 2);
    },
    get darker() {
      return tintColor(colors.pink.dark, 1);
    },
    dark: '#1E6937',
    base: '#DE1D8D',
    get light() {
      return tintColor(colors.pink.base, -1);
    },
    get lighter() {
      return tintColor(colors.pink.base, -2);
    },
    get lightest() {
      return tintColor(colors.pink.base, -3);
    },
  },
  transparent: {
    black: 'rgba(0, 5, 7, 0.35)',
    white: 'rgba(255, 255, 255, 0.25)',
  },
  translucent: {
    black: 'rgba(0, 5, 7, 0.75)',
    white: 'rgba(255, 255, 255, 0.84)',
  },
  solid: {
    black: '#000507',
    white: '#FFFFFF',
  },
};
