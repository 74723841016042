import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { EnergyExpertsData, EnergyExpertsDataState } from '../types';
import { defaultContactValues, useHasStoreHydrated } from '../utils';

export const initialState = {
  jobType: 'home_energy_assessment',
  productId: '',
  motivations: '',
  otherReasonDetail: undefined,
  timeSlots: [],
  metadata: {},
  ...defaultContactValues,
  setState: () => {},
  clearState: () => {},
};

export const getEmptyState = () => {
  return initialState;
};

export const createEnergyExpertsStore = create<EnergyExpertsDataState>()(
  devtools(
    persist(
      (set) => ({
        ...getEmptyState(),
        setState: (energyExpertsData: Partial<EnergyExpertsData>) =>
          set(() => ({
            ...energyExpertsData,
          })),
        clearState: () =>
          set(() => ({
            jobType: 'home_energy_assessment',
            productId: '',
            timeSlots: [],
            motivations: '',
            metadata: {},
            otherReasonDetail: undefined,
            ...defaultContactValues,
          })),
      }),
      {
        name: 'energy-experts',
        getStorage: () => sessionStorage,
      },
    ),
  ),
);

export const useEnergyExpertsStore = ((selector, compare) => {
  const store = createEnergyExpertsStore(selector, compare);
  const hydrated = useHasStoreHydrated();

  return hydrated ? store : selector?.(getEmptyState()) ?? getEmptyState();
}) as typeof createEnergyExpertsStore;
