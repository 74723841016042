import { Theme } from '@emotion/react';
import styled from '@emotion/styled';
import Image from 'next/legacy/image';

import { RequiredAsterisk } from 'components/RequiredAsterisk';

const getVariantColor = (theme: Theme, variant: InfoTextVariant) => {
  switch (variant) {
    case 'error':
      return theme.app.messages.error;
    case 'success':
      return theme.app.messages.success;
    default:
      return theme.app.text.primary;
  }
};

const getVariantMargin = (theme: Theme, variant: InfoTextVariant) => {
  switch (variant) {
    case 'error':
      return theme.app.margin.smallMargin;
    case 'success':
      return theme.app.margin.smallMargin;
    default:
      return theme.app.margin.mediumLargeMargin;
  }
};

interface StyledContainerProps {
  $topMargin?: number;
}

const StyledContainer = styled.div<StyledContainerProps>`
  ${({ $topMargin }) =>
    `margin-top: ${$topMargin}px;
  display: flex;
  align-items: center;
  `}
`;

interface StyledInfoTextProps {
  variant: InfoTextVariant;
}

const StyledInfoText = styled.div<StyledInfoTextProps>`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize['12']};
  line-height: 1.3;
  color: ${({ theme, variant }) => getVariantColor(theme, variant)};
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme, variant }) => getVariantMargin(theme, variant)};

  @media (min-width: 680px) {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize['16']};
  }
`;

type InfoTextVariant = 'normal' | 'error' | 'success';

interface InfoTextProps {
  required?: boolean;
  children: React.ReactNode | string;
  variant?: InfoTextVariant;
  topMargin?: number;
  className?: string;
}

const ImageContainer = styled.span`
  display: inline-block;
  margin-right: ${({ theme }) => theme.app.margin.smallMargin};
`;

export const InfoText = ({
  required,
  children,
  variant = 'normal',
  topMargin = 0,
  className,
}: InfoTextProps) => {
  return (
    <StyledContainer $topMargin={topMargin}>
      <StyledInfoText variant={variant} className={className}>
        {variant === 'error' && (
          <ImageContainer>
            <Image
              src="/assets/svg/ErrorIcon.svg"
              alt="Error Icon"
              width="14"
              height="14"
            />
          </ImageContainer>
        )}
        {children}
        {required && <RequiredAsterisk />}
      </StyledInfoText>
    </StyledContainer>
  );
};
