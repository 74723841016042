/**
 * TODO: consider using Apollo Client's reactive vars instead
 */
import React, { PropsWithChildren, useContext, useState } from 'react';
import { PickProperties } from 'ts-essentials';

interface GlobalState {
  hasAppliedParamsFromUrlRef: React.MutableRefObject<boolean>;
  tpName: string;
}

const initialState: GlobalState = {
  hasAppliedParamsFromUrlRef: { current: false },
  tpName: '',
};

const GlobalStateContext = React.createContext<
  [GlobalState, React.Dispatch<React.SetStateAction<GlobalState>>]
>([initialState, () => {}]);

export const useGlobalState = <TKey extends keyof GlobalState>(key: TKey) => {
  const [state, setState] = useContext(GlobalStateContext);

  const property = state[key];

  const setStateProperty = (value: GlobalState[TKey]) => {
    setState((state) => {
      if (state[key] === value) return state;
      return { ...state, [key]: value };
    });
  };

  return [property, setStateProperty] as const;
};

type GlobalRefs = PickProperties<GlobalState, React.MutableRefObject<unknown>>;

export const useGlobalRef = <TKey extends keyof GlobalRefs>(key: TKey) => {
  const [state] = useContext(GlobalStateContext);
  const ref = state[key];

  return ref;
};

export const GlobalStateProvider = ({ children }: PropsWithChildren<{}>) => {
  const [state, setState] = useState<GlobalState>(initialState);

  return (
    <GlobalStateContext.Provider value={[state, setState]}>
      {children}
    </GlobalStateContext.Provider>
  );
};
