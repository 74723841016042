import { ConfirmTadoPurchase } from './ConfirmTadoPurchase';
import { IneligibleHomeSetup } from './IneligibleHomeSetup';
import { NotInYourArea } from './NotInYourArea';
import { SelectBoilerType } from './SelectBoilerType';
import { SelectFuelType } from './SelectFuelType';
import { SignUpAcknowledge } from './SignUpAcknowledge';
import { TadoLogo } from './TadoLogo';
import { YourHome } from './YourHome/YourHome';
import { YourHomeQuestion } from './YourHomeQuestion';

export {
  IneligibleHomeSetup,
  SelectBoilerType,
  SelectFuelType,
  TadoLogo,
  YourHome,
  YourHomeQuestion,
  NotInYourArea as NotInYourAreaTado,
  SignUpAcknowledge as SignUpAcknowledgeTado,
  ConfirmTadoPurchase,
};
