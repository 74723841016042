import { domAnimation } from 'framer-motion';

export default domAnimation;

export const stepSlideTransitionProps = {
  initial: {
    x: '120%',
  },
  animate: {
    x: '0%',
  },
  exit: {
    x: '-120%',
  },
  transition: {
    duration: 0.7,
  },
};

export const stepFadeInTransitionProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { x: '-120%' },
  transition: { delay: 0.5, duration: 0.5 },
};
