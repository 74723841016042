import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import {
  Cancel as CancelIcon,
  Search as SearchIcon,
} from '@mui/icons-material';
import {
  CircularProgress,
  ListItem,
  ListItemText,
  MenuItem,
  MenuList,
  TextField,
} from '@mui/material';
import clsx from 'clsx';
import {
  useCombobox,
  UseComboboxGetItemPropsOptions,
  UseComboboxState,
  UseComboboxStateChangeOptions,
} from 'downshift';
import React, { useCallback, useEffect } from 'react';

import { LocationIcon } from 'components/redesign/LocationIcon';
import { isNotNullOrUndefined } from 'utils/isNotNullOrUndefined';
import {
  AddCategory,
  AddressCategory,
  AddressSuggestion,
  useSearchAddress,
} from 'utils/useSearchAddress';
import { useUpdatingRef } from 'utils/useUpdatingRef';

const ICON_STYLE = { color: '#696C75' };

export const validPostcodeRegex =
  /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/;

export const roundAddressCount = (count: number) => {
  // If it's less than 10, just display it
  if (count < 10) return count;

  // Round number down to nearest 10, add '+'
  count = Math.floor(count / 10) * 10;
  return `${count}+`;
};

export const formatAddressFromPostcoder = (
  address: AddressSuggestion,
): AddressData => {
  // The postcode placement can be random, so use a regex match to pull it out
  const postcode = (
    `${address.summaryline} ${address.locationsummary}`.match(
      validPostcodeRegex,
    ) as RegExpMatchArray
  )[0];

  // Remove the postcode if it was present in either strings
  const summaryLine = address.summaryline.replace(`, ${postcode}`, '');
  const locationSummary = address.locationsummary.replace(`, ${postcode}`, '');

  // Split the long string into usable, trimmed sections
  const sections = locationSummary.split(',').map((s) => s.trim());

  const line1 = summaryLine;
  const line2 = sections.length >= 2 ? sections[0] : '';
  // The final index will always be the state/province
  // The index before that will always be the town/city
  let town = sections[sections.length - 2];

  // When sections contain only 2 items, last one is town
  if (sections.length === 2) {
    town = sections[sections.length - 1];
  }

  return {
    line1,
    line2,
    town,
    postcode,
  };
};

export interface AddressData {
  id?: string;
  line1?: string;
  line2?: string;
  town?: string;
  postcode?: string;
}

type RenderAddressProps = {
  item: AddressSuggestion;
  addCategory: AddCategory;
  selectAddress: (address: AddressData) => void;
  getItemProps: (
    options: UseComboboxGetItemPropsOptions<AddressSuggestion>,
  ) => any;
  shouldBeHighlighted: boolean;
  closeMenu: () => void;
  index?: number;
};

type CategoryProps = {
  name: string;
  setCategory: (addressCategory: AddressCategory | undefined) => void;
};

const Category = ({ name, setCategory }: CategoryProps) => {
  return (
    <MenuItem
      onClick={() => setCategory(undefined)}
      style={{ backgroundColor: 'lightgrey' }}
    >
      <CancelIcon style={ICON_STYLE} />
      <ListItemText style={{ paddingLeft: 5, whiteSpace: 'normal' }}>
        {name}
      </ListItemText>
    </MenuItem>
  );
};

export const BasicAddress = ({
  item,
  selectAddress,
  getItemProps,
  shouldBeHighlighted,
  closeMenu,
  index,
}: Omit<RenderAddressProps, 'addCategory'>) => {
  const theme = useTheme();
  return (
    <MenuItem
      {...getItemProps({ item, index })}
      style={{
        backgroundColor: shouldBeHighlighted ? '#f5f5f5' : 'unset',
      }}
      onClick={() => {
        selectAddress(formatAddressFromPostcoder(item));
        closeMenu();
      }}
    >
      <LocationIcon height="20" width="14" color={theme.app.text.primary} />
      <ListItemText
        style={{
          paddingLeft: 10,
          whiteSpace: 'normal',
          lineHeight: theme.app.fontSettings.fontSize[20],
          fontSize: theme.app.fontSettings.fontSize[16],
          fontWeight: theme.app.fontSettings.fontWeight.light,
          color: theme.app.text.primary,
        }}
        disableTypography
        primary={`${item.summaryline}, ${item.locationsummary}`}
      />
    </MenuItem>
  );
};

export const NestedAddress = ({
  item,
  addCategory,
  getItemProps,
  shouldBeHighlighted,
}: Omit<RenderAddressProps, 'selectAddress' | 'closeMenu'>) => {
  const summary = `${item.summaryline}, ${item.locationsummary}`;
  const count = roundAddressCount(item.count);
  return (
    <MenuItem
      {...getItemProps({ item })}
      style={{
        backgroundColor: shouldBeHighlighted ? '#f5f5f5' : 'unset',
      }}
      onClick={() => addCategory({ id: item.id, name: summary })}
    >
      <SearchIcon style={ICON_STYLE} />
      <ListItemText
        style={{
          paddingLeft: 5,
          whiteSpace: 'normal',
        }}
        primary={summary}
        secondary={`${count} Addresses`}
      />
    </MenuItem>
  );
};

export const NoAddresses = () => (
  <ListItem>
    <ListItemText primary="No addresses found" />
  </ListItem>
);

export const PostcodeLookup = ({
  shouldAutoFocus,
  addressSelectedCallback,
  shouldUseNewDesign,
  onFocusChange,
  classes,
}: {
  shouldAutoFocus?: boolean;
  addressSelectedCallback: (address: AddressData) => void;
  shouldUseNewDesign?: boolean;
  onFocusChange?: (focused: boolean) => void;
  classes?: { field?: string };
}) => {
  const [
    searchString,
    suggestedAddresses,
    search,
    addCategory,
    currentCategory,
    isAutocompleteLoading,
  ] = useSearchAddress();

  const selectAddress = (address: AddressData | null) => {
    if (address) {
      addressSelectedCallback(address);
      window.dataLayer?.push({ event: 'address-selected' });
    }
  };

  const stateReducer = useCallback(
    (
      state: UseComboboxState<AddressSuggestion>,
      actionAndChanges: UseComboboxStateChangeOptions<AddressSuggestion>,
    ) => {
      const { type, changes } = actionAndChanges;
      const { selectedItem } = changes;

      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
          if (isNotNullOrUndefined(selectedItem)) {
            const isNestedAddress = selectedItem?.type !== 'ADD';

            if (isNestedAddress) {
              addCategory({
                id: selectedItem.id,
                name: `${selectedItem.summaryline}, ${selectedItem.locationsummary}`,
              });
              return {
                ...changes,
                inputValue: searchString,
                isOpen: true,
              };
            } else {
              return {
                ...changes,
                inputValue: searchString,
                isOpen: false,
              };
            }
          }
          return changes;

        default:
          return changes;
      }
    },
    [searchString, addCategory],
  );

  const {
    isOpen,
    getComboboxProps,
    getInputProps,
    getMenuProps,
    getItemProps,
    openMenu,
    closeMenu,
    highlightedIndex,
  } = useCombobox({
    inputValue: searchString,
    onInputValueChange({ inputValue }) {
      if (inputValue?.length === 1)
        window.dataLayer?.push({ event: 'start-searching-address' });

      search(inputValue ?? '');
    },
    onSelectedItemChange({ selectedItem }) {
      if (selectedItem && selectedItem.type === 'ADD') {
        addressSelectedCallback(formatAddressFromPostcoder(selectedItem));
        window.dataLayer?.push({ event: 'address-selected' });
      }
    },
    items: suggestedAddresses,
    itemToString(item) {
      return !!item?.summaryline && !!item?.locationsummary
        ? `${item.summaryline}, ${item.locationsummary}`
        : '';
    },
    stateReducer,
  });

  const onFocusChangeRef = useUpdatingRef(onFocusChange);
  useEffect(() => {
    onFocusChangeRef.current?.(isOpen);
  }, [isOpen, onFocusChangeRef]);

  return (
    <>
      <div>
        <div
          className={clsx(
            'marketplace-lookup',
            shouldUseNewDesign
              ? 'marketplace-lookup--design_v2'
              : 'marketplace-lookup--design_v1',
          )}
        >
          <div
            style={{ display: 'flex', position: 'relative' }}
            {...getComboboxProps({
              id: 'marketplace-postcode-lookup',
              'aria-owns': 'menu-list-grow',
            })}
          >
            <TextField
              {...getInputProps({
                id: 'searchAddress',
                onFocus: openMenu,
                'aria-controls': 'postcode-lookup-menu',
                'aria-labelledby': 'legend',
              })}
              fullWidth
              variant="outlined"
              placeholder="Enter your postcode"
              autoFocus={shouldAutoFocus}
              value={searchString}
              className={clsx('marketplace-lookup__field', classes?.field)}
            />
            {isAutocompleteLoading && (
              <CircularProgress
                className="marketplace-lookup__spinner"
                size={shouldUseNewDesign ? 20 : 40}
              />
            )}
          </div>
          <StyledMenuList
            autoFocusItem={isOpen}
            $isOpen={isOpen}
            {...getMenuProps({
              id: 'menu-list-grow',
              'aria-label': 'postcode-list',
              'aria-labelledby': 'legend',
            })}
          >
            {currentCategory && isOpen && (
              <Category name={currentCategory.name} setCategory={addCategory} />
            )}
            {searchString.length > 3 &&
              suggestedAddresses.length === 0 &&
              isOpen && <NoAddresses />}
            {isOpen &&
              suggestedAddresses.map((address, index) =>
                address.type === 'ADD' ? (
                  <BasicAddress
                    key={address.id}
                    item={address}
                    selectAddress={selectAddress}
                    getItemProps={getItemProps}
                    shouldBeHighlighted={highlightedIndex === index}
                    closeMenu={closeMenu}
                  />
                ) : (
                  <NestedAddress
                    key={address.id}
                    item={address}
                    addCategory={addCategory}
                    getItemProps={getItemProps}
                    shouldBeHighlighted={highlightedIndex === index}
                  />
                ),
              )}
          </StyledMenuList>
        </div>
      </div>
    </>
  );
};

const StyledMenuList = styled(MenuList)<{ $isOpen: boolean }>`
  display: ${({ $isOpen }) => ($isOpen ? 'block' : 'none')};
  box-shadow: 0.05em 0.2em 0.6em rgba(0, 0, 0, 0.2);
  max-height: 300px;
  max-width: 100%;
  overflow-y: auto;
  z-index: 1000;
`;
