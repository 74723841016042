import styled from '@emotion/styled';
import { mediaQuery } from 'theme/energy_experts';

const OuterContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 363px;
  margin: 0 auto;

  button {
    font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.bold};
  }

  > div:first-of-type > button {
    border: ${({ theme }) => `1px solid ${theme.app.text.actionCta}`};
  }

  > div:last-of-type:not:(:first-of-type) > button {
    border: none;
  }

  @media (${mediaQuery('tablet')}) {
    flex-direction: row;
    width: unset;
    max-width: 458px;
    margin: 0 auto;
  }
`;

const AlignButton = styled.div`
  width: fit-content;
  padding-bottom: 2px;

  &:last-of-type {
    flex: 1;
    margin-right: 16px;
    @media (${mediaQuery('tablet')}) {
      width: 328px;
      margin-right: unset;
    }

    button {
      min-width: unset !important;
      max-width: unset !important;
      width: 100% !important;
      margin-left: 16px;
      @media (${mediaQuery('tablet')}) {
        min-width: 328px;
        width: 328px;
      }
    }
  }

  &:first-of-type:last-of-type {
    flex: 1;
    margin-right: 16px;
    @media (${mediaQuery('tablet')}) {
      width: 328px;
      margin-right: unset;
    }

    button {
      min-width: unset !important;
      max-width: unset !important;
      width: 100% !important;
      margin-left: 0px !important;
      @media (${mediaQuery('tablet')}) {
        min-width: 328px;
        width: 328px;
      }
    }
  }

  @media (${mediaQuery('tablet')}) {
    padding-bottom: unset;
  }
`;

export { OuterContainer, AlignButton };
