import { Icon } from 'theme/types';

export const DocumentIcon: Icon = (props) => {
  const { color, ...svgProps } = props;

  return (
    <svg
      width="28"
      height="40"
      viewBox="0 0 28 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M0 0V40H28V0H0ZM7 38.4334V33.5248H4L9.7 25.7963V30.705H12.7L7 38.4334ZM16.5 23.4987H4.4V19.8433H16.5V23.4987ZM23.6 16.188H4.4V12.5326H23.6V16.188ZM23.6 8.87728H4.4V5.22193H23.6V8.87728Z"
        fill={color || '#0A9828'}
      />
      <path
        opacity="0.3"
        d="M25.6919 5.22192H4.59534V8.87728H25.6919V5.22192Z"
        fill={color || '#0A9828'}
      />
      <path
        opacity="0.3"
        d="M24.6476 12.5327H4.59534V16.1881H24.6476V12.5327Z"
        fill={color || '#0A9828'}
      />
      <path
        opacity="0.3"
        d="M17.2324 19.8433H4.59534V23.4986H17.2324V19.8433Z"
        fill={color || '#0A9828'}
      />
      <path
        opacity="0.3"
        d="M10.1305 25.7964L4.17749 33.5248H7.31065V38.4335L13.2637 30.705H10.1305V25.7964Z"
        fill={color || '#0A9828'}
      />
    </svg>
  );
};
