import { IconBase } from './utils';

export const ArrowRightIcon: IconBase = (props) => {
  const { color, ...svgProps } = props;

  return (
    <svg width={12} height={9} fill="none" viewBox="0 0 12 9" {...svgProps}>
      <path
        d="m8.056 8.24-1.28-1.28.96-.96.704-.608-.016-.064-1.28.064H.52V3.44h6.624l1.28.064.016-.064-.704-.608-.96-.96 1.28-1.28 3.824 3.824L8.056 8.24Z"
        fill={color || 'black'}
      />
    </svg>
  );
};
