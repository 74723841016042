import { Button } from 'components/Button';
import { ButtonVariants } from 'components/Button/Button';
import { Tooltip } from 'components/Tooltip';

import {
  AlignButton,
  OuterContainer,
} from './NavigationButtonsContainer.styles';

type ButtonsContainerProps = {
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  isBusy?: boolean;
  leftButtonLabel?: string;
  rightButtonLabel?: string;
  onClickRightButton?: () => void;
  onClickLeftButton?: () => void;
  tooltipMessage?: string;
  styleVariant?: ButtonVariants;
};

export const NavigationButtonsContainer = ({
  disabled,
  type,
  isBusy,
  leftButtonLabel,
  rightButtonLabel,
  onClickRightButton,
  onClickLeftButton,
  tooltipMessage,
  styleVariant,
}: ButtonsContainerProps) => {
  return (
    <OuterContainer>
      {onClickLeftButton && (
        <AlignButton>
          <Button variant="secondary" onClick={onClickLeftButton}>
            {leftButtonLabel ?? 'Back'}
          </Button>
        </AlignButton>
      )}
      <Tooltip title={tooltipMessage}>
        <AlignButton>
          <Button
            type={type}
            disabled={disabled}
            variant={styleVariant}
            busy={isBusy}
            onClick={onClickRightButton}
          >
            {rightButtonLabel ?? 'Next'}
          </Button>
        </AlignButton>
      </Tooltip>
    </OuterContainer>
  );
};
