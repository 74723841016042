import axios from 'axios';
import { DataToAppend } from 'productAgnostic/ReviewBooking';
import { useCallback } from 'react';

import { EnergyExpertsData, GenericProductData } from 'utils/usePersistedState';

export const useCreateJob = ({
  data,
}: {
  data: EnergyExpertsData | GenericProductData;
}) => {
  const createJob = useCallback(
    async (dataToAppend?: DataToAppend) => {
      const payload = { ...data, ...dataToAppend };
      const response = await axios.post('/api/services/create-job', payload, {
        headers: {
          url: window.location.href,
        },
      });
      const job = response.data;
      return job;
    },
    [data],
  );

  return { createJob };
};
