import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { AddressData, AddressDataState } from '../types';
import { defaultAddressValues, useHasStoreHydrated } from '../utils';

const emptyState = {
  ...defaultAddressValues,
  setState: () => {},
  clearState: () => {},
};

export const createAddressStore = create<AddressDataState>()(
  devtools(
    persist(
      (set) => ({
        ...emptyState,
        setState: (addressData: Partial<AddressData>) =>
          set(() => ({
            ...addressData,
          })),
        clearState: () =>
          set(() => ({
            ...defaultAddressValues,
          })),
      }),
      {
        name: 'address',
        getStorage: () => sessionStorage,
      },
    ),
  ),
);

export const useAddressStore = ((selector, compare) => {
  const store = createAddressStore(selector, compare);
  const hydrated = useHasStoreHydrated();

  return hydrated ? store : selector?.(emptyState) ?? emptyState;
}) as typeof createAddressStore;
