import { useEffect, useRef } from 'react';

/**
 * Creates a ref object that is kept up to date with the given
 * value.
 *
 * Useful for custom hooks that take callbacks without constantly
 * triggering effects / memos.
 *
 * @example
 * const useMyHook = (query, callback) => {
 *   const callbackRef = useUpdatingRef(callback);
 *
 *   useEffect(() => {
 *     if (query.loading) {
 *       callbackRef.current();
 *     }
 *   }, [query.loading, callbackRef]);
 * }
 *
 * @param value A value to watch
 */
export const useUpdatingRef = <T>(value: T) => {
  const ref = useRef(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref;
};
