import styled from '@emotion/styled';
import { Drawer, useMediaQuery } from '@mui/material';
import { m } from 'framer-motion';
import { useRouter } from 'next/router';
import { useCreateInvoicePol } from 'productSpecific/energy-experts/useCreateInvoicePol';
import { getMotivationLabels } from 'productSpecific/energy-experts/utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useAddressStore, useEnergyExpertsStore, useGenericStore } from 'store';
import { createEnergyExpertsStore } from 'store/modules/energy-experts';
import { EnergyExpertsDataState, GenericStoreDataState } from 'store/types';
import { useHasStoreHydrated } from 'store/utils';
import { mediaQuery } from 'theme/energy_experts';

import { AppointmentSummary } from 'components/AppointmentSummary';
import { CheckboxField } from 'components/form';
import { PaymentSummaryOld as PaymentSummary } from 'components/marketplace/ReviewFormOld/PaymentSummaryOld';
import { NavigationButtonsContainer } from 'components/NavigationButtonsContainer';
import {
  CTAContainer as _CTAContainer,
  ReviewPageForm,
  TermsConditionsPrivacyPolicy,
} from 'components/redesign/ReviewPage/utilityStyles';
import { AppointmentSummaryContainer } from 'layouts/MarketingLayout/components/utilityStyles';
import { DEFAULT_CURRENCY } from 'layouts/MarketingLayout/MarketingLayout';
import { useExistingStepContext } from 'pages/[productType]/[rateDomain]/[step]';
import { ContactJob } from 'services/kantanClient';
import { stepSlideTransitionProps } from 'utils/framerMotion';
import { isNotNullOrUndefined } from 'utils/isNotNullOrUndefined';
import { formatReferenceId } from 'utils/jobs';
import { getPriceFromJobType, grabGclid, trackEvent } from 'utils/tracking';
import { shouldForwardProp } from 'utils/transientStyled';
import { useManagedRates } from 'utils/useManagedRates';

import { ChargingInfo } from '../components/redesign/ChargingInfo';
import { useCreateJob } from './hooks/useCreateJob';

export type DataToAppend = {
  metadata: {
    yourHome: string;
  };
};

export const ReviewBooking = () => {
  const context = useExistingStepContext();
  const addressData = useAddressStore();
  const energyExpertsStore = useEnergyExpertsStore();
  const genericStore = useGenericStore();
  const [isSubmitting, setSubmitting] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const managedRates = useManagedRates();
  const hasStoreHydrated = useHasStoreHydrated();
  const router = useRouter();

  const {
    productConfig: {
      bookingSummaryHeader,
      termsAndConditionsText,
      termsAndConditionsUrl,
      privacyPolicyUrl,
      motivations,
      paymentType,
      useGenericStoreData: shouldUseGenericStoreData,
      bookingSummaryDetailKeyName,
    },
  } = context;

  const storeData = shouldUseGenericStoreData
    ? genericStore
    : energyExpertsStore;
  const {
    timeSlots,
    fullName,
    email,
    phone,
    alternativePhoneNumber,
    jobType,
    setState,
    metadata,
  } = storeData;

  const isTabletOrLarger = useMediaQuery(`${mediaQuery('tablet', true)}`);

  const formulateMotivationDetails = useCallback(() => {
    if (shouldUseGenericStoreData) return;

    const { motivations, otherReasonDetail: _otherReasonDetail } =
      energyExpertsStore;
    const motivationsSplitByComma = motivations.split(',');
    const doReasonsIncludeOther = motivationsSplitByComma.includes('other');

    if (doReasonsIncludeOther) {
      const otherReasonDetail = _otherReasonDetail?.trim();
      const updatedMotivation = motivationsSplitByComma.map((e) => {
        if (e === 'other') {
          return `other: ${otherReasonDetail}`;
        } else {
          return e;
        }
      });
      return updatedMotivation.join(',');
    } else {
      return motivations;
    }
  }, [energyExpertsStore, shouldUseGenericStoreData]);

  const createJobData: (GenericStoreDataState | EnergyExpertsDataState) & {
    problem?: string;
  } = {
    ...addressData,
    ...storeData,
  };

  const motivation = useMemo(
    () => formulateMotivationDetails(),
    [formulateMotivationDetails],
  );
  if (!!motivation && createJobData['metadata']) {
    createJobData['metadata']['problem'] = motivation;
  }

  const { createJob } = useCreateJob({
    data: createJobData,
  });
  const { createInvoice: createInvoicePol } = useCreateInvoicePol({});

  const { prevStep, nextStep } = context.currentStep.options;
  const onClickLeftButton = () => void router.push(prevStep);

  let requiresPrePayment: boolean | undefined = undefined;
  const hydrated = useHasStoreHydrated();
  if (hydrated) {
    const price = getPriceFromJobType(jobType, managedRates);
    requiresPrePayment =
      paymentType === 'pre_booking' && price.amount !== '0.00';
  }

  const constructDataToAppend = (): DataToAppend | undefined => {
    const yourHomeData = genericStore.metadata.yourHome;
    if (yourHomeData) {
      const underFloorHeatingAnswer =
        yourHomeData['do_you_have_underfloor_heating_in_your_property?'];
      const underfloorHeatingString = `do_you_have_underfloor_heating_in_your_property: ${underFloorHeatingAnswer}`;

      const dataToAppend = {
        metadata: {
          ...genericStore.metadata,
          yourHome: underfloorHeatingString,
        },
      };
      return dataToAppend;
    }
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      if (!hasStoreHydrated || !managedRates) return;
      const dataToAppend = constructDataToAppend();
      const job = await createJob(dataToAppend);
      const jobQueryParams = extractQueryParams(job);

      if (requiresPrePayment) {
        const polPaymentUrl = await createInvoicePol(job.id);
        window.open(polPaymentUrl, '_self');
        createEnergyExpertsStore.persist.clearStorage();
      } else {
        trackEvent('Booking confirmed', {
          job_type: job.jobType,
          revenue: getPriceFromJobType(job.jobType, managedRates),
          currency: DEFAULT_CURRENCY,
          gclid: grabGclid(router),
        });

        await router.push({
          pathname: nextStep,
          query: {
            job: JSON.stringify(jobQueryParams),
          },
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  const extractQueryParams = (job: ContactJob & { timeSlots: string[] }) => {
    const { referenceId, metadata, address, contacts } = job;

    const bookingSummaryDetail =
      metadata[bookingSummaryDetailKeyName ?? 'problem'];

    const jobQueryParams = {
      referenceId: formatReferenceId(referenceId),
      bookingSummaryDetail,
      address,
      timeSlots: createJobData.timeSlots,
      contact: contacts[0],
    };
    return jobQueryParams;
  };

  const form = useForm({
    mode: 'all',
  });

  useEffect(() => {
    if (hasStoreHydrated) {
      form.reset(storeData, {
        keepErrors: true,
      });
    }
  }, [form, hasStoreHydrated, storeData]);

  const getMotivation = () => {
    if (shouldUseGenericStoreData && bookingSummaryDetailKeyName) {
      return genericStore['metadata'][bookingSummaryDetailKeyName];
    } else {
      return getMotivationLabels(
        energyExpertsStore.motivations,
        motivations?.options,
      );
    }
  };

  if (timeSlots.length === 0) {
    return <div>Loading...</div>;
  }

  const requiresHomeownerConfirmation = jobType === 'home_energy_assessment';

  return (
    <OuterContainer {...stepSlideTransitionProps}>
      <InnerContainer>
        <FormProvider {...form}>
          <ReviewBookingForm onSubmit={form.handleSubmit(handleSubmit)}>
            <AppointmentSummaryContainer $inGrid="summary">
              <AppointmentSummary
                type="service"
                isHeating={true}
                bookingSummaryDetail={getMotivation()}
                appointmentSlots={timeSlots}
                addressData={addressData}
                contactData={{
                  fullName,
                  email,
                  phone,
                  alternativePhoneNumber,
                }}
                ovoAccountNumber={metadata?.ovoAccountNumber}
                bookingSummaryHeader={bookingSummaryHeader}
              />
            </AppointmentSummaryContainer>
            <PaymentGridContainer>
              <AppointmentSummaryContainer $inGrid="payment">
                <PaymentSummary jobType={jobType} />
              </AppointmentSummaryContainer>
              {jobType === 'smart_thermostat_install' && (
                <PriceInfo>
                  <p onClick={() => setIsDrawerOpen(true)}>
                    How much will I be charged?
                  </p>
                </PriceInfo>
              )}
            </PaymentGridContainer>
            <CheckboxesSection>
              <CheckboxField
                name="acceptTerms"
                label={
                  <TermsConditionsPrivacyPolicy
                    termsAndConditionsText={termsAndConditionsText}
                    termsAndConditionsUrl={termsAndConditionsUrl}
                    privacyPolicyUrl={privacyPolicyUrl}
                  />
                }
                onChange={(event) =>
                  setState({
                    [event.target.name]: event.target.checked,
                  })
                }
                required
                requiredMessage=""
              />
              {requiresHomeownerConfirmation && (
                <CheckboxField
                  name="confirmHomeowner"
                  label={`I confirm I'm the homeowner or I have the homeowner’s permission for an Energy Expert to visit my home.`}
                  onChange={(event) =>
                    setState({
                      [event.target.name]: event.target.checked,
                    })
                  }
                  required
                  requiredMessage=""
                />
              )}
            </CheckboxesSection>
            <CTAContainer>
              {isNotNullOrUndefined(requiresPrePayment) && (
                <NavigationButtonsContainer
                  disabled={
                    !form.getValues('acceptTerms') ||
                    (requiresHomeownerConfirmation &&
                      !form.getValues('confirmHomeowner'))
                  }
                  isBusy={isSubmitting}
                  onClickLeftButton={onClickLeftButton}
                  leftButtonLabel="Back"
                  rightButtonLabel={
                    requiresPrePayment
                      ? 'Pay Now'
                      : `Confirm ${isTabletOrLarger ? 'Booking' : ''}`
                  }
                  type="submit"
                />
              )}
            </CTAContainer>
          </ReviewBookingForm>
        </FormProvider>
      </InnerContainer>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <ChargingInfo
          isRepair={false}
          jobType={jobType}
          setIsDrawerOpen={setIsDrawerOpen}
        />
      </Drawer>
    </OuterContainer>
  );
};

const OuterContainer = styled(m.div, {
  shouldForwardProp,
})`
  display: flex;
  flex-flow: column;
  align-items: center;
  grid-column: 1 / -1;
  width: 100%;
  height: 100%;
  background-color: transparent;

  @media (${mediaQuery('tablet')}) {
    background-color: transparent;
  }
`;

const InnerContainer = styled.div`
  width: 100%;
  max-width: 990px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.app.borderRadius.largeCorner};
  overflow: hidden;
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[14]};
  margin-top: 90px;
  padding-top: 5px;
  background-color: transparent;

  @media (${mediaQuery('tablet')}) {
    height: max-content;
    box-shadow: 0 8px 28px rgba(0, 0, 0, 0.06);
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(20px);
    margin-top: 16px;
    padding: ${({ theme }) => theme.app.padding.largePadding};
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
  }
`;

const ReviewBookingForm = styled(ReviewPageForm)`
  width: 100%;

  label {
    align-items: start;

    > span:first-of-type {
      padding: 0 4px 0 9px;
    }
  }

  p.MuiFormHelperText-root {
    margin-left: 26px;
    margin-top: 4px;

    @media (${mediaQuery('tablet')}) {
      position: fixed;
      margin-top: 48px;
    }
  }
`;

const CheckboxesSection = styled.section<{ $marginTop?: number }>`
  grid-area: checkbox;
  display: flex;
  flex-direction: column;
  margin-top: ${({ $marginTop = 15 }) => `${$marginTop}px`};
  justify-content: end;
`;

const CTAContainer = styled(_CTAContainer)`
  display: flex;
  justify-content: center;
  box-shadow: none;
  margin-top: 20px;
  margin-bottom: 70px;

  @media (${mediaQuery('tablet')}) {
    margin-top: 95px;
    margin-bottom: unset;
  }
`;

const PaymentGridContainer = styled.div`
    grid-area: 'payment;
`;

const PriceInfo = styled.div`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[14]};
  color: ${({ theme }) => theme.app.messages.action};
  text-decoration: underline;
  margin-top: ${({ theme }) => theme.app.spacing[8]};

  > p {
    cursor: pointer;
    margin-left: auto;
    width: fit-content;
  }
`;
