import React, { useEffect, useRef } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { CheckboxInput } from 'components/CheckboxInput';

interface CheckboxFieldProps {
  name: string;
  label: React.ReactNode;
  required?: boolean;
  requiredMessage?: string;
  overrideStyle?: React.CSSProperties;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const CheckboxField = ({
  name,
  label,
  required,
  requiredMessage,
  overrideStyle,
  onChange,
}: CheckboxFieldProps) => {
  const form = useFormContext();
  const checkboxRef = useRef<HTMLElement | null>(null);
  const validateField = (value: unknown) => {
    if (required) {
      if (!value) {
        return requiredMessage ?? 'Required';
      }
    }
  };

  const {
    field: { ref, ...restOfField },
    fieldState,
    formState: { isSubmitting, isSubmitted },
  } = useController({
    name,
    control: form.control,
    rules: { validate: validateField },
  });

  useEffect(() => {
    if (!isSubmitting && isSubmitted && !!fieldState.error?.message) {
      checkboxRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isSubmitting, isSubmitted, fieldState.error]);

  return (
    <CheckboxInput
      inputRef={(e) => {
        ref(e);
        checkboxRef.current = e;
      }}
      {...restOfField}
      checked={restOfField.value}
      label={label}
      required={required}
      invalid={!!fieldState.error}
      // invalid={isNotNullOrUndefined(error)}
      helperText={fieldState.error?.message}
      onChange={(e) => {
        const checked = e.target.checked;
        onChange?.(e);
        restOfField.onChange(checked);
      }}
      overrideStyle={overrideStyle}
    />
  );
};
