import styled from '@emotion/styled';
import { mediaQuery } from 'theme/kantan';

export const DaySlot = styled.div<{
  $isChecked: boolean;
}>`
  position: relative;
  background-color: ${({ theme }) => theme.app.background.white};
  box-shadow: inset 0px -1px 0px #d0d8e5;
  border-radius: ${({ theme }) => theme.app.borderRadius.smallCorner};

  display: flex;
  flex-flow: column wrap;
  align-items: start;
  justify-content: center;
  padding: 1.5vw;
  ${({ theme, $isChecked }) =>
    $isChecked &&
    `
  border: 4px solid ${theme.app.border.focus};
  `}

  @media (${mediaQuery('tablet')}) {
    padding: 3%;
    max-height: 66px;
    justify-content: start;
    border: ${({ theme, $isChecked }) =>
      $isChecked
        ? `4px solid ${theme.app.border.focus}`
        : `1px solid ${theme.app.border.secondary}`};
  }
`;

export const DaySlotName = styled.h3`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[12]};
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.regular};
  line-height: 1;
  color: ${({ theme }) => theme.app.text.primary};
  letter-spacing: -0.02em;

  @media (${mediaQuery('tablet')}) {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
    line-height: 1.25;
  }
`;

export const DaySlotTime = styled.p`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[12]};
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.light};
  line-height: 1;
  text-align: center;
  margin-top: 2px;

  color: ${({ theme }) => theme.app.text.tertiary};

  @media (${mediaQuery('tablet')}) {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
    line-height: 1.25;
  }
`;

export const EcoSlotLabel = styled(DaySlotName)`
  color: ${({ theme }) => theme.app.messages.ecoBanner};
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.bold};
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[12]};

  margin-top: auto;

  @media (${mediaQuery('tablet')}) {
    margin-top: unset;
    flex: 1 1 16px;
    align-self: end;
  }
`;

export const DaySlotInput = styled.input`
  cursor: pointer;
  appearance: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  @supports not (appearance: none) {
    border: none;
  }
`;
