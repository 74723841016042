import {
  format,
  isAfter,
  isBefore,
  isSameDay,
  isSameWeek,
  setHours,
} from 'date-fns';
import {
  format as formatTz,
  utcToZonedTime,
  zonedTimeToUtc,
} from 'date-fns-tz';

import { BookableSlot } from './useBookableSlots';

export const DEFAULT_TIMEZONE = 'Europe/London';

/**
 * Convert a date string plus a slot string into two complete start and end date time strings adjusted for London/Europe timezone and returned as UTC.
 *
 * @param date Date string (example: "2020-04-21")
 * @param slot Slot string indicating slot start and end hours (example: "8-12")
 */
export const toStartAndEndDateTime = (
  date: string,
  slot: string,
): [Date, Date] => {
  const numberRegEx = /^\d+/;
  const [startHour, endHour] = slot.split('-').map((hourStr) => {
    const hour = numberRegEx.exec(hourStr)![0];
    return Number(hour);
  });

  const londonTimezone = 'Europe/London';

  const startDateTime = setHours(new Date(date), startHour);
  const endDateTime = setHours(new Date(date), endHour);

  const startDateTimeUtc = zonedTimeToUtc(startDateTime, londonTimezone);
  const endDateTimeUtc = zonedTimeToUtc(endDateTime, londonTimezone);

  return [startDateTimeUtc, endDateTimeUtc];
};

export type TimeSlot = {
  date: Date;
  slot: string;
};

/**
 * Encode a Date and slot string to a single timeslot string value.
 *
 * @param date
 * @param slot
 */
export const toTimeSlotValue = ({ date, slot }: TimeSlot): string => {
  const valueDate = format(date, 'yyyy-MM-dd');
  return `${valueDate}||${slot}`;
};

/**
 * Split a string that has been encoded with @function toTimeSlotString into a date string and a slot string.
 *
 * @param timeSlotString Format: "2020-04-21||8-12"
 */
export const splitTimeSlotValue = (timeSlotValue: string): [string, string] => {
  const [date, slot] = timeSlotValue.split('||');
  return [date, slot];
};

export const toTimeSlot = (
  startDateTime: Date,
  endDateTime: Date,
): TimeSlot => {
  const startHour = format(startDateTime, 'HH');
  const endHour = format(endDateTime, 'HH');
  const slot = `${startHour}-${endHour}`;

  return {
    date: startDateTime,
    slot,
  };
};

export const sortByStart = (bookableSlots: BookableSlot[]): BookableSlot[] => {
  return bookableSlots.sort((a, b) =>
    isBefore(a.startDateTime, b.startDateTime)
      ? -1
      : isAfter(a.startDateTime, b.startDateTime)
      ? 1
      : 0,
  );
};

export const getStartTimes = (bookableSlots: BookableSlot[]): Date[] => {
  return bookableSlots.map((slot) => slot.startDateTime);
};

export const getEndTimes = (bookableSlots: BookableSlot[]): Date[] => {
  return bookableSlots.map((slot) => slot.endDateTime);
};

export const groupByDay = (bookableSlots: BookableSlot[]): BookableSlot[][] => {
  return bookableSlots.reduce<BookableSlot[][]>((acc, slot) => {
    if (acc.length === 0) {
      return [[slot]];
    }

    const lastDay = acc[acc.length - 1];
    if (isSameDay(lastDay[0].startDateTime, slot.startDateTime)) {
      lastDay.push(slot);
      return acc;
    }

    return [...acc, [slot]];
  }, []);
};

export const groupByWeek = (
  bookableSlots: BookableSlot[][],
): BookableSlot[][][] => {
  return bookableSlots.reduce<BookableSlot[][][]>((acc, day) => {
    if (acc.length === 0) {
      return [[day]];
    }

    const lastWeek = acc[acc.length - 1];
    if (
      isSameWeek(lastWeek[0][0].startDateTime, day[0].startDateTime, {
        weekStartsOn: 1,
      })
    ) {
      lastWeek.push(day);
      return acc;
    }

    return [...acc, [day]];
  }, []);
};

export const getAvailableSlotsPerWeek = (
  weeks: BookableSlot[][][],
): number[] => {
  return weeks.map<number>((week) => {
    return week.reduce<number>((dailyTotal, day) => {
      return dailyTotal + day.filter((slot) => slot.isAvailable).length;
    }, 0);
  });
};

export const format24hAs12h = (hours: number): string => {
  if (hours % 24 === 0) {
    return `12am`;
  }
  if (hours === 12) {
    return `12pm`;
  }
  if (hours < 12) {
    return `${hours}am`;
  }
  return `${hours % 12}pm`;
};

/**
 * Find the best slot
 *
 * If there is at least one Eco slot, the Eco slot that has the maximum number of existing bookings (fullest eco-slot)
 * If there is more than one Eco slot with the same max number  of bookings, the slot that is earliest is recommended (earliest fullest eco-slot)
 * If there are no Eco slots, recommend the earliest empty slot
 */
export const getBestBookableSlot = (
  bookableSlots: BookableSlot[],
): [BookableSlot | undefined, BookableSlot[]] => {
  const slots = [...bookableSlots];
  let bestSlot: BookableSlot | undefined;
  let firstEmptySlotIndex = -1;
  let currentBestSlotIndex = -1;
  let currentBestSlotAppointments = -1;

  slots.forEach((slot, index) => {
    if (!slot.isAvailable) {
      return;
    }

    if (slot.isRatingGood) {
      if (
        slot.appointmentsCount &&
        slot.appointmentsCount > currentBestSlotAppointments
      ) {
        currentBestSlotIndex = index;
        currentBestSlotAppointments = slot.appointmentsCount;
      }
    } else {
      if (firstEmptySlotIndex === -1 && slot.appointmentsCount === 0) {
        firstEmptySlotIndex = index;
      }
    }
  });

  // no eco slot found, best slot is the first (non-eco) empty slot
  if (currentBestSlotIndex === -1 && firstEmptySlotIndex !== -1) {
    currentBestSlotIndex = firstEmptySlotIndex;
  }

  if (currentBestSlotIndex !== -1) {
    slots[currentBestSlotIndex].isBestSlot = true;
    bestSlot = slots[currentBestSlotIndex];
  }

  return [bestSlot, slots];
};

/**
 * Returns the date formatted as weekday: "Monday"
 * @param dateTime ISO date string
 * @param timezone Optional. String indicating the timezone to use.
 */
export const dateWeekDay = (
  dateTime: string,
  timezone = DEFAULT_TIMEZONE,
): string => {
  return formatTz(utcToZonedTime(dateTime, timezone), 'EEEE');
};

/**
 * Returns the date formatted as "7 Dec"
 * @param dateTime ISO date string
 * @param timezone Optional. String indicating the timezone to use.
 */

export const dateDayMonth = (
  dateTime: string,
  timezone = DEFAULT_TIMEZONE,
): string => {
  return formatTz(utcToZonedTime(dateTime, timezone), 'd MMM');
};

/**
 * Returns a time slot formatted as "12am-4pm"
 *
 * @param startDateTime ISO date string (example: "2020-06-20T18:00:00Z")
 * @param endDateTime ISO date string
 * @param timezone Optional. String indicating the timezone to use.
 */
export function prettyTimeSlot({
  startDateTime,
  endDateTime,
  timezone = DEFAULT_TIMEZONE,
}: {
  startDateTime: string;
  endDateTime: string;
  timezone?: string;
}): string {
  const zonedStartDateTime = formatTz(
    utcToZonedTime(startDateTime, timezone),
    'ha',
  );
  const zonedStartEndTime = formatTz(
    utcToZonedTime(endDateTime, timezone),
    'ha',
  );

  return `${zonedStartDateTime}-${zonedStartEndTime}`.toLowerCase();
}

/**
 * Returns a time slot formatted as "Tuesday 25 May"
 *
 * @param dateTime ISO date string
 * @param timezone Optional. String indicating the timezone to use.
 */
export const prettyDayDateMonth = (
  dateTime: string,
  timezone = 'Europe/London',
) => {
  return formatTz(utcToZonedTime(dateTime, timezone), 'EEEE d MMMM');
};
