import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';

import { StyledButton } from 'components/Button/Button';

import { ExpertsSection } from './components/ExpertsSection';
import { HeroSection } from './components/HeroSection';
import { NeedMoreHelpSection } from './components/NeedMoreHelpSection';
import { PartnersSection } from './components/PartnersSection';
import { PaymentInfoSection } from './components/PaymentInfoSection';
import { PriceSection } from './components/PriceSection';
import { ReasonsToChooseSection } from './components/ReasonsToChooseSection';
import { ReviewsSection } from './components/ReviewsSection';
import { WhoWeAreSection } from './components/WhoWeAreSection';

/**
 * Utility components
 */
const MainMarketingContainer = styled.div`
  background-color: ${({ theme }) => theme.app.background.primary};

  @media (min-width: 680px) {
    padding-bottom: 80px;

    > * {
      margin-bottom: 80px;

      &:not(h1):last-of-type {
        margin-top: 80px;
        margin-bottom: 0;
      }
    }

    &[aria-label='marketing-content-2'] {
      padding-top: 80px;
      padding-bottom: 80px;
    }
  }
`;

const StickyButtonContainer = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 50%;
  margin-left: -50%;
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.app.padding.mediumPadding} 0;
  background-color: rgba(255, 255, 255, 0.7);
  // NOTE: backdrop-filter is still experimental on FF and not supported on IE
  backdrop-filter: blur(4px);
  box-shadow: 0 3px 16px rgba(11, 78, 213, 0.3);
  z-index: 1;
`;

const StickyButton = styled(StyledButton)`
  width: 90%;

  @media (min-width: 680px) {
    max-width: 345px;
  }
`;

export const DEFAULT_REPAIR_PRICING = '75.00';
export const DEFAULT_CURRENCY = 'GBP';
export const DEFAULT_BRAND = 'Kantan';

interface MarketingLayoutProps {
  showBookNow?: boolean;
  target: HTMLElement | null;
}

export const MarketingLayout = ({
  children,
  showBookNow,
  target,
}: PropsWithChildren<MarketingLayoutProps>) => {
  return (
    <>
      <MainMarketingContainer role="region" aria-label="marketing-content-1">
        <HeroSection target={target} />
        <PriceSection />
        <ReviewsSection />
        <PartnersSection />
        <PaymentInfoSection />
      </MainMarketingContainer>
      {children}
      <MainMarketingContainer role="region" aria-label="marketing-content-2">
        <ExpertsSection />
        <WhoWeAreSection />
        <ReasonsToChooseSection />
        <NeedMoreHelpSection />
      </MainMarketingContainer>
      {showBookNow && (
        <StickyButtonContainer>
          <StickyButton
            $variant="primary"
            onClick={() =>
              !!target && target.scrollIntoView({ behavior: 'smooth' })
            }
          >
            Book Now
          </StickyButton>
        </StickyButtonContainer>
      )}
    </>
  );
};
