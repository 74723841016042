import { Icon } from 'theme/types';

export const PriceTagIcon: Icon = (props) => {
  const { color, ...svgProps } = props;

  return (
    <svg
      width="38"
      height="39"
      viewBox="0 0 38 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M20.9049 0L0 20.9049L17.1928 38.0977L38 17.1928V0H20.9049ZM17.0951 9.76864C18.1697 8.69409 19.8303 8.69409 20.8072 9.76864C21.8817 10.8432 21.8817 12.5039 20.8072 13.5784C19.7326 14.653 18.072 14.653 17.0951 13.5784C16.1183 12.5039 16.1183 10.8432 17.0951 9.76864ZM20.9049 28.4267C19.8303 29.5013 18.1697 29.5013 17.1928 28.4267C16.1183 27.3522 16.1183 25.6915 17.1928 24.7147C18.2674 23.6401 19.928 23.6401 20.9049 24.7147C21.8817 25.7892 21.8817 27.3522 20.9049 28.4267ZM28.6221 20.6118H9.28021V17.5835H28.6221V20.6118ZM32.4319 7.9126C31.0643 7.9126 29.892 6.83805 29.892 5.37275C29.892 4.00514 30.9666 2.83291 32.4319 2.83291C33.8972 2.83291 34.9717 3.90746 34.9717 5.37275C34.874 6.83805 33.7995 7.9126 32.4319 7.9126Z"
        fill={color || '#0A9828'}
      />
      <path
        opacity="0.3"
        d="M28.6221 17.5835H9.28021V20.6118H28.6221V17.5835Z"
        fill={color || '#0A9828'}
      />
      <path
        opacity="0.3"
        d="M20.9049 13.5783C21.9794 12.5038 21.9794 10.8431 20.9049 9.76856C19.8303 8.69401 18.1697 8.69401 17.1928 9.76856C16.1183 10.8431 16.1183 12.5038 17.1928 13.5783C18.1697 14.5552 19.8303 14.5552 20.9049 13.5783Z"
        fill={color || '#0A9828'}
      />
      <path
        opacity="0.3"
        d="M17.0951 24.6169C16.0205 25.6915 16.0205 27.3521 17.0951 28.329C18.1696 29.4036 19.8303 29.4036 20.8072 28.329C21.8817 27.2545 21.8817 25.5938 20.8072 24.6169C19.7326 23.6401 18.1696 23.6401 17.0951 24.6169Z"
        fill={color || '#0A9828'}
      />
    </svg>
  );
};
