import { IconProps } from 'theme/types';

export function BlockageIcon(props: IconProps) {
  const { color, ...svgProps } = props;
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <circle cx="7" cy="8" r="2" fill="#292B2E" />
      <circle cx="12" cy="6" r="2" fill="#292B2E" />
      <circle cx="7" cy="13" r="2" fill="#292B2E" />
      <circle cx="12" cy="12" r="2" fill="#292B2E" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 6C4.88071 6 6 4.88071 6 3.5V1C6 0.447715 5.55228 0 5 0C4.44772 0 4 0.447715 4 1V3.5C4 3.77614 3.77614 4 3.5 4H1C0.447715 4 0 4.44772 0 5C0 5.55228 0.447715 6 1 6H3.5ZM16.5 6C15.1193 6 14 4.88071 14 3.5V1C14 0.447715 14.4477 0 15 0C15.5523 0 16 0.447715 16 1V3.5C16 3.77614 16.2239 4 16.5 4H19C19.5523 4 20 4.44772 20 5C20 5.55228 19.5523 6 19 6H16.5ZM6 16.5C6 15.1193 4.88071 14 3.5 14H1C0.447715 14 0 14.4477 0 15C0 15.5523 0.447715 16 1 16H3.5C3.77614 16 4 16.2239 4 16.5V19C4 19.5523 4.44772 20 5 20C5.55228 20 6 19.5523 6 19V16.5ZM16.5 14C15.1193 14 14 15.1193 14 16.5V19C14 19.5523 14.4477 20 15 20C15.5523 20 16 19.5523 16 19V16.5C16 16.2239 16.2239 16 16.5 16H19C19.5523 16 20 15.5523 20 15C20 14.4477 19.5523 14 19 14H16.5Z"
        fill={color ?? '#292B2E'}
      />
    </svg>
  );
}
