import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { CalloutData, CalloutDataState } from '../types';
import { defaultContactValues, useHasStoreHydrated } from '../utils';

const emptyState: CalloutDataState = {
  jobType: '',
  mainProblem: '',
  fuelType: '',
  additionalInformation: '',
  appointmentSlots: [],
  paymentMethodId: undefined,
  metadata: {},
  ...defaultContactValues,
  setState: () => {},
  clearState: () => {},
};

export const createCalloutStore = create<CalloutDataState>()(
  devtools(
    persist(
      (set) => ({
        ...emptyState,
        setState: (calloutData: Partial<CalloutData>) =>
          set(() => ({
            ...calloutData,
          })),
        clearState: () =>
          set(() => ({
            jobType: '',
            mainProblem: '',
            fuelType: '',
            additionalInformation: '',
            appointmentSlots: [],
            metadata: {},
            paymentMethodId: undefined,
            ...defaultContactValues,
          })),
      }),
      {
        name: 'callouts',
        getStorage: () => sessionStorage,
      },
    ),
  ),
);

export const useCalloutsStore = ((selector, compare) => {
  const store = createCalloutStore(selector, compare);

  const hydrated = useHasStoreHydrated();

  return hydrated ? store : selector?.(emptyState) ?? emptyState;
}) as typeof createCalloutStore;
