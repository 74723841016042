import { JOB_TYPES } from 'utils/jobTypes';

export type ManagedRate = {
  amount: string;
  unit: JobRateItemUnitEnum;
  jobType: JOB_TYPES;
  isAmountWithVat: boolean;
  isPromoRate: boolean;
  prePromoAmount?: string;
};

export enum JobRateItemUnitEnum {
  Job = 'job',
  Hour = 'hour',
}
