import * as React from 'react';

import { IconBase } from './utils';

export const EVChargerIcon: IconBase = (props) => {
  const { color, ...svgProps } = props;

  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_357_18346)">
        <path
          d="M24 14.5C24 19.725 28.275 24 33.5 24C38.725 24 43 19.725 43 14.5H24Z"
          fill={color || '#0A9828'}
        />
        <path
          opacity="0.3"
          d="M37.5766 14.5L43.0391 14.5L43.0391 5L37.5766 5L37.5766 14.5Z"
          fill={color || '#0A9828'}
        />
        <path
          opacity="0.3"
          d="M23.9672 14.5L29.4297 14.5L29.4297 5L23.9672 5L23.9672 14.5Z"
          fill={color || '#0A9828'}
        />
        <path
          opacity="0.3"
          d="M32.0812 14.5342L34.8125 14.5342L34.8125 9.07168L32.0812 9.07168L32.0812 14.5342Z"
          fill={color || '#0A9828'}
        />
        <path
          d="M20.4375 43C28.9875 43 35.875 36.1125 35.875 27.5625V21.625H31.125V27.5625C31.125 33.5 26.375 38.25 20.4375 38.25C14.5 38.25 9.75 33.5 9.75 27.5625H5C5 36.1125 11.8875 43 20.4375 43Z"
          fill={color || '#0A9828'}
        />
      </g>
      <defs>
        <clipPath id="clip0_357_18346">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
