import { Icon } from 'theme/types';

export const ControllerLight: Icon = (props) => {
  const {
    color = '#0A9828',
    secondarycolor = '#0A9828',
    opacity = 0.3,
    ...svgProps
  } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      {...svgProps}
    >
      <path opacity={opacity} d="M44 9H4V39H44V9Z" fill={color} />
      <path
        opacity={opacity}
        d="M16 30C19.3137 30 22 27.3137 22 24C22 20.6863 19.3137 18 16 18C12.6863 18 10 20.6863 10 24C10 27.3137 12.6863 30 16 30Z"
        fill={secondarycolor}
      />
      <path
        opacity={opacity}
        d="M28 30C29.1046 30 30 29.1046 30 28C30 26.8954 29.1046 26 28 26C26.8954 26 26 26.8954 26 28C26 29.1046 26.8954 30 28 30Z"
        fill={secondarycolor}
      />
      <path
        opacity={opacity}
        d="M28 22C29.1046 22 30 21.1046 30 20C30 18.8954 29.1046 18 28 18C26.8954 18 26 18.8954 26 20C26 21.1046 26.8954 22 28 22Z"
        fill={secondarycolor}
      />
      <path
        opacity={opacity}
        d="M36 30C37.1046 30 38 29.1046 38 28C38 26.8954 37.1046 26 36 26C34.8954 26 34 26.8954 34 28C34 29.1046 34.8954 30 36 30Z"
        fill={secondarycolor}
      />
      <path
        opacity={opacity}
        d="M36 22C37.1046 22 38 21.1046 38 20C38 18.8954 37.1046 18 36 18C34.8954 18 34 18.8954 34 20C34 21.1046 34.8954 22 36 22Z"
        fill={secondarycolor}
      />
    </svg>
  );
};
