import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import Image from 'next/legacy/image';

import { SectionHeader } from 'layouts/MarketingLayout/components/utilityStyles';

const StyledFooter = styled.footer`
  position: relative;
  padding: ${({ theme }) => theme.app.padding.largePadding}
    ${({ theme }) => theme.app.padding.mediumLargePadding};
  padding-bottom: 80px;
  background-color: ${({ theme }) => theme.app.background.dark};
  color: ${({ theme }) => theme.app.text.white};
`;

const FooterMainContent = styled.div`
  @media (min-width: 680px) {
    display: flex;
    justify-content: space-between;
  }
`;

const FooterHeader = styled(SectionHeader)`
  color: ${({ theme }) => theme.app.text.white};
`;

const FooterContactSection = styled.address`
  margin-bottom: ${({ theme }) => theme.app.margin.mediumLargeMargin};

  > p {
    margin-top: ${({ theme }) => theme.app.margin.mediumLargeMargin};
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[14]};
    font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.lightest};

    & span {
      font-size: ${({ theme }) => theme.app.fontSettings.fontSize[24]};
    }
  }
`;

const FooterSocialLinks = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.app.margin.mediumLargeMargin};

  > a {
    margin-right: ${({ theme }) => theme.app.margin.largeMargin};
  }
`;

const TradeMark = styled.div`
  display: inline-block;
  margin-top: ${({ theme }) => theme.app.margin.largeMargin};

  > span {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[14]};
    font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.lightest};
  }

  @media (min-width: 680px) {
    display: block;
  }
`;

const TradeMarkLink = styled.span`
  color: ${({ theme }) => theme.app.colors.blue.lightest};

  &:before {
    content: ' · ';
  }
`;

const CompanyRegistration = styled(Typography)`
  display: inline-block;
  color: ${({ theme }) => theme.app.colors.grey.base};
  margin-top: ${({ theme }) => theme.app.margin.largeMargin};
`;

const MadeBy = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: right;

  > span {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[14]};
    font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.lightest};

    > a {
      color: ${({ theme }) => theme.app.colors.blue.lightest};
    }
  }

  @media (min-width: 680px) {
    position: absolute;
    bottom: 80px;
    right: ${({ theme }) => theme.app.padding.mediumLargePadding};
  }
`;

const socialLinks = [
  {
    name: 'SocialTwitter',
    url: 'https://twitter.com/KantanTech',
  },
  {
    name: 'SocialLinkedIn',
    url: 'https://www.linkedin.com/company/kantan',
  },
  {
    name: 'SocialGithub',
    url: 'https://github.com/kantanhq',
  },
];

export const trademarkLinks = [
  { title: 'Privacy', url: 'https://kantan.co.uk/privacy-policy' },
  { title: 'Cookies', url: 'https://kantan.co.uk/cookie-policy' },
  { title: 'Terms', url: 'https://kantan.co.uk/terms-and-conditions' },
];

export const Footer = () => {
  return (
    <StyledFooter>
      <FooterMainContent>
        <div>
          <FooterHeader>Find us</FooterHeader>
          <FooterContactSection>
            <p>
              <span>📍</span>{' '}
              <a
                href="https://goo.gl/maps/noViPaBYjrTyv6Jw7"
                target="_blank"
                rel="noreferrer"
              >
                140-142 Kensington Church Street, London, W8 4BN
              </a>
            </p>
            <p>
              <span>📧</span>{' '}
              <a href="mailto:support@kantan.tech">support@kantan.tech</a>
            </p>
          </FooterContactSection>
        </div>
        <div>
          <FooterHeader>Follow us</FooterHeader>
          <FooterSocialLinks>
            {socialLinks.map(({ name, url }) => (
              <a key={name} href={url} target="_blank" rel="noreferrer">
                <Image
                  src={`/assets/svg/${name}.svg`}
                  width={24}
                  height={24}
                  alt={name.replace('Social', '')}
                />
              </a>
            ))}
          </FooterSocialLinks>
        </div>
      </FooterMainContent>
      <TradeMark>
        <span>© 2022 Kantan, All rights reserved</span>{' '}
        {trademarkLinks.map(({ title, url }) => (
          <TradeMarkLink key={title}>
            <a href={url} target="_blank" rel="noreferrer">
              {title}
            </a>
          </TradeMarkLink>
        ))}
      </TradeMark>
      <CompanyRegistration variant="caption">
        Company number 11681210 · Registered in England & Wales
      </CompanyRegistration>
      <MadeBy>
        <span>
          Made with 💙 by{' '}
          <a
            href="https://kantan.co.uk/careers"
            target="_blank"
            rel="noreferrer"
          >
            Team Kantan
          </a>
        </span>
      </MadeBy>
    </StyledFooter>
  );
};
