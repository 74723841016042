import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useController } from 'react-hook-form';
import { mediaQuery } from 'theme/energy_experts';

import { CalloutInput as Input } from 'components/redesign/CalloutSelectionRadioButton/CalloutSelectionRadioButton.styles';
import { RadioContainer } from 'components/redesign/ServiceFuelTypeRadioButton/ServiceFuelTypeRadioButton.styles';
import { MotivationOption } from 'services/kantanClient';

interface MotivationRadioButtonProps {
  option: MotivationOption;
  name: string;
  isSelected: boolean;
  required?: boolean;
  onChange: () => void;
  disabled?: boolean;
}

export const MotivationRadioButton = ({
  option,
  name,
  isSelected,
  required,
  onChange,
  disabled,
}: MotivationRadioButtonProps) => {
  const {
    field: { ref, ...restOfField },
  } = useController({
    name,
    rules: {
      required,
    },
  });
  const theme = useTheme();
  const { value, icon, label } = option;
  return (
    <RadioContainer
      whileHover={!disabled ? { scale: 1.1 } : {}}
      whileTap={!disabled ? { scale: 0.9 } : {}}
      $isSelected={isSelected}
      onClick={onChange}
    >
      <InputContainer $isSelected={isSelected}>
        <Input
          type="radio"
          {...restOfField}
          ref={ref}
          name={name}
          value={value}
          checked={isSelected}
          onChange={(e) => {
            restOfField.onChange(e);
          }}
          disabled={disabled}
        />
        <InnerContainer $isSelected={isSelected}>
          {icon && (
            <div key={icon}>
              {theme.app.icons[icon]({
                color: isSelected && theme.app.text.white,
              })}
            </div>
          )}
          <p>{label}</p>
        </InnerContainer>
      </InputContainer>
    </RadioContainer>
  );
};

const InnerContainer = styled.div<{ $isSelected: boolean }>`
  display: grid;
  gap: 10px 0;
  justify-items: center;
  text-align: center;

  p {
    color: ${({ theme, $isSelected }) =>
      $isSelected
        ? theme.app.text.white
        : theme.app.landingPage.tracker.simplifiedStepsTitle};
    font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.regular};
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[14]};
    line-height: 1.25;
    padding: 0 ${({ theme }) => theme.app.spacing[16]};
  }

  @media (${mediaQuery('tablet')}) {
    p {
      font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
      padding: 0 ${({ theme }) => theme.app.spacing[18]};
    }
  }
`;

const InputContainer = styled.div<{
  $isSelected: boolean;
}>`
  position: relative;
  height: 100%;
  display: grid;
  place-items: center;
  cursor: pointer;

  ${({ theme, $isSelected }) =>
    $isSelected &&
    `
      background-color: ${theme.app.background.selectedDark};
    `}

  &:hover {
    ${InnerContainer} {
      @keyframes fadeIn {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }
  }

  animation: ease-in;
`;
