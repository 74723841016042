import { createTheme } from '@mui/material/styles';
import {
  CombiBoiler,
  ControllerLight,
  PowerLead,
  SystemBoiler,
} from 'productSpecific/tado/icons';

import {
  CarbonIcon,
  DocumentIcon,
  EVChargerIcon,
  HeatPumpIcon,
  HouseIcon,
  PriceTagIcon,
  RaisedHandIcon,
  SolarIcon,
} from 'components/icons';
import {
  BoilerLeakingIcon,
  BoilerNotWorkingIcon,
  ElectricIcon,
  GasIcon,
  GasSafetyCertificateIcon,
  LPGIcon,
  NoHeatingIcon,
  NoHotWaterIcon,
  OilIcon,
  SpannerIcon,
} from 'components/redesign/ServiceTypeIcons';
import { BlockageIcon } from 'components/redesign/ServiceTypeIcons/Blockage';

import { colors } from './colors';
import { spacing } from './spacing';
import {
  fontFamily,
  fontFamilyHeadings,
  fontImport,
  fontSize,
  fontWeight,
} from './typography';

export type EnergyExpertsThemeType = typeof theme;

export const theme = {
  landingPage: {
    light: {
      background: colors.grey.lightest,
      title: colors.green.base,
      text: colors.green.base,
      disclaimer: colors.solid.white,
      priceSection: colors.grey.veryDark,
    },
    dark: {
      background: colors.green.base,
      title: colors.solid.white,
      text: colors.solid.white,
    },
    veryLight: {
      background: colors.solid.almostWhite,
      title: colors.grey.veryDark,
      text: colors.grey.veryDark,
    },
    white: {
      background: colors.solid.white,
      title: colors.grey.veryDark,
      text: colors.grey.veryDark,
    },
    muted: {
      background: colors.lime.muted,
      title: colors.grey.veryDark,
      text: colors.grey.veryDark,
    },
    generalMarketingContent: {
      subTitle: colors.green.base,
      title: colors.grey.veryDark,
    },
    bookingStepsPromo: {
      paymentTerms: colors.grey.quiteDark,
    },
    threeReasons: colors.grey.veryDark,
    repairsUrgencyPromo: {
      circularProgress: colors.green.base,
      progressLabelPercentage: colors.green.base,
      progressLabelText: colors.green.base,
    },
    tracker: {
      placeholder: colors.grey.quiteDark,
      simplifiedStepsTitle: colors.grey.veryDark,
      stepsCounter: colors.grey.dark,
      stepsTitle: colors.green.base,
    },
    generalText: {
      minor: colors.grey.quiteDark,
      major: colors.grey.veryDark,
    },
  },
  reviewPage: {
    text: {
      summary: colors.green.base,
    },
  },
  booking: {
    title: colors.solid.black,
  },
  text: {
    primary: colors.grey.darkest,
    secondary: colors.grey.darker,
    tertiary: colors.grey.dark,
    caption: colors.grey.base,
    comboPrice: '#C64A18',
    action: colors.green.base,
    actionCta: colors.green.ctaButton,
    actionDark: colors.green.darker,
    success: colors.green.dark,
    warning: colors.orange.base,
    error: colors.red.base,
    currentCalendarWeekDay: colors.green.base,
    white: colors.solid.white,
    black: colors.solid.black,
    awardCopy: colors.grey.veryDark,
    snackBarSuccess: colors.solid.white,
    link: colors.green.ctaButton,
    pageTitle: colors.lime.ovoDark,
  },
  messages: {
    action: colors.green.dark,
    actionDark: colors.blue.darker,
    actionBase: colors.blue.base,
    error: colors.red.dark,
    success: colors.green.success,
    warning: colors.orange.lightest,
    banner: colors.purple.lightest,
    ecoBanner: colors.green.base,
    black: colors.grey.darkest,
    logo: colors.lime.ovoLogo,
    white: colors.solid.white,
  },
  background: {
    primary: colors.grey.lighter,
    secondary: colors.solid.white,
    selected: colors.green.dark,
    selectedDark: colors.green.darkest,
    selectedRed: colors.red.darkerSelected,
    white: colors.solid.white,
    unselected: colors.grey.base,
    dark: colors.grey.darkest,
    calendarWeekDays: colors.green.lightest,
    calendarNavigation: colors.grey.lighter,
    lettingAgency: colors.purple.lighter,
    success: colors.green.lightest,
    specialPrice: colors.orange.light,
    circularProgress: colors.green.light,
    translucentAction: colors.grey.lightTranslucent,
    greyTranslucentRow: colors.grey.lightTranslucent,
    snackBarSuccess: colors.nebula.base,
    kantanBlue: colors.blue.dark,
    emailSupportButton: colors.green.ctaButton,
    cancel: colors.red.cancel,
    layoutContainerDesktop: 'rgba(13,132,38,0.1)',
    layoutContainerMobile: '#F5F5F5',
    tooltip: colors.green.midnight,
    supplementaryInfo: colors.green.success,
    radioButton: colors.pink.dark,
  },
  border: {
    primary: colors.grey.darker,
    secondary: colors.grey.light,
    focus: colors.green.dark,
    eco: colors.green.base,
    lettingAgency: colors.purple.lighter,
    success: colors.green.darker,
  },
  component: {
    sliderArrow: colors.green.ctaButton,
  },
  boxShadow: {
    standard: 'inset 0px -1px 0px #d0d8e5',
  },
  borderRadius: {
    smallCorner: '4px',
    mediumCorner: '6px',
    largeCorner: '8px',
    extraLargeCorner: '20px',
  },
  padding: {
    smallPadding: spacing[8],
    smallMediumPadding: spacing[12],
    mediumPadding: spacing[16],
    mediumLargePadding: spacing[24],
    largePadding: spacing[32],
    extraLargePadding: spacing[40],
    massivePadding: spacing[80],
  },
  margin: {
    smallMargin: spacing[4],
    mediumMargin: spacing[8],
    mediumLargeMargin: spacing[16],
    largeMargin: spacing[24],
    extraLargeMargin: spacing[32],
    extraExtraLargeMargin: spacing[40],
    massiveMargin: spacing[80],
  },
  spacing,
  fontSettings: {
    fontImport,
    fontFamily,
    fontFamilyHeadings,
    fontSize,
    fontWeight,
  },
  colors,
  breakpoints: {
    wideMobile: '480px',
    tablet: '768px',
    desktop: '1024px',
    wide: '1280px',
    max: '1400px',
  },
  icons: {
    spanner: (props: any) => SpannerIcon(props),
    gasSafetyCertificate: (props: any) => GasSafetyCertificateIcon(props),
    gas: (props: any) => GasIcon(props),
    noHotWaterIcon: (props: any) => NoHotWaterIcon(props),
    noHeating: (props: any) => NoHeatingIcon(props),
    boilerNotWorking: (props: any) => BoilerNotWorkingIcon(props),
    boilerLeaking: (props: any) => BoilerLeakingIcon(props),
    blockage: (props: any) => BlockageIcon(props),
    lpg: (props: any) => LPGIcon(props),
    oil: (props: any) => OilIcon(props),
    electric: (props: any) => ElectricIcon(props),
    carbon: (props: any) => CarbonIcon(props),
    priceTag: (props: any) => PriceTagIcon(props),
    raisedHand: (props: any) => RaisedHandIcon(props),
    document: (props: any) => DocumentIcon(props),
    solarPanel: (props: any) => SolarIcon(props),
    heatPump: (props: any) => HeatPumpIcon(props),
    house: (props: any) => HouseIcon(props),
    powerLead: (props: any) => PowerLead(props),
    controllerLight: (props: any) => ControllerLight(props),
    combiBoiler: (props: any) => CombiBoiler(props),
    systemBoiler: (props: any) => SystemBoiler(props),
    evCharger: (props: any) => EVChargerIcon(props),
  },
};

export const mediaQuery = (
  breakpoint: keyof (typeof theme)['breakpoints'],
  addBrackets?: boolean,
  useMaxWidth?: boolean,
) =>
  addBrackets
    ? `(${useMaxWidth ? 'max-width' : 'min-width'}: ${
        theme.breakpoints[breakpoint]
      })`
    : `${useMaxWidth ? 'max-width' : 'min-width'}: ${
        theme.breakpoints[breakpoint]
      }`;

export const materialUiTheme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: theme.fontSettings.fontFamily,
        },
        h1: {
          fontSize: theme.fontSettings.fontSize[18],
          lineHeight: 1.22,
          marginBottom: theme.margin.mediumMargin,
        },
        h2: {
          fontSize: theme.fontSettings.fontSize[16],
          lineHeight: 1.5,
        },
        h3: {
          fontSize: theme.fontSettings.fontSize[16],
          lineHeight: 1.5,
        },
        body1: {
          fontSize: theme.fontSettings.fontSize[14],
          fontWeight: theme.fontSettings.fontWeight.regular,
        },
        body2: {
          fontSize: theme.fontSettings.fontSize[14],
          fontWeight: theme.fontSettings.fontWeight.light,
        },
        caption: {
          fontSize: theme.fontSettings.fontSize[12],
          fontWeight: theme.fontSettings.fontWeight.light,
          color: theme.text.secondary,
          lineHeight: 1.33,
        },
      },
    },
  },
});
