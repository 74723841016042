import {
  Controller,
  ControllerRenderProps,
  useFormContext,
} from 'react-hook-form';

import { Button } from 'components/Button';

import { YourHomeOption, YourHomeQuestionKey } from '../YourHome/YourHome';
import {
  Container,
  QuestionContainer,
  QuestionText,
  RadioButton,
  RadioButtonsContainer,
} from './YourHomeQuestion.styles';

const answers = ['Yes', 'No'];

type YourHomeQuestionProps = {
  option: YourHomeOption;
};

export const YourHomeQuestion = ({ option }: YourHomeQuestionProps) => {
  const form = useFormContext<Record<YourHomeQuestionKey, string>>();
  const { key, label, marginBottom, supplementaryText } = option;

  const handleClick = (
    field: ControllerRenderProps<
      Record<YourHomeQuestionKey, string>,
      YourHomeQuestionKey
    >,
    answer: string,
  ) => {
    field.onChange(answer);
    form.watch();
  };

  const constructTestId = (answer: string) => {
    const question = key;
    return `${question}: ${answer}`;
  };

  return (
    <Container $marginBottom={marginBottom}>
      <Controller
        name={key}
        control={form.control}
        rules={{ required: true }}
        render={({ field }) => (
          <QuestionContainer>
            <QuestionText>{label}</QuestionText>
            <RadioButtonsContainer>
              {answers.map((answer) => (
                <RadioButton key={answer} $isSelected={answer === field.value}>
                  <Button
                    testId={constructTestId(answer)}
                    onClick={() => handleClick(field, answer)}
                  >
                    {answer}
                  </Button>
                </RadioButton>
              ))}
            </RadioButtonsContainer>
          </QuestionContainer>
        )}
      />
      {supplementaryText && supplementaryText(form)}
    </Container>
  );
};
