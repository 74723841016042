import styled from '@emotion/styled';
import Image from 'next/legacy/image';
import corgiLogo from 'public/assets/images/CorgiLogo.webp';
import ovoLogo from 'public/assets/images/OVOLogo.webp';

import { StrongText } from 'components/StrongText';
import { JOB_TYPES } from 'utils/jobTypes';

import {
  AlignContent,
  ResponsiveContainer,
  SectionHeader,
} from './utilityStyles';

const PartnersContainer = styled(ResponsiveContainer)`
  background-color: ${({ theme }) => theme.app.background.white};
  padding: ${({ theme }) => theme.app.padding.largePadding}
    ${({ theme }) => theme.app.padding.mediumLargePadding};

  @media (min-width: 680px) {
    border-radius: ${({ theme }) => theme.app.borderRadius.extraLargeCorner};
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.08);
  }
`;

const PartnerLogosContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({ theme }) => theme.app.margin.largeMargin};

  @media (min-width: 680px) {
    justify-content: center;

    > * {
      margin: 0 20px;
    }
  }
`;

const selectVariant = ({
  $variant,
  isDesktop,
}: {
  $variant: string;
  isDesktop?: boolean;
}) => {
  switch ($variant) {
    case 'chp':
      return isDesktop
        ? { width: '90px', height: '160px' }
        : { width: '46px', height: '81px' };
    case 'ovo':
      return isDesktop
        ? { width: '224px', height: '90px' }
        : { width: '112px', height: '45px' };
    default:
      return isDesktop
        ? { width: '180px', height: '90px' }
        : { width: '90px', height: '45px' };
  }
};

const PartnerLogo = styled.div<{ $variant: string }>`
  position: relative;
  height: ${({ $variant }) => selectVariant({ $variant }).height};
  width: ${({ $variant }) => selectVariant({ $variant }).width};

  @media (min-width: 680px) {
    height: ${({ $variant }) =>
      selectVariant({ $variant, isDesktop: true }).height};
    width: ${({ $variant }) =>
      selectVariant({ $variant, isDesktop: true }).width};
  }
`;

interface PartnersSectionProps {
  jobType?: JOB_TYPES;
}

export const PartnersSection = ({ jobType }: PartnersSectionProps) => {
  return (
    <AlignContent>
      <PartnersContainer>
        <SectionHeader>
          <StrongText>Major energy companies</StrongText> use our{' '}
          {jobType === JOB_TYPES.PLUMBING_REPAIR ? 'plumbing' : 'engineers'}
        </SectionHeader>
        <PartnerLogosContainer>
          <PartnerLogo $variant="chp">
            <Image
              src={corgiLogo}
              alt="Corgi Logo"
              width={91}
              height={161}
              placeholder="blur"
            />
          </PartnerLogo>
          <PartnerLogo $variant="ovo">
            <Image
              src={ovoLogo}
              alt="OVO Logo"
              width={224}
              height={90}
              placeholder="blur"
            />
          </PartnerLogo>
        </PartnerLogosContainer>
      </PartnersContainer>
    </AlignContent>
  );
};
