import { Icon } from 'theme/types';

export const ElectricIcon: Icon = (props) => {
  const { color, secondarycolor, ...svgProps } = props;

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      {...svgProps}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7543_29931)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.01206 31.7873C6.46008 31.4464 6.23203 30.782 6.46504 30.1936L10.434 20.1711L4.28956 20.662C3.71689 20.7078 3.17378 20.4114 2.9226 19.9161C2.67142 19.4208 2.76385 18.8284 3.15523 18.4252L20.6264 0.424923C21.0679 -0.0298538 21.7785 -0.132317 22.34 0.177851C22.9015 0.488016 23.1651 1.12865 22.9758 1.72264L19.748 11.8464L26.3229 11.3376C26.9034 11.2927 27.4517 11.5991 27.6969 12.1054C27.942 12.6117 27.8322 13.2107 27.4216 13.6066L8.75261 31.6066C8.28965 32.053 7.56405 32.1283 7.01206 31.7873ZM22.7936 14.2861L17.9311 14.6623C17.4718 14.6979 17.0239 14.5131 16.735 14.169C16.4462 13.8249 16.3539 13.366 16.4885 12.9437L18.5492 6.4804L7.64166 17.7183L12.4023 17.3379C12.8784 17.2999 13.3416 17.4986 13.6291 17.8644C13.9166 18.2301 13.9882 18.7116 13.8186 19.1397L11.3846 25.2861L22.7936 14.2861Z"
          fill={color || 'black'}
        />
      </g>
      <defs>
        <clipPath id="clip0_7543_29931">
          <rect width="32" height="32" fill={secondarycolor || 'white'} />
        </clipPath>
      </defs>
    </svg>
  );
};
