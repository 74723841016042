import styled from '@emotion/styled';
import { Modal } from '@mui/material';
import Image from 'next/legacy/image';
import { useRouter } from 'next/router';
import { createContext, useContext } from 'react';

import { StyledButton } from '../Button/Button';

export const ErrorModalContext = createContext<{
  isOpen: boolean;
  setIsOpen: (newState: boolean) => void;
}>({ isOpen: false, setIsOpen: () => {} });

export const ModalContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: grid;
  grid-template: 0.5fr 0.5fr 1fr / 0.5fr 3fr;
  grid-gap: 8px;

  background-color: ${({ theme }) => theme.app.background.white};
  border-radius: 8px;
  width: 95%;
  padding: ${({ theme }) => theme.app.padding.mediumPadding};

  @media (min-width: 680px) {
    grid-template: repeat(4, 1fr) / 1fr;
    max-width: 345px;
    justify-items: center;
  }
`;

const ModalIcon = styled.div`
  grid-row: 1/3;

  @media (min-width: 680px) {
    grid-row: 1/1;
  }
`;

const ModalHeader = styled.h1`
  grid-row: 1/1;
  grid-column: 2/3;

  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.semiBold};
  line-height: 1.33;
  color: ${({ theme }) => theme.app.colors.solid.black};

  @media (min-width: 680px) {
    grid-row: 2/2;
    grid-column: span 2;

    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[18]};
  }
`;

const ModalDescription = styled.p`
  grid-row: 2/2;
  grid-column: 2/3;

  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.light};
  line-height: 1.33;
  color: ${({ theme }) => theme.app.colors.solid.black};

  @media (min-width: 680px) {
    grid-row: 3/3;
    grid-column: span 2;
  }
`;

const ModalButton = styled(StyledButton)`
  grid-row: 3/3;
  grid-column: 1/3;
  width: 100%;

  @media (min-width: 680px) {
    grid-row: 4/4;
  }
`;

const errorModalText = {
  header: 'Something went wrong',
  description: 'Please check your details and try again',
  button: 'Go back',
};

const redirectModalText = {
  header: 'You are about to leave this page',
  description:
    'Are you sure you want to do this? You won`t be able to return to this page.',
  button: 'Cancel',
};

type ErrorModalVariants = 'error' | 'redirect';
interface ErrorModalProps {
  variant?: ErrorModalVariants;
}

export const ErrorModal = ({ variant = 'error' }: ErrorModalProps) => {
  const { isOpen, setIsOpen } = useContext(ErrorModalContext);
  const router = useRouter();

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleRedirect = () => {
    router.replace({
      pathname: `/service/${
        router.query.service_type ?? 'boiler-service'
      }/services`,
      query: router.query,
    });
  };

  const modalText = variant === 'error' ? errorModalText : redirectModalText;

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <ModalContainer>
        <ModalIcon>
          <Image
            src="/assets/svg/WarningIcon.svg"
            alt="Warning Icon"
            width={48}
            height={48}
          />
        </ModalIcon>
        <ModalHeader>{modalText?.header}</ModalHeader>
        <ModalDescription>{modalText?.description}</ModalDescription>
        <ModalButton $variant="primary" onClick={handleClose}>
          {modalText?.button}
        </ModalButton>
        {variant === 'redirect' && (
          <ModalButton $variant="secondary" onClick={handleRedirect}>
            Continue
          </ModalButton>
        )}
      </ModalContainer>
    </Modal>
  );
};
