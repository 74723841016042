import styled from '@emotion/styled';
import { LinearProgress } from '@mui/material';
import Link from 'next/link';
import React from 'react';

import { VerticalDivider } from 'components/redesign/Tracker/Tracker.styles';
import {
  OuterContainer,
  ProgressContainer,
  TrackerMobileContainer,
} from 'components/redesign/TrackerMobile/TrackerMobile.styles';
import { StrongText } from 'components/StrongText';

interface TrackerMobileProps {
  stepNumber?: number;
  logo: (value: any) => JSX.Element;
  title?: string;
  subtitle?: string;
  progress?: number;
  totalSteps?: number;
  logoProps?: object;
  stepHidden?: boolean;
  homePageUrl: string;
  mobileBackgroundColor?: string;
  handleLogoClick?: () => void;
}

export const TrackerMobile = ({
  stepNumber,
  logo: Logo,
  logoProps,
  title,
  subtitle,
  progress,
  totalSteps,
  stepHidden,
  homePageUrl,
  mobileBackgroundColor,
  handleLogoClick,
}: TrackerMobileProps) => {
  const renderLogo = (handleLogoClick?: () => void) => {
    if (handleLogoClick) {
      return (
        <LogoContainer onClick={handleLogoClick}>
          <Logo {...logoProps} />
        </LogoContainer>
      );
    } else {
      return (
        <Link href={homePageUrl} passHref legacyBehavior>
          <LogoContainer>
            <Logo {...logoProps} />
          </LogoContainer>
        </Link>
      );
    }
  };

  return (
    <OuterContainer>
      {progress !== undefined && (
        <ProgressContainer>
          <LinearProgress
            variant="determinate"
            color="inherit"
            value={progress}
          />
        </ProgressContainer>
      )}

      <TrackerMobileContainer>
        {renderLogo(handleLogoClick)}
        {!stepHidden && (
          <>
            <VerticalDivider />
            <StepsContainer>
              {stepNumber ? (
                <>
                  <StepsCounter>
                    Step {stepNumber} of {totalSteps}
                  </StepsCounter>
                  <StepsTitle>{title}</StepsTitle>
                </>
              ) : (
                <StrongText>{title}</StrongText>
              )}
            </StepsContainer>
          </>
        )}
      </TrackerMobileContainer>
      {subtitle && (
        <SubTitle $mobileBackgroundColor={mobileBackgroundColor}>
          {subtitle}
        </SubTitle>
      )}
    </OuterContainer>
  );
};

const LogoContainer = styled.div`
  padding-top: ${({ theme }) => theme.app.padding.smallPadding};
  svg {
    width: 150px;
    height: 45px;
  }
`;

const StepsCounter = styled.span`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[12]};
  line-height: 1.33;
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.light};
  color: ${({ theme }) => theme.app.landingPage.tracker.stepsTitle};
`;

const StepsTitle = styled.span`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
  line-height: 1.25;
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.bold};
  color: ${({ theme }) => theme.app.landingPage.tracker.stepsTitle};
`;

const SubTitle = styled.span<{
  $mobileBackgroundColor?: string;
}>`
  padding: ${({ theme }) => theme.app.spacing[16]};
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.bold};
  background-color: ${({ $mobileBackgroundColor = '#F5F5F5' }) =>
    $mobileBackgroundColor};
  display: block;
`;

const StepsContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  align-items: start;
  justify-content: center;

  padding: 13px ${({ theme }) => theme.app.padding.mediumPadding};
  color: ${({ theme }) => theme.app.text.actionCta};
`;
