import {
  CommonNestedProductConfigOptions,
  CommonRootProductConfigOptions,
  ProductConfigResponse,
  ServerOnlyRootProductConfigOptions,
  ServerProductOptions,
} from 'services/kantanClient';

import { isNullOrUndefined } from '../isNullOrUndefined';

interface ConfigMappingOptions {
  productType: string;
  rateDomain: string;
  nextAddressStep: string;
}

export function mapServerProductConfig(
  config: ProductConfigResponse,
  options: ConfigMappingOptions,
) {
  let product: FlatServerProductConfig | undefined = undefined;

  if (config.product) {
    const flatConfig = extractResidentPortalSpecificOptions(config.product);
    const configWithCompletePaths = completePaths(flatConfig, options);
    product = configWithCompletePaths;
  }

  return { ...config, product };
}

type FlatServerProductConfig = ServerOnlyRootProductConfigOptions &
  CommonRootProductConfigOptions &
  CommonNestedProductConfigOptions;

function extractResidentPortalSpecificOptions(
  config: ServerProductOptions,
): FlatServerProductConfig {
  const { residentPortalOptions, ...commonOptions } = config;
  const flatConfig = { ...commonOptions, ...residentPortalOptions };

  return flatConfig;
}

/**
 * Paths coming from the server are templates
 * like "/<product-type>/<rate-domain>/postcode".
 * That way we support one product config to work for multiple rate domains
 * and variations of the user input.
 *
 * Here we evaluate those templates and produce complete
 * paths such as "/energy-experts/oceanenergysavings/postcode"
 */
function completePaths(
  config: FlatServerProductConfig,
  options: ConfigMappingOptions,
): FlatServerProductConfig {
  const nextConfig = { ...config };

  const variables = {
    // TODO (S2-752): expose product type -like URL segment to product config
    'product-type': options.productType ?? '',
    'rate-domain': options.rateDomain ?? 'default',
    'next-address-step': options.nextAddressStep,
  };

  nextConfig.homePageUrl = evaluateTemplate(config.homePageUrl, variables);

  nextConfig.routeToRedirectIfMissingData = evaluateTemplate(
    config.routeToRedirectIfMissingData,
    variables,
  );

  nextConfig.steps = config.steps.map((step) => {
    const { options } = step;
    return {
      ...step,
      options: {
        ...options,
        prevStep: evaluateTemplate(options.prevStep, variables),
        nextStep: evaluateTemplate(options.nextStep, variables),
      },
    };
  });

  return nextConfig;
}

function evaluateTemplate(
  template: string,
  variables: Record<string, string | undefined>,
): string {
  return template.replace(/<([a-zA-Z-]*)>/g, (_, variableName): string => {
    const variableValue = variables[variableName];

    if (isNullOrUndefined(variableValue)) {
      throw new Error(
        `Expected to find a variable "${variableName}"` +
          `in the template "${template}", but found ${variableValue}`,
      );
    }

    return variableValue;
  });
}
