import { useRouter } from 'next/router';
import { useState } from 'react';

import { queryParamToJobTypeMap, SERVICE_TYPES_ROUTES } from 'utils/jobTypes';
import {
  currentPartner,
  mapPartnerEnvVariableToFullName,
  mapPartnerEnvVariableToShortName,
  PARTNERS,
} from 'utils/partners';
import { getPriceFromJobType } from 'utils/tracking';
import { useManagedRates } from 'utils/useManagedRates';

export const useLandingPage = () => {
  const {
    asPath,
    query: { service_type, product_type },
  } = useRouter();
  const managedRates = useManagedRates();

  const partner = process.env.NEXT_PUBLIC_PARTNER;

  const [shouldAnimateExit, setShouldAnimateExit] = useState(false);

  const isRepair = product_type === 'repair';
  const isCombo = service_type === SERVICE_TYPES_ROUTES.COMBO_SAVER;
  const isGas = service_type === SERVICE_TYPES_ROUTES.GAS_SAFETY_CERTIFICATE;
  const isPlumbing = service_type === SERVICE_TYPES_ROUTES.PLUMBING_REPAIR;

  const isKantanPartner = currentPartner === PARTNERS.KANTAN;
  const isOVOPartner = currentPartner === PARTNERS.OVO;

  const jobType = queryParamToJobTypeMap(service_type);

  const currentPrice = getPriceFromJobType(
    queryParamToJobTypeMap(service_type),
    managedRates,
  );
  const disclaimerPrice = currentPrice;

  const exitAnimation = shouldAnimateExit ? { opacity: 0 } : undefined;

  const contactUsClickTarget: HTMLElement | null = IS_BROWSER
    ? document.querySelector('#support')!
    : null;

  const handleContactUsClick = () =>
    contactUsClickTarget?.scrollIntoView({ behavior: 'smooth' });

  const partnerCopy = mapPartnerEnvVariableToFullName(partner);
  const partnerCopyShort = mapPartnerEnvVariableToShortName(partner);

  const seoTitle = (serviceType: string | string[] | undefined) => {
    switch (serviceType) {
      case SERVICE_TYPES_ROUTES.HEATING_REPAIR:
        return `Book a Boiler Repair Online - Same Day Repairs | ${partnerCopy}`;
      case SERVICE_TYPES_ROUTES.GAS_SAFETY_CERTIFICATE:
        return `Book your Gas Safety Certificate Online | ${partnerCopy}`;
      case SERVICE_TYPES_ROUTES.COMBO_SAVER:
        return `Book Boiler Service and Safety Certificate Online | ${partnerCopy}`;
      case SERVICE_TYPES_ROUTES.PLUMBING_REPAIR:
        return `Book a Plumbing Repair Online with ${partnerCopy}`;
      default:
        return `Book Boiler Service Online - Same Day Service | ${partnerCopy}`;
    }
  };

  const seoDescription = (
    jobType?: string | string[] | undefined,
    price?: string,
  ) => {
    switch (jobType) {
      case SERVICE_TYPES_ROUTES.BOILER_SERVICE:
        return 'Book your online boiler service with local and certified gas engineers. Same day appointments available. Pay when the service is completed. No hidden cost.';
      case SERVICE_TYPES_ROUTES.GAS_SAFETY_CERTIFICATE:
        return `Book your same day Gas Safety Certificate for commercial and residential properties with Kantan. We offer a Fixed price of £${price} with no extra hidden costs.`;
      case SERVICE_TYPES_ROUTES.COMBO_SAVER:
        return 'Book your boiler service and gas safety certificate at the same time. Our gas safe engineers conduct a safety inspection alongside your service at a cut price.';
      case SERVICE_TYPES_ROUTES.PLUMBING_REPAIR:
        return 'Only Pay When Your Repair is Completed. Certified Plumbers Available Immediately in Your Area.';
      case SERVICE_TYPES_ROUTES.HEATING_REPAIR:
        return `Book a boiler repair online for just £${price} inc VAT. Expert gas safety engineers complete 70% of repairs within 24 hours at the most competitive industry prices.`;
      default:
        return undefined;
    }
  };

  const getServiceTypeText = () => {
    switch (service_type) {
      case SERVICE_TYPES_ROUTES.GAS_SAFETY_CERTIFICATE:
        return 'gas safety checks';
      case SERVICE_TYPES_ROUTES.HEATING_REPAIR:
        return 'boiler repairs';
      case SERVICE_TYPES_ROUTES.PLUMBING_REPAIR:
        return 'boiler repairs';
      default:
        return 'boiler servicing';
    }
  };

  const getSimpleStepsTitle = () => {
    switch (service_type) {
      case SERVICE_TYPES_ROUTES.GAS_SAFETY_CERTIFICATE:
        return 'gas safety check';
      case SERVICE_TYPES_ROUTES.HEATING_REPAIR:
        return 'boiler repair';
      case SERVICE_TYPES_ROUTES.PLUMBING_REPAIR:
        return 'plumbing repair';
      default:
        return 'freshly serviced boiler';
    }
  };

  const getGridIconsTitle = () => {
    switch (service_type) {
      case SERVICE_TYPES_ROUTES.COMBO_SAVER:
        return 'boiler service and checks';
      case SERVICE_TYPES_ROUTES.GAS_SAFETY_CERTIFICATE:
        return 'gas safety check';
      default:
        return 'boiler service';
    }
  };

  const getHighlightSubtitle = () => {
    if (isOVOPartner) {
      return isRepair
        ? `We believe it should be quick and easy to book a ${
            isPlumbing ? 'plumbing' : 'boiler'
          } repair.`
        : "A boiler service can help catch any issues early, and help ensure your boiler's running smoothly and efficiently.";
    }

    return `At Kantan we think it's about time that boiler ${
      isRepair ? 'repairs' : 'servicing'
    } got a whole lot easier.`;
  };

  const getDescriptionListItems = () => {
    if (isOVOPartner) {
      return [
        isPlumbing
          ? 'Trusted network of over 2,000 nationwide plumbers'
          : 'Trusted network of nationwide Gas Safe engineers',
        'ECO friendly booking available',
        'No need to be an existing OVO customer to book with us',
      ];
    }

    return [
      'Book online in just a few clicks',
      'Local Gas Safe engineers that turn up on time',
      'Transparent pricing with no hidden costs',
    ];
  };

  return {
    isKantanPartner,
    isOVOPartner,
    currentPrice: currentPrice.amount,
    disclaimerPrice: disclaimerPrice.amount,
    disclaimerPriceIsAmountWithVat: disclaimerPrice.isAmountWithVat,
    exitAnimation,
    handleContactUsClick,
    isRepair,
    isCombo,
    isGas,
    isPlumbing,
    jobType,
    service_type,
    product_type,
    asPath,
    shouldAnimateExit,
    setShouldAnimateExit,
    partner,
    partnerCopy,
    partnerCopyShort,
    seoTitle,
    seoDescription,
    managedRates,
    getSimpleStepsTitle,
    getGridIconsTitle,
    getServiceTypeText,
    getHighlightSubtitle,
    getDescriptionListItems,
  };
};

export const IS_BROWSER = typeof window !== 'undefined';
