import { useEffect, useState } from 'react';

import { JobRateItemUnitEnum, ManagedRate } from 'services/models/ManagedRate';
import { JOB_TYPES } from 'utils/jobTypes';

import { AddressData, ContactData } from './types';

export const defaultAddressValues: AddressData = {
  line1: '',
  line2: '',
  town: '',
  postcode: '',
};

export const defaultContactValues: ContactData = {
  fullName: '',
  email: '',
  phone: '',
  alternativePhoneNumber: '',
};

export const defaultManagedRates: Record<JOB_TYPES, ManagedRate> = {
  [JOB_TYPES.HEATING_REPAIR]: {
    amount: '75.00',
    jobType: JOB_TYPES.HEATING_REPAIR,
    unit: JobRateItemUnitEnum.Hour,
    isAmountWithVat: false,
    isPromoRate: false,
  },
  [JOB_TYPES.BOILER_SERVICE]: {
    amount: '75.00',
    jobType: JOB_TYPES.BOILER_SERVICE,
    unit: JobRateItemUnitEnum.Hour,
    isAmountWithVat: false,
    isPromoRate: false,
  },
  [JOB_TYPES.GAS_SAFETY_CERTIFICATE]: {
    amount: '85.00',
    jobType: JOB_TYPES.GAS_SAFETY_CERTIFICATE,
    unit: JobRateItemUnitEnum.Hour,
    isAmountWithVat: false,
    isPromoRate: false,
  },
  [JOB_TYPES.BOILER_SERVICE_COMBO]: {
    amount: '125.00',
    jobType: JOB_TYPES.BOILER_SERVICE_COMBO,
    unit: JobRateItemUnitEnum.Hour,
    isAmountWithVat: false,
    isPromoRate: false,
  },
  [JOB_TYPES.PLUMBING_REPAIR]: {
    amount: '75.00',
    jobType: JOB_TYPES.PLUMBING_REPAIR,
    unit: JobRateItemUnitEnum.Hour,
    isAmountWithVat: false,
    isPromoRate: false,
  },
  [JOB_TYPES.HOME_ENERGY_ASSESSMENT]: {
    amount: '199.00',
    jobType: JOB_TYPES.HOME_ENERGY_ASSESSMENT,
    unit: JobRateItemUnitEnum.Hour,
    isAmountWithVat: false,
    isPromoRate: false,
  },
  [JOB_TYPES.TADO_INSTALLATION]: {
    amount: '82.50',
    jobType: JOB_TYPES.TADO_INSTALLATION,
    unit: JobRateItemUnitEnum.Hour,
    isAmountWithVat: false,
    isPromoRate: false,
  },
};

export const useHasStoreHydrated = () => {
  const [hydrated, setHydrated] = useState(false);
  useEffect(() => {
    setHydrated(true);
  }, []);

  return hydrated;
};
