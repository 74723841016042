import { Theme } from '@emotion/react';

import { CheckMark } from 'components/redesign/CheckMark';
import {
  Check,
  Progress,
  Step,
  TrackerTypography,
} from 'components/redesign/Tracker/Tracker.styles';
import { Step as StepType } from 'pages/[productType]/[rateDomain]/[step]';
import { StepOptions } from 'services/kantanClient';

export const renderTrackerSteps = (
  currentStep: StepOptions,
  stepsToRender: StepType[],
  theme: Theme,
) => {
  const renderProgressBar = (index: number, hasBeenVisited: boolean) => {
    const { progressBarStart, progressBarEnd } = currentStep.tracker;

    if (index === currentStep.index) {
      return (
        <Progress
          initial={{
            scaleX: progressBarStart,
          }}
          animate={{
            scaleX: progressBarEnd,
          }}
          transition={{
            delay: 1,
            duration: 0.5,
          }}
        />
      );
    } else if (hasBeenVisited) {
      return <Progress />;
    }
  };

  const stepNodes = stepsToRender.map((step, index) => {
    const { renderType, label } = step.options.tracker;
    const { isTrackerActive = true } = currentStep.tracker;
    const hasBeenVisited = currentStep.index >= index;
    switch (renderType) {
      case 'text':
        return (
          <Step key={index}>
            <TrackerTypography
              $isActive={currentStep.index === index}
              $hasBeenVisited={hasBeenVisited}
              $isGreyedOut={!isTrackerActive}
            >
              {` ${label} `}
            </TrackerTypography>
            {renderProgressBar(index, hasBeenVisited)}
          </Step>
        );
      case 'check-mark':
        return (
          <Check key={index}>
            <CheckMark
              color={
                currentStep.tracker.isLastStep
                  ? theme.app.text.action
                  : undefined
              }
            />
            {renderProgressBar(index, hasBeenVisited)}
          </Check>
        );
    }
  });

  return stepNodes;
};
