import styled from '@emotion/styled';
import { mediaQuery } from 'theme/kantan';

import {
  CalloutInputContainer,
  RadioContainer as CalloutRadioContainer,
} from '../CalloutSelectionRadioButton/CalloutSelectionRadioButton.styles';

export const RadioContainer = styled(CalloutRadioContainer)`
  &:hover {
    box-shadow: 0 8px 10px rgba(208, 216, 229, 0.5);
  }
  cursor: pointer;
`;

export const InnerContainer = styled.div<{
  $isSelected: boolean;
  $gridGap?: string;
}>`
  display: grid;
  gap: ${({ $gridGap = '10px 0' }) => $gridGap};
  justify-items: center;
  text-align: center;

  p {
    &:first-of-type {
      font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.bold};
      font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
      line-height: 1.25;
    }

    &:last-of-type {
      color: ${({ theme, $isSelected }) =>
        $isSelected ? theme.app.text.white : theme.app.landingPage.light.text};
      font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.light};
      font-size: ${({ theme }) => theme.app.fontSettings.fontSize[12]};
      line-height: 1.33;
      user-select: none;
      cursor: pointer;
    }
  }

  @media (${mediaQuery('tablet')}) {
    gap: 10px 0;
    p:last-of-type {
      display: none;
    }
  }
`;

export const ServiceFuelInputContainer = styled(CalloutInputContainer)<{
  $isSelected: boolean;
}>`
  display: grid;
  place-items: center;
  cursor: pointer;

  ${({ theme, $isSelected }) =>
    $isSelected &&
    `
      background-color: ${theme.app.background.selectedDark};
    `}

  &:hover {
    ${InnerContainer} {
      p:last-of-type {
        display: unset;
        animation: fadeIn 1s;
      }
      @keyframes fadeIn {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }
  }

  animation: ease-in;
`;
