import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import { TadoLogo } from 'productSpecific/tado/TadoLogo';
import { useContext } from 'react';
import { mediaQuery } from 'theme/kantan';

import { KantanLogo } from 'components/redesign/KantanLogo';
import { OvoLogo } from 'components/redesign/OvoLogo';
import {
  HeaderContainer,
  Logo as LogoContainer,
  Progress,
  SubHeader,
} from 'components/redesign/Tracker/Tracker.styles';
import { AppContext } from 'pages/_app';
import {
  Step,
  useExistingStepContext,
} from 'pages/[productType]/[rateDomain]/[step]';
import {
  LogoComponentName,
  LogoOptions,
  LogoProps,
} from 'services/kantanClient';
import { constructPath } from 'utils/product/constructPath';
import { shouldForwardProp } from 'utils/transientStyled';

import { renderTrackerSteps } from './renderTrackerSteps';
import { TrackerMobile } from './TrackerMobile';

type TrackerProps = {
  logoOptions?: LogoOptions;
  mobileBackgroundColor?: string;
};

const defaultLogoOptions = {
  component: 'OvoLogo' as LogoComponentName,
  props: {},
};

export const Tracker = ({
  logoOptions = defaultLogoOptions,
  mobileBackgroundColor,
}: TrackerProps) => {
  const context = useExistingStepContext();
  const theme = useTheme();
  const router = useRouter();

  const {
    currentStep,
    productConfig: {
      homePageUrl,
      totalNumberOfSteps,
      steps,
      flows,
      selectedSlotsRequiredMinOnFallback,
    },
  } = context;
  const { tracker } = currentStep.options;
  const {
    stepNumber,
    subtitle,
    mobileTitle,
    mobileSubtitle,
    mobileProgress,
    mobileStepHidden,
  } = tracker;

  const {
    component: primaryLogoComponentName,
    props: primaryLogoProps,
    secondaryLogo,
  } = logoOptions;
  const PrimaryLogoComponent = LOGO_COMPONENT_BY_NAME[primaryLogoComponentName];
  const { component: secondaryLogoComponentName, props: secondaryLogoProps } =
    secondaryLogo ?? {};
  const SecondaryLogoComponent =
    secondaryLogoComponentName &&
    LOGO_COMPONENT_BY_NAME[secondaryLogoComponentName];

  const stepsToRender = steps.filter(
    (step: Step) => !!step.options.tracker.renderType,
  );

  const stepNodes = renderTrackerSteps(
    currentStep.options,
    stepsToRender,
    theme,
  );

  const handleLogoClick = () => {
    const homePagePath = constructPath(router, homePageUrl);
    void router.push(homePagePath);
  };

  const { isFallbackAvailability } = useContext(AppContext);

  const overrideSubtitleFallback = (
    subtitle?: string,
    isFallback?: boolean,
    selectedSlotsRequiredMinOnFallback?: number,
  ) => {
    if (
      mobileTitle?.toLowerCase() === 'select time' &&
      isFallback &&
      !!selectedSlotsRequiredMinOnFallback
    ) {
      return `Choose a minimum of ${selectedSlotsRequiredMinOnFallback} preferred slots`;
    }
    return subtitle;
  };

  return (
    <>
      <TrackerOuterContainer>
        <PageTitle>{flows.booking?.pageTitle}</PageTitle>
        <TrackerGrid $hasSteps={Boolean(totalNumberOfSteps)}>
          {renderLogo(handleLogoClick)}
          {stepNodes}
          <HeaderContainer>
            {renderBookingFlowHeading(
              overrideSubtitleFallback(
                subtitle,
                isFallbackAvailability,
                selectedSlotsRequiredMinOnFallback,
              ),
            )}
            {mobileTitle && (
              <SubHeader $variant="mobile">{mobileTitle}</SubHeader>
            )}
          </HeaderContainer>
        </TrackerGrid>
      </TrackerOuterContainer>
      <TrackerMobile
        stepNumber={stepNumber}
        logo={PrimaryLogoComponent}
        logoProps={primaryLogoProps}
        title={mobileTitle}
        subtitle={overrideSubtitleFallback(
          mobileSubtitle,
          isFallbackAvailability,
          selectedSlotsRequiredMinOnFallback,
        )}
        progress={mobileProgress}
        totalSteps={totalNumberOfSteps}
        stepHidden={mobileStepHidden}
        homePageUrl={homePageUrl}
        mobileBackgroundColor={mobileBackgroundColor}
        handleLogoClick={handleLogoClick}
      />
    </>
  );

  function renderLogo(onClick: () => void) {
    return (
      <LogoContainer onClick={onClick}>
        <PrimaryLogoComponent {...primaryLogoProps} width="50" />
        {SecondaryLogoComponent && (
          <SecondaryLogoComponent {...secondaryLogoProps} />
        )}
        <Header data-testid="expertsLogo"></Header>
        <Progress />
      </LogoContainer>
    );
  }

  function renderBookingFlowHeading(subtitle?: string) {
    if (!subtitle) return null;
    return <SubHeader>{subtitle}</SubHeader>;
  }
};

const LOGO_COMPONENT_BY_NAME: Record<
  LogoComponentName,
  (props: LogoProps) => JSX.Element
> = {
  OvoLogo,
  KantanLogo,
  TadoLogo,
};

const TrackerOuterContainer = styled.div`
  display: none;
  * {
    letter-spacing: -0.02em;
  }

  @media (${mediaQuery('tablet')}) {
    grid-column: 1 / -1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const PageTitle = styled.h1`
  display: none;

  @media (${mediaQuery('tablet')}) {
    grid-column: 1 / -1;
    display: block;
    width: 100%;
    max-width: 990px;
    margin-top: ${({ theme }) => theme.app.spacing[8]};
    margin-bottom: ${({ theme }) => theme.app.spacing[24]};
    font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.bold};
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[32]};
    line-height: ${({ theme }) => theme.app.fontSettings.fontSize[48]};
    letter-spacing: 0;
    color: ${({ theme }) => theme.app.text.pageTitle};
`;

const TrackerGrid = styled('div', { shouldForwardProp })<{
  $hasSteps?: boolean;
}>`
  width: 100%;
  max-width: 990px;
  border-radius: ${({ theme }) => theme.app.borderRadius.largeCorner};
  overflow: hidden;

  @media (${mediaQuery('tablet')}) {
    display: grid;
    grid-template-columns: ${({ $hasSteps = true }) =>
      $hasSteps
        ? '[logo-price] 1.4fr [steps] repeat(4, 1fr) [checkmark] 0.5fr'
        : '[logo-price] 2fr'};
    grid-template-rows: [logo-price] 80px [content] auto;
  }
`;

const Header = styled.h1`
  ${({ theme }) =>
    `
    padding-bottom: 3px;
    color: ${theme.app.messages.logo};
    font-weight: ${theme.app.fontSettings.fontWeight.regular};
    font-size: 22px;
    line-height: 1;
    text-transform: lowercase;
    right: 3px;
    margin-left: -3px;
  `}
`;
