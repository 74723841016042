import styled from '@emotion/styled';
import { m } from 'framer-motion';
import { mediaQuery } from 'theme/kantan';

import { shouldForwardProp } from 'utils/transientStyled';

export const RadioContainer = styled(m.div, { shouldForwardProp })<{
  $isSelected: boolean;
  $height?: string;
  $maxWidth?: string;
  $maxHeightMobile?: string;
  $gridColumnMobile?: string;
}>`
  box-shadow: ${({ theme }) => theme.app.boxShadow.standard};
  background-color: ${({ theme }) => theme.app.background.white};
  border-radius: ${({ theme }) => theme.app.borderRadius.largeCorner};
  border: 1px solid ${({ theme }) => theme.app.colors.grey.light};
  cursor: pointer;
  grid-column: ${({ $maxWidth }) => $maxWidth === '100%' && '1 / -1'};
  max-height: ${({ $maxHeightMobile = '326px' }) => $maxHeightMobile};
  grid-column: ${({ $gridColumnMobile = '326px' }) => $gridColumnMobile};

  @media (${mediaQuery('tablet')}) {
    max-height: unset;
    grid-column: unset;
    width: 100%;
    max-width: ${({ $maxWidth = '326px' }) => $maxWidth};
    height: ${({ $height = '176px' }) => $height};
  }

  ${({ theme, $isSelected }) =>
    $isSelected &&
    `
      background-color: ${theme.app.background.white};
    `};
`;

export const InputContainer = styled.div`
  display: grid;
  place-items: center;
  position: relative;
  height: 100%;
  margin: 0 -1px;
`;

export const Input = styled.input`
  appearance: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: ${({ theme }) => theme.app.borderRadius.smallCorner};
  z-index: -1;

  @supports not (appearance: none) {
    border: none;
  }
`;
