import { NextRouter } from 'next/router';

import { getStringOrFirstElement } from 'utils/getStringOrFirstElement';

export const constructPath = (router: NextRouter, pathSuffix: string) => {
  const productType = getStringOrFirstElement(router.query.productType);
  const rateDomain = getStringOrFirstElement(router.query.rateDomain);
  if (rateDomain === 'ratedomain') {
    return `/${productType}/${pathSuffix}`;
  } else {
    return `/${productType}/${rateDomain}/${pathSuffix}`;
  }
};
