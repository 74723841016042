import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { ServicesData, ServicesDataState } from '../types';
import { defaultContactValues, useHasStoreHydrated } from '../utils';

export const initialState = {
  jobType: '',
  timeSlots: [],
  fuelType: '',
  metadata: {},
  paymentMethodId: undefined,
  ...defaultContactValues,
  setState: () => {},
  clearState: () => {},
};

export const getEmptyState = () => {
  return initialState;
};

export const createServicesStore = create<ServicesDataState>()(
  devtools(
    persist(
      (set) => ({
        ...getEmptyState(),
        setState: (servicesData: Partial<ServicesData>) =>
          set(() => ({
            ...servicesData,
          })),
        clearState: () =>
          set(() => ({
            jobType: '',
            timeSlots: [],
            fuelType: '',
            paymentMethodId: undefined,
            metadata: {},
            ...defaultContactValues,
          })),
      }),
      {
        name: 'services',
        getStorage: () => sessionStorage,
      },
    ),
  ),
);

export const useServicesStore = ((selector, compare) => {
  const store = createServicesStore(selector, compare);
  const hydrated = useHasStoreHydrated();

  return hydrated ? store : selector?.(getEmptyState()) ?? getEmptyState();
}) as typeof createServicesStore;
