import styled from '@emotion/styled';
import { useController } from 'react-hook-form';
import { mediaQuery } from 'theme/kantan';

import { OptionInput } from 'components/OptionInput';
import { FormattedSlot } from 'utils/dateManipulation';
import { trackEvent } from 'utils/tracking';
import {
  filterOutStaleSlots,
  isAnySlotStale,
} from 'utils/useCheckForStaleAppointments';

interface SlotProps {
  $isAllDay: boolean;
}

interface SlotFieldProps {
  slot: FormattedSlot;
  group: number;
  dataStorageMethod: any;
  isCalloutsV2: boolean;
}

export type SlotShape = {
  group: string;
  value: string;
};

export const SlotField = ({
  slot,
  group,
  dataStorageMethod,
  isCalloutsV2,
}: SlotFieldProps) => {
  const [state, setState] = dataStorageMethod();
  const {
    field: { ref, onChange, value, ...field },
  } = useController({ name: 'appointmentSlots', rules: { validate } });

  const handleSlotSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    selectedSlots: Array<SlotShape>,
    onChange: (nextState: Array<SlotShape>) => void,
  ) => {
    const { name, value } = event.target;

    let nextSelectedSlots: Array<SlotShape>;

    if (selectedSlots?.some((slot) => slot.group === name)) {
      const slotToReplace = selectedSlots.findIndex(
        (slot) => slot.group === name,
      );
      selectedSlots.splice(slotToReplace, 1, {
        group: name,
        value,
      });
      nextSelectedSlots = selectedSlots;
    } else {
      nextSelectedSlots = [...selectedSlots, { group: name, value }];
    }

    onChange(nextSelectedSlots);
    setState({ ...state, appointmentSlots: nextSelectedSlots });
  };

  const handleSlotClick = (
    event: React.MouseEvent<HTMLInputElement>,
    selectedSlots: Array<SlotShape>,
    onChange: (nextState: Array<SlotShape>) => void,
  ) => {
    const { value } = event.currentTarget;
    const filteredSlots = filterOutStaleSlots(selectedSlots);

    const slotExists = filteredSlots.some((slot) => slot.value === value);

    if (slotExists) {
      const slotToRemove = filteredSlots.findIndex(
        (slot) => slot.value === value,
      );
      filteredSlots.splice(slotToRemove, 1);
      trackEvent('Slot Removed', {
        slot: value,
      });
    }

    !slotExists &&
      trackEvent('Slot Selected', {
        slot: value,
      });

    onChange(filteredSlots);
    setState({ ...state, appointmentSlots: filteredSlots });
  };

  function validate(selectedSlots: Array<SlotShape>) {
    if (!selectedSlots.length) {
      return 'Please select at least one option';
    }

    const isDateBefore = isAnySlotStale(selectedSlots);
    const filteredSlots = filterOutStaleSlots(selectedSlots);

    if (isDateBefore) {
      onChange(filteredSlots);
      setState({ ...state, appointmentSlots: filteredSlots });
    }
  }

  return isCalloutsV2 ? (
    <SlotNew key={slot.name} $isAllDay={slot.name === 'All Day'}>
      <OptionInput
        type="radio"
        {...field}
        inputRef={ref}
        name={`day${group}`}
        label={slot.name}
        value={`${slot.startDateTime}||${slot.endDateTime}||${slot.name}`}
        checked={value.some(
          (f: SlotShape) =>
            f.value ===
            `${slot.startDateTime}||${slot.endDateTime}||${slot.name}`,
        )}
        onChange={(e) => handleSlotSelect(e, value, onChange)}
        onClick={(e) => handleSlotClick(e, value, onChange)}
        isCalloutsV2={true}
      />
    </SlotNew>
  ) : (
    <Slot key={slot.name} $isAllDay={slot.name === 'All Day'}>
      <OptionInput
        type="radio"
        {...field}
        inputRef={ref}
        name={`day${group}`}
        label={slot.name}
        value={`${slot.startDateTime}||${slot.endDateTime}||${slot.name}`}
        checked={value.some(
          (f: SlotShape) =>
            f.value ===
            `${slot.startDateTime}||${slot.endDateTime}||${slot.name}`,
        )}
        onChange={(e) => handleSlotSelect(e, value, onChange)}
        onClick={(e) => handleSlotClick(e, value, onChange)}
      />
    </Slot>
  );
};

const Slot = styled.div<SlotProps>`
  ${({ $isAllDay, theme }) =>
    $isAllDay &&
    `label {
      font-weight: ${theme.app.fontSettings.fontWeight.semiBold};
    }`}

  &:first-of-type {
    margin-left: 0;
  }

  &:nth-of-type(2) {
    margin-left: ${({ theme }) => theme.app.spacing['22']};
  }
`;

const SlotNew = styled.div<SlotProps>`
  label {
    height: 38px;
    line-height: ${({ theme }) => theme.app.fontSettings.fontSize[24]};
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[14]};
    text-align: center;
  }

  &:first-of-type {
    margin-left: 0;
  }
  &:nth-of-type(2) {
    margin-left: ${({ theme }) => theme.app.spacing['18']};
  }
  &:nth-of-type(3) {
    margin-left: ${({ theme }) => theme.app.spacing['8']};
  }

  ${({ $isAllDay, theme }) =>
    $isAllDay
      ? `
        label {
          width: 69px;
        }

        @media (${mediaQuery('tablet')}) {
          label {
            font-weight: ${theme.app.fontSettings.fontWeight.semiBold};
            width: 88px;
          }
        }
      `
      : `
        &:nth-of-type(2) {
          width: 76px;
        }
        &:nth-of-type(3) {
          width: 87px;
        }

        label {
          &:nth-of-type(2) {
            width: 76px;
          }
          &:nth-of-type(3) {
            width: 87px;
          }
        }

        @media (${mediaQuery('tablet')}) {
          &:nth-of-type(2) {
            width: unset;
          }
          &:nth-of-type(3) {
            width: unset;
          }

          label {
            width: 152px
          }
        }
      `}

  @media (${mediaQuery('tablet')}) {
    label {
      height: 48px;
      line-height: ${({ theme }) => theme.app.fontSettings.fontSize[32]};
      font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
    }

    &:nth-of-type(2) {
      margin-left: ${({ theme }) => theme.app.spacing['22']};
    }
    &:nth-of-type(3) {
      margin-left: unset;
    }
  }
`;
