import styled from '@emotion/styled';
import Image from 'next/legacy/image';
import EmailIcon from 'public/assets/svg/EmailIcon.svg';
import { mediaQuery } from 'theme/kantan';

import { Maybe } from 'utils/types';

type ContactSupportToCancelProps = {
  emailAddress: string;
  thresholdHours: Maybe<number>;
};

export const ContactSupportToCancel = ({
  emailAddress,
  thresholdHours,
}: ContactSupportToCancelProps) => {
  const titleCopy =
    thresholdHours && thresholdHours > 0
      ? `Your visit is less than ${thresholdHours} hours away`
      : 'Your visit is too soon to change';
  return (
    <Container>
      <ImageContainer>
        <Image
          src="/assets/images/CalendarWithoutCross.webp"
          alt="Calendar"
          width={48}
          height={48}
          layout="intrinsic"
        />
      </ImageContainer>
      <TextContainer>
        <Title>{titleCopy}</Title>
        <Description>
          As a result please contact us to cancel your visit
        </Description>
        <EmailLink
          href={`mailto:${emailAddress}`}
          target="_blank"
          rel="noreferrer"
        >
          <Image src={EmailIcon} alt="email icon" priority />
          {emailAddress}
        </EmailLink>
      </TextContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme.app.padding.mediumPadding};

  @media (${mediaQuery('tablet')}) {
    padding: ${({ theme }) => theme.app.padding.extraLargePadding};
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const TextContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    height: 44px;
    margin-top: ${({ theme }) => theme.app.margin.largeMargin};
  }

  > h1 {
    text-align: center;
    font-size: 16px;
    line-height: 24px;

    @media (${mediaQuery('tablet')}) {
      text-align: unset;
      font-size: 18px;
    }
  }

  > h6 {
    text-align: center;
    margin-top: 16px;
    max-width: 592px;
    line-height: 20px;
  }
`;

const Title = styled.div`
  margin-top: ${({ theme }) => theme.app.margin.mediumLargeMargin};
  text-align: center;
  color: ${({ theme }) => theme.app.messages.black};
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[18]};
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.regular};
  line-height: 1.33;

  @media (${mediaQuery('tablet')}) {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[28]};
    font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.semiBold};
    line-height: 1.25;
    letter-spacing: -0.02em;
  }
`;

const Description = styled.div`
  margin-top: ${({ theme }) => theme.app.margin.mediumMargin};
  max-width: 28em;

  text-align: center;
  color: ${({ theme }) => theme.app.messages.black};
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[14]};
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.lightest};
  line-height: 1.28;

  @media (${mediaQuery('tablet')}) {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
    line-height: 1.25;
  }
`;

const EmailLink = styled.a`
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.app.margin.mediumLargeMargin};
  padding: 12px 16px;

  background: ${({ theme }) => theme.app.background.emailSupportButton};
  border-radius: 8px;

  color: ${({ theme }) => theme.app.messages.white};
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.semiBold};
  line-height: 1.33;
  gap: ${({ theme }) => theme.app.spacing[6]};

  > span {
    top: 1px;
    height: 23px !important;
  }
`;
