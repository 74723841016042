import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';
import React from 'react';
import { RefCallBack } from 'react-hook-form';

import { CheckboxIcon, CheckboxSelectedOutlineIcon } from 'components/icons';
import { shouldForwardProp } from 'utils/transientStyled';

const StyledFormHelperText = styled(FormHelperText)`
  margin-left: 31px;
  margin-top: -8px;
  margin-bottom: -4px;
  font-size: 12px;
  line-height: 18px;
`;

interface StyledFormControlProps {
  $invalid?: boolean;
}

const StyledFormControl = styled(FormControl, {
  shouldForwardProp,
})<StyledFormControlProps>`
  ${({ $invalid }) =>
    $invalid &&
    `
    ${StyledFormHelperText} {
      color: #DD2417;
    }
  `}
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    font-size: 14px;
    line-height: 18px;
    color: #292b2e;
  }
`;

const StyledAsterisk = styled.span`
  color: #dd2417;
`;

interface CheckboxInputProps {
  name?: string;
  label: React.ReactNode;
  checked?: boolean;
  required?: boolean;
  invalid?: boolean;
  helperText?: string;
  overrideStyle?: React.CSSProperties;
  onBlur: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  inputRef?: RefCallBack;
}

export const CheckboxInput = ({
  name,
  label,
  checked,
  required,
  invalid,
  helperText,
  overrideStyle,
  onBlur,
  onChange,
  inputRef,
}: CheckboxInputProps) => {
  const theme = useTheme();
  return (
    <StyledFormControl
      $invalid={invalid}
      required={required}
      error={invalid}
      style={overrideStyle}
    >
      <StyledFormControlLabel
        control={
          <Checkbox
            ref={inputRef}
            name={name}
            checked={checked || false}
            color="primary"
            icon={<CheckboxIcon color="#DADADA" />}
            checkedIcon={
              <CheckboxSelectedOutlineIcon
                {...{ color: theme.app.text.action }}
              />
            }
            onBlur={onBlur}
            onChange={onChange}
          />
        }
        label={
          <>
            {label}
            {required && <StyledAsterisk> *</StyledAsterisk>}
          </>
        }
      />
      {helperText && <StyledFormHelperText>{helperText}</StyledFormHelperText>}
    </StyledFormControl>
  );
};
