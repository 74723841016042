import { PRODUCT_TYPE } from 'utils/jobTypes';
import {
  ProductDrivenConfigQuery,
  useProvideProductConfig,
} from 'utils/useProductDrivenConfig';

interface ProductConfigProviderProps {
  productType?: PRODUCT_TYPE;
  productId?: string;
  requestBaseUrl?: string;
  children: (
    query: ProductDrivenConfigQuery,
    isConfigLoading: boolean,
  ) => React.ReactNode;
}

export const ProductConfigProvider = ({
  productType,
  productId,
  requestBaseUrl,
  children,
}: ProductConfigProviderProps) => {
  const productConfigQuery = useProvideProductConfig({
    productType,
    productId,
    requestBaseUrl,
  });

  const isConfigLoading =
    productConfigQuery.isLoading && !productConfigQuery.data?.product;

  return <>{children(productConfigQuery, isConfigLoading)}</>;
};
