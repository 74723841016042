import { ProductDrivenConfig } from 'utils/useProductDrivenConfig';
import { MARKETPLACE_SERVICE_WEEKS_TO_SHOW } from 'utils/useServiceAvailability';
export const getAvailabilityWeeksToShow = (
  productConfig: ProductDrivenConfig | undefined,
) => {
  if (productConfig) {
    const { numberOfWeeksAvailabilityToShow } = productConfig;
    return numberOfWeeksAvailabilityToShow;
  }
  return MARKETPLACE_SERVICE_WEEKS_TO_SHOW;
};

export const getAvailabilityLeadTimeDays = (
  productConfig: ProductDrivenConfig | undefined,
) => {
  if (productConfig) {
    const { leadTimeDays } = productConfig;
    return leadTimeDays;
  }
  return undefined;
};

export const getAvailabilityFallbackEnabled = (
  productConfig: ProductDrivenConfig | undefined,
  isAvailabilityFallbackEnabled: boolean,
) => {
  if (productConfig) {
    const { fallbackEnabled } = productConfig;
    return fallbackEnabled;
  }
  return isAvailabilityFallbackEnabled;
};

export const getFallbackSlotThreshold = (
  productConfig: ProductDrivenConfig | undefined,
) => {
  if (productConfig) {
    const { fallbackSlotThreshold } = productConfig;
    return fallbackSlotThreshold;
  }
  return 5;
};

export const getThresholdHours = (
  productConfig: ProductDrivenConfig | undefined,
) => {
  if (productConfig) {
    const { rescheduleCutoffHours } = productConfig;
    return rescheduleCutoffHours;
  }
  return 48;
};

type SlotsRequiredOnFallback = {
  selectedSlotsRequiredMinOnFallback: number;
  selectedSlotsRequiredMaxOnFallback: number;
};

export const getSlotsRequiredOnFallback = (
  productConfig: ProductDrivenConfig | undefined,
): SlotsRequiredOnFallback => {
  if (productConfig) {
    const {
      selectedSlotsRequiredMinOnFallback = 3,
      selectedSlotsRequiredMaxOnFallback = 5,
    } = productConfig;
    return {
      selectedSlotsRequiredMinOnFallback,
      selectedSlotsRequiredMaxOnFallback,
    };
  } else {
    return {
      selectedSlotsRequiredMinOnFallback: 3,
      selectedSlotsRequiredMaxOnFallback: 5,
    };
  }
};
