import styled from '@emotion/styled';

export const LoadingWrapper = styled.div<{
  $short: boolean;
  $useTheme?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: ${({ theme, $useTheme }) =>
    $useTheme
      ? `${theme.app.padding.extraLargePadding} ${theme.app.padding.extraLargePadding}`
      : '40px 40px'};
  min-height: ${({ $short }) => ($short ? 0 : 'calc(100vh - 300px)')};

  p {
    margin-top: ${({ theme, $useTheme }) =>
      $useTheme ? theme.app.margin.largeMargin : '24px'};
  }
`;

export const LoadingIcon = styled.div`
  animation: rotate 1.2s linear infinite;
  transform-origin: center;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
`;
