import { Theme } from '@emotion/react';
import styled from '@emotion/styled';
import React, { PropsWithChildren } from 'react';
import { mediaQuery } from 'theme/kantan';

import { Spinner } from '../Spinner';

export type ButtonVariants =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'bookingFlowCTA'
  | 'shadow'
  | 'action'
  | 'cancel';

interface StyledButtonProps {
  $variant: ButtonVariants;
  $isDisabled?: boolean;
  $size?: 'normal' | 'large';
}

const getButtonBorderVariant = ({
  variant,
  theme,
  isFocused,
}: {
  variant: ButtonVariants;
  theme: Theme;
  isFocused?: boolean;
}) => {
  if (isFocused) {
    switch (variant) {
      case 'secondary':
        return `1px solid ${theme.app.border.focus}`;
      case 'tertiary':
        return `2px solid ${theme.app.colors.solid.white}`;
      default:
        return 'none';
    }
  } else {
    switch (variant) {
      case 'secondary':
        return '1px solid transparent';
      case 'tertiary':
        return `2px solid ${theme.app.colors.solid.white}`;
      default:
        return 'none';
    }
  }
};

const getButtonTextColorVariant = (variant: ButtonVariants, theme: Theme) => {
  switch (variant) {
    case 'primary':
    case 'bookingFlowCTA':
    case 'tertiary':
    case 'action':
      return theme.app.text.white;
    case 'cancel':
      return theme.app.text.white;
    default:
      return theme.app.text.action;
  }
};

const getButtonBackgroundColorVariant = ({
  variant,
  theme,
  isFocused,
}: {
  variant: ButtonVariants;
  theme: Theme;
  isFocused?: boolean;
}) => {
  if (isFocused) {
    switch (variant) {
      case 'primary':
      case 'action':
      case 'bookingFlowCTA':
        return theme.app.text.actionCta;
      case 'shadow':
        return theme.app.background.white;
      case 'cancel':
        return theme.app.background.cancel;
      default:
        return 'transparent';
    }
  } else {
    switch (variant) {
      case 'primary':
      case 'bookingFlowCTA':
        return theme.app.text.action;
      case 'action':
        return theme.app.messages.action;
      case 'shadow':
        return theme.app.background.white;
      case 'cancel':
        return theme.app.background.cancel;
      default:
        return 'transparent';
    }
  }
};

const getButtonFontWeightVariant = ({
  variant,
  theme,
}: {
  variant: ButtonVariants;
  theme: Theme;
}) => {
  switch (variant) {
    case 'primary':
    case 'bookingFlowCTA':
    case 'shadow':
    case 'action':
      return theme.app.fontSettings.fontWeight.semiBold;
    default:
      return theme.app.fontSettings.fontWeight.regular;
  }
};

const getButtonDimensions = ({
  variant,
  media,
}: {
  variant: ButtonVariants;
  media: 'mobile' | 'tabletOrLarger';
}) => {
  switch (variant) {
    case 'bookingFlowCTA':
      if (media === 'mobile') {
        return { width: '448px', maxWidth: '100%', height: '47px' };
      } else {
        return { width: '448px', maxWidth: 'none', height: '64px' };
      }
    case 'cancel':
      if (media === 'mobile') {
        return { width: '336px', maxWidth: '100%', height: '44px' };
      } else {
        return { width: '336px', maxWidth: 'none', height: '44px' };
      }
    default:
      return { width: '100%', maxWidth: 'max-content', height: 'none' };
  }
};

export const StyledButton = styled.button<StyledButtonProps>`
  outline: none;
  border: none;
  cursor: pointer;

  // By default, tooltips don't display on disabled buttons, the line below is needed to change this behaviour
  pointer-events: ${({ $isDisabled }) => ($isDisabled ? 'none' : undefined)};

  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[18]};
  line-height: 1.3;
  color: ${({ theme, $variant }) => getButtonTextColorVariant($variant, theme)};
  background-color: ${({ theme, $variant, $isDisabled }) =>
    $isDisabled
      ? theme.app.text.tertiary
      : getButtonBackgroundColorVariant({ variant: $variant, theme })};
  width: ${({ $variant }) =>
    getButtonDimensions({ variant: $variant, media: 'mobile' }).width};
  min-width: 114px;
  max-width: ${({ $variant }) =>
    getButtonDimensions({ variant: $variant, media: 'mobile' }).maxWidth};
  height: ${({ $variant }) =>
    getButtonDimensions({ variant: $variant, media: 'mobile' }).height};
  padding: 10px 14px;
  font-weight: ${({ theme, $variant }) =>
    getButtonFontWeightVariant({ variant: $variant, theme })};
  border-radius: ${({ theme }) => theme.app.borderRadius.mediumCorner};
  transition: 0.2s ease-out all;
  border: ${({ theme, $variant }) =>
    getButtonBorderVariant({ variant: $variant, theme })};
  box-shadow: ${({ $variant }) =>
    $variant === 'shadow' ? '0 0 6px rgba(0,0,0,0.25)' : 'none'};

  &:hover,
  &:focus-visible {
    background-color: ${({ theme, $variant, $isDisabled }) =>
      $isDisabled
        ? theme.app.text.tertiary
        : getButtonBackgroundColorVariant({
            variant: $variant,
            theme,
            isFocused: true,
          })};
    border: ${({ theme, $variant }) =>
      getButtonBorderVariant({ variant: $variant, theme, isFocused: true })};
  }

  ${({ $size, theme }) =>
    $size === 'large' &&
    `
    box-shadow: 0 4px 4px rgba(0,0,0,0.25);
    padding: 10px 32px;
    @media (${mediaQuery('tablet')}) {
      font-size: ${theme.app.fontSettings.fontSize[32]};
    }
  `}

  @media (${mediaQuery('tablet')}) {
    height: ${({ $variant }) =>
      getButtonDimensions({ variant: $variant, media: 'tabletOrLarger' })
        .height};
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[18]};
  }
`;

export interface ButtonProps {
  type?: React.ButtonHTMLAttributes<unknown>['type'];
  variant?: ButtonVariants;
  busy?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  size?: 'normal' | 'large';
  testId?: string;
}

export const Button = ({
  type = 'button',
  variant = 'primary',
  size = 'normal',
  children,
  busy,
  disabled,
  testId,
  onClick,
}: PropsWithChildren<ButtonProps>) => {
  return (
    <StyledButton
      $variant={variant}
      $isDisabled={disabled}
      $size={size}
      type={type}
      onClick={onClick}
      disabled={busy || disabled}
      data-testid={testId}
    >
      {!busy && <>{children}</>}
      {busy && <Spinner />}
    </StyledButton>
  );
};
