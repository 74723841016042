import { MutableRefObject } from 'react';
import { ValidateResult } from 'react-hook-form';

type DebounceProps = {
  callback: (args: unknown) => Promise<ValidateResult>;
  timer: MutableRefObject<NodeJS.Timeout | undefined>;
  wait: number;
};

type DebounceReturnFunction = (...args: unknown[]) => Promise<ValidateResult>;

export function debounce({
  callback,
  timer,
  wait = 300,
}: DebounceProps): DebounceReturnFunction {
  return (...args: unknown[]): Promise<ValidateResult> => {
    return new Promise(async (resolve) => {
      if (timer.current !== undefined) {
        clearTimeout(timer.current);
      }
      timer.current = await setTimeout(() => {
        const result = callback([...args]);
        resolve(result);
      }, wait);
    });
  };
}
