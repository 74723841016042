import Head from 'next/head';
import { useRouter } from 'next/router';
import { getMotivationLabels } from 'productSpecific/energy-experts/utils';
import { useEffect } from 'react';
import { useAddressStore, useEnergyExpertsStore, useGenericStore } from 'store';
import { createAddressStore } from 'store/modules/address';
import { createEnergyExpertsStore } from 'store/modules/energy-experts';
import { createGenericStore } from 'store/modules/genericStore';

import { BaseConfirmationView } from 'components/redesign/ConfirmationPage/BaseConfirmationView';
import { useExistingStepContext } from 'pages/[productType]/[rateDomain]/[step]';

import { partnerFullName } from '../utils/partners';

export const BookingConfirmation = () => {
  const context = useExistingStepContext();
  const {
    productConfig: {
      jobTypeGroup,
      bookingSummaryHeader,
      supportEmail,
      supportPhoneNumber,
      motivations,
      useGenericStoreData: shouldUseGenericStoreData,
    },
  } = context;

  const { clearAddressStore } = useAddressStore(({ clearState }) => ({
    clearAddressStore: clearState,
  }));
  const energyExpertsStore = useEnergyExpertsStore();
  const genericStore = useGenericStore();

  const storeData = shouldUseGenericStoreData
    ? genericStore
    : energyExpertsStore;
  const { clearState: clearProductSpecificStore } = storeData;

  useEffect(() => {
    createEnergyExpertsStore.persist.clearStorage();
    createGenericStore.persist.clearStorage();
    createAddressStore.persist.clearStorage();
    clearProductSpecificStore();
    clearAddressStore();
  }, [clearAddressStore, clearProductSpecificStore]);

  const router = useRouter();
  const jobJSON = Array.isArray(router.query.job)
    ? router.query.job[0]
    : router.query.job;

  if (!jobJSON) return <></>;

  const job = JSON.parse(jobJSON ?? '');
  const { referenceId, bookingSummaryDetail, address, contact, timeSlots } =
    job;
  const { fullName, emailAddress, phoneNumbers } = contact;

  const generateBookingSummaryDetail = () => {
    if (shouldUseGenericStoreData) {
      return bookingSummaryDetail;
    } else {
      return getMotivationLabels(bookingSummaryDetail, motivations?.options);
    }
  };

  return (
    <>
      <Head>
        <title>Thank you! - {partnerFullName}</title>
      </Head>
      <BaseConfirmationView
        referenceId={referenceId}
        type={jobTypeGroup}
        isHeating={false}
        appointmentSlots={timeSlots}
        bookingSummaryHeader={bookingSummaryHeader}
        bookingSummaryDetail={generateBookingSummaryDetail()}
        addressData={address}
        contactData={{
          fullName,
          email: emailAddress,
          phone: phoneNumbers[0].originallyEnteredAs,
        }}
        emailAddress={supportEmail}
        phoneNumber={supportPhoneNumber}
      />
    </>
  );
};
