import styled from '@emotion/styled';
import Image from 'next/legacy/image';
import engineer2 from 'public/assets/images/Engineer2.webp';

import { StrongText } from 'components/StrongText';

import {
  AccordionDetailsTypography,
  AlignContent,
  ResponsiveContainer,
  ResponsiveImageContainer,
  SectionHeader,
} from './utilityStyles';

const WhoWeAreSectionContainer = styled(ResponsiveContainer)`
  @media (min-width: 680px) {
    border-radius: ${({ theme }) => theme.app.borderRadius.extraLargeCorner};
    overflow: hidden;
  }
`;

const Grid = styled.div`
  display: grid;
  margin-top: ${({ theme }) => theme.app.margin.extraLargeMargin};
  grid-template-columns: 1fr 4fr;
  grid-template-rows: repeat(2, auto);
  grid-gap: 64px ${({ theme }) => theme.app.padding.mediumPadding};
  grid-template-areas:
    'image .'
    'image2 .';
  align-items: start;

  @media (min-width: 680px) {
    align-items: center;
    max-width: 601px;
    grid-gap: 64px ${({ theme }) => theme.app.padding.largePadding};
  }
`;

const WhoWeAreGridContainer = styled(AlignContent)`
  background-color: ${({ theme }) => theme.app.background.selected};
  padding: 40px ${({ theme }) => theme.app.padding.mediumLargePadding};
`;

const WhoWeAreHeader = styled(SectionHeader)`
  color: ${({ theme }) => theme.app.text.white};

  @media (min-width: 680px) {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[32]};
    font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.semiBold};
    line-height: 1;
  }
`;

const GridImage = styled.div`
  grid-area: image;
  justify-self: center;
  position: relative;
  width: 32px;
  height: 32px;
  padding-top: ${({ theme }) => theme.app.padding.smallPadding};

  @media (min-width: 680px) {
    width: 69px;
    height: 69px;
    padding-top: 0;
  }
`;

const GridImage2 = styled(GridImage)`
  grid-area: image2;
  width: 17px;
  height: 27px;

  @media (min-width: 680px) {
    width: 32px;
    height: 48px;
  }
`;

const GridText = styled(AccordionDetailsTypography)`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[18]};
  line-height: 1.33;
`;

export const WhoWeAreSection = () => {
  return (
    <AlignContent>
      <WhoWeAreSectionContainer>
        <ResponsiveImageContainer>
          <Image
            src={engineer2}
            alt="Engineer with clipboard"
            layout="fill"
            objectFit="cover"
            placeholder="blur"
          />
        </ResponsiveImageContainer>
        <WhoWeAreGridContainer>
          <WhoWeAreHeader>
            About <StrongText>Kantan</StrongText>
          </WhoWeAreHeader>
          <Grid>
            <GridImage>
              <Image
                src="/assets/svg/KantanLogoSmall.svg"
                width={69}
                height={69}
                alt="Kantan logo"
              />
            </GridImage>
            <GridText>
              We believe{' '}
              <StrongText>every profession deserves great tools</StrongText> in
              order to build their business
            </GridText>
            <GridImage2>
              <Image
                src="/assets/svg/MobilePhoneIcon.svg"
                width={32}
                height={48}
                alt="Mobile phone"
              />
            </GridImage2>
            <GridText>
              We provide great{' '}
              <StrongText weight="semiBold">organisational tools</StrongText>{' '}
              and job marketplaces so gas engineers can{' '}
              <StrongText weight="semiBold">grow their business</StrongText>
            </GridText>
          </Grid>
        </WhoWeAreGridContainer>
      </WhoWeAreSectionContainer>
    </AlignContent>
  );
};
