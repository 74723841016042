import { PropsWithChildren } from 'react';

import { formatPrice } from 'utils/formatPrice';

import { CalloutDisclaimerText } from './CalloutDisclaimer.styles';

interface CalloutDisclaimerProps {
  disclaimerPrice: string;
  isAmountWithVat: boolean;
  isPayment?: boolean;
  partner?: string;
}

export const CalloutDisclaimer = ({
  disclaimerPrice,
  isPayment,
  partner,
  isAmountWithVat,
}: PropsWithChildren<CalloutDisclaimerProps>) => {
  return (
    <CalloutDisclaimerText
      role="note"
      $isPayment={isPayment}
      $partner={partner}
    >
      *£{formatPrice(disclaimerPrice)}
      {isAmountWithVat ? ' inc. VAT' : ` exc. VAT`} callout charge covers first
      hour only. Additional hours charged at £{formatPrice(disclaimerPrice)}
      {isAmountWithVat ? ' inc. VAT' : ` exc. VAT`}. Parts charged separately.
      70% repairs are completed at no extra charge.
    </CalloutDisclaimerText>
  );
};
