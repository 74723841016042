import { Icon } from 'theme/types';

export const NoHotWaterIcon: Icon = (props) => {
  const { color, ...svgProps } = props;

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9422 13.8417C18.8814 14.6244 19.4669 15.7435 19.5816 16.9489L15.6023 12.9298V8.39985C15.6023 7.95802 15.2442 7.59985 14.8023 7.59985C14.3605 7.59985 14.0023 7.95802 14.0023 8.39985V8.49576L14.0052 8.49977L14.0023 8.50603V11.3138L12.0091 9.30063L9.00233 5.04968L8.54579 5.80273L7.13783 4.38068L8.35965 2.36532C8.65515 1.8779 9.34952 1.8779 9.64502 2.36532L12.8969 6.94046C13.3355 6.36857 14.0259 5.99985 14.8023 5.99985C16.1278 5.99985 17.2023 7.07437 17.2023 8.39985V13.2252L17.9422 13.8417ZM17.9328 20.9385L19.0588 22.0758L20.459 20.6616L3.30646 3.33748L1.90625 4.75169L5.0115 7.888L3.0599 11.1071C0.177647 15.8613 5.49736 20.9997 11.0052 20.5C11.0425 20.4967 11.077 20.4573 11.1099 20.3852C11.9561 21.3735 13.213 21.9999 14.6161 21.9999H14.9886C16.1078 21.9999 17.1339 21.6014 17.9328 20.9385ZM16.8003 19.7947C16.296 20.1746 15.6686 20.3999 14.9886 20.3999H14.6161C12.9931 20.3999 11.6697 19.1169 11.6048 17.5099C11.6589 17.1456 11.719 16.7565 11.787 16.3474C11.9222 15.9792 12.1287 15.6389 12.3968 15.3471L13.4248 16.3855C13.2835 16.624 13.2023 16.9025 13.2023 17.1999C13.2023 18.0835 13.9187 18.7999 14.8023 18.7999C15.094 18.7999 15.3675 18.7218 15.603 18.5854L16.8003 19.7947ZM6.41947 9.31005L4.70107 12.1445C2.67971 15.4787 6.30316 18.3182 10.1356 18.4917C10.0485 18.1375 10.0023 17.7672 10.0023 17.3861C10.0023 16.1972 10.4608 15.0617 11.2702 14.2093L6.41947 9.31005ZM19.6023 7.59985C19.1605 7.59985 18.8023 7.95802 18.8023 8.39985C18.8023 8.84168 19.1605 9.19985 19.6023 9.19985H21.2023C21.6442 9.19985 22.0023 8.84168 22.0023 8.39985C22.0023 7.95802 21.6442 7.59985 21.2023 7.59985H19.6023ZM18.8023 11.5999C18.8023 11.158 19.1605 10.7999 19.6023 10.7999H21.2023C21.6442 10.7999 22.0023 11.158 22.0023 11.5999C22.0023 12.0417 21.6442 12.3999 21.2023 12.3999H19.6023C19.1605 12.3999 18.8023 12.0417 18.8023 11.5999Z"
        fill={color || '#292B2E'}
      />
    </svg>
  );
};
