import styled from '@emotion/styled';
import { Accordion, Typography } from '@mui/material';
import { mediaQuery } from 'theme/kantan';

import { StyledButton } from 'components/Button/Button';

export const AlignContent = styled.section`
  @media (min-width: 680px) {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
`;

export const ResponsiveContainer = styled.div`
  display: block;
  position: relative;
  min-width: 340px;
  max-width: 680px;
  width: 100%;

  @media (min-width: 680px) {
    min-width: 600px;
    max-width: 940px;
  }
`;

export const ResponsiveImageContainer = styled(ResponsiveContainer)`
  max-height: 240px;
  height: 100vh;

  @media (min-width: 680px) {
    min-height: 300px;
    max-height: 380px;
  }
`;

export const PageHeaderImageSection = styled(AlignContent)`
  position: relative;
`;

export const BasicSection = styled.section`
  padding: ${({ theme }) => theme.app.padding.mediumPadding};
  flex: 1;

  @media (min-width: 680px) {
    display: none;
  }
`;

export const PageHeaderHighlight = styled.p`
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.lightest};
  letter-spacing: -0.04em;
  line-height: 1.88;

  @media (min-width: 680px) {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[24]};
    line-height: 1.33;
  }
`;

export const DesktopBasicSection = styled(BasicSection)`
  display: none;

  @media (min-width: 680px) {
    display: block;
    margin-top: 72px;
    margin-left: 32px;

    > p {
      margin-bottom: 16px;
    }
  }
`;

export const SectionHeader = styled.h1`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[24]};
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.lightest};
  color: ${({ theme }) => theme.app.text.primary};
  line-height: 1.33;
  letter-spacing: -0.04em;
  width: 91%;

  @media (min-width: 680px) {
    width: unset;
  }
`;

export const StaticButton = styled(StyledButton)`
  width: 90%;
  align-self: center;
  margin-bottom: 20px;
  font-family: ${({ theme }) => theme.app.fontSettings.fontFamilyHeadings};

  @media (min-width: 680px) {
    max-width: 345px;
    margin: auto 0;
  }
`;

// TODO: We should keep the accordion and its styles for later use
// const AccordionSection = styled.section`
//   margin-top: ${({ theme }) => theme.app.margin.largeMargin};
// `;
//
export const BasicAccordion = styled(Accordion)`
  background-color: inherit;
  box-shadow: none;

  &:before {
    display: none;
  }
`;

// const StyledAccordionSummary = styled(AccordionSummary)`
//   .MuiAccordionSummary-expandIconWrapper {
//     color: ${({ theme }) => theme.app.text.white};
//   }
// `;
//
// const StyledAccordionDetails = styled(AccordionDetails)`
//   padding: 0 0 0 ${({ theme }) => theme.app.padding.largePadding};
// `;

export const AccordionDetailsTypography = styled(Typography)`
  color: ${({ theme }) => theme.app.text.white};
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.lightest};
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};

  @media (min-width: 680px) {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[18]};
  }
`;

export const SectionSubtitle = styled.h2`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.lightest};
  line-height: 1.25;

  margin-top: ${({ theme }) => theme.app.margin.largeMargin};
`;

export const AppointmentSummaryContainer = styled.section<{
  $overrideMargin?: number;
  $overridePadding?: string;
  $inGrid?: string;
}>`
  background-color: ${({ theme }) => theme.app.background.white};
  box-shadow: inset 0px -1px 0px #d0d8e5;
  border-radius: ${({ theme }) => theme.app.borderRadius.smallCorner};

  padding: ${({ theme, $overridePadding }) =>
    $overridePadding ? $overridePadding : theme.app.padding.smallPadding};
  margin-top: ${({ theme, $overrideMargin }) =>
    $overrideMargin
      ? `${$overrideMargin}px`
      : theme.app.margin.mediumLargeMargin};

  ${({ $inGrid }) =>
    $inGrid &&
    `
    grid-area: ${$inGrid};
    height: fit-content;
  `}

  @media (${mediaQuery('tablet')}) {
    width: ${({ $inGrid }) => ($inGrid ? '100%' : '50%')};
    ${({ theme, $overrideMargin, $inGrid }) =>
      !$inGrid && $overrideMargin
        ? `
        margin-top: ${theme.app.margin.mediumLargeMargin};
    `
        : 'margin-top: unset;'}
  }
`;
