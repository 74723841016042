import styled from '@emotion/styled';
import { mediaQuery } from 'theme/tado-kantan';

const OptionsContainer = styled.div<{
  $padding?: string;
  $maxOptionsPerRowDesktop: number;
  $rowHeightMobile?: string;
}>`
  display: grid;
  grid-gap: 16px;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(164px, 1fr));
  grid-auto-rows: ${({ $rowHeightMobile = '148px' }) => $rowHeightMobile};

  padding-top: 30px;
  margin-bottom: 16px;

  @media (${mediaQuery('tablet')}) {
    padding-top: unset;
    margin-bottom: unset;
    padding: ${({ $padding }) => $padding};
    grid-auto-rows: 174px;
    grid-template-columns: ${({ $maxOptionsPerRowDesktop }) =>
      `repeat(${$maxOptionsPerRowDesktop}, 1fr)`};
  }
`;

export { OptionsContainer };
