import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { GenericStoreData, GenericStoreDataState } from '../types';
import { defaultContactValues, useHasStoreHydrated } from '../utils';

export const initialState = {
  jobType: '',
  productId: '',
  timeSlots: [],
  metadata: {},
  userCanEditDetails: true,
  ...defaultContactValues,
  setState: () => {},
  clearState: () => {},
};

export const getEmptyState = () => {
  return initialState;
};

export const createGenericStore = create<GenericStoreDataState>()(
  devtools(
    persist(
      (set) => ({
        ...getEmptyState(),
        setState: (genericStoreData: Partial<GenericStoreData>) =>
          set(() => ({
            ...genericStoreData,
          })),
        clearState: () =>
          set(() => ({
            jobType: '',
            productId: '',
            timeSlots: [],
            metadata: {},
            ...defaultContactValues,
          })),
      }),
      {
        name: 'genericStore',
        getStorage: () => sessionStorage,
      },
    ),
  ),
);

export const useGenericStore = ((selector, compare) => {
  const store = createGenericStore(selector, compare);
  const hydrated = useHasStoreHydrated();

  return hydrated ? store : selector?.(getEmptyState()) ?? getEmptyState();
}) as typeof createGenericStore;
