import styled from '@emotion/styled';
import Image from 'next/legacy/image';
import heroImage from 'public/assets/images/HeroImage.webp';
import { useContext } from 'react';

import { StrongText } from 'components/StrongText';
import { AppContext } from 'pages/_app';
import { UTM_CAMPAIGNS } from 'utils/getLandingPageVariant';

import {
  AlignContent,
  BasicSection,
  DesktopBasicSection,
  PageHeaderHighlight,
  PageHeaderImageSection,
  ResponsiveImageContainer,
  SectionHeader,
  StaticButton,
} from './utilityStyles';

const FirstScreenContainer = styled.section`
  display: flex;
  flex-flow: column;

  height: fit-content;
  width: 100%;

  @media (min-width: 680px) {
    border-radius: ${({ theme }) => theme.app.borderRadius.extraLargeCorner};
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.08);
    height: 500px;
    max-width: 940px;
    background-color: white;
    overflow: hidden;
  }
`;

const FirstScreenSectionHeader = styled(SectionHeader)`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[32]};
  color: ${({ theme }) => theme.app.text.white};
  width: 100%;
  padding: ${({ theme }) => theme.app.padding.mediumPadding};
  background-color: ${({ theme }) => theme.app.background.selected};

  @media (min-width: 680px) {
    text-align: center;
    margin-bottom: 56px;
    padding: 40px 0;
  }
`;

const HeroImageContainer = styled.div`
  position: relative;
  height: 240px;
  width: 100%;

  @media (min-width: 680px) {
    height: inherit;
    width: 376px;
  }
`;

const GasSafeImage = styled.div`
  position: absolute;
  bottom: 16px;
  right: 16px;
  width: 44px;
  height: 48px;

  @media (min-width: 680px) {
    width: 94px;
    height: 104px;
  }
`;

const Hero = styled.div`
  @media (min-width: 680px) {
    background-color: ${({ theme }) => theme.app.background.primary};
    display: flex;
    height: 100%;
  }
`;

const PageHeaderHighlightContainer = styled.div`
  display: flex;

  @media (min-width: 680px) {
    margin-bottom: 16px;
  }
`;

const PageHeaderHighlightContainerText = styled.div`
  margin-left: 4px;
`;

const EmojiWrapper = styled.span`
  padding-top: 2px;

  @media (min-width: 680px) {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[24]};
    line-height: 1.33;
  }
`;

const HighlightSubtitle = styled.span`
  display: block;
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[12]};
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.lightest};
  color: ${({ theme }) => theme.app.colors.grey.darker};
  margin-top: -10px;

  @media (min-width: 680px) {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
    line-height: 1;
    margin-top: -2px;
  }
`;

interface HeroSectionProps {
  target: HTMLElement | null;
}

export const HeroSection = ({ target }: HeroSectionProps) => {
  const { headerVariant, cityLocation } = useContext(AppContext);

  const renderPageHighlights = () => {
    return (
      <>
        <PageHeaderHighlight>
          ✅ Over <StrongText>2,000 expert engineers</StrongText> at your
          fingertips
        </PageHeaderHighlight>
        <PageHeaderHighlightContainer>
          <EmojiWrapper>✅</EmojiWrapper>
          <PageHeaderHighlightContainerText>
            <PageHeaderHighlight>
              <StrongText>Same day</StrongText> repair appointment*
            </PageHeaderHighlight>
            <HighlightSubtitle>*Monday to Friday</HighlightSubtitle>
          </PageHeaderHighlightContainerText>
        </PageHeaderHighlightContainer>
        <PageHeaderHighlight>
          ✅ <StrongText>No upfront</StrongText> cost
        </PageHeaderHighlight>
      </>
    );
  };

  const renderHeader = (variant?: string) => {
    switch (variant) {
      case UTM_CAMPAIGNS.EMERGENCY_BOILER_REPAIR:
        return (
          <>
            Book your <StrongText>emergency boiler repair</StrongText> now
          </>
        );
      case UTM_CAMPAIGNS.HEATING_REPAIR:
        return (
          <>
            Book your <StrongText>heating repair now</StrongText>
          </>
        );
      case UTM_CAMPAIGNS.BOILER_NOT_WORKING:
        return (
          <>
            <StrongText>Boiler not working?</StrongText> Book a{' '}
            <StrongText>repair now</StrongText>
          </>
        );
      case UTM_CAMPAIGNS.HOT_WATER:
        return (
          <>
            <StrongText>No hot water?</StrongText> Book a{' '}
            <StrongText>repair</StrongText> now.
          </>
        );
      default:
        return cityLocation ? (
          <>
            <StrongText>Boiler repairs</StrongText> in {cityLocation.name}.{' '}
            <StrongText>Book now</StrongText>
          </>
        ) : (
          <>
            <StrongText>Repair your boiler</StrongText> now. Book a{' '}
            <StrongText>gas safe engineer</StrongText>
          </>
        );
    }
  };

  return (
    <>
      <FirstScreenSectionHeader>
        {renderHeader(headerVariant)}
      </FirstScreenSectionHeader>
      <AlignContent>
        <FirstScreenContainer>
          <PageHeaderImageSection>
            <ResponsiveImageContainer>
              <Hero>
                <HeroImageContainer>
                  <Image
                    src={heroImage}
                    alt="Engineer fixing the boiler"
                    layout="fill"
                    sizes="100vw, (min-width: 680px) 50vw, (min-width: 880px) 33.3vw, (min-width: 940px) 10vw"
                    objectFit="cover"
                    priority
                    placeholder="blur"
                  />
                </HeroImageContainer>
                <DesktopBasicSection>
                  {renderPageHighlights()}
                </DesktopBasicSection>
                <GasSafeImage>
                  <Image
                    src="/assets/images/GasSafeRegister.webp"
                    alt="Gas Safe Register"
                    width={94}
                    height={104}
                    priority
                  />
                </GasSafeImage>
              </Hero>
            </ResponsiveImageContainer>
          </PageHeaderImageSection>
          <BasicSection>{renderPageHighlights()}</BasicSection>
          <StaticButton
            id="static-button"
            $variant="primary"
            onClick={() =>
              !!target && target.scrollIntoView({ behavior: 'smooth' })
            }
          >
            Book Now
          </StaticButton>
        </FirstScreenContainer>
      </AlignContent>
    </>
  );
};
