import 'swiper/css';
import 'swiper/css/navigation';

import { Theme, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useMediaQuery } from '@mui/material';
import {
  addWeeks,
  eachDayOfInterval,
  isFriday,
  isSameWeek,
  startOfWeek,
} from 'date-fns';
import Image from 'next/image';
import { useRouter } from 'next/router';
import EcoIcon from 'public/assets/svg/LeafIcon.svg';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Navigation, Pagination } from 'swiper';
import { SwiperSlide } from 'swiper/react';
import { mediaQuery } from 'theme/kantan';

import { InfoIcon } from 'components/icons/InfoIcon';
import { InfoText as _InfoText } from 'components/InfoText';
import { Loading } from 'components/Loading/Loading';
import { extractDaySlots } from 'components/marketplace/CalendarFormNew/CalendarInput';
import { NavigationButtonsContainer } from 'components/NavigationButtonsContainer';
import { StrongText } from 'components/StrongText';
import { AppContext } from 'pages/_app';
import { useNullableProductContext } from 'pages/[productType]/[rateDomain]/[step]';
import { CommonNestedProductConfigOptions } from 'services/kantanClient';
import {
  groupByDay,
  groupByWeek,
  sortByStart,
  splitTimeSlotValue,
  toStartAndEndDateTime,
  toTimeSlotValue,
} from 'utils/calendarUtils';
import { getSlotsRequiredOnFallback } from 'utils/product/getProductConfigProperties';
import { checkRequiredSlotsSelected } from 'utils/slotRequirements';
import { numberToTextRepresentation } from 'utils/stringConversions';
import { trackEvent } from 'utils/tracking';
import { BookableSlot } from 'utils/useBookableSlots';

import {
  CalendarDay,
  CalendarGrid,
  CalendarLegend,
  CalendarSwiper,
  EmptySlot,
  InfoText,
  InfoTextContainer,
  MoreAvailabilityContainer,
} from './CalendarView.styles';
import { CalendarDaySlot } from './components/CalendarDaySlot';
import { CalendarHeader } from './components/CalendarHeader';
import { CalendarWeekDays } from './components/CalendarWeekDays';

const weekContainsDate = (week: BookableSlot[][], date: Date): boolean => {
  if (week.length === 0 || week[0].length === 0) {
    return false;
  }
  return isSameWeek(week[0][0].startDateTime, date, { weekStartsOn: 1 });
};

const groupByWeekAndFilter = (
  sortedSlots: BookableSlot[],
): BookableSlot[][][] => {
  const weeks = groupByWeek(groupByDay(sortedSlots));
  const filteredWeeks = weeks.filter(function hasAvailableSlots(week) {
    return week.some((day) => day.some((slot) => slot.isAvailable));
  });
  return filteredWeeks;
};
export interface Availability {
  status: 'loading' | 'success' | 'failure';
  isFallback?: boolean;
  bookableSlots: BookableSlot[];
}

export interface SubmitOptions {
  sortedBookableSlots: BookableSlot[];
  isFallback?: boolean;
}

interface BaseCalendarViewProps {
  availability: Availability;
  timeSlots: string[];
  stopSalesDates?: CommonNestedProductConfigOptions['stopSalesDates'];
  infoAboveCta?: () => JSX.Element;
  infoBelowCta?: () => JSX.Element;
  onTimeSlotsChange: (timeSlots: string[]) => void;
  onSubmit: (options: SubmitOptions) => void;
}

interface AgeLimitInfoProps {
  hasPreviousStep: boolean;
}

const renderInfoAboveCalendar = (
  theme: Theme,
  infoAboveCalenderText: string,
) => (
  <InfoTextContainer>
    <InfoIcon color={theme.app.text.actionDark} width={16} height={16} />
    <InfoText>{infoAboveCalenderText}</InfoText>
  </InfoTextContainer>
);

const EcoInfo = () => (
  <CalendarLegend>
    <Image src={EcoIcon} alt="Eco icon" priority />
    <StrongText weight="semiBold">ECO</StrongText>
    <p>
      Time when engineers are close to your address. Choose one to reduce your
      carbon footprint.
    </p>
  </CalendarLegend>
);

const AgeLimitInfoText = styled(_InfoText)<{ hasPreviousStep: boolean }>`
  width: 100%;
  align-items: start;
  padding: 0 8px;
  margin-bottom: 20px;

  @media (${mediaQuery('tablet')}) {
    width: unset;
    margin: ${(props) =>
      props.hasPreviousStep ? `0 auto -24px` : '0 auto 2px'};
    align-items: center;
  }

  > svg {
    width: 30px;
    height: 18px;

    @media (${mediaQuery('tablet')}) {
      width: unset;
      height: unset;
    }
  }
`;

const AgeLimitInfo = ({ hasPreviousStep }: AgeLimitInfoProps) => (
  <AgeLimitInfoText
    variant="success"
    topMargin={20}
    className="energyExpertsSelectTime"
    hasPreviousStep={hasPreviousStep}
  >
    <InfoIcon color="#1E6937" width={16} height={16} />
    <p style={{ fontSize: 14, fontWeight: 400, marginLeft: 6 }}>
      Someone 18 years or over will need to be present at the property
      throughout the appointment.
    </p>
  </AgeLimitInfoText>
);

export const BaseCalendarView = ({
  availability,
  timeSlots,
  onTimeSlotsChange,
  onSubmit,
  stopSalesDates,
}: BaseCalendarViewProps) => {
  const theme = useTheme();
  const { setIsFallbackAvailability } = useContext(AppContext);

  const context = useNullableProductContext();
  const {
    showAgeLimitInfo,
    mobileViewBackgroundColor,
    mobileViewCalendarTileBackgroundColor,
    selectedSlotsRequiredMinNonFallback = 1,
    selectedSlotsRequiredMaxNonFallback = 1,
    infoAboveCalendarText,
    allowFriday,
  } = context.productConfig ?? {};
  const prevStep = context.currentStep?.options.prevStep;

  const {
    selectedSlotsRequiredMinOnFallback,
    selectedSlotsRequiredMaxOnFallback,
  } = getSlotsRequiredOnFallback(context.productConfig);

  useEffect(() => {
    setIsFallbackAvailability(availability.isFallback || false);
  }, [availability.isFallback, setIsFallbackAvailability]);
  const sortedSlots = sortByStart(availability?.bookableSlots ?? []);
  const form = useForm({
    mode: 'onChange',
    defaultValues: { timeSlots },
  });
  const router = useRouter();
  const [isNavigating, setIsNavigating] = useState(false);

  useEffect(() => {
    form.reset({ timeSlots }, { keepErrors: true });
  }, [form, timeSlots]);

  const haveSlotsLoaded = useRef(false);

  useEffect(() => {
    if (availability.status === 'success') {
      haveSlotsLoaded.current = true;
    }

    if (haveSlotsLoaded.current) {
      trackEvent('Slots shown on appointment page visit', {
        slots_shown: availability.bookableSlots
          .filter((slot) => slot.isAvailable)
          .map((slot) => ({
            ...slot,
            startDateTime: slot.startDateTime.toISOString(),
            endDateTime: slot.endDateTime.toISOString(),
          })),
        is_fallback: availability?.isFallback,
      });
    }
  }, [
    availability.status,
    availability.bookableSlots,
    availability.isFallback,
  ]);

  const isTabletOrLarger = useMediaQuery(`${mediaQuery('tablet', true)}`);

  const weeks = groupByWeekAndFilter(sortedSlots);

  const firstAvailableSlot = sortedSlots.find((slot) => slot.isAvailable);

  const initialTimeSlot = timeSlots[0]
    ? splitTimeSlotValue(timeSlots[0])
    : undefined;

  const firstSlotShown =
    initialTimeSlot !== undefined
      ? toStartAndEndDateTime(initialTimeSlot[0], initialTimeSlot[1])[0]
      : firstAvailableSlot?.startDateTime;

  const initialWeekIndex = firstSlotShown
    ? weeks.findIndex((week) => weekContainsDate(week, firstSlotShown))
    : undefined;

  const handleSubmit = () => {
    setIsNavigating(true);
    onSubmit({
      sortedBookableSlots: sortedSlots,
      isFallback: availability.isFallback,
    });
  };

  if (availability.status === 'loading') {
    return <Loading />;
  }

  const minSlotsRequired = availability.isFallback
    ? selectedSlotsRequiredMinOnFallback
    : selectedSlotsRequiredMinNonFallback;
  const maxSlotsRequired = availability.isFallback
    ? selectedSlotsRequiredMaxOnFallback
    : selectedSlotsRequiredMaxNonFallback;

  const {
    equalToMaxSelected,
    moreThanMaxSelected,
    requiredAmountSelected: requiredNumSlotsSelected,
  } = checkRequiredSlotsSelected(minSlotsRequired, maxSlotsRequired, timeSlots);

  const ctaButtonTooltipMessage = (showTooltip: boolean) => {
    if (!showTooltip) {
      return '';
    }
    return minSlotsRequired > 1
      ? `Please choose between ${numberToTextRepresentation(
          minSlotsRequired,
        )} and ${numberToTextRepresentation(
          maxSlotsRequired,
        )} available times. We will prioritize the earliest slot`
      : 'Please choose a slot';
  };

  const today = new Date();
  const nextWeek = addWeeks(today, 1);
  const stopSalesDatesLookup = generateStopSalesDatesLookup(stopSalesDates);
  return (
    <>
      {infoAboveCalendarText &&
        renderInfoAboveCalendar(theme, infoAboveCalendarText)}
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <CalendarSwiper
            slidesPerView={1}
            initialSlide={initialWeekIndex ?? 0}
            threshold={isTabletOrLarger ? 10 : 0}
            navigation={true}
            pagination={{
              clickable: true,
            }}
            modules={[Navigation, Pagination]}
          >
            {weeks.map((week, weekIndex) => {
              const weekStart = startOfWeek(week[0][0].startDateTime, {
                weekStartsOn: 1,
              });
              const weekEnd = week[week.length - 1][0].startDateTime;
              const title = weekContainsDate(week, today)
                ? 'This Week'
                : weekContainsDate(week, nextWeek)
                ? 'Next Week'
                : null;
              return (
                <SwiperSlide key={weekIndex}>
                  <CalendarGrid $backgroundColor={mobileViewBackgroundColor}>
                    <CalendarHeader
                      title={title}
                      weekStart={weekStart}
                      weekEnd={weekEnd}
                    />
                    {week.map((day, dayIndex) => {
                      const { date, slots } = extractDaySlots(
                        day,
                        stopSalesDatesLookup,
                      );

                      return (
                        <Fragment key={dayIndex}>
                          <CalendarWeekDays
                            date={date}
                            startColumn={dayIndex}
                          />
                          <CalendarDay $startColumn={dayIndex}>
                            {slots.map((slot, slotIndex) => {
                              const { time, available, isEcoSlot } = slot;
                              const value = toTimeSlotValue({
                                date,
                                slot: time,
                              });

                              const hideFridaySlots =
                                !allowFriday && isFriday(date);

                              if (!available || hideFridaySlots) {
                                return (
                                  <EmptySlot
                                    key={slotIndex}
                                    $backgroundColor={
                                      mobileViewCalendarTileBackgroundColor
                                    }
                                  />
                                );
                              }

                              return (
                                <CalendarDaySlot
                                  key={slotIndex}
                                  isEcoSlot={isEcoSlot}
                                  slotIndex={slotIndex}
                                  value={value}
                                  checked={timeSlots.includes(value)}
                                  setState={onTimeSlotsChange}
                                  selectedSlots={timeSlots}
                                  canSelectMultipleSlots={maxSlotsRequired > 1}
                                  isFallback={availability.isFallback}
                                  maxSlotsRequired={maxSlotsRequired}
                                  moreThanOrEqualToMaxSlotsSelected={
                                    equalToMaxSelected || moreThanMaxSelected
                                  }
                                />
                              );
                            })}
                          </CalendarDay>
                        </Fragment>
                      );
                    })}
                  </CalendarGrid>
                </SwiperSlide>
              );
            })}
            <SwiperSlide>
              <CalendarGrid>
                <MoreAvailabilityContainer>
                  <h1>More Availability</h1>
                  <h2>
                    New availability is released every week. Please come back on
                    Monday to see more choices
                  </h2>
                </MoreAvailabilityContainer>
              </CalendarGrid>
            </SwiperSlide>
          </CalendarSwiper>
          <EcoInfo />
          <NavigationButtonsContainer
            type="submit"
            styleVariant="bookingFlowCTA"
            disabled={!requiredNumSlotsSelected}
            onClickLeftButton={
              prevStep ? () => void router.push(prevStep) : undefined
            }
            isBusy={isNavigating}
            tooltipMessage={ctaButtonTooltipMessage(!requiredNumSlotsSelected)}
          />
        </form>
      </FormProvider>
      {showAgeLimitInfo && <AgeLimitInfo hasPreviousStep={Boolean(prevStep)} />}
    </>
  );
};

export const generateStopSalesDatesLookup = (
  stopSalesDates: BaseCalendarViewProps['stopSalesDates'],
) => {
  const stopSalesDatesList = stopSalesDates
    ?.map((dates) => {
      const startDate = new Date(dates.start);
      const endDate = new Date(dates.end);
      const stopDays = eachDayOfInterval({ start: startDate, end: endDate });
      // endDate is not inclusive but eachDayOfInterval generates an entry for it
      stopDays.pop();
      return stopDays;
    })
    .flat();
  const stopSalesDatesLookup =
    stopSalesDatesList?.reduce((lookup: Record<string, boolean>, stopDate) => {
      // formats date to YYYY-MM-DD
      lookup[stopDate.toISOString().split('T')[0]] = true;
      return lookup;
    }, {}) || {};

  return stopSalesDatesLookup;
};
