import * as React from 'react';

import { IconBase } from './utils';

export const CheckboxSelectedIcon: IconBase = (props) => {
  const { color, ...svgProps } = props;

  return (
    <svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...svgProps}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 3h14c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2Zm11.58 4.58L17.99 9l-8 8-4-3.99 1.42-1.41 2.58 2.57 6.59-6.59Z"
        fill={color || '#0B4ED5'}
      />
    </svg>
  );
};
