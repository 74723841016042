import { Icon } from 'theme/types';

export const GasSafetyCertificateIcon: Icon = (props) => {
  const { color = '#000000', width = 16, height = 20 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
        d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 10.5389 14.8173 12.8016 12.9727 14.2671C12.9906 14.3418 13 14.4198 13 14.5V19C13 19.3318 12.8354 19.642 12.5606 19.8281C12.2859 20.0141 11.9367 20.0517 11.6286 19.9285L8 18.477L4.37139 19.9285C4.0633 20.0517 3.71414 20.0141 3.43937 19.8281C3.1646 19.642 3 19.3318 3 19V14.5C3 14.4198 3.00944 14.3418 3.02726 14.2671C1.18272 12.8016 0 10.5389 0 8ZM5 15.4185V17.523L7.62861 16.4715C7.86702 16.3762 8.13298 16.3762 8.37139 16.4715L11 17.523V15.4185C10.0736 15.7935 9.0609 16 8 16C6.9391 16 5.92643 15.7935 5 15.4185ZM8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2ZM11.0892 5.19199C11.5355 5.51738 11.6334 6.14292 11.308 6.58917L8.39138 10.5892C8.20725 10.8417 7.91564 10.9936 7.60317 10.9998C7.29071 11.006 6.99332 10.8657 6.79933 10.6207L5.21599 8.6207C4.87319 8.18768 4.94632 7.55875 5.37934 7.21595C5.81235 6.87314 6.44128 6.94627 6.78409 7.37929L7.55065 8.34758L9.69203 5.41082C10.0174 4.96457 10.643 4.86659 11.0892 5.19199Z"
      />
    </svg>
  );
};
