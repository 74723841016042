import React, { forwardRef } from 'react';
import { ChangeHandler } from 'react-hook-form';

import { CheckboxIcon, CheckboxSelectedIcon } from '../../icons';

const selectColor = ({
  isEcoSlot,
  isBestSlot,
}: {
  isEcoSlot: boolean;
  isBestSlot: boolean;
}) => {
  return isBestSlot ? '#5028e2' : isEcoSlot ? 'green' : undefined;
};

export type CalendarSlotInputProps = {
  title: string;
  group: string;
  value: string;
  disabled: boolean;
  checked: boolean;
  isEcoSlot: boolean;
  isBestSlot: boolean;
  inputRef?: (instance: HTMLInputElement | null) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: ChangeHandler;
};

export const CalendarSlotInput = forwardRef<
  HTMLInputElement,
  CalendarSlotInputProps
>(function CalendarSlotInput(
  {
    title,
    group,
    value,
    disabled,
    checked,
    isEcoSlot,
    isBestSlot,
    onChange,
    onBlur,
  },
  ref,
) {
  const toolTipText = 'Not available';

  return (
    <div className="marketplace-calendar__slot marketplace-calendar-slot">
      {disabled && (
        <div
          data-tip={toolTipText}
          className="calendar__time-slot--empty-slot"
        />
      )}
      {!disabled && (
        <>
          <div className="marketplace-calendar-slot__container">
            <input
              type="radio"
              className="marketplace-calendar-slot__input"
              ref={ref}
              id={value}
              name={group}
              value={value}
              disabled={disabled}
              onChange={onChange}
              onBlur={onBlur}
            />

            {checked ? (
              <CheckboxSelectedIcon
                color={selectColor({ isBestSlot, isEcoSlot })}
              />
            ) : (
              <CheckboxIcon color={selectColor({ isBestSlot, isEcoSlot })} />
            )}
            {isBestSlot ? (
              <div className="marketplace-calendar-slot--rated-best" />
            ) : isEcoSlot ? (
              <div className="marketplace-calendar-slot--rated-good" />
            ) : null}
          </div>

          <label className="marketplace-calendar-slot__label" htmlFor={value}>
            <span className="screen-reader">{title}</span>
          </label>
        </>
      )}
    </div>
  );
});
