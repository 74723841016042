import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import Head from 'next/head';

type ProductNotAvailableProps = {
  headText?: string;
  bodyText?: string;
};

export const ProductNotAvailable = ({
  headText,
  bodyText,
}: ProductNotAvailableProps) => {
  return (
    <>
      <Head>
        <title>{headText}</title>
      </Head>
      <OuterContainer>
        <Typography variant="h1">{bodyText}</Typography>
      </OuterContainer>
    </>
  );
};

export default ProductNotAvailable;

const OuterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  grid-column: 1 / -1;
  background-color: ${({ theme }) => theme.app.background.primary};
  text-align: center;
`;
