import axios from 'axios';
import {
  addDays,
  differenceInDays,
  eachDayOfInterval,
  endOfDay,
  isAfter,
  isBefore,
  isWeekend,
  setHours,
  startOfDay,
  startOfWeek,
} from 'date-fns';
import { useEffect, useMemo, useState } from 'react';

import { isSlotCloseEnough } from 'components/marketplace/CalendarFormNew/CalendarSlotRatingInput';
import {
  AvailabilityResponse,
  AvailabilityShape,
} from 'pages/api/availability';
import { SlotRatingResponse } from 'pages/api/slot-rating';
import { chunk } from 'utils/useServiceAvailability/helpers';

import { BookableSlot } from '../useBookableSlots';
import { useUpdatingRef } from '../useUpdatingRef';

export const MARKETPLACE_SERVICE_WEEKS_TO_SHOW = 3;
export const MARKETPLACE_SERVICE_LEAD_TIME = 2;

interface SlotAvailabilityOptions {
  postcode: string | undefined;
  isConsolidatedAvailabilityEnabled: boolean;
  isAvailabilityFallbackEnabled: boolean;
  numberOfWeeksToShow?: number;
  leadTimeDays?: number;
  jobType?: string;
  isBulkSlotRatingsEnabled?: boolean;
  fallbackSlotThreshold?: number;
  productId?: string;
}

export interface Availability {
  status: 'loading' | 'success' | 'failure';
  data?: SlotAvailabilityWithRating[] | AvailabilityResponse;
}

export interface SlotAvailabilityWithRating {
  startDatetime: string;
  endDatetime: string;
  isAvailable: boolean;
  score: number;
  isEco: boolean;
}

export const useServiceAvailability = ({
  postcode,
  isConsolidatedAvailabilityEnabled,
  isAvailabilityFallbackEnabled,
  numberOfWeeksToShow,
  leadTimeDays,
  jobType,
  isBulkSlotRatingsEnabled = false,
  fallbackSlotThreshold = 5,
  productId,
}: SlotAvailabilityOptions) => {
  const [realAvailabilityState, setRealAvailability] = useState<Availability>(
    isConsolidatedAvailabilityEnabled
      ? { status: 'loading' }
      : { status: 'success', data: [] },
  );

  const { startDateTime, endDateTime, cutOff } = useMemo(
    () => getAvailabilityInterval(numberOfWeeksToShow, leadTimeDays),
    [numberOfWeeksToShow, leadTimeDays],
  );

  useConsolidatedAvailability({
    startDateTime: cutOff,
    endDateTime,
    postcode,
    skip: !isConsolidatedAvailabilityEnabled,
    onComplete: (data) => setRealAvailability({ status: 'success', data }),
    onError: () => setRealAvailability({ status: 'failure', data: [] }),
    jobType,
    isBulkSlotRatingsEnabled,
    isConsolidatedAvailabilityEnabled,
    isAvailabilityFallbackEnabled,
    fallbackSlotThreshold,
    productId,
  });

  const slotAvailability = useMemo(() => {
    const { data: realAvailability } = realAvailabilityState;

    if (!realAvailability) {
      return {
        bookableSlots: [],
        rejectedRatingSlots: [],
        status: realAvailabilityState.status,
      };
    }

    const { slots: slotsToRender, isFallback } = getFilledSlotsToRender({
      startDate: startDateTime,
      endDate: endDateTime,
      cutOff,
      realAvailability,
      isConsolidatedAvailabilityEnabled,
      isAvailabilityFallbackEnabled,
      fallbackSlotThreshold,
    });

    return {
      bookableSlots: slotsToRender,
      rejectedRatingSlots: [],
      status: realAvailabilityState.status,
      isFallback,
    };
  }, [
    startDateTime,
    endDateTime,
    cutOff,
    realAvailabilityState,
    isConsolidatedAvailabilityEnabled,
    isAvailabilityFallbackEnabled,
    fallbackSlotThreshold,
  ]);

  return slotAvailability;
};

export const getAvailabilityInterval = (
  numberOfWeeksToShow?: number,
  leadTimeDays?: number,
) => {
  const now = new Date();
  const interval = getAvailabilityIntervalFromDate(
    now,
    numberOfWeeksToShow,
    leadTimeDays,
  );

  return interval;
};

export const getAvailabilityIntervalFromDate = (
  date: Date,
  numberOfWeeksToShow: number = MARKETPLACE_SERVICE_WEEKS_TO_SHOW,
  leadTimeDays: number = MARKETPLACE_SERVICE_LEAD_TIME,
) => {
  const dayOfWeek = date.getDay() || 7;

  // Adds an extra day's leadTime on Saturdays
  const leadTime = dayOfWeek === 6 ? leadTimeDays + 1 : leadTimeDays;
  const cutOff = startOfDay(addDays(date, leadTime));

  const DAYS_TO_SHOW = numberOfWeeksToShow * 7;
  const startDateTime = startOfWeek(cutOff, { weekStartsOn: 1 });
  const endDate = addDays(startDateTime, DAYS_TO_SHOW - 1);
  const endDateTime = endOfDay(endDate);

  return { startDateTime, endDateTime, cutOff };
};

interface FilledSlotsToRenderOptions {
  startDate: Date;
  endDate: Date;
  cutOff: Date;
  realAvailability: SlotAvailabilityWithRating[] | AvailabilityResponse;
  isConsolidatedAvailabilityEnabled: boolean;
  isAvailabilityFallbackEnabled: boolean;
  fallbackSlotThreshold: number;
}

export const getFilledSlotsToRender = ({
  startDate,
  endDate,
  cutOff,
  realAvailability,
  isConsolidatedAvailabilityEnabled,
  isAvailabilityFallbackEnabled,
  fallbackSlotThreshold,
}: FilledSlotsToRenderOptions) => {
  const availableSlotsCount = realAvailability.filter(
    (slot) => slot.isAvailable,
  ).length;

  // this is the second check to see if we should use fallback availability - this time it's based on TP availability together with slot ratings
  const shouldUseFallbackFollowingRatingsFetch = shouldUseAvailabilityFallback({
    isConsolidatedAvailabilityEnabled,
    isAvailabilityFallbackEnabled,
    fallbackSlotThreshold,
    availableSlotsCount,
  });

  const slots = getSlotsToRender(startDate, endDate).map(
    (slot): BookableSlot => {
      const slotAvailability = findAvailabilityForSlot(slot, realAvailability);
      const isAvailable = shouldUseFallbackFollowingRatingsFetch
        ? isAvailableByFallback(slot, cutOff)
        : slotAvailability?.isAvailable;

      const isRatingGood =
        slotAvailability && 'isEco' in slotAvailability
          ? slotAvailability?.isEco
          : false;

      return {
        isAvailable: isAvailable ?? false,
        isRatingGood,
        appointmentsCount: undefined,
        startDateTime: slot.startDateTime,
        endDateTime: slot.endDateTime,
      };
    },
  );

  return { slots, isFallback: shouldUseFallbackFollowingRatingsFetch };
};

const startTimes = [8, 12, 16];
const endTimes = [12, 16, 20];

export interface Slot {
  startDateTime: Date;
  endDateTime: Date;
  indexWithinDay: number;
}

export const getSlotsToRender = (startDate: Date, endDate: Date): Slot[] => {
  const days = eachDayOfInterval({ start: startDate, end: endDate });

  const slots = days.flatMap((date) => {
    if (isWeekend(date)) return [];

    const slotsWithinDay = [0, 1, 2].map((slotIndex) => {
      const slotStartDateTime = setHours(date, startTimes[slotIndex]);
      const slotEndDateTime = setHours(date, endTimes[slotIndex]);

      return {
        startDateTime: slotStartDateTime,
        endDateTime: slotEndDateTime,
        indexWithinDay: slotIndex,
      };
    });

    return slotsWithinDay;
  });

  return slots;
};

const shouldUseAvailabilityFallback = ({
  isConsolidatedAvailabilityEnabled,
  isAvailabilityFallbackEnabled,
  fallbackSlotThreshold,
  availableSlotsCount,
}: {
  isConsolidatedAvailabilityEnabled: boolean;
  isAvailabilityFallbackEnabled: boolean;
  fallbackSlotThreshold: number;
  availableSlotsCount: number;
}) => {
  if (!isConsolidatedAvailabilityEnabled) {
    return true;
  }

  if (
    availableSlotsCount < fallbackSlotThreshold &&
    isAvailabilityFallbackEnabled
  ) {
    return true;
  }

  return false;
};

const findAvailabilityForSlot = (
  slot: Slot,
  realAvailability: SlotAvailabilityWithRating[] | AvailabilityResponse,
): SlotAvailabilityWithRating | AvailabilityShape | undefined => {
  const startDateTimeISOString =
    slot.startDateTime.toISOString().split('.')[0] + 'Z';

  const endDateTimeISOString =
    slot.endDateTime.toISOString().split('.')[0] + 'Z';

  const availabilityForSlot = realAvailability.find(
    (slot) =>
      slot.startDatetime === startDateTimeISOString &&
      slot.endDatetime === endDateTimeISOString,
  );

  return availabilityForSlot;
};

export const isAvailableByFallback = (slot: Slot, cutOff: Date) => {
  const adjustedFallbackCutOff = addDays(cutOff, 3);
  const isAvailable =
    slot.endDateTime > adjustedFallbackCutOff &&
    slot.indexWithinDay < 2 &&
    !isWeekend(slot.startDateTime);

  return isAvailable;
};

interface ConsolidatedAvailabilityOptions {
  startDateTime: Date;
  endDateTime: Date;
  postcode: string | undefined;
  skip?: boolean;
  onComplete: (
    availability: SlotAvailabilityWithRating[] | AvailabilityResponse,
  ) => void;
  onError?: (error: unknown) => void;
  jobType?: string;
  isBulkSlotRatingsEnabled?: boolean;
  isConsolidatedAvailabilityEnabled: boolean;
  isAvailabilityFallbackEnabled: boolean;
  fallbackSlotThreshold: number;
  productId?: string;
}

const useConsolidatedAvailability = ({
  startDateTime,
  endDateTime,
  postcode,
  skip,
  onComplete,
  onError,
  jobType,
  isBulkSlotRatingsEnabled = false,
  isConsolidatedAvailabilityEnabled,
  isAvailabilityFallbackEnabled,
  fallbackSlotThreshold,
  productId,
}: ConsolidatedAvailabilityOptions) => {
  const fetchAvailability = useFetchAvailability();
  const fetchAvailabilityRef = useUpdatingRef(fetchAvailability);
  const onCompleteRef = useUpdatingRef(onComplete);
  const onErrorRef = useUpdatingRef(onError);

  useEffect(() => {
    if (skip) return;
    if (!postcode) return;
    if (endDateTime < startDateTime) return;
    if (!jobType) return;

    fetchAvailabilityRef
      .current({
        postcode,
        startDateTime,
        endDateTime,
        jobType,
        isBulkSlotRatingsEnabled,
        isConsolidatedAvailabilityEnabled,
        isAvailabilityFallbackEnabled,
        fallbackSlotThreshold,
        productId,
      })
      .then(onCompleteRef.current, onErrorRef.current);
  }, [
    postcode,
    startDateTime,
    endDateTime,
    onCompleteRef,
    onErrorRef,
    skip,
    jobType,
    isBulkSlotRatingsEnabled,
    isConsolidatedAvailabilityEnabled,
    isAvailabilityFallbackEnabled,
    fallbackSlotThreshold,
    productId,
    fetchAvailabilityRef,
  ]);
};

interface FetchAvailabilityWithoutRatingsParams {
  postcode: string;
  startDateTime: Date;
  endDateTime: Date;
  jobType: string;
  isBulkSlotRatingsEnabled?: boolean;
  productId?: string;
}

interface FetchAvailabilityParams
  extends FetchAvailabilityWithoutRatingsParams {
  isConsolidatedAvailabilityEnabled: boolean;
  isAvailabilityFallbackEnabled: boolean;
  fallbackSlotThreshold: number;
}

export function useFetchAvailability() {
  async function fetchAvailability({
    postcode,
    startDateTime,
    endDateTime,
    jobType,
    isBulkSlotRatingsEnabled = false,
    isConsolidatedAvailabilityEnabled,
    isAvailabilityFallbackEnabled,
    fallbackSlotThreshold,
    productId,
  }: FetchAvailabilityParams): Promise<
    SlotAvailabilityWithRating[] | AvailabilityResponse
  > {
    const availabilityWithoutRatings = await fetchAvailabilityWithoutRatings({
      postcode,
      startDateTime,
      endDateTime,
      jobType,
      productId,
    });

    const availableSlotsCount = availabilityWithoutRatings.filter(
      (slot) => slot.isAvailable,
    ).length;

    // this is the first check to see if we should use fallback availability - it's based purely on TP availability without taking into account slot ratings and is here to prevent unnecessary slot ratings fetches on occasions where availabilityWithoutRatings is below the fallbackSlotThreshold
    const shouldUseFallbackBeforeRatingsFetch = shouldUseAvailabilityFallback({
      isConsolidatedAvailabilityEnabled,
      isAvailabilityFallbackEnabled,
      fallbackSlotThreshold,
      availableSlotsCount,
    });
    if (shouldUseFallbackBeforeRatingsFetch) return availabilityWithoutRatings;

    let availabilityWithRatings;
    if (isBulkSlotRatingsEnabled) {
      const ratings = await fetchBulkSlotRatings({
        postcode,
        slots: availabilityWithoutRatings,
        jobType,
      });

      availabilityWithRatings = availabilityWithoutRatings.map((slot, i) =>
        mapSlots(slot, i, ratings),
      );
    } else {
      const ratings = await fetchRatings({
        postcode,
        slots: availabilityWithoutRatings,
        jobType,
      });

      availabilityWithRatings = availabilityWithoutRatings.map((slot, i) =>
        mapSlots(slot, i, ratings),
      );
    }
    return availabilityWithRatings;
  }

  function mapSlots(
    slot: AvailabilityShape,
    index: number,
    ratings: SlotRatingResponse[],
  ) {
    const rating = ratings[index];

    return {
      startDatetime: slot.startDatetime,
      endDatetime: slot.endDatetime,
      isEco: rating.isEco,
      score: rating.score,
      isAvailable: slot.isAvailable && isSlotCloseEnough(rating),
    };
  }

  return fetchAvailability;
}

async function fetchAvailabilityWithoutRatings({
  postcode,
  startDateTime,
  endDateTime,
  jobType,
  productId,
}: FetchAvailabilityWithoutRatingsParams): Promise<AvailabilityResponse> {
  const weekIntervals = splitIntervalByWeeks({ startDateTime, endDateTime });

  const weekPromises = weekIntervals.map((interval) => {
    return fetchWeekAvailability({ postcode, ...interval, jobType, productId });
  });

  const weekAvailability = await Promise.all(weekPromises);

  const totalAvailability = weekAvailability.flat();

  return totalAvailability;
}

interface Interval {
  startDateTime: Date;
  endDateTime: Date;
}

export const splitIntervalByWeeks = ({
  startDateTime,
  endDateTime,
}: Interval): Interval[] => {
  if (differenceInDays(endDateTime, startDateTime) <= 7) {
    return [{ startDateTime, endDateTime }];
  }

  const days = eachDayOfInterval({
    start: startDateTime,
    end: endDateTime,
  });

  const groupsOfSevenDays = chunk(days, 7);

  const weekIntervals = groupsOfSevenDays.map((daysInChunk) => {
    const chunkStartDate = daysInChunk[0];
    const chunkEndDate = daysInChunk[daysInChunk.length - 1];

    const chunkStartDateTime = startOfDay(chunkStartDate);
    const chunkEndDateTime = endOfDay(chunkEndDate);

    const finalChunkStartDateTime = isAfter(chunkStartDateTime, startDateTime)
      ? chunkStartDateTime
      : startDateTime;

    const finalChunkEndDateTime = isBefore(chunkEndDateTime, endDateTime)
      ? chunkEndDateTime
      : endDateTime;

    return {
      startDateTime: finalChunkStartDateTime,
      endDateTime: finalChunkEndDateTime,
    };
  });

  return weekIntervals;
};

export type GetAvailabilityRequestParams = {
  postcode: string;
  startDatetime: string;
  endDatetime: string;
  timezone: string;
  jobType: string;
  productId: string;
};

export const fetchWeekAvailability = async ({
  postcode,
  startDateTime,
  endDateTime,
  jobType,
  productId,
}: FetchAvailabilityWithoutRatingsParams): Promise<AvailabilityResponse> => {
  const region = new Intl.DateTimeFormat('en-GB');
  const timezone = region.resolvedOptions().timeZone;
  const startDateTimeISO = startDateTime.toISOString();
  const endDateTimeISO = endDateTime.toISOString();

  const response = await axios.get<AvailabilityResponse>('/api/availability', {
    params: {
      postcode,
      startDatetime: startDateTimeISO,
      endDatetime: endDateTimeISO,
      timezone,
      jobType,
      productId,
    },
  });

  return response.data;
};

interface GetSlotRatingsParams {
  slots: AvailabilityResponse;
  postcode: string;
  jobType?: string;
}

const fetchRatings = async ({
  slots,
  postcode,
  jobType,
}: GetSlotRatingsParams): Promise<SlotRatingResponse[]> => {
  const ratingPromises = slots.map((slot) => {
    if (!slot.isAvailable) {
      return { score: 0, isEco: false };
    }

    return fetchSlotRating({
      postcode,
      startDateTime: new Date(slot.startDatetime),
      endDateTime: new Date(slot.endDatetime),
      jobType,
    });
  });

  const ratings = Promise.all(ratingPromises);

  return ratings;
};

interface GetSlotRatingParams {
  postcode: string;
  startDateTime: Date;
  endDateTime: Date;
  jobType?: string;
}

export type GetSlotRatingRequestParams = {
  postcode: string;
  startDatetime: string;
  endDatetime: string;
  timezone: string;
  jobType?: string;
};

interface GetBulkSlotRatingParams {
  postcode: string;
  slots: AvailabilityResponse;
  jobType?: string;
}

export type PostBulkSlotRatingRequestParams = {
  postcode: string;
  slots: string; // JSON stringified AvailabilityResponse
  jobType?: string;
  timezone: string;
};

const fetchSlotRating = async ({
  postcode,
  startDateTime,
  endDateTime,
  jobType,
}: GetSlotRatingParams): Promise<SlotRatingResponse> => {
  const region = new Intl.DateTimeFormat('en-GB');
  const timezone = region.resolvedOptions().timeZone;

  const response = await axios.get<SlotRatingResponse>('/api/slot-rating', {
    params: {
      postcode,
      startDatetime: startDateTime.toISOString(),
      endDatetime: endDateTime.toISOString(),
      timezone,
      jobType,
    },
  });

  return response.data;
};

const fetchBulkSlotRatings = async ({
  postcode,
  slots,
  jobType,
}: GetBulkSlotRatingParams): Promise<SlotRatingResponse[]> => {
  const region = new Intl.DateTimeFormat('en-GB');
  const timezone = region.resolvedOptions().timeZone;
  const chunkSize = 10;

  const slotPromises = chunk(slots, chunkSize).map((chunk) =>
    axios.get<SlotRatingResponse[]>('/api/bulk-slot-rating', {
      params: {
        postcode,
        timezone,
        jobType,
        slots: JSON.stringify(chunk),
      },
    }),
  );

  return await Promise.all(slotPromises).then((responses) =>
    responses.map((response) => response.data).flat(),
  );
};
