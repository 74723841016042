export type Spacing =
  | 1
  | 2
  | 4
  | 6
  | 8
  | 12
  | 14
  | 16
  | 18
  | 22
  | 24
  | 32
  | 40
  | 80;

export const spacing: Record<Spacing, string> = {
  1: '1px',
  2: '2px',
  4: '4px',
  6: '6px',
  8: '8px',
  12: '12px',
  14: '14px',
  16: '16px',
  18: '18px',
  22: '22px',
  24: '24px',
  32: '32px',
  40: '40px',
  80: '80px',
};
