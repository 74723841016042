import type { IncomingMessage } from 'http';
import { NextRouter } from 'next/router';

export const isBeyond = (route: string) => route.includes('/beyond/');

export const generateNextRoute = ({
  router,
  nextRoute,
  serviceType,
}: {
  router: NextRouter;
  nextRoute: string;
  serviceType?: string;
}) => {
  if (isBeyond(router.asPath)) {
    return `/beyond/${router.query.product_type ?? 'service'}/${
      serviceType || router.query.service_type
    }/${nextRoute}`;
  } else {
    return `/${router.query.product_type ?? 'service'}/${
      (serviceType || router.query.service_type) ?? 'boiler-service'
    }/${nextRoute}`;
  }
};

export function getPagePath(req: Pick<IncomingMessage, 'url'>) {
  if (!req.url) return;

  if (req.url.startsWith('/_next')) {
    const urlPartsMatch = req.url.match(
      /\/_next\/data\/[^/]+(?<pathname>\/[^.]+).json(?<search>\?.*)?$/,
    );
    const pathname = urlPartsMatch?.groups?.pathname;
    const search = urlPartsMatch?.groups?.search ?? '';

    return pathname ? pathname + search : undefined;
  }

  return req.url;
}

export const generateRateDomain = (router: NextRouter) => {
  if (isBeyond(router.asPath)) {
    return '/beyond';
  } else {
    return '';
  }
};
