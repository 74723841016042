import { SlotShape } from 'components/form';
import { JOB_TYPES } from 'utils/jobTypes';

export interface AddressData {
  line1?: string;
  line2?: string;
  town?: string;
  postcode?: string;
}

export interface AddressDataState extends AddressData {
  setState: (appointmentData: Partial<AddressData>) => void;
  clearState: () => void;
}

export interface ContactData {
  fullName?: string;
  email?: string;
  phone?: string;
  alternativePhoneNumber?: string;
  customerReferenceId?: string;
  customerSource?: CustomerSource;
}

export enum CustomerSource {
  BEYOND = 'beyond',
  ENERGY_EXPERTS = 'energy-experts',
}

export interface ServicesData extends ContactData {
  jobType: JOB_TYPES | string;
  fuelType?: string;
  timeSlots: string[];
  startDateTime?: string;
  acceptPromos?: boolean;
  acceptTerms?: boolean;
  paymentMethodId?: string;
  metadata?: Record<string, string>;
}

export interface ServicesDataState extends ServicesData {
  setState: (servicesData: Partial<ServicesData>) => void;
  clearState: () => void;
}

export interface CalloutData extends ContactData {
  acceptPromos?: boolean;
  acceptTerms?: boolean;
  jobType: JOB_TYPES | string;
  mainProblem?: string;
  fuelType?: string;
  additionalInformation?: string;
  appointmentSlots?: Array<SlotShape>;
  paymentMethodId?: string;
  metadata?: Record<string, any>;
}

export interface CalloutDataState extends CalloutData {
  setState: (calloutData: Partial<CalloutData>) => void;
  clearState: () => void;
}

export interface EnergyExpertsData extends ContactData {
  jobType: JOB_TYPES | string;
  productId: string;
  motivations: string;
  otherReasonDetail?: string;
  timeSlots: string[];
  startDateTime?: string;
  acceptPromos?: boolean;
  acceptTerms?: boolean;
  metadata?: Record<string, string>;
}

export interface EnergyExpertsDataState extends EnergyExpertsData {
  setState: (energyExpertsData: Partial<EnergyExpertsData>) => void;
  clearState: () => void;
}

export interface GenericStoreData extends ContactData {
  jobType: JOB_TYPES | string;
  productId: string;
  timeSlots: string[];
  metadata: Record<string, any>;
  startDateTime?: string;
  acceptPromos?: boolean;
  acceptTerms?: boolean;
  userCanEditDetails?: boolean;
}

export interface GenericStoreDataState extends GenericStoreData {
  setState: (genericStoreData: Partial<GenericStoreData>) => void;
  clearState: () => void;
}
