import { useTheme } from '@emotion/react';
import { CSSProperties } from 'react';
import { useController } from 'react-hook-form';

import { MainProblemOption } from 'utils/formRadioOptions';

import { CalloutInput } from '../CalloutSelectionRadioButton/CalloutSelectionRadioButton.styles';
import {
  InnerContainer,
  RadioContainer,
  ServiceFuelInputContainer,
} from './ServiceFuelTypeRadioButton.styles';

interface FuelTypeOptionInputProps {
  option: MainProblemOption;
  name: string;
  isSelected: boolean;
  required?: boolean;
  onChange: () => void;
  disabled?: boolean;
  styles?: CSSProperties;
}

export const ServiceFuelTypeRadioButton = ({
  option,
  name,
  isSelected,
  required,
  onChange,
  disabled,
  styles,
}: FuelTypeOptionInputProps) => {
  const {
    field: { ref, ...restOfField },
  } = useController({
    name,
    rules: {
      required,
    },
  });
  const theme = useTheme();
  const { value, gridGap } = option;
  return (
    <RadioContainer
      whileHover={!disabled ? { scale: 1.1 } : {}}
      whileTap={!disabled ? { scale: 0.9 } : {}}
      $isSelected={isSelected}
      onClick={onChange}
      style={styles}
    >
      <ServiceFuelInputContainer $isSelected={isSelected}>
        <CalloutInput
          type="radio"
          {...restOfField}
          ref={ref}
          name={name}
          value={value}
          checked={isSelected}
          onChange={(e) => {
            restOfField.onChange(e);
          }}
          disabled={disabled}
        />
        <InnerContainer $isSelected={isSelected} $gridGap={gridGap}>
          {option.icons?.map((icon) => (
            <div key={icon}>
              {theme.app.icons[icon]({
                color: isSelected && theme.app.text.white,
              })}
            </div>
          ))}
          <p>{option.serviceTypeLabel}</p>
          <p>{option.tooltipLabel}</p>
        </InnerContainer>
      </ServiceFuelInputContainer>
    </RadioContainer>
  );
};
