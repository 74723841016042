import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { ExpandMore } from '@mui/icons-material';
import { AccordionDetails, AccordionSummary } from '@mui/material';
import { m } from 'framer-motion';
import Link from 'next/link';
import { mediaQuery } from 'theme/kantan';

import { AppointmentSummary } from 'components/AppointmentSummary';
import { AppointmentSummaryProps } from 'components/AppointmentSummary/AppointmentSummary';
import { CheckMark } from 'components/redesign/CheckMark';
import { StrongText } from 'components/StrongText';
import {
  AppointmentSummaryContainer,
  BasicAccordion,
} from 'layouts/MarketingLayout/components/utilityStyles';

const ConfirmationPageSection = styled(m.section)`
  grid-column: 1 / -1;
  grid-row: 2;

  display: flex;
  flex-flow: column;

  margin-top: 60px;

  > * {
    margin-top: ${({ theme }) => theme.app.margin.largeMargin};
  }

  // TODO: Once NextJS v12.2.5 use component selectors instead
  > section:first-of-type,
  ol {
    margin-top: 0;
  }

  @media (${mediaQuery('tablet')}) {
    width: 100%;
    max-width: 990px;
    justify-self: center;

    margin-top: unset;
    padding: ${({ theme }) => theme.app.padding.largePadding};

    // TODO: Good idea to extract these as reusable styles
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0 8px 28px rgba(0, 0, 0, 0.06);
    backdrop-filter: blur(20px);
    border-radius: ${({ theme }) => theme.app.borderRadius.largeCorner};

    height: max-content;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 4rem 6rem auto;
  }
`;

const TitleContainer = styled.section`
  display: flex;
  align-items: center;

  @media (${mediaQuery('tablet')}) {
    grid-column: 1 / span 2;
    grid-row: 1;
  }
`;

const CheckMarkIcon = styled.span`
  height: 20px;

  @media (${mediaQuery('tablet')}) {
    height: 33px;
    svg {
      width: 33px;
      height: 33px;
    }
  }
`;

const ConfirmationPageTitle = styled.h1`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[24]};
  line-height: 1.33;
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.lightest};
  color: ${({ theme }) => theme.app.text.primary};
  margin-left: ${({ theme }) => theme.app.margin.mediumMargin};

  @media (${mediaQuery('tablet')}) {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[32]};
    line-height: 1.25;
    letter-spacing: -0.02em;
  }
`;

const ConfirmationPageSubtitle = styled.h2`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[18]};
  line-height: 1.75;
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.lightest};
  color: ${({ theme }) => theme.app.text.primary};
  letter-spacing: -0.02em;

  align-self: center;

  @media (${mediaQuery('tablet')}) {
    margin-top: 0;
    justify-self: end;
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[24]};
    line-height: 1.33;
  }
`;

const ConfirmationPageTextContainer = styled.section`
  @media (${mediaQuery('tablet')}) {
    grid-column: 1 / -1;
    grid-row: 2;
  }
`;

const ConfirmationPageText = styled.p`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[14]};
  line-height: 1.5;
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.light};

  @media (${mediaQuery('tablet')}) {
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
    line-height: 1.5;
  }
`;

const StyledOrderedList = styled.ol`
  li {
    list-style-type: numbered;
    margin-left: 20px;
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[14]};
    line-height: 1.6;
  }
`;

const StyledA = styled.a`
  color: ${({ theme }) => theme.app.messages.action};
`;

const StyledAccordion = styled(BasicAccordion)`
  .MuiCollapse-entered.MuiCollapse-root {
    margin-bottom: 20px;
  }

  margin-top: 30px;

  &.Mui-expanded:first-of-type {
    margin-top: 30px;
  }

  @media (${mediaQuery('tablet')}) {
    grid-column: 1/ -1;
    grid-row: 3;
    background-color: unset;

    place-self: center;
    display: flex;
    flex-flow: column;
    align-items: center;
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  color: ${({ theme }) => theme.app.text.action};

  .MuiAccordionSummary-content {
    flex-grow: 0;
  }

  .MuiAccordionSummary {
    &-expandIconWrapper {
      color: inherit;
    }

    &-content {
      margin: 0;
    }
  }
`;

const ConfirmationAppointmentSummaryContainer = styled(
  AppointmentSummaryContainer,
)`
  @media (${mediaQuery('tablet')}) {
    width: 100%;
    min-width: 480px;
  }
`;

const AccordionEntry = styled(AccordionDetails)`
  padding: 0;
`;

interface BaseConfirmationViewProps extends AppointmentSummaryProps {
  referenceId?: string;
  rescheduled?: boolean;
  emailAddress?: string;
  phoneNumber?: string;
}

export const BaseConfirmationView = ({
  type,
  isHeating,
  appointmentSlots,
  bookingSummaryHeader,
  bookingSummaryDetail,
  addressData,
  contactData,
  referenceId,
  rescheduled,
  emailAddress,
  phoneNumber,
}: BaseConfirmationViewProps) => {
  const theme = useTheme();

  return (
    <>
      <ConfirmationPageSection
        initial={{ x: '120%' }}
        animate={{ x: '0%' }}
        transition={{ duration: 0.3 }}
      >
        <TitleContainer>
          <CheckMarkIcon>
            <CheckMark color={theme.app.messages.success} />
          </CheckMarkIcon>
          <ConfirmationPageTitle>
            Your booking is{' '}
            <StrongText>{rescheduled ? 'rescheduled' : 'confirmed'}</StrongText>
          </ConfirmationPageTitle>
        </TitleContainer>
        {referenceId && (
          <ConfirmationPageSubtitle>
            Reference # <StrongText>{referenceId}</StrongText>
          </ConfirmationPageSubtitle>
        )}
        <ConfirmationPageTextContainer>
          <ConfirmationPageText>
            <StrongText>
              Thank you! You&apos;ll receive a confirmation email{' '}
              {!rescheduled && 'and text '}
              shortly. If you don&apos;t receive an email:
            </StrongText>
          </ConfirmationPageText>
          <StyledOrderedList>
            <li>Check your spam folder</li>
            <li>
              Get in touch with us on{' '}
              <Link passHref href={`mailto: ${emailAddress}`} legacyBehavior>
                <StyledA>{emailAddress}</StyledA>
              </Link>{' '}
              or call{' '}
              <Link passHref href={`tel:${phoneNumber}`} legacyBehavior>
                <StyledA> {phoneNumber}</StyledA>
              </Link>
            </li>
          </StyledOrderedList>
        </ConfirmationPageTextContainer>

        {!!bookingSummaryDetail && (
          <StyledAccordion
            disableGutters
            square
            TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
          >
            <StyledAccordionSummary expandIcon={<ExpandMore />}>
              <StrongText weight="semiBold">Review Booking</StrongText>
            </StyledAccordionSummary>
            <AccordionEntry>
              <ConfirmationAppointmentSummaryContainer>
                <AppointmentSummary
                  type={type}
                  isHeating={isHeating}
                  appointmentSlots={appointmentSlots}
                  addressData={addressData}
                  contactData={contactData}
                  bookingSummaryHeader={bookingSummaryHeader}
                  bookingSummaryDetail={bookingSummaryDetail}
                />
              </ConfirmationAppointmentSummaryContainer>
            </AccordionEntry>
          </StyledAccordion>
        )}
      </ConfirmationPageSection>
    </>
  );
};
