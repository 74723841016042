export const getLandingPageVariant = (utm_campaign?: string | string[]) => {
  if (Array.isArray(utm_campaign)) return;

  return utm_campaign ? utm_campaign : UTM_CAMPAIGNS.DEFAULT;
};

export enum UTM_CAMPAIGNS {
  HOT_WATER = 'heatinghotwater',
  EMERGENCY_BOILER_REPAIR = 'emergencyboilerrepair',
  BOILER_NOT_WORKING = 'boilernotworking',
  HEATING_REPAIR = 'heatingrepair',
  DEFAULT = 'default',
}
