import styled from '@emotion/styled';

const StyledSpan = styled.span`
  color: ${({ theme }) => theme.app.text.error};
  font-size: inherit;
`;

export const RequiredAsterisk = () => {
  return <StyledSpan>*</StyledSpan>;
};
