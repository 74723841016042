import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useRouter } from 'next/router';
import { useCalloutsStore, useGenericStore, useServicesStore } from 'store';

import { ManagedRate } from '../services/models/ManagedRate';
import { CustomerSource } from '../store/types';
import { JOB_TYPES, PRODUCT_TYPE } from './jobTypes';

const fetchRates = async (isBeyondCustomer: boolean) => {
  const response = await axios.get('/api/services/get-managed-rates', {
    params: {
      isBeyondCustomer,
    },
    headers: {
      url: window.location.href,
    },
  });

  return response.data;
};

const mapRates = (managedRates: ManagedRate[] = []) => {
  const managedRatesMap = {} as Record<JOB_TYPES, ManagedRate>;

  managedRates.forEach((managedRate) => {
    managedRatesMap[managedRate.jobType] = managedRate;
  });

  return managedRatesMap;
};

export const useManagedRates = () => {
  const [isBeyondCustomer] = useIsBeyondCustomer();

  const { data } = useQuery(
    ['managed-rates'],
    () => fetchRates(isBeyondCustomer),
    {
      select: (data) =>
        data.error ? ({} as Record<JOB_TYPES, ManagedRate>) : mapRates(data),
      staleTime: 5 * 60 * 1000,
    },
  );

  return data;
};

const useIsBeyondCustomer = () => {
  const {
    query: { product_type },
  } = useRouter();
  const isRepair = product_type === PRODUCT_TYPE.REPAIR;
  const isService = product_type === PRODUCT_TYPE.SERVICE;

  const calloutsState = useCalloutsStore();
  const servicesState = useServicesStore();
  const genericState = useGenericStore();

  let residentDetails;
  if (isRepair) {
    residentDetails = calloutsState;
  } else if (isService) {
    residentDetails = servicesState;
  } else {
    residentDetails = genericState;
  }

  const isBeyondCustomer =
    residentDetails.customerSource === CustomerSource.BEYOND;

  return [isBeyondCustomer];
};
