import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useAddressStore, useEnergyExpertsStore, useGenericStore } from 'store';
import { AddressDataState } from 'store/types';
import { useHasStoreHydrated } from 'store/utils';

import { NavigationButtonsContainer } from 'components/NavigationButtonsContainer';
import { AddressInputManual } from 'components/redesign/AddressStep';
import { OnChange } from 'components/redesign/AddressStep/AddressInputManual';
import { InputChangeEvent } from 'components/redesign/MuiTextField/MuiTextField';
import { DEFAULT_CURRENCY } from 'layouts/MarketingLayout/MarketingLayout';
import { useExistingStepContext } from 'pages/[productType]/[rateDomain]/[step]';
import { StepOptions } from 'services/kantanClient';
import { stepSlideTransitionProps } from 'utils/framerMotion';
import { JOB_TYPES, mapJobTypeToProductType } from 'utils/jobTypes';
import { getPriceFromJobType, grabGclid, trackEvent } from 'utils/tracking';
import { useManagedRates } from 'utils/useManagedRates';
import { validatePostcode } from 'utils/validation';

import { AddressStyles } from '.';

export const ConfirmAddress = (stepConfigOptions: StepOptions) => {
  const {
    OuterContainer,
    MainContent,
    SelectAddressContainer,
    InfoTextConfirmAddress: InfoText,
  } = AddressStyles;

  const context = useExistingStepContext();
  const prevStep = context.currentStep.options.prevStep;
  const jobType =
    context.productConfig.temporaryJobType ?? JOB_TYPES.HOME_ENERGY_ASSESSMENT;

  const [addressData, setAddressData] = useAddressStore((state) => [
    state,
    state.setState,
  ]);
  const { motivations } = useEnergyExpertsStore(({ motivations }) => ({
    motivations,
  }));
  const userCanEditDetails = useGenericStore(
    (state) => state.userCanEditDetails,
  );

  const form = useForm<AddressDataState>({
    mode: 'all',
    defaultValues: addressData,
  });
  const { reset, formState, handleSubmit } = form;

  const hasStoreHydrated = useHasStoreHydrated();
  const hasFormReset = useRef(false);

  useEffect(() => {
    if (!hasFormReset.current && hasStoreHydrated) {
      reset(addressData, { keepErrors: true });
      hasFormReset.current = true;
    }
  }, [hasStoreHydrated, reset, addressData]);

  const [isNavigating, setIsNavigating] = useState(false);
  const router = useRouter();
  const managedRates = useManagedRates();

  const handleFieldChange = (event: InputChangeEvent, onChange: OnChange) => {
    const { name, value } = event.target;
    onChange(value);
    setAddressData({ ...addressData, [name]: event.target.value });
  };

  const onClickLeftButton = () => {
    void router.push(prevStep);
  };

  const onSubmit = async () => {
    setIsNavigating(true);
    const { postcode } = addressData;
    try {
      await validatePostcode(postcode);
      trackEvent('Address submitted', {
        product_type: mapJobTypeToProductType(jobType),
        job_type: jobType,
        visit_reason: motivations,
        price: getPriceFromJobType(jobType, managedRates).amount,
        currency: DEFAULT_CURRENCY,
        gclid: grabGclid(router),
      });
      await router.push({ pathname: stepConfigOptions.nextStep });
    } catch (e) {
      form.setError('postcode', { message: 'Please enter a valid postcode' });
    }
  };

  return (
    <OuterContainer {...stepSlideTransitionProps}>
      <FormProvider {...form}>
        <MainContent onSubmit={handleSubmit(onSubmit)}>
          <SelectAddressContainer>
            <InfoText>Your address</InfoText>
            <AddressInputManual
              addressData={addressData}
              handleFieldChange={handleFieldChange}
              disabled={!userCanEditDetails}
            />
          </SelectAddressContainer>
          <NavigationButtonsContainer
            type="submit"
            disabled={!formState.isValid}
            isBusy={isNavigating}
            onClickLeftButton={onClickLeftButton}
          />
        </MainContent>
      </FormProvider>
    </OuterContainer>
  );
};
