import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import Image from 'next/legacy/image';

import { StrongText } from 'components/StrongText';

import {
  AlignContent,
  ResponsiveContainer,
  SectionHeader,
} from './utilityStyles';

const ReviewsContainer = styled(ResponsiveContainer)`
  background-color: ${({ theme }) => theme.app.background.white};
  padding: ${({ theme }) => theme.app.padding.largePadding}
    ${({ theme }) => theme.app.padding.mediumLargePadding};

  @media (min-width: 680px) {
    border-radius: ${({ theme }) => theme.app.borderRadius.extraLargeCorner};
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.08);
  }
`;

const StarsSection = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.app.padding.smallPadding} 0;
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[24]};

  > * {
    margin-left: ${({ theme }) => theme.app.margin.smallMargin};

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-left: ${({ theme }) => theme.app.margin.mediumMargin};
    }
  }
`;

const ReviewItem = styled.li``;

const ReviewsList = styled.ul`
  > * {
    margin-top: 40px;
  }

  > *:first-of-type {
    margin-top: 0;
  }
`;

const ReviewItemRating = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.app.padding.mediumPadding} 0;

  > * {
    margin-right: ${({ theme }) => theme.app.margin.smallMargin};
  }
`;

const ReviewItemFooter = styled(Typography)`
  display: inline-block;
  margin-top: ${({ theme }) => theme.app.margin.mediumLargeMargin};

  > span {
    color: ${({ theme }) => theme.app.text.action};
  }
`;

const reviewsInfo = [
  {
    name: 'Livia O.',
    reviewDate: 'Feb 2022',
    reviewScore: '5.0',
    reviewText:
      'The service was very good. Honest, prompt and easy. I give you 5 stars!',
    company: 'Gas & Heating Direct',
    location: 'Epsom',
  },
  {
    name: 'Fiona B.',
    reviewDate: 'Jul 2021',
    reviewScore: '5.0',
    reviewText:
      'Extremely happy with the service! The online booking system is great and the customer support is very helpful.',
    company: 'A M Heating',
    location: 'London',
  },
  {
    name: 'Andrew E.',
    reviewDate: 'Feb 2022',
    reviewScore: '5.0',
    reviewText:
      'Brilliant service, from start to finish! I highly recommend this organisation, and I will be using them again next time round.',
    company: 'Ace Plumbing & Heating',
    location: 'Maidenhead',
  },
];

export const ReviewsSection = () => {
  const renderStars = (starSize: number, number: number = 5) =>
    Array.from(Array(number)).map((e, i) => (
      <div key={i}>
        <Image
          src="/assets/svg/StarIcon.svg"
          alt="Star Icon"
          width={starSize}
          height={starSize}
        />
      </div>
    ));

  return (
    <AlignContent>
      <ReviewsContainer>
        <SectionHeader>
          Customers <StrongText>trust Kantan</StrongText>
        </SectionHeader>
        <StarsSection>
          {renderStars(26)}
          <StrongText>5 Stars</StrongText>
        </StarsSection>
        <ReviewsList>
          {reviewsInfo.map(
            (
              { name, reviewDate, reviewScore, reviewText, company, location },
              i,
            ) => (
              <ReviewItem key={i}>
                <StrongText>{name}</StrongText>
                <Typography variant="body2">{reviewDate}</Typography>
                <ReviewItemRating>
                  {renderStars(20, Number(reviewScore))}
                  <StrongText>{reviewScore.substring(0, 1)} Stars</StrongText>
                </ReviewItemRating>
                <Typography variant="body2">{reviewText}</Typography>
                <ReviewItemFooter variant="caption">
                  For: <span>{company},</span> <span>{location}</span>
                </ReviewItemFooter>
              </ReviewItem>
            ),
          )}
        </ReviewsList>
      </ReviewsContainer>
    </AlignContent>
  );
};
