import { InferGetServerSidePropsType } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import {
  BookingConfirmation,
  ConfirmAddress,
  PaymentConfirmation,
  Postcode,
  ResidentDetails,
  ReviewBooking,
  SelectAddress,
  SelectTime,
  Tracker,
} from 'productAgnostic';
import ProductNotAvailable from 'productAgnostic/ProductNotAvailable';
import {
  Motivation,
  NoAvailability,
  NotInYourArea,
  SignUpAcknowledge,
} from 'productSpecific/energy-experts';
import {
  ConfirmTadoPurchase,
  IneligibleHomeSetup,
  NotInYourAreaTado,
  SelectBoilerType,
  SelectFuelType,
  SignUpAcknowledgeTado,
  YourHome,
} from 'productSpecific/tado';
import { useEffect } from 'react';
import { useAddressStore, useEnergyExpertsStore, useGenericStore } from 'store';
import { getTheme } from 'theme/theme';
import { mapProductTypeToProductName } from 'theme/utils';

import EnergyExpertsLayout from 'layouts/EnergyExpertsLayout';
import { useThemeContext } from 'pages/_app';
import { StepComponentName, StepOptions } from 'services/kantanClient';
import { getPreloadedData } from 'utils/beyond/preloaded-data';
import { createSafeContext } from 'utils/createSafeContext';
import { getStringOrFirstElement } from 'utils/getStringOrFirstElement';
import { PRODUCT_TYPE } from 'utils/jobTypes';
import { currentPartner, PARTNERS } from 'utils/partners';
import { constructPath } from 'utils/product/constructPath';
import { isBeyond } from 'utils/urls';
import { ProductDrivenConfig } from 'utils/useProductDrivenConfig';
import { useRedirectIfMissingData } from 'utils/useRedirectIfMissingDataRedesign';

import { CustomerSource } from '../../../store/types';

export type RenderType = 'text' | 'check-mark' | null;

export type Step = {
  component: StepComponentName;
  options: StepOptions;
};

export interface ProductParams {
  productType?: PRODUCT_TYPE | undefined;
  productId?: string;
}

export interface NullableStepContextValue {
  productConfig?: ProductDrivenConfig;
  currentStep?: Step;
  isBulkSlotRatingsEnabled?: boolean;
  setProductParams: (params: ProductParams) => void;
  setStep: (step: string) => void;
}

export interface ExistingStepContextValue extends NullableStepContextValue {
  productConfig: ProductDrivenConfig;
  currentStep: Step;
}

export const [StepContext, useNullableStepContext] =
  createSafeContext<NullableStepContextValue>('step');

export const useNullableProductContext = useNullableStepContext;

export interface RequiredProductConfigContextValue
  extends NullableStepContextValue {
  productConfig: ProductDrivenConfig;
}

export const useExistingProductContext =
  useNullableStepContext as () => RequiredProductConfigContextValue;

/**
 * Intended to be used in the components inside a step.
 */
export const useExistingStepContext =
  useNullableStepContext as () => ExistingStepContextValue;

const Route = ({
  isBulkSlotRatingsEnabled,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const { setTheme } = useThemeContext();

  const router = useRouter();
  const { productType: _productType, step: _step } = router.query;
  const productType = getStringOrFirstElement(_productType) as PRODUCT_TYPE;
  const step = getStringOrFirstElement(_step);

  const context = useNullableStepContext();
  const {
    currentStep,
    setProductParams,
    setStep,
    productConfig: {
      routeToRedirectIfMissingData,
      useGenericStoreData: shouldUseGenericStoreData,
      logo,
      flowDisabledOptions,
    } = {},
  } = context;

  const { isFlowDisabled, headText, bodyText } = flowDisabledOptions ?? {};

  const productName = mapProductTypeToProductName(productType);
  const theme = getTheme(router, productName);

  useEffect(() => {
    setProductParams({ productType });
  }, [setProductParams, productType]);

  useEffect(() => {
    setStep(step);
    setTheme(theme);
  }, [productType, setStep, setTheme, step, theme]);

  const addressStore = useAddressStore();
  const energyExpertsStore = useEnergyExpertsStore();
  const genericStore = useGenericStore();
  const setAddressStore = addressStore.setState;
  const setEnergyExpertsStore = energyExpertsStore.setState;
  const setGenericStore = genericStore.setState;

  // if running in OVO Beyond WebView and on first step of booking flow (currently this is always postcode), update store with the injected data
  useEffect(() => {
    if (!(isBeyond(router.asPath) && step === 'postcode')) return;

    const beyondCustomerData = getPreloadedData();

    if (!beyondCustomerData) return;

    const { address, contact, userCanEditDetails } = beyondCustomerData;
    setAddressStore(address);
    setEnergyExpertsStore({
      ...contact,
      customerSource: CustomerSource.BEYOND,
    });
    setGenericStore({
      userCanEditDetails,
    });
  }, [
    router.asPath,
    setAddressStore,
    setEnergyExpertsStore,
    setGenericStore,
    step,
  ]);

  // TODO: use generic store for EE
  const storeData = shouldUseGenericStoreData
    ? genericStore
    : energyExpertsStore;

  const routeToRedirectTo = routeToRedirectIfMissingData
    ? constructPath(router, routeToRedirectIfMissingData)
    : undefined;
  useRedirectIfMissingData<Record<string, unknown>>({
    data: { ...addressStore, ...storeData },
    dataToCheck: currentStep?.options.redirectIfMissingData?.dataToCheck,
    routeToRedirectTo,
    keepQueryParams: false,
    isFlowDisabled,
  });

  if (!currentStep) return;
  if (isFlowDisabled) {
    return <ProductNotAvailable headText={headText} bodyText={bodyText} />;
  }

  const { options, component: componentName } = currentStep;
  const StepComponent = STEP_COMPONENT_BY_NAME[componentName];
  const { subtitle } = options.tracker;

  let mobileBackgroundColor;
  if (currentPartner === PARTNERS.KANTAN) {
    mobileBackgroundColor = '#eff4fc';
  } else if (currentPartner === PARTNERS.OVO) {
    mobileBackgroundColor = '#F3F7F9';
  } else {
    mobileBackgroundColor = options.mobileBackgroundColor;
  }

  return (
    <EnergyExpertsLayout mobileBackgroundColor={mobileBackgroundColor}>
      <Head>
        <title>{subtitle}</title>
      </Head>
      <Tracker
        logoOptions={logo}
        mobileBackgroundColor={mobileBackgroundColor}
      />
      <StepComponent
        {...options}
        isBulkSlotRatingsEnabled={isBulkSlotRatingsEnabled}
      />
    </EnergyExpertsLayout>
  );
};

export default Route;

export async function getServerSideProps() {
  const { configCatClient } = await import('flagClient');
  const isBulkSlotRatingsEnabled = await configCatClient.getValueAsync(
    'bulkSlotRatings',
    false,
  );
  return {
    props: {
      isBulkSlotRatingsEnabled,
    },
  };
}

interface StepComponentProps extends StepOptions {
  isBulkSlotRatingsEnabled: boolean;
}

const STEP_COMPONENT_BY_NAME: Record<
  StepComponentName,
  (props: StepComponentProps) => JSX.Element
> = {
  BookingConfirmation,
  PaymentConfirmation,
  ConfirmAddress,
  IneligibleHomeSetup,
  NoAvailability,
  NotInYourArea,
  Postcode,
  ResidentDetails,
  ReviewBooking,
  SelectAddress,
  Motivation,
  SelectBoilerType,
  SelectFuelType,
  SelectTime,
  SignUpAcknowledge,
  YourHome,
  SignUpAcknowledgeTado,
  NotInYourAreaTado,
  ConfirmTadoPurchase,
};
