import React, { useEffect, useState } from 'react';

import { SlotShape } from '../components/form';

export interface AddressData {
  line1?: string;
  line2?: string;
  town?: string;
  postcode?: string;
}

interface ContactData {
  fullName?: string;
  email?: string;
  phone?: string;
  alternativePhoneNumber?: string;
  customerReferenceId?: string;
}

export interface AppointmentData extends AddressData, ContactData {
  jobType?: string;
  productId?: string;
  problem?: string;
  metadata?: Record<string, string>;
  timeSlots?: string[];
  startDateTime?: string;
  acceptPromos?: boolean;
  acceptTerms?: boolean;
  mainProblem?: string;
  fuelType?: string;
  paymentMethodId?: string;
}

export interface CalloutData extends AddressData, ContactData {
  acceptPromos?: boolean;
  acceptTerms?: boolean;
  jobType?: string;
  mainProblem?: string;
  fuelType?: string;
  additionalInformation?: string;
  paymentMethodId?: string;
  appointmentSlots?: Array<SlotShape>;
  metadata: Record<string, string>;
}

export interface EnergyExpertsData extends AddressData, ContactData {
  acceptPromos?: boolean;
  acceptTerms?: boolean;
  jobType?: string;
  productId?: string;
  motivations?: string;
  timeSlots?: string[];
}

export interface GenericProductData extends AddressData, ContactData {
  acceptPromos?: boolean;
  acceptTerms?: boolean;
  jobType?: string;
  productId?: string;
  metadata?: Record<string, string>;
  timeSlots?: string[];
}

const defaultAddressValues: AddressData = {
  line1: '',
  line2: '',
  town: '',
  postcode: '',
};

const defaultContactValues: ContactData = {
  fullName: '',
  email: '',
  phone: '',
};

export const defaultValue: AppointmentData = {
  jobType: '',
  timeSlots: [],
  mainProblem: '',
  fuelType: '',
  ...defaultAddressValues,
  ...defaultContactValues,
};

export const calloutDefaultValue: CalloutData = {
  jobType: 'heating_repair',
  mainProblem: '',
  fuelType: '',
  additionalInformation: '',
  appointmentSlots: [],
  ...defaultAddressValues,
  ...defaultContactValues,
  metadata: {},
};

export const usePersistedAppointmentData = (
  overrideData: Partial<AppointmentData> = {},
) => {
  return usePersistedState('appointmentData', defaultValue, overrideData);
};

export const usePersistedCalloutData = (
  overrideData: Partial<CalloutData> = {},
) => {
  return usePersistedState('calloutData', calloutDefaultValue, overrideData);
};

export function usePersistedState<T>(
  key: string,
  defaultData: T,
  overrideData: Partial<T>,
) {
  const [state, setState] = React.useState<T>({
    ...defaultData,
    ...overrideData,
  });

  const [hasStateHydrated, setHasStateHydrated] = useState(false);

  useEffect(() => {
    if (hasStateHydrated) {
      localStorage.setItem(key, JSON.stringify(state));
    } else {
      const persistedData = JSON.parse(localStorage.getItem(key) || '""');
      const val = {
        ...(persistedData || defaultData),
        ...overrideData,
      };
      setState(val);
      setHasStateHydrated(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, state]);

  return [state, setState, hasStateHydrated] as const;
}
