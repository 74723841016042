import { css } from '@emotion/react';

const fontImport = css`
  @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;600;700&display=swap');
`;

const fontFamily = "'IBM Plex Sans', sans-serif";
const fontFamilyHeadings = "'IBM Plex Sans', sans-serif";

const fontWeight = {
  lightest: 300,
  light: 400,
  regular: 500,
  semiBold: 600,
  bold: 700,
};

type FontScale =
  | 12
  | 14
  | 16
  | 18
  | 20
  | 24
  | 26
  | 28
  | 32
  | 36
  | 42
  | 48
  | 52
  | 54
  | 60
  | 80;

const fontSize: Record<FontScale, string> = {
  12: '12px',
  14: '14px',
  16: '16px',
  18: '18px',
  20: '20px',
  24: '24px',
  26: '26px',
  28: '28px',
  32: '32px',
  36: '36px',
  42: '42px',
  48: '48px',
  52: '52px',
  54: '54px',
  60: '60px',
  80: '80px',
};

export { fontImport, fontFamily, fontFamilyHeadings, fontWeight, fontSize };
