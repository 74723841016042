import { Icon } from 'theme/types';

export const OilOVOIcon: Icon = (props) => {
  const { color, ...svgProps } = props;

  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        opacity="0.3"
        d="M38.5556 30.3333C38.5556 32.6556 36.5501 34.5556 34.1223 34.5556C31.6945 34.5556 29.689 32.6556 29.689 30.3333C29.689 28.0111 34.1223 24 34.1223 24C34.1223 24 38.5556 28.0111 38.5556 30.3333Z"
        fill={color || '#0A9828'}
      />
      <path
        d="M26.3111 13.4444C22.9333 13.4444 19.8722 14.7111 17.55 16.7167V9.22222H22.7222V5H9V9.22222H13.1167V26.1111V29.2778V38.7778V43H17.55V38.7778V26.1111C17.55 21.4667 21.4556 17.6667 26.3111 17.6667C28.6333 17.6667 30.85 18.5111 32.5389 20.0944L35.6 17.1389C33.1722 14.7111 29.7944 13.4444 26.3111 13.4444Z"
        fill={color || '#0A9828'}
      />
    </svg>
  );
};
