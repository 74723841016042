import styled from '@emotion/styled';
import { Typography } from '@mui/material';

import { StrongText } from 'components/StrongText';

import {
  AlignContent,
  ResponsiveContainer,
  SectionHeader,
} from './utilityStyles';

const NeedMoreHelpContainer = styled(ResponsiveContainer)`
  background-color: ${({ theme }) => theme.app.background.selected};
  padding: 40px ${({ theme }) => theme.app.padding.mediumPadding};

  @media (min-width: 680px) {
    border-radius: ${({ theme }) => theme.app.borderRadius.extraLargeCorner};
  }
`;

const NeedMoreHelpHeader = styled(SectionHeader)`
  color: ${({ theme }) => theme.app.text.white};
`;

const ContactInformation = styled(AlignContent)`
  color: ${({ theme }) => theme.app.text.white};
  margin-top: ${({ theme }) => theme.app.margin.largeMargin};

  > address {
    margin-top: ${({ theme }) => theme.app.margin.mediumLargeMargin};
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[24]};
  }

  @media (min-width: 680px) {
    > address {
      display: flex;
      flex-flow: column;
      align-items: center;
    }
  }
`;

const AddressSectionCaption = styled(Typography)`
  color: inherit;
`;

export const NeedMoreHelpSection = () => {
  return (
    <AlignContent>
      <NeedMoreHelpContainer>
        <AlignContent>
          <NeedMoreHelpHeader>
            Need more <StrongText>help?</StrongText>
          </NeedMoreHelpHeader>
          <ContactInformation>
            <AddressSectionCaption variant="caption">
              Send us an email at
            </AddressSectionCaption>
            <address>
              <a href="mailto:support@kantan.tech">
                <StrongText>support@kantan.tech</StrongText>
              </a>
            </address>
          </ContactInformation>
          <ContactInformation>
            <AddressSectionCaption variant="caption">
              Call us
            </AddressSectionCaption>
            <address>
              <a href="tel:+443308188759">
                <StrongText>+44 330 818 8759</StrongText>
              </a>
              <br />
              <span>(Monday-Friday 9am-5pm)</span>
            </address>
          </ContactInformation>
        </AlignContent>
      </NeedMoreHelpContainer>
    </AlignContent>
  );
};
