export const contactDetailEntryOptions = [
  {
    name: 'fullName',
    label: 'Full name',
    required: true,
    canBeDisabled: false,
  },
  {
    name: 'email',
    label: 'Email address',
    required: true,
    canBeDisabled: true,
    type: 'email',
  },
  {
    name: 'phone',
    label: 'Phone number',
    type: 'tel',
    required: true,
    canBeDisabled: false,
  },
  {
    name: 'alternativePhoneNumber',
    label: 'Alternative phone number',
    type: 'tel',
    required: false,
    canBeDisabled: false,
  },
];
