import styled from '@emotion/styled';
import { MenuList } from '@mui/material';
import { useSelect } from 'downshift';
import { mediaQuery } from 'theme/kantan';

import { Loading } from 'components/Loading/Loading';
import {
  AddressData,
  BasicAddress,
  NestedAddress,
  NoAddresses,
} from 'components/marketplace/PostcodeLookup';
import { AddCategory, AddressSuggestion } from 'utils/useSearchAddress';

type AddressPickerProps = {
  suggestedAddresses: AddressSuggestion[];
  selectAddress: (address: AddressData) => void;
  closeMenu: () => void;
  addCategory: AddCategory;
  loading: boolean;
};

export const AddressPicker = ({
  suggestedAddresses,
  selectAddress,
  closeMenu,
  addCategory,
  loading,
}: AddressPickerProps) => {
  const { getToggleButtonProps, getMenuProps, getItemProps, highlightedIndex } =
    useSelect({
      items: suggestedAddresses,
    });

  return (
    <AddressPickerContainer
      {...getMenuProps({
        id: 'select-address-menu',
        'aria-labelledby': 'InfoText',
      })}
    >
      {/* TODO: fix keyboard navigation of the address picker's menu list */}
      <button
        style={{ display: 'none' }}
        {...getToggleButtonProps({
          id: 'select-address-button',
          'aria-labelledby': 'none',
        })}
      ></button>
      {loading ? (
        <Loading loadingText="" short />
      ) : (
        <StyledMenuList autoFocusItem={true}>
          {suggestedAddresses.length === 0 && <NoAddresses />}
          {suggestedAddresses.map((address: AddressSuggestion, index: number) =>
            address.type === 'ADD' ? (
              <BasicAddress
                key={address.id}
                item={address}
                selectAddress={selectAddress}
                getItemProps={getItemProps}
                shouldBeHighlighted={highlightedIndex === index}
                closeMenu={closeMenu}
                index={index}
              />
            ) : (
              <NestedAddress
                key={address.id}
                item={address}
                addCategory={addCategory}
                getItemProps={getItemProps}
                shouldBeHighlighted={highlightedIndex === index}
              />
            ),
          )}
        </StyledMenuList>
      )}
    </AddressPickerContainer>
  );
};

export const InfoText = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.app.spacing[16]};
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[18]};

  & > * {
    margin: 0 2px;
  }

  @media (${mediaQuery('tablet')}) {
    margin-bottom: ${({ theme }) => theme.app.spacing[32]};
  }
`;

const AddressPickerContainer = styled.div`
  width: 100%;
  max-width: 688px;
  height: 45vh;
  overflow: hidden;
  border: 1px solid;
  border-color: ${({ theme }) => theme.app.colors.blue.lightest};
  background-color: ${({ theme }) => theme.app.background.white};

  @media (${mediaQuery('tablet')}) {
    height: 184px;
  }
`;

const StyledMenuList = styled(MenuList)`
  display: block;
  box-shadow: 0.05em 0.2em 0.6em rgba(0, 0, 0, 0.2);
  height: 100%;
  max-width: 100%;
  overflow-y: auto;
  z-index: 1000;
`;
