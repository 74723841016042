import { ManagedRate } from '../services/models/ManagedRate';
import { JOB_TYPES } from './jobTypes';
import { getPriceFromJobType } from './tracking';

export const formatPrice = (price: string) => {
  const integerPrice = price.substring(0, price.length - 3);
  const decimalsPrice = price.split('.')[1];
  const doNotShowDecimals = decimalsPrice
    ?.split('')
    .every((char) => char === '0');

  return !doNotShowDecimals ? `${integerPrice}.${decimalsPrice}` : integerPrice;
};

export const getDiscountAndFullPrice = (
  managedRates?: Record<JOB_TYPES, ManagedRate>,
) => {
  const boilerServicePrice = getPriceFromJobType(
    JOB_TYPES.BOILER_SERVICE,
    managedRates,
  );
  const gasSafetyPrice = getPriceFromJobType(
    JOB_TYPES.GAS_SAFETY_CERTIFICATE,
    managedRates,
  );
  const comboPrice = getPriceFromJobType(
    JOB_TYPES.BOILER_SERVICE_COMBO,
    managedRates,
  );

  const fullComboPrice = (
    +boilerServicePrice.amount + +gasSafetyPrice.amount
  ).toFixed(2);
  const discountAmount = (+fullComboPrice - +comboPrice.amount).toFixed(2);

  const fullComboPriceRounded = (
    +boilerServicePrice.amount + +gasSafetyPrice.amount
  ).toFixed(0);
  const discountAmountRounded = (+fullComboPrice - +comboPrice.amount).toFixed(
    0,
  );

  return {
    fullComboPrice,
    discountAmount,
    fullComboPriceRounded,
    discountAmountRounded,
    isPromoComboRate: comboPrice.isPromoRate,
    prePromoComboAmount: comboPrice.prePromoAmount,
    promoComboDiscount: (
      parseFloat(comboPrice.prePromoAmount || '0') -
      parseFloat(comboPrice.amount || '0')
    ).toFixed(0),
  };
};
