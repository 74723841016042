import styled from '@emotion/styled';
import { FormControl, InputBase, InputLabel } from '@mui/material';
import { ChangeEvent, useRef } from 'react';
import { FieldValues, useController, ValidateResult } from 'react-hook-form';
import { mediaQuery } from 'theme/kantan';

import { getFieldValidators } from 'utils/fieldValidation';
import { shouldForwardProp } from 'utils/transientStyled';

interface MuiTextFieldProps {
  option: Required<FieldValues>;
  handleFieldChange: (event: InputChangeEvent, onChange: OnChange) => void;
  placeholder?: string;
  showAsterisk?: boolean;
  disabled?: boolean;
}

export type InputChangeEvent = ChangeEvent<
  HTMLInputElement | HTMLTextAreaElement
>;

type OnChange = (...event: any[]) => void;

export const MuiTextField = ({
  option,
  handleFieldChange,
  placeholder,
  showAsterisk = true,
  disabled = false,
}: MuiTextFieldProps) => {
  const { name, label, required, type, maxLength } = option;

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    rules: {
      validate: validateField,
    },
  });

  const isValid = !error;

  const timer = useRef<ReturnType<typeof setTimeout>>();

  function validateField(value: unknown) {
    const validators = getFieldValidators({ required, type, timer, name });

    for (const validate of validators) {
      const error = validate(value);
      if (error) {
        return error as ValidateResult;
      }
    }
  }

  return (
    <BootstrapFormControl variant="standard" required={required}>
      <BootstrapLabel shrink $showAsterisk={showAsterisk}>
        {label}
      </BootstrapLabel>
      <BootstrapInput
        id="bootstrap-input"
        type={type}
        {...field}
        error={!isValid}
        onChange={(e: InputChangeEvent) => handleFieldChange(e, field.onChange)}
        placeholder={placeholder}
        disabled={disabled}
        inputProps={{ maxLength }}
      />
      {error?.message && <ErrorMessage>{error.message}</ErrorMessage>}
    </BootstrapFormControl>
  );
};

const BootstrapFormControl = styled(FormControl)`
  & label.Mui-focused {
    color: ${({ theme }) => theme.app.text.tertiary};
  }
`;

const BootstrapLabel = styled(InputLabel, { shouldForwardProp })<{
  $showAsterisk?: boolean;
}>`
  color: ${({ theme }) => theme.app.text.tertiary};

  .MuiFormLabel-asterisk {
    display: ${({ $showAsterisk }) => ($showAsterisk ? undefined : 'none')}
    color: #e92c2c;
  }
`;

const BootstrapInput = styled(InputBase)`
  label + & {
    margin-top: ${({ theme }) => theme.app.spacing[12]};
  }
  & .MuiInputBase-input {
    border-bottom: 1px solid
      ${({ error, theme }) =>
        !error ? theme.app.colors.green.ctaButton : theme.app.messages.error};
    font-size: ${({ theme }) => theme.app.fontSettings.fontSize[14]};
    padding: 5px 0 6px;
    border-radius: 0;
  }
  @media (${mediaQuery('tablet')}) {
    & .MuiInputBase-input {
      font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
    }
  }
`;

const ErrorMessage = styled.p`
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[12]};
  line-height: ${({ theme }) => theme.app.spacing[16]};
  color: #e92c2c;
  margin-top: ${({ theme }) => theme.app.spacing[2]};
`;
