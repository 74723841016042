import { Icon } from 'theme/types';

export const OilIcon: Icon = (props) => {
  const { color, ...svgProps } = props;

  return (
    <svg
      width="25"
      height="32"
      viewBox="0 0 25 32"
      fill="none"
      {...svgProps}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1903 1.09228C15.2279 3.28269 17.9568 6.25131 20.1985 9.43571C22.4401 12.62 24.2541 16.0176 24.2541 19.2711C24.2541 21.2213 23.5325 24.0384 21.7011 26.511C19.8697 28.9838 16.8296 31.0401 12.4865 31.0401C8.1439 31.0401 5.10372 28.9838 3.27197 26.511C1.44034 24.0382 0.71875 21.2212 0.71875 19.2711C0.71875 15.9974 2.62867 12.6135 4.90025 9.43571C7.17183 6.25791 9.8686 3.29025 11.6773 1.1343C12.2645 0.65025 12.6219 0.691526 13.1902 1.09232L13.1903 1.09228ZM12.476 3.30947C10.6854 5.40424 8.46096 7.91429 6.53976 10.6022C4.3646 13.6454 2.73625 16.8287 2.73625 19.2711C2.73625 20.6833 3.35938 23.2359 4.8901 25.3025C6.42082 27.3693 8.75995 29.0222 12.4861 29.0222C16.2122 29.0222 18.5517 27.3691 20.082 25.3025C21.6127 23.2356 22.2359 20.6831 22.2359 19.2711C22.2359 16.8083 20.6852 13.6381 18.548 10.6022C16.6507 7.90685 14.3904 5.38509 12.4752 3.30947H12.476ZM20.5552 17.9262C22.5726 20.6162 18.5379 28.35 12.8224 27.0051C17.8656 24.6512 19.8828 17.9262 20.5552 17.9262Z"
        fill={color || 'black'}
      />
    </svg>
  );
};
