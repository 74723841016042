import { Icon } from 'theme/types';

export const HeatPumpIcon: Icon = (props) => {
  const { color, ...svgProps } = props;

  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path opacity="0.3" d="M43 5H5V31.2248H43V5Z" fill={color || '#0A9828'} />
      <path d="M43 31.2249H5V38.4156H43V31.2249Z" fill={color || '#0A9828'} />
      <path
        d="M9.30898 42.6452V38.4153H17.5358L9.30898 42.6452Z"
        fill={color || '#0A9828'}
      />
      <path
        d="M38.6906 42.6452V38.4153H30.4638L38.6906 42.6452Z"
        fill={color || '#0A9828'}
      />
      <path
        d="M25.4512 19.068C26.2659 19.385 26.9683 19.9599 27.4626 20.7142C27.9569 21.4685 28.2191 22.3655 28.2133 23.283C28.2082 24.456 27.765 25.5795 26.98 26.409C26.1951 27.2385 25.1319 27.7069 24.0218 27.7122H23.9204V20.4713C23.6196 21.3327 23.0747 22.0751 22.3601 22.5974C21.6455 23.1198 20.7959 23.3966 19.927 23.3901C18.8165 23.3834 17.7533 22.9139 16.9685 22.0836C16.1837 21.2533 15.7406 20.1293 15.7355 18.9558V18.8486H22.5877C21.7729 18.5316 21.0705 17.9568 20.5762 17.2025C20.0819 16.4482 19.8198 15.5511 19.8255 14.6337C19.8319 13.4601 20.2762 12.3367 21.0619 11.5073C21.8476 10.678 22.9113 10.2098 24.0218 10.2044H24.1232V17.4453C24.4232 16.5843 24.9672 15.8421 25.681 15.3197C26.3948 14.7974 27.2436 14.5204 28.1118 14.5265C29.2224 14.5319 30.286 15.0001 31.0718 15.8294C31.8575 16.6588 32.3018 17.7822 32.3081 18.9558V19.068H25.4512ZM24.0025 9.22978C21.563 9.23651 19.2252 10.2632 17.4998 12.0856C15.7743 13.908 14.8016 16.3779 14.7939 18.9558C14.8003 21.5345 15.7725 24.0058 17.4981 25.8293C19.2236 27.6527 21.5622 28.6801 24.0025 28.6869C25.2117 28.6875 26.4093 28.4362 27.5265 27.9474C28.6438 27.4585 29.6589 26.7417 30.5137 25.8379C31.3685 24.9341 32.0464 23.861 32.5084 22.6801C32.9704 21.4992 33.2076 20.2336 33.2063 18.9558C33.1987 16.3823 32.2292 13.9162 30.509 12.0945C28.7888 10.2729 26.4571 9.24323 24.0218 9.22978H24.0025Z"
        fill={color || '#0A9828'}
      />
    </svg>
  );
};
