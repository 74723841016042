import styled from '@emotion/styled';
import { m } from 'framer-motion';

import { shouldForwardProp } from 'utils/transientStyled';

export const RadioContainer = styled(m.div, { shouldForwardProp })<{
  $isSelected: boolean;
}>`
  box-shadow: ${({ theme }) => theme.app.boxShadow.standard};
  background-color: ${({ theme }) => theme.app.background.white};
  border-radius: ${({ theme }) => theme.app.borderRadius.largeCorner};
  border: 1px solid ${({ theme }) => theme.app.colors.grey.light};
  cursor: pointer;

  ${({ theme, $isSelected }) =>
    $isSelected &&
    `
      background-color: ${theme.app.background.selectedDark};

      h3 {
        color: ${theme.app.text.white};
        font-weight: ${theme.app.fontSettings.fontWeight.semiBold};
      }
    `};
`;

export const InputContainer = styled.div`
  position: relative;
  height: 100%;
  margin: 0 -1px;
`;

export const Input = styled.input`
  appearance: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: ${({ theme }) => theme.app.borderRadius.smallCorner};
  z-index: -1;

  @supports not (appearance: none) {
    border: none;
  }
`;
