import { Icon } from 'theme/types';

export const LPGIcon: Icon = (props) => {
  const { color, ...svgProps } = props;

  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      {...svgProps}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3458 2.58772C17.3084 6.92522 16.3614 10.1502 14.3178 12.8627C11.9751 15.9752 11.2274 17.2752 10.5421 19.5127C8.95954 24.6127 10.9159 29.7002 15.3271 31.9377C17.0218 32.8002 19.4517 33.1002 21.4455 32.7002C25.7197 31.8252 28.648 28.3127 29.4331 23.1252C29.6574 21.6752 29.4829 19.1377 29.0468 17.3377C28.8349 16.4502 28.5857 15.5502 28.486 15.3377C28.3988 15.1127 28.187 14.6252 28.0374 14.2502C27.2898 12.3752 26.3676 10.7377 24.8349 8.50022C24.3614 7.81272 22.9658 6.11272 21.7944 4.80022C20.5608 3.40022 17.6324 0.500217 17.458 0.500217C17.4081 0.500217 17.3583 1.43772 17.3458 2.58772ZM21.1091 17.3627C21.3458 18.4627 21.8443 19.4877 22.5795 20.4252C23.4891 21.5752 23.539 21.6502 23.9502 22.6127C24.3365 23.5002 24.3614 23.6627 24.3614 24.9377C24.3614 26.2002 24.324 26.3627 24 26.9877C22.8785 29.1502 20.5234 30.1502 18.4299 29.3627C16.7228 28.7127 15.4642 27.0002 15.2274 25.0002C14.8536 21.9502 16.2617 18.9002 19.6885 15.3377L20.7477 14.2252L20.8225 15.3377C20.8598 15.9377 20.9969 16.8502 21.1091 17.3627Z"
        fill={color || '#292B2E'}
      />
      <path
        d="M12.7103 35.0752C12.162 35.1877 10.5545 35.7627 10.2555 35.9627C9.99379 36.1377 9.96887 36.2502 9.96887 37.0752C9.96887 38.0377 10.0935 38.2752 10.4424 37.9752C10.9658 37.5252 13.0717 37.0002 14.3053 37.0002C15.1776 37.0002 16.8972 37.3127 18.5421 37.7627C23.2524 39.0502 23.9003 39.1627 25.7321 39.0877C27.2025 39.0377 27.6636 38.9377 28.972 38.3752L29.5327 38.1377V37.1127C29.5327 36.2002 29.5078 36.1127 29.3209 36.1877C27.3645 37.0002 27.1651 37.0377 25.296 37.0377C23.377 37.0502 23.053 36.9877 19.0031 35.8377C16.7975 35.2127 16.1371 35.1002 14.5795 35.0627C13.7197 35.0377 12.8848 35.0502 12.7103 35.0752Z"
        fill={color || '#292B2E'}
      />
    </svg>
  );
};
