import styled from '@emotion/styled';
import { m } from 'framer-motion';
import { mediaQuery } from 'theme/kantan';

import { shouldForwardProp } from 'utils/transientStyled';

import {
  Input,
  InputContainer,
} from '../ServiceSelectionRadioButton/ServiceSelectionRadioButton.styles';

export const RadioContainer = styled(m.div, { shouldForwardProp })<{
  $isSelected: boolean;
}>`
  box-shadow: ${({ theme }) => theme.app.boxShadow.standard};
  background-color: ${({ theme }) => theme.app.background.white};
  border-radius: ${({ theme }) => theme.app.borderRadius.largeCorner};
  border: 1px solid ${({ theme }) => theme.app.colors.grey.light};
  padding: 10px;
  cursor: pointer;

  ${({ theme, $isSelected }) =>
    $isSelected &&
    `
    background-color: ${theme.app.background.selectedDark};
    color: ${theme.app.text.white};
    font-weight: ${theme.app.fontSettings.fontWeight.bold};
    `}

  @media (${mediaQuery('tablet')}) {
    min-height: 85px;
    min-width: 120px;
    flex-grow: 1;
  }
`;

export const CalloutInputContainer = styled(InputContainer)`
  margin: unset;
`;

export const CalloutInput = styled(Input)``;

export const InnerContainer = styled.div<{ $isFuel?: boolean }>`
  height: inherit;
  border-radius: ${({ theme }) => theme.app.borderRadius.smallCorner};

  label {
    cursor: pointer;
  }

  > div {
    display: none;
  }

  @media (${mediaQuery('tablet')}) {
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      display: unset;
    }

    > p {
      margin-left: ${({ theme }) => theme.app.margin.smallMargin};
    }

    ${({ $isFuel }) =>
      $isFuel &&
      `
      flex-direction: column;
    `}
  }
`;
