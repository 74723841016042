import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import Image from 'next/image';
import { useRouter } from 'next/router';
import tickCircleImageKantan from 'public/assets/svg/kantan/TickCircle.svg';
import tickCircleImageOVO from 'public/assets/svg/ovo/TickCircle.svg';

import {
  AppointmentSummaryTitle,
  Divider,
} from 'components/AppointmentSummary/AppointmentSummary';
import { CalloutDisclaimer } from 'components/redesign/CalloutDisclaimer';
import { useExistingStepContext } from 'pages/[productType]/[rateDomain]/[step]';
import { JOB_TYPES, JOB_TYPES_MAP, REPAIR_JOB_TYPES } from 'utils/jobTypes';
import { currentPartner, PARTNERS } from 'utils/partners';
import { getPriceFromJobType } from 'utils/tracking';
import { useManagedRates } from 'utils/useManagedRates';

const PriceSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PriceContent = styled.span`
  color: ${({ theme }) => theme.app.landingPage.light.title};
  font-size: ${({ theme }) => theme.app.fontSettings.fontSize[16]};
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.bold};
`;

const DiscountContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.app.margin.extraLargeMargin}};
`;

const DiscountText = styled.p`
  font-size: 10px;
  margin-left: 6px;
  color: ${({ theme }) => theme.app.reviewPage.text.summary};
  font-weight: ${({ theme }) => theme.app.fontSettings.fontWeight.semiBold};
`;

export const PaymentSummaryOld = ({ jobType }: { jobType: string }) => {
  const theme = useTheme();
  const managedRates = useManagedRates();
  const context = useExistingStepContext();
  const {
    productConfig: { discountLabel, temporaryJobType },
  } = context;

  const {
    query: { product_type },
  } = useRouter();

  const price = getPriceFromJobType(jobType, managedRates);
  const isRepair = REPAIR_JOB_TYPES.includes(temporaryJobType);
  return (
    <>
      <AppointmentSummaryTitle>Payment information</AppointmentSummaryTitle>
      <Divider />
      <PriceSection>
        <PriceContent>
          {jobType === JOB_TYPES.HEATING_REPAIR
            ? 'Boiler Repair Visit'
            : JOB_TYPES_MAP[jobType]}
        </PriceContent>
        <PriceContent>
          {price.isPromoRate && (
            <>
              <Typography
                fontSize="inherit"
                sx={{ textDecoration: 'line-through' }}
                component="span"
                color={theme.app.text.tertiary}
              >
                £{price.prePromoAmount || price.amount}
              </Typography>{' '}
            </>
          )}
          £{price.amount}
          {price.isAmountWithVat ? ' inc. VAT' : ` exc. VAT`}
          {isRepair && '*'}
        </PriceContent>
      </PriceSection>
      {isRepair && (
        <CalloutDisclaimer
          disclaimerPrice={price.amount}
          isAmountWithVat={price.isAmountWithVat}
          partner="kantan"
          isPayment
        />
      )}
      {discountLabel && (
        <DiscountContainer>
          <Image
            src={
              currentPartner === PARTNERS.KANTAN
                ? tickCircleImageKantan
                : tickCircleImageOVO
            }
            alt="Boiler Repair"
            width={14}
            height={14}
          />
          <DiscountText>{discountLabel}</DiscountText>
        </DiscountContainer>
      )}
    </>
  );
};
