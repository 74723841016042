import { Icon } from 'theme/types';

export const BoilerNotWorkingIcon: Icon = (props) => {
  const { color, ...svgProps } = props;

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.55512 1.16537C7.9287 0.724915 6.19663 1.17705 5.00408 2.36959C3.90973 3.46394 3.4357 5.01638 3.71131 6.529L1.8541 8.38621C0.798633 9.44168 0.798633 11.1529 1.8541 12.2084C2.90957 13.2639 4.62082 13.2639 5.67629 12.2084L7.53349 10.3512C9.04611 10.6268 10.5986 10.1528 11.6929 9.05842C12.8772 7.87409 13.3318 6.15725 12.9056 4.53908C12.8998 4.51712 12.8939 4.4952 12.8878 4.47331C12.772 4.05824 12.252 3.9217 11.9473 4.2264L10.3216 5.85209L8.57586 5.48878L8.23846 3.77128L9.88154 2.1282C10.1842 1.82549 10.0518 1.30908 9.64077 1.18943C9.6123 1.18114 9.58375 1.17312 9.55512 1.16537ZM5.80037 3.16588C6.47055 2.4957 7.3665 2.13234 8.29098 2.12618L7.31485 3.10231C7.12915 3.28801 7.04812 3.55398 7.09875 3.81167L7.51407 5.92581C7.57517 6.23684 7.81663 6.48102 8.12696 6.54561L10.2708 6.99177C10.531 7.04592 10.8009 6.96534 10.9888 6.77743L11.9354 5.83081C11.9145 6.73454 11.5523 7.60644 10.8966 8.26213C10.0448 9.11399 8.82572 9.47238 7.64329 9.2253L7.16645 9.12566L4.88 11.4121C4.26431 12.0278 3.26608 12.0278 2.65039 11.4121C2.0347 10.7964 2.0347 9.79819 2.65039 9.1825L4.93684 6.89605L4.8372 6.41921C4.59011 5.23678 4.94851 4.01774 5.80037 3.16588ZM3.70117 9.67114C3.42503 9.67114 3.20117 9.895 3.20117 10.1711V10.2973C3.20117 10.5734 3.42503 10.7973 3.70117 10.7973H3.82729C4.10344 10.7973 4.32729 10.5734 4.32729 10.2973V10.1711C4.32729 9.895 4.10344 9.67114 3.82729 9.67114H3.70117Z"
        fill={color || '#292B2E'}
      />
      <path
        d="M9.33337 15.9999C9.33337 19.6818 12.3181 22.6666 16 22.6666C19.6819 22.6666 22.6667 19.6818 22.6667 15.9999C22.6667 12.318 19.6819 9.33325 16 9.33325C12.3181 9.33325 9.33337 12.318 9.33337 15.9999ZM10.6667 15.9999C10.6667 13.0544 13.0545 10.6666 16 10.6666C18.9456 10.6666 21.3334 13.0544 21.3334 15.9999C21.3334 18.9454 18.9456 21.3333 16 21.3333C13.0545 21.3333 10.6667 18.9454 10.6667 15.9999ZM16.3651 16.7106C16.3278 16.9533 16.1318 17.1679 15.8611 17.1679H15.7864C15.5158 17.1679 15.3198 16.9626 15.2824 16.7106L15.1891 16.0759C15.1424 15.7679 15.3198 15.5533 15.6184 15.5159C16.4304 15.4133 16.8038 15.1613 16.8038 14.7039V14.6853C16.8038 14.2653 16.4864 14.0226 15.9451 14.0226C15.5438 14.0226 15.1984 14.1439 14.8624 14.3959C14.7504 14.4799 14.6011 14.5453 14.4424 14.5453C14.0878 14.5453 13.7891 14.2466 13.7891 13.8919C13.7891 13.7146 13.8638 13.5279 14.0318 13.3879C14.5264 12.9773 15.1518 12.7159 15.9731 12.7159C17.3171 12.7159 18.2318 13.4066 18.2318 14.6479V14.6666C18.2318 15.8519 17.4104 16.3373 16.3931 16.5426L16.3651 16.7106ZM14.9744 18.5959V18.5773C14.9744 18.1293 15.3198 17.7839 15.7864 17.7839C16.2531 17.7839 16.5984 18.1293 16.5984 18.5773V18.5959C16.5984 19.0439 16.2531 19.3893 15.7864 19.3893C15.3198 19.3893 14.9744 19.0439 14.9744 18.5959Z"
        fill={color || '#292B2E'}
      />
    </svg>
  );
};
