import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { TextInput } from 'components/TextInput';
import { getFieldValidators } from 'utils/fieldValidation';
import { isNullOrUndefined } from 'utils/isNullOrUndefined';

interface TextFieldProps {
  name: string;
  label: string;
  type?: string;
  autoComplete?: string;
  required?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  customErrorMessage?: string;
}

export const TextField = ({
  name,
  label,
  required,
  onChange,
  type,
  autoComplete,
  customErrorMessage,
}: TextFieldProps) => {
  const form = useFormContext();

  function validateField(value: unknown) {
    const validators = getFieldValidators({ required, type });

    for (const validate of validators) {
      const error = validate(value);

      if (error) {
        return error;
      }
    }
  }

  const getErrorMessage = (fieldState?: string) => {
    switch (true) {
      case customErrorMessage && !!fieldState:
        return customErrorMessage;
      case !!fieldState:
        return fieldState;
      default:
        return undefined;
    }
  };

  return (
    <Controller
      name={name}
      control={form.control}
      rules={{ validate: validateField }}
      render={({ field, fieldState }) => (
        <TextInput
          {...field}
          label={label}
          required={required}
          type={type}
          autoComplete={autoComplete}
          helperText={getErrorMessage(fieldState.error?.message)}
          invalid={!!fieldState.error}
          valid={
            required
              ? isNullOrUndefined(validateField(field.value))
              : field.value && isNullOrUndefined(validateField(field.value))
          }
          onChange={(e) => {
            onChange?.(e);
            field.onChange(e);
          }}
        />
      )}
    />
  );
};
