import Image from 'next/image';

import { LoadingIcon, LoadingWrapper } from './Loading.styles';

interface LoadingProps {
  loadingText?: string;
  short?: boolean;
  useTheme?: boolean;
}

export const Loading = ({
  loadingText = 'Finding the best slots for you...',
  short = false,
  useTheme = true,
}: LoadingProps) => {
  return (
    <LoadingWrapper $short={short} $useTheme={useTheme}>
      <LoadingIcon>
        <Image
          src={`/assets/svg/LoadingIcon.svg`}
          alt="Loading..."
          width={42}
          height={42}
        />
      </LoadingIcon>
      <p>{loadingText}</p>
    </LoadingWrapper>
  );
};
